import { Box, SxProps, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import {
  ClassroomPostFilter,
  ClassroomPostsProvider,
  useClassroomPostsContext
} from "../../../providers/ClassroomPostsProvider";
import LearnerClassPostList from "../ClassPostList";

interface ClassroomAnnouncementsProps {
  sx?: SxProps;
  titleRef?: React.RefObject<HTMLSpanElement>;
}
export const ClassroomAnnouncements = ({
  sx,
  titleRef
}: ClassroomAnnouncementsProps) => (
  <ClassroomPostsProvider postsFilter={ClassroomPostFilter.Announcements}>
    <AnnouncementContent sx={sx} titleRef={titleRef} />
  </ClassroomPostsProvider>
);

interface AnnouncementContentProps {
  sx?: SxProps;
  titleRef?: React.RefObject<HTMLSpanElement>;
}
const AnnouncementContent = ({ sx, titleRef }: AnnouncementContentProps) => {
  const { loading, posts } = useClassroomPostsContext();

  if (loading || !posts || posts.length === 0) {
    return null;
  }

  return (
    <Box
      sx={[
        { display: "flex", flexDirection: "column", gap: 16 },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <Typography variant="h3" ref={titleRef}>
        Announcements
      </Typography>
      <LearnerClassPostList />
    </Box>
  );
};
