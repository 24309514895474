import { OAUTH2_SESSION_TOKEN } from "@outschool/auth-shared";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useQueryParams } from "@patched/hookrouter";
import Cookies from "js-cookie";
import React from "react";

import * as Routes from "../../lib/Routes";
import { useLearnerAuth } from "../../providers/LearnerAuthProvider";

export default function RedirectAfterLogin() {
  const { setSessionToken } = useLearnerAuth();
  const [queryParams] = useQueryParams();
  React.useEffect(() => {
    const redirect = queryParams.redirect || Routes.dashboardPath();
    const sessionToken = Cookies.get(OAUTH2_SESSION_TOKEN);
    Cookies.remove(OAUTH2_SESSION_TOKEN);
    if (sessionToken) {
      setSessionToken({ sessionToken }); // TODO: Should this have refreshToken passed through?
    }
    window?.location.replace(redirect);
  }, [queryParams.redirect, setSessionToken]);
  return null;
}
