// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Image, Typography } from "@outschool/backpack";
import React from "react";

import caughtUpStar from "./caught-up-star.png";

export default function NotificationsReadBanner() {
  return (
    <Box
      flex
      sx={{
        flexDirection: "column"
      }}
    >
      <Image
        src={caughtUpStar}
        sx={{
          margin: "auto",
          width: 166
        }}
      />
      <Typography
        variant="h5"
        align="center"
        sx={{
          marginTop: "10px"
        }}
      >
        You've caught up with your notifications
      </Typography>
      <Box
        sx={{
          height: "1px",
          backgroundColor: "#f1f2f3",
          marginTop: "1em",
          marginBottom: "2em"
        }}
      />
    </Box>
  );
}
