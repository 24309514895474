import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";

import {
  LearnerKeywordSearchResultsQuery,
  LearnerKeywordSearchResultsQueryVariables
} from "../generated/graphql";
import LearnerActivitySearchResultFragment from "./LearnerActivitySearchResultFragment";

export const LearnerKeywordSearchResults = gql`
  query LearnerKeywordSearchResults(
    $query: String
    $cursor: String
    $activityExperiences: [ActivityExperience!]
  ) {
    learnerSearchKeyword(
      search: { query: $query, activityExperiences: $activityExperiences }
      cursor: $cursor
    ) {
      cursor
      activities {
        ...LearnerActivitySearchResultFragment
      }
    }
  }
  ${LearnerActivitySearchResultFragment}
`;

export function useLearnerKeywordSearchResults(
  variables: LearnerKeywordSearchResultsQueryVariables
) {
  const { loading, error, data, fetchMore } = useQueryWithPreviousData<
    LearnerKeywordSearchResultsQuery,
    LearnerKeywordSearchResultsQueryVariables
  >(LearnerKeywordSearchResults, {
    variables,
    notifyOnNetworkStatusChange: true
  });
  const [canFetchMore, setCanFetchMore] = useState<boolean>(true);
  const activities = useMemo(
    () => data?.learnerSearchKeyword.activities || [],
    [data?.learnerSearchKeyword.activities]
  );
  const cursor = data?.learnerSearchKeyword.cursor ?? undefined;

  useEffect(() => {
    setCanFetchMore(true);
  }, [variables.query, variables.activityExperiences]);

  useEffect(() => {
    if ((!activities.length || !cursor) && !loading) {
      setCanFetchMore(false);
    }
  }, [activities, cursor, loading, setCanFetchMore]);

  const fetchMoreActivities = () =>
    fetchMore({
      variables: {
        ...variables,
        cursor
      },
      updateQuery: (
        previousResult: LearnerKeywordSearchResultsQuery,
        { fetchMoreResult }
      ) => {
        const moreActivities =
          fetchMoreResult?.learnerSearchKeyword?.activities;
        const cursor = fetchMoreResult?.learnerSearchKeyword?.cursor;
        if (!moreActivities || !cursor) {
          setCanFetchMore(false);
          return previousResult;
        }

        return {
          learnerSearchKeyword: {
            ...previousResult?.learnerSearchKeyword,
            activities: _.uniqBy([...activities, ...moreActivities], "uid"),
            cursor
          }
        };
      }
    });
  return {
    loading,
    error,
    fetchMoreActivities,
    activities,
    canFetchMore,
    isInfiniteScrollEnabled: Boolean(
      canFetchMore && activities.length > 0 && cursor && !loading && !error
    ),
    isFetchingInitial: loading && activities.length === 0,
    isFetchingMore: loading && activities.length > 0
  };
}
