import { Box, SxProps } from "@outschool/backpack";
import { CurrentLearnerFragmentFragment } from "@outschool/gql-frontend-generated";
import { dayjs } from "@outschool/time";
import { gql } from "@outschool/ui-apollo";
import {
  ClassPostFragments,
  ClassPost_ClassPostFragment,
  MeetingPostFragments,
  MeetingPost_MeetingFragment
} from "@outschool/ui-components-classroom";
import { usePreviousValue } from "@outschool/ui-utils";
import React from "react";

import LearnerMeetingPost from "../../components/LearnerMeetingPost";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useClassroomPostsContext } from "../../providers/ClassroomPostsProvider";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import { useLearnerClassroomContext } from "../../providers/LearnerClassroomProvider";
import LearnerClassPost from "./ClassPost";

type ClassPost = ClassPost_ClassPostFragment;
type ClassOrMeetingPost = ClassPost | MeetingPost_MeetingFragment;

const isClassPost = (post: ClassOrMeetingPost): post is ClassPost =>
  post.__typename === "ClassPost";

const sortClassOrMeetingPost =
  (welcomePostsToTop: boolean) =>
  (a: ClassOrMeetingPost, b: ClassOrMeetingPost) => {
    // Welcome posts should always be first
    if (welcomePostsToTop && isClassPost(a) && a.isWelcomePost) {
      return -1;
    }
    if (welcomePostsToTop && isClassPost(b) && b.isWelcomePost) {
      return 1;
    }

    const fieldA = isClassPost(a) ? a.publishAt : a.start_time;
    const fieldB = isClassPost(b) ? b.publishAt : b.start_time;
    return dayjs(fieldB).diff(fieldA);
  };

interface LearnerClassPostListProps {
  sx?: SxProps;
}
function LearnerClassPostList({ sx }: LearnerClassPostListProps) {
  const currentLearner = useCurrentLearner()!;

  const { section: classroomSection } = useLearnerClassroomContext();
  const { posts, section, mentionNames, usersCanComment, handleAddComment } =
    useClassroomPostsContext();

  const meetingPastOrStartingSoon = React.useMemo(() => {
    const meetings = section?.meetings;
    if (!meetings) {
      return [];
    }
    return meetings.filter(meeting =>
      dayjs(meeting.start_time).isBefore(dayjs().add(1, "hour"))
    );
  }, [section]);

  // Only show welcome posts for the first week
  const welcomePostsToTop = dayjs().isBefore(
    dayjs(classroomSection?.start_time).add(1, "week")
  );
  const allPosts = [
    ...(posts ? (posts as ClassPost_ClassPostFragment[]) : []),
    ...(meetingPastOrStartingSoon as MeetingPost_MeetingFragment[])
  ].sort(sortClassOrMeetingPost(welcomePostsToTop));

  const postUids = allPosts.map(p => p.uid);
  const seenPosts = usePreviousValue(postUids);
  const isVerified = !!(currentLearner as CurrentLearnerFragmentFragment)
    ?.isVerified;
  const hasAttendances = classroomSection?.currentUserHasAttendances;

  if (!allPosts.length) {
    return null;
  }

  return (
    <Box
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          gap: 24
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {allPosts.map(classOrMeetingPost =>
        isClassPost(classOrMeetingPost) ? (
          <LearnerClassPost
            key={classOrMeetingPost.uid}
            post={classOrMeetingPost}
            isNew={!seenPosts.includes(classOrMeetingPost.uid)}
            mentionNames={mentionNames}
            usersCanComment={!!usersCanComment}
            handleAddComment={handleAddComment}
          />
        ) : (
          <LearnerMeetingPost
            key={classOrMeetingPost.uid}
            meeting={classOrMeetingPost}
            isNew={!seenPosts.includes(classOrMeetingPost.uid)}
            canViewRecordings={isVerified || !!hasAttendances}
          />
        )
      )}
    </Box>
  );
}

LearnerClassPostList.fragments = {
  classroom: gql`
    fragment LearnerClassPostList_classroom on Classroom {
      usersCanPost
      usersCanComment
      posts(
        publishedAfter: $start
        publishedBefore: $end
        lessonUid: $lessonUid
        filter: $postsFilter
      ) {
        ...ClassPost_classPost
      }
      unpublishedPosts {
        ...ClassPost_classPost
      }
      activity {
        user_uid
        ...ClassPost_activity
        ...MeetingPost_activity
      }
      section {
        currentUserCanManage
        meetings(filter: Enrolled, startAfter: $start, startBefore: $end) {
          ...MeetingPost_meeting
        }
        ...ClassPost_section
        ...MeetingPost_section
      }
      mentionNames {
        ...ClassPost_mentionNames
      }
    }
    ${ClassPostFragments.classPost}
    ${ClassPostFragments.activity}
    ${ClassPostFragments.section}
    ${ClassPostFragments.mentionNames}
    ${MeetingPostFragments.activity}
    ${MeetingPostFragments.meeting}
    ${MeetingPostFragments.section}
  `
};

export default LearnerClassPostList;
