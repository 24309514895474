import { Box, Button } from "@outschool/backpack";
import { ErrorMessages } from "@outschool/errors";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { ErrorMessage } from "@outschool/ui-components-shared";
import React from "react";

import * as Env from "../../Env";
import { useIsLearnerLogin } from "../../hooks/useIsLearnerLogin";
import { LearnerSwitcher } from "../../layout/nav/LearnerSwitcher";
import { User } from "../../lib/utils";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";

const ClassroomError: React.FC<{ error?: Error }> = ({ error }) => {
  const currentLearner = useCurrentLearner();
  const isLearnerLogin = useIsLearnerLogin();
  const errorMessage = currentLearner
    ? `Oops! It doesn't look like ${User.firstName(
        currentLearner
      )} has access to this classroom.`
    : `Oops! It doesn't look like you have access to this classroom`;
  return (
    <Box
      sx={{
        textAlign: "center"
      }}
    >
      {error?.message.endsWith(ErrorMessages.LEARNER_CLASSROOM_NO_ACCESS) ? (
        <>
          <ErrorMessage
            value={errorMessage}
            showStatusPageLink={Env.IS_READ_ONLY_MODE}
          />
          {isLearnerLogin ? (
            <Button
              onClick={() => window.history.go(-1)}
              sx={{
                marginTop: "1em",
                marginBottom: "2em"
              }}
            >
              Go back
            </Button>
          ) : (
            <Box
              flex
              sx={{
                justifyContent: "center"
              }}
            >
              <LearnerSwitcher switcherType={"button"} />
            </Box>
          )}
        </>
      ) : (
        <ErrorMessage
          value={error}
          showStatusPageLink={Env.IS_READ_ONLY_MODE}
        />
      )}
    </Box>
  );
};

export default ClassroomError;
