// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { LinkProps } from "@outschool/ui-utils";
import { getBasepath, navigate } from "@patched/hookrouter";
import _ from "lodash";
import React from "react";
import { useAnalytics } from "use-analytics";

const Link: React.ForwardRefRenderFunction<HTMLAnchorElement, LinkProps> = (
  {
    to,
    children,
    trackingName,
    sharedProperties,
    navigateQueryParams = {},
    ...props
  },
  ref
) => {
  const href = to.substr(0, 1) === "/" ? getBasepath() + to : to;
  const { track } = useAnalytics();
  return (
    <a
      ref={ref}
      href={href}
      {..._.omit(props, "isActive")}
      onClick={e => {
        if (trackingName) {
          track(trackingName, sharedProperties);
        }
        if (
          !e.shiftKey &&
          !e.ctrlKey &&
          !e.altKey &&
          props.target !== "_blank"
        ) {
          e.preventDefault();
          navigate(href, undefined, navigateQueryParams);
        }

        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      {children}
    </a>
  );
};

export default React.forwardRef(Link);
