import { Box } from "@outschool/backpack";
import {
  ActivityExperience,
  ActivityTeacher,
  ActivityTitle,
  ClassCardBase,
  ClassDetails,
  ClassVideoModalWithImageFallback
} from "@outschool/ui-components-classroom";
import { useLinkComponent } from "@outschool/ui-utils";
import React from "react";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { LearnerListingRecommendationsQuery } from "../generated/graphql";
import { activityPath } from "../lib/Routes";
import SaveButton from "./SaveButton";
import VideoPlayer from "./VideoPlayer";

type RecommendedActivity = NonNullable<
  LearnerListingRecommendationsQuery["featured"]["perLearnerRecommendations"][0]["activity"]
> & { price_cents?: number | null } & { age_min?: number | null } & {
  age_max?: number | null;
};
interface ClassCardProps {
  activity: RecommendedActivity;
  trackingPrefix: string;
  trackingProperties?: { [key: string]: string | number };
}

function ClassCard({
  activity,
  trackingPrefix,
  trackingProperties
}: ClassCardProps) {
  const Link = useLinkComponent();
  const activityVideoUrl =
    activity.videoForLearners?.file.url ?? activity.video?.file.url;

  const Details = () => (
    <ClassDetails>
      <ActivityTitle activity={activity}>
        <SaveButton
          activity={activity}
          simpleHeart
          sx={{ height: "16px !important" }}
          unfilledHeartColor="blue3"
          trackingPrefix={trackingPrefix}
          trackingProperties={trackingProperties}
        />
      </ActivityTitle>
      <ActivityExperience isClub={activity.isClub} />
      <ActivityTeacher leader={activity.leader}></ActivityTeacher>
    </ClassDetails>
  );

  return (
    <ClassCardBase>
      {activityVideoUrl ? (
        <>
          <ClassVideoModalWithImageFallback
            photo={activity.details.photo}
            videoUrl={activityVideoUrl}
            VideoPlayer={VideoPlayer}
          />
          <Link
            to={activityPath(activity)}
            style={{ flexGrow: 1, display: "flex", textDecoration: "none" }}
            trackingName={`${trackingPrefix}.pick_class.${activity.uid}`}
            sharedProperties={trackingProperties}
          >
            <Details />
          </Link>
        </>
      ) : (
        <Link
          to={activityPath(activity)}
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            textDecoration: "none"
          }}
          trackingName={`${trackingPrefix}.pick_class.${activity.uid}`}
          sharedProperties={trackingProperties}
        >
          <ClassVideoModalWithImageFallback
            photo={activity.details.photo}
            videoUrl={activityVideoUrl}
            VideoPlayer={VideoPlayer}
          />
          <Details />
        </Link>
      )}
    </ClassCardBase>
  );
}
const ClassCardMemoized = React.memo(
  ClassCard,
  ({ activity: prevActivity }, { activity: nextActivity }) =>
    prevActivity.uid === nextActivity.uid
);

interface ClassCardsGridProps {
  activities: Array<RecommendedActivity>;
  trackingPrefix: string;
  addBottomPadding?: boolean;
}

export function ClassCardsGrid({
  activities,
  trackingPrefix,
  addBottomPadding = false
}: ClassCardsGridProps) {
  return (
    <Box
      sx={{
        paddingTop: "2em",
        paddingBottom: addBottomPadding ? "2em" : 0,
        display: "grid",
        gap: "2em",
        justifyItems: "center",
        position: "relative",
        width: "100%",
        gridTemplateColumns: "repeat(auto-fill,minmax(250px, 1fr))"
      }}
    >
      {activities.map((activity, i) => (
        <ClassCardMemoized
          key={activity.uid}
          activity={activity}
          trackingPrefix={trackingPrefix}
          trackingProperties={{ positionInResults: i }}
        />
      ))}
    </Box>
  );
}

export default ClassCardMemoized;
