import { Button, Icon } from "@outschool/backpack";
import {
  GetLearnerToParentTransferTokenMutation,
  GetLearnerToParentTransferTokenMutationVariables
} from "@outschool/gql-frontend-generated";
import { faArrowLeft, faExternalLink } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { Component } from "@outschool/ownership-areas";
import { subscriptionsPath } from "@outschool/routes";
import { useMutation } from "@outschool/ui-apollo";
import { useSwitchToParentMode } from "@outschool/ui-components-website";
import React from "react";

import { GetLearnerToParentTransferToken } from "../../../queries/LearnerSwitcherQuery";

export function useSwitchToParent() {
  /**
   * Shamelessly copy-pasted from LearnerSwitcher to move fast.
   *
   */
  const switchToParentModeWithToken = useSwitchToParentMode(
    subscriptionsPath()
  );
  const [createParentTokenAndRedirectMutation] = useMutation<
    GetLearnerToParentTransferTokenMutation,
    GetLearnerToParentTransferTokenMutationVariables
  >(GetLearnerToParentTransferToken);

  return React.useCallback(() => {
    async function doSwitch() {
      const { data } = await createParentTokenAndRedirectMutation();
      const transferData = data?.learnerToParentTransferToken;
      if (!transferData) {
        OsPlatform.captureError(new Error("No transferData"), {
          component: Component.Subscriptions
        });
        return;
      }

      if (!transferData?.transferToken) {
        OsPlatform.captureError(new Error("No transferToken"), {
          component: Component.Subscriptions
        });
        return;
      }

      const { transferToken: transferTokenToUse } = transferData;

      switchToParentModeWithToken(transferTokenToUse);
    }
    doSwitch();
  }, [createParentTokenAndRedirectMutation, switchToParentModeWithToken]);
}

export function BackToParentUnlimitedButton() {
  const { t } = useTranslation("learnerApp\\UnlimitedLessonsPage");

  const doSwitch = useSwitchToParent();

  return (
    <Button
      startIcon={<Icon icon={faArrowLeft} />}
      size="small"
      variant="link"
      onClick={() => {
        doSwitch();
      }}
    >
      {t`Back`}
    </Button>
  );
}

export function BrowseMoreOnUnlimited() {
  const { t } = useTranslation("learnerApp\\UnlimitedLessonsPage");

  const doSwitch = useSwitchToParent();

  return (
    <Button
      endIcon={<Icon icon={faExternalLink} />}
      size="small"
      onClick={() => {
        doSwitch();
      }}
    >
      {t`Browse more on Unlimited`}
    </Button>
  );
}
