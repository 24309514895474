import {
  Box,
  Button,
  Divider,
  Icon,
  SxProps,
  Typography
} from "@outschool/backpack";
import { SectionLessonType } from "@outschool/gql-frontend-generated";
import { faCheckCircle } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { LearnerRoutes } from "@outschool/routes";
import { dayjs, formatDateWithFullMonth } from "@outschool/time";
import {
  LessonLiveMeetingSection,
  LessonMakeupMeetingSection,
  useLearnerLiveMeeting,
  useLiveMeeting
} from "@outschool/ui-components-classroom";
import { useConfetti } from "@outschool/ui-components-website";
import { Loading } from "@outschool/ui-legacy-component-library";
import { useNavigation, usePrefersReducedMotion } from "@outschool/ui-utils";
import React from "react";

import scribbleSketchBg from "../../../../../images/lessons/scribble-sketch-texture-bg-translucent.png";
import rocketThumbsUps from "../../../../../images/lessons/thumbs-up-rocket-complete-success.png";
import Link from "../../../components/Link";
import { learnerRoutes } from "../../../lib/Routes";
import {
  Assignment,
  ClassroomAssignmentsByLessonProvider,
  useClassroomAssignmentsContext
} from "../../../providers/ClassroomAssignmentsByLessonProvider";
import {
  ClassPost,
  ClassroomPostsProvider,
  useClassroomPostsContext
} from "../../../providers/ClassroomPostsProvider";
import { useCurrentLearner } from "../../../providers/CurrentLearnerProvider";
import {
  CurrentLesson,
  Lesson,
  SectionLesson,
  useLearnerClassroomContext
} from "../../../providers/LearnerClassroomProvider";
import {
  LessonCard,
  MakeupMeetingCard,
  SectionLessonCard
} from "../LessonCard";
import { LessonAssignmentAccordionSection } from "./LessonAssignmentAccordionSection";
import { LessonClassPostAccordionSection } from "./LessonClassPostAccordionSection";
import MarkLessonCompleteButton from "./MarkLessonCompleteButton";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

interface LessonViewProps {
  heading?: React.ReactNode;
  lesson: Lesson | CurrentLesson;
  sx?: SxProps;
}

export const LessonView = ({ heading, lesson, sx }: LessonViewProps) => {
  const {
    loading: classroomLoading,
    error: classroomError,
    sectionUid,
    section,
    syllabus,
    allLessonsCompleted
  } = useLearnerClassroomContext();

  const currentLearner = useCurrentLearner();

  const {
    loading: liveMeetingLoading,
    error: liveMeetingError,
    liveMeeting
  } = useLearnerLiveMeeting(
    {
      sectionUid,
      learnerUid: currentLearner?.uid || "",
      lessonUid: lesson.uid
    },
    !currentLearner?.uid
  );

  const loading = classroomLoading || liveMeetingLoading;
  const error = classroomError || liveMeetingError;

  // Redirect user to "all lessons complete" view when they complete their final lesson.
  const navigate = useNavigation();
  const reduceMotion = usePrefersReducedMotion();
  // Track the "marked complete" state here because the button unrenders after the mutation finishes
  const [markedCompleted, setMarkedCompleted] = React.useState(false);
  React.useEffect(() => {
    if (markedCompleted && allLessonsCompleted) {
      setTimeout(() => {
        setMarkedCompleted(false);
        navigate(learnerRoutes.lessonsPath(sectionUid));
        window.scrollTo({
          top: 0,
          behavior: reduceMotion ? "auto" : "smooth"
        });
      }, 3000);
    }
  }, [
    markedCompleted,
    allLessonsCompleted,
    navigate,
    sectionUid,
    reduceMotion
  ]);

  const showMarkCompleteButton =
    section?.is_self_paced && !lesson.isCompleted && !lesson.isLocked;

  if (!loading && error) {
    return null;
  }

  return (
    <Box
      sx={[
        { display: "flex", flexDirection: "column", gap: 16 },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {heading}
      <Box
        sx={{
          border: "1px solid",
          borderColor: "neutral.200",
          borderRadius: 16,
          paddingX: 36,
          paddingY: 24
        }}
      >
        {loading ? (
          <Box sx={{ height: 100 }}>
            <Loading />
          </Box>
        ) : (
          <>
            <LessonCard
              lesson={lesson}
              learnerUid={currentLearner!.uid}
              timeZone={currentLearner!.timeZone}
              liveMeeting={liveMeeting!}
              syllabus={syllabus!}
              isSelfPaced={!!section?.is_self_paced}
              sx={{ marginBottom: 16 }}
            />
            {!section?.is_self_paced && liveMeeting && (
              <Box
                sx={{
                  backgroundColor: "primary.50",
                  borderRadius: 16,
                  marginY: 24,
                  padding: 16
                }}
              >
                <LessonLiveMeetingSection
                  sectionUid={sectionUid}
                  liveMeeting={liveMeeting}
                  userTimeZone={currentLearner!.timeZone}
                  sectionJoinPathFn={learnerRoutes.sectionJoinPath}
                  classRecordingPathFn={learnerRoutes.recordingPath}
                  isLocked={lesson.isLocked}
                  isMissed={lesson.isMissed}
                />
              </Box>
            )}
          </>
        )}
        <ClassroomPostsProvider lessonUid={lesson.uid}>
          <ClassroomAssignmentsByLessonProvider lessonUid={lesson.uid}>
            <LessonPostsAndAssignments />
          </ClassroomAssignmentsByLessonProvider>
        </ClassroomPostsProvider>
        <Box
          sx={{
            display: "flex",
            paddingY: 16
          }}
        >
          {showMarkCompleteButton && (
            <MarkLessonCompleteButton
              lessonUid={lesson.uid}
              sectionUid={sectionUid}
              onCompleted={() => setMarkedCompleted(true)}
            />
          )}
          {lesson.isCompleted && (
            <LessonCompleteIcon
              triggerConfetti={markedCompleted && allLessonsCompleted}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

interface SectionLessonViewProps {
  heading?: React.ReactNode;
  sectionLesson: SectionLesson;
  sx?: SxProps;
}
export const PreSectionLessonView = ({
  heading,
  sectionLesson,
  sx
}: SectionLessonViewProps) => {
  const {
    loading: classroomLoading,
    error: classroomError,
    sectionUid,
    section,
    allLessonsCompleted
  } = useLearnerClassroomContext();

  const currentLearner = useCurrentLearner();

  const loading = classroomLoading;
  const error = classroomError;
  const [expand, setExpand] = React.useState(false);
  // Redirect user to "all lessons complete" view when they complete their final lesson.
  const navigate = useNavigation();
  const reduceMotion = usePrefersReducedMotion();
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\lessonsPage\\LessonView"
  );
  // Track the "marked complete" state here because the button unrenders after the mutation finishes
  const [markedCompleted, setMarkedCompleted] = React.useState(false);
  React.useEffect(() => {
    if (markedCompleted && allLessonsCompleted) {
      setTimeout(() => {
        setMarkedCompleted(false);
        navigate(learnerRoutes.lessonsPath(sectionUid));
        window.scrollTo({
          top: 0,
          behavior: reduceMotion ? "auto" : "smooth"
        });
      }, 3000);
    }
  }, [
    markedCompleted,
    allLessonsCompleted,
    navigate,
    sectionUid,
    reduceMotion
  ]);

  const showMarkCompleteButton =
    sectionLesson.type !== SectionLessonType.Meeting &&
    section?.is_self_paced &&
    !sectionLesson.isCompleted &&
    !sectionLesson.isLocked;

  if (!loading && error) {
    return null;
  }

  return (
    <Box
      sx={[
        { display: "flex", flexDirection: "column", gap: 16 },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {heading}
      <Box
        sx={{
          border: "1px solid",
          borderColor: "neutral.200",
          borderRadius: 16,
          paddingX: 36,
          paddingY: 24
        }}
      >
        {loading ? (
          <Box sx={{ height: 100 }}>
            <Loading />
          </Box>
        ) : (
          <>
            <SectionLessonCard
              sectionLesson={sectionLesson}
              learnerUid={currentLearner!.uid}
              timeZone={currentLearner!.timeZone}
              section={section!}
              sx={{ marginBottom: 16 }}
            />
          </>
        )}
        {sectionLesson.lesson && (
          <ClassroomPostsProvider lessonUid={sectionLesson.lesson.uid}>
            <ClassroomAssignmentsByLessonProvider
              lessonUid={sectionLesson.lesson.uid}
            >
              <LessonPostsAndAssignments />
            </ClassroomAssignmentsByLessonProvider>
          </ClassroomPostsProvider>
        )}
        <Box
          sx={{
            display: "flex",
            paddingY: 16
          }}
        >
          {showMarkCompleteButton && (
            <MarkLessonCompleteButton
              lessonUid={sectionLesson!.lesson!.uid}
              sectionUid={sectionUid}
              onCompleted={() => setMarkedCompleted(true)}
            />
          )}
          {sectionLesson.isCompleted && (
            <LessonCompleteIcon
              triggerConfetti={markedCompleted && allLessonsCompleted}
            />
          )}
        </Box>

        {!section?.is_self_paced && sectionLesson.liveMeetingDetails && (
          <>
            <Box
              sx={{
                marginTop: -20
              }}
            >
              <Button
                variant="text"
                onClick={() => setExpand(!expand)}
                edge="start"
              >
                {t("View Class Recording")}
              </Button>
            </Box>
            <Box>
              {expand && (
                <Box
                  sx={{
                    backgroundColor: "primary.50",
                    borderRadius: 16,
                    marginY: 24,
                    padding: 16,
                    marginBottom: 16
                  }}
                >
                  <LessonLiveMeetingSection
                    sectionUid={sectionUid}
                    liveMeeting={sectionLesson.liveMeetingDetails}
                    userTimeZone={currentLearner!.timeZone}
                    sectionJoinPathFn={learnerRoutes.sectionJoinPath}
                    classRecordingPathFn={learnerRoutes.recordingPath}
                    isLocked={sectionLesson.isLocked}
                    isMissed={sectionLesson.lesson?.isMissed}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export const SectionLessonView = ({
  heading,
  sectionLesson,
  sx
}: SectionLessonViewProps) => {
  const {
    loading: classroomLoading,
    error: classroomError,
    sectionUid,
    section,
    allLessonsCompleted
  } = useLearnerClassroomContext();

  const currentLearner = useCurrentLearner();

  const loading = classroomLoading;
  const error = classroomError;

  // Redirect user to "all lessons complete" view when they complete their final lesson.
  const navigate = useNavigation();
  const reduceMotion = usePrefersReducedMotion();
  // Track the "marked complete" state here because the button unrenders after the mutation finishes
  const [markedCompleted, setMarkedCompleted] = React.useState(false);
  React.useEffect(() => {
    if (markedCompleted && allLessonsCompleted) {
      setTimeout(() => {
        setMarkedCompleted(false);
        navigate(learnerRoutes.lessonsPath(sectionUid));
        window.scrollTo({
          top: 0,
          behavior: reduceMotion ? "auto" : "smooth"
        });
      }, 3000);
    }
  }, [
    markedCompleted,
    allLessonsCompleted,
    navigate,
    sectionUid,
    reduceMotion
  ]);

  const showMarkCompleteButton =
    sectionLesson.type !== SectionLessonType.Meeting &&
    section?.is_self_paced &&
    !sectionLesson.isCompleted &&
    !sectionLesson.isLocked;

  if (!loading && error) {
    return null;
  }

  return (
    <Box
      sx={[
        { display: "flex", flexDirection: "column", gap: 16 },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {heading}
      <Box
        sx={{
          border: "1px solid",
          borderColor: "neutral.200",
          borderRadius: 16,
          paddingX: 36,
          paddingY: 24
        }}
      >
        {loading ? (
          <Box sx={{ height: 100 }}>
            <Loading />
          </Box>
        ) : (
          <>
            <SectionLessonCard
              sectionLesson={sectionLesson}
              learnerUid={currentLearner!.uid}
              timeZone={currentLearner!.timeZone}
              section={section!}
              sx={{ marginBottom: 16 }}
            />
            {!section?.is_self_paced && sectionLesson.liveMeetingDetails && (
              <Box
                sx={{
                  backgroundColor: "primary.50",
                  borderRadius: 16,
                  marginY: 24,
                  padding: 16,
                  marginBottom: 16
                }}
              >
                <LessonLiveMeetingSection
                  sectionUid={sectionUid}
                  liveMeeting={sectionLesson.liveMeetingDetails}
                  userTimeZone={currentLearner!.timeZone}
                  sectionJoinPathFn={learnerRoutes.sectionJoinPath}
                  classRecordingPathFn={learnerRoutes.recordingPath}
                  isLocked={sectionLesson.isLocked}
                  isMissed={sectionLesson.lesson?.isMissed}
                />
              </Box>
            )}
          </>
        )}
        {sectionLesson.lesson && (
          <ClassroomPostsProvider lessonUid={sectionLesson.lesson.uid}>
            <ClassroomAssignmentsByLessonProvider
              lessonUid={sectionLesson.lesson.uid}
            >
              <LessonPostsAndAssignments />
            </ClassroomAssignmentsByLessonProvider>
          </ClassroomPostsProvider>
        )}
        <Box
          sx={{
            display: "flex",
            paddingY: 16
          }}
        >
          {showMarkCompleteButton && (
            <MarkLessonCompleteButton
              lessonUid={sectionLesson!.lesson!.uid}
              sectionUid={sectionUid}
              onCompleted={() => setMarkedCompleted(true)}
            />
          )}
          {sectionLesson.isCompleted && (
            <LessonCompleteIcon
              triggerConfetti={markedCompleted && allLessonsCompleted}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const LessonViewHeading = ({
  text,
  sectionUid,
  showLessonsLink
}: {
  text: string;
  sectionUid?: string;
  showLessonsLink?: boolean;
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    }}
  >
    <Typography variant="h3">{text}</Typography>
    {showLessonsLink && sectionUid && (
      <Button
        variant="text"
        component={Link}
        to={LearnerRoutes.lessonsPath(sectionUid)}
        edge="end"
      >
        View All Lessons
      </Button>
    )}
  </Box>
);

export const AllLessonsCompletedView = ({
  heading,
  sx
}: {
  heading: React.ReactNode;
  sx?: SxProps;
}) => {
  const { loading, section } = useLearnerClassroomContext();
  const isSelfPaced = section?.is_self_paced;

  const lessonsAvailableUntilDate = formatDateWithFullMonth(
    dayjs(section?.start_time).add(1, "year")
  );

  return (
    <Box
      sx={[
        { display: "flex", flexDirection: "column", gap: 16 },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {heading}
      {loading ? (
        <Box sx={{ height: 100 }}>
          <Loading />
        </Box>
      ) : (
        <Box
          sx={{
            color: "white",
            border: "1px solid",
            borderColor: "neutral.200",
            background: `url(${rocketThumbsUps}), url(${scribbleSketchBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "40%, cover",
            backgroundPosition: "right, center",
            backgroundBlendMode: "normal, overlay",
            backgroundColor: "secondary.600",
            borderRadius: 6,
            padding: 24,
            overflow: "hidden"
          }}
        >
          <Typography variant="h3" component="h4" sx={{ paddingBottom: 16 }}>
            It&apos;s time to celebrate!
          </Typography>
          <Box
            sx={{
              maxWidth: "60%"
            }}
          >
            {isSelfPaced ? (
              <Typography>
                Congratulations on completing this course. You have until{" "}
                <Typography sx={{ fontWeight: "bold" }}>
                  {lessonsAvailableUntilDate}
                </Typography>{" "}
                to access all of your lessons. Continue to practice what
                you&apos;ve learned!
              </Typography>
            ) : (
              <Typography>
                Congratulations on completing this course. Continue to practice
                what you&apos;ve learned and message your teacher if you have
                any questions.
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

interface MakeupMeetingViewProps {
  heading: React.ReactNode;
  sx?: SxProps;
}

const MAKEUP_LESSON = {
  uid: "makeup-lesson",
  title: "Bonus Meeting",
  description:
    "Your next meeting is not tied to a lesson. Please refresh the page to see the latest attendance information and announcements from your teacher.",
  lessonNumber: "" as any,
  lessonStatus: "in_progress" as any,
  isLocked: false
} as unknown as Lesson;
export const MakeupMeetingView = ({ heading, sx }: MakeupMeetingViewProps) => {
  const {
    loading: classroomLoading,
    error: classroomError,
    sectionUid,
    section
  } = useLearnerClassroomContext();

  const currentLearner = useCurrentLearner();

  const {
    loading: liveMeetingLoading,
    error: liveMeetingError,
    data: { joinMeeting: liveMeeting } = {}
  } = useLiveMeeting(sectionUid, currentLearner?.uid || "");

  const loading = classroomLoading || liveMeetingLoading;

  const error = classroomError || liveMeetingError;
  if (!loading && error) {
    return null;
  }

  return (
    <Box
      sx={[
        { display: "flex", flexDirection: "column", gap: 16 },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {heading}
      <Box
        sx={{
          border: "1px solid",
          borderColor: "neutral.200",
          borderRadius: 6,
          paddingX: 36,
          paddingY: 24
        }}
      >
        {loading ? (
          <Box sx={{ height: 100 }}>
            <Loading />
          </Box>
        ) : (
          liveMeeting &&
          !section?.is_self_paced && (
            <>
              <MakeupMeetingCard
                lesson={MAKEUP_LESSON}
                meetingStartTime={liveMeeting?.meetingStartTime}
                timeZone={currentLearner!.timeZone}
                sx={{ marginBottom: 16 }}
              />
              <Box
                sx={{
                  backgroundColor: "primary.50",
                  borderRadius: 16,
                  marginY: 24,
                  padding: 16
                }}
              >
                <LessonMakeupMeetingSection
                  sectionUid={sectionUid}
                  liveMeeting={liveMeeting}
                  userTimeZone={currentLearner!.timeZone}
                  sectionJoinPathFn={learnerRoutes.sectionJoinPath}
                  isLocked={MAKEUP_LESSON.isLocked}
                />
              </Box>
            </>
          )
        )}
      </Box>
    </Box>
  );
};

const LessonPostsAndAssignments = () => {
  const { posts, loading: postsLoading } = useClassroomPostsContext();
  const { assignments, loading: assignmentsLoading } =
    useClassroomAssignmentsContext();

  if (postsLoading || assignmentsLoading) {
    return <Loading />;
  }

  const postsAndAssignments: (ClassPost | Assignment)[] = [
    ...(posts || []),
    ...(assignments || [])
  ].sort((a, b) => dayjs(a.publishAt).diff(dayjs(b.publishAt)));

  if (postsAndAssignments.length === 0) {
    return null;
  }

  return (
    <>
      {postsAndAssignments.map((postOrAssignment: ClassPost | Assignment) => (
        <LessonPostOrAssignment
          key={postOrAssignment.uid}
          postOrAssignment={postOrAssignment}
        />
      ))}
      <Divider />
    </>
  );
};

const LessonPostOrAssignment = ({
  postOrAssignment
}: {
  postOrAssignment: ClassPost | Assignment;
}) =>
  postOrAssignment.__typename === "ClassPost" ? (
    <LessonClassPostAccordionSection classPost={postOrAssignment} />
  ) : postOrAssignment.__typename === "AssignmentClassPost" ? (
    <LessonAssignmentAccordionSection assignment={postOrAssignment} />
  ) : null;

const LessonCompleteIcon = ({
  triggerConfetti
}: {
  triggerConfetti: boolean;
}) => {
  const { fireConfetti, confettiCannon } = useConfetti({ spread: 90 });

  const [animateIcon, setAnimateIcon] = React.useState(false);

  React.useEffect(() => {
    if (triggerConfetti) {
      fireConfetti();
      setAnimateIcon(true);
    }
  }, [triggerConfetti, fireConfetti, setAnimateIcon]);

  return (
    <Box sx={{ margin: "auto" }}>
      {confettiCannon}
      <Icon
        icon={faCheckCircle}
        color="disabled"
        size="large"
        sx={{
          "@keyframes icon-animation": {
            "20%": {
              color: "success.main",
              transform: "scale(1.5)"
            },
            "40%, 70%": { transform: "scale(1)" },
            "60%": { transform: "scale(1.2)" },
            "80%": { transform: "scale(1.1)" }
          },
          animation: animateIcon ? "icon-animation 500ms" : "none"
        }}
      />
    </Box>
  );
};
