import { useFeatureFlag } from "@outschool/ui-components-shared";
import { usePath } from "@patched/hookrouter";
import { useCallback, useEffect } from "react";
import { useAnalytics } from "use-analytics";

interface FpsTrackingCallback {
  (fps: number): void;
}

/**
 * Reports when the FPS for animations falls below a certain threshold.
 */
export const useFpsTracking = () => {
  const isEnabled = useFeatureFlag("lxp-fps-tracking");
  const { track } = useAnalytics();
  const path = usePath();

  const onFps = useCallback(
    (fps: number) => track("low_fps", { fps, path }),
    [track, path]
  );

  useEffect(() => {
    if (!window.requestAnimationFrame || !isEnabled) {
      return undefined;
    }

    startFpsTracking(onFps);
    return () => stopFpsTracking(onFps);
  }, [onFps, isEnabled]);
};

// We only need one FPS tracker...
let fpsTracker: FpsTracker | null = null;
// to forward FPS readings to all listeners.
const allUseFpsTrackingCallbacks = new Set<FpsTrackingCallback>();

export const startFpsTracking = (track: FpsTrackingCallback) => {
  allUseFpsTrackingCallbacks.add(track);

  if (!fpsTracker) {
    const measurementPeriodMs = 5000;
    fpsTracker = new FpsTracker(fps => {
      if (fps > 25) {
        return;
      }
      allUseFpsTrackingCallbacks.forEach(cb => cb(fps));
    }, measurementPeriodMs);
  }
};

export const stopFpsTracking = (track: FpsTrackingCallback) => {
  allUseFpsTrackingCallbacks.delete(track);

  if (allUseFpsTrackingCallbacks.size === 0) {
    fpsTracker?.stop();
    fpsTracker = null;
  }
};

/**
 * Counts the number of frames over a time period and reports them until stopped.
 */
class FpsTracker {
  private shouldRequestAnimationFrame: boolean = true;
  private measurementPeriodStartTime = -1;
  private framesInMeasurementPeriod = 0;

  constructor(private track: FpsTrackingCallback, private measurementPeriodMs) {
    window.requestAnimationFrame(this.onAnimationFrame.bind(this));
  }

  private onAnimationFrame(timestamp) {
    if (this.framesInMeasurementPeriod === 0) {
      // If this is the first frame in the measurement period, record the start time.
      this.measurementPeriodStartTime = timestamp;
    }

    if (
      timestamp <
      this.measurementPeriodStartTime + this.measurementPeriodMs
    ) {
      // A measurement period is in progress.
      this.framesInMeasurementPeriod += 1;
    } else {
      // More than a measurement period has elapsed.
      const msSinceMeasurementPeriodStart =
        timestamp - this.measurementPeriodStartTime;
      const fps =
        this.framesInMeasurementPeriod / (msSinceMeasurementPeriodStart / 1000);

      this.track(fps);

      // Restart measurement period.
      this.framesInMeasurementPeriod = 0;
    }

    if (this.shouldRequestAnimationFrame) {
      window.requestAnimationFrame(this.onAnimationFrame.bind(this));
    }
  }

  stop() {
    this.shouldRequestAnimationFrame = false;
  }
}
