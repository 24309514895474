import {
  ACCESS_JWT_REFRESH_INTERVAL_MS,
  LEARNER_REFRESH_TOKEN_LOCAL_STORAGE_NAME
} from "@outschool/auth-shared";
import { ErrorMessages } from "@outschool/errors";
import { TokenRefreshSource } from "@outschool/gql-frontend-generated";
import { useLocalStorageReference } from "@outschool/local-storage";
import { useApolloClient } from "@outschool/ui-apollo";
import { sessionRefresh } from "@outschool/ui-auth";
import { clearLearnerTokenDataAndGoToMainApp } from "@outschool/ui-components-website";
import React from "react";

import { useLearnerAuth } from "../providers/LearnerAuthProvider";

export default function useRefreshSessionTokenRepeater() {
  const client = useApolloClient();

  const localStorage = useLocalStorageReference();
  const { sessionTokenStore, setSessionToken, learnerAuth } = useLearnerAuth();

  React.useEffect(() => {
    // Do refresh v2
    const intervalId = setInterval(async () => {
      if (!sessionTokenStore?.getLearnerAuth()?.isLoggedIn) {
        return;
      }
      const refreshToken = localStorage.localStorage
        ? localStorage.localStorage.getItem(
            LEARNER_REFRESH_TOKEN_LOCAL_STORAGE_NAME
          )
        : null;

      try {
        const { data } = await sessionRefresh(client, {
          refreshToken,
          refreshSource: TokenRefreshSource.LearnerApp
        });

        if (!data) {
          return;
        }

        setSessionToken({
          sessionToken: data.refreshV2.sessionToken,
          refreshToken: data.refreshV2.refreshToken
        });
      } catch (e) {
        if (
          e.message.endsWith(ErrorMessages.USER_ACCOUNT_NOT_FOUND) ||
          e.message.endsWith(ErrorMessages.USER_ACCOUNT_SUSPENDED)
        ) {
          clearLearnerTokenDataAndGoToMainApp();
        }
        clearInterval(intervalId);
      }
    }, ACCESS_JWT_REFRESH_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [
    sessionTokenStore,
    localStorage,
    learnerAuth?.userUid,
    setSessionToken,
    client
  ]);
}
