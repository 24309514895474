// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { isLocalStorageSupported } from "@outschool/local-storage";
import { BASE_LOCALE, I18nLocale } from "@outschool/localization";
import { navigate, useRoutes } from "@patched/hookrouter";
import React, { useEffect } from "react";
import { useAnalytics } from "use-analytics";

import { useFpsTracking } from "../lib/analytics/useFpsTracking";
import { LEARNER_LOGIN_WELCOME_STORE_KEY } from "../lib/constants";
import * as Routes from "../lib/Routes";
import { useCurrentLearner } from "../providers/CurrentLearnerProvider";
import { useLearnerAuth } from "../providers/LearnerAuthProvider";
import AuthCodePage from "./AuthCode";
import LearnerActivityDetails from "./LearnerActivityDetails";
import LearnerClassroom from "./LearnerClassroom";
import LearnerClub from "./LearnerClub";
import LearnerDashboard from "./LearnerDashboard";
import LearnerFindClasses from "./LearnerFindClasses";
import LearnerProfile from "./LearnerProfile";
import { NotFoundPage } from "./NotFoundPage";
import ReportIssue from "./ReportIssue";
import SavedActivities from "./SavedActivities";
import Settings from "./Settings";
import UnlimitedClassroom from "./Unlimited/Classroom";
import UnlimitedDashboard from "./Unlimited/Dashboard";
import Users from "./Users";
import Login from "./Users/Login";
import Welcome from "./Welcome";

const localizedAuthCodeRoutes = Object.fromEntries(
  Object.values(I18nLocale)
    .filter(locale => locale !== BASE_LOCALE)
    .map(locale => [
      `/${locale}/auth-code/:authCode`,
      ({ authCode }) => <AuthCodePage authCode={authCode} />
    ])
);

const learnerRoutes = {
  "/": () => <LoggedInRedirectOrHome />,
  "/dashboard*": () => <LearnerDashboard />,
  "/classroom/:sectionUid*": ({ sectionUid }) => (
    <LearnerClassroom sectionUid={sectionUid} />
  ),
  "/unlimited/:sectionUid*": ({ sectionUid }) => (
    <UnlimitedClassroom sectionUid={sectionUid} />
  ),
  "/unlimited": () => <UnlimitedDashboard />,
  "/find-classes*": () => <LearnerFindClasses />,
  "/saved-classes": () => <SavedActivities />,
  "/activity/:activitySlugOrUid": ({ activitySlugOrUid }) => (
    <LearnerActivityDetails activitySlugOrUid={activitySlugOrUid} />
  ),
  "/group*": () => <LearnerClub />,
  "/users*": () => <Users />,
  "/profile/:learnerUid": ({ learnerUid }) => (
    <LearnerProfile learnerUid={learnerUid} />
  ),
  "/auth-code/:authCode": ({ authCode }) => (
    <AuthCodePage authCode={authCode} />
  ),
  ...localizedAuthCodeRoutes,
  "/welcome": () => <Welcome />,
  "/settings": () => <Settings />,
  "/report-issue": () => <ReportIssue />
};

const Router: React.FC = () => {
  const { identify } = useAnalytics();
  useFpsTracking();

  const { sessionTokenStore } = useLearnerAuth();
  const learnerAuth = sessionTokenStore?.getLearnerAuth() ?? null;

  // Identify the learner as early as possible.
  useEffect(() => {
    if (learnerAuth) {
      identify(learnerAuth.learnerUid, {
        parentUid: learnerAuth.userUid,
        isLearnerLogin: learnerAuth.isLearnerLogin,
        ignoreNetworkError: true
      });
    }
  }, [learnerAuth, identify]);

  const route = useRoutes(learnerRoutes) || <NotFoundPage />;
  return route;
};

const LoggedInRedirectOrHome: React.FC = () => {
  const currentLearner = useCurrentLearner();
  const { sessionTokenStore, learnerAuth } = useLearnerAuth();

  useEffect(() => {
    if (currentLearner) {
      const localStorage = isLocalStorageSupported();
      if (localStorage) {
        const hasSeenWelcomePage = localStorage.getItem(
          LEARNER_LOGIN_WELCOME_STORE_KEY
        );
        if (
          (!hasSeenWelcomePage || hasSeenWelcomePage === "false") &&
          sessionTokenStore?.getLearnerAuth()?.isLearnerLogin
        ) {
          navigate(Routes.welcomePath());
          return;
        }
      }
      navigate(
        currentLearner.hasUpcomingScheduleEvent
          ? Routes.dashboardPath()
          : Routes.findClassesPath()
      );
    }
  }, [currentLearner, sessionTokenStore]);

  if (learnerAuth) {
    // If logged in, wait for the navigation from useEffect
    return null;
  }

  return <Login />;
};

export default Router;
