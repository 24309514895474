// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  Box,
  Image,
  SxProps,
  Typography,
  visuallyHidden
} from "@outschool/backpack";
import { useCurrentSubscription } from "@outschool/ui-components-website";
import { useIsMobile, useMotionSx } from "@outschool/ui-utils";
import { navigate } from "@patched/hookrouter";
import React from "react";

import * as curveNoShadow from "../../../../images/learner/nav/curve-no-shadow.svg";
import * as curveShadow from "../../../../images/learner/nav/curve-shadow.svg";
import * as highFive from "../../../../images/learner/nav/high-five.svg";
import * as iconHeart from "../../../../images/learner/nav/icon-heart.svg";
import * as iconSchedule from "../../../../images/learner/nav/icon-schedule.svg";
import * as iconSearch from "../../../../images/learner/nav/icon-search.svg";
import * as tabNavShapeActive from "../../../../images/learner/nav/tab-active.svg";
import * as tabNavShapeMobile from "../../../../images/learner/nav/tab-mobile.svg";
import * as tabNavShape from "../../../../images/learner/nav/tab.svg";
import { learnerRoutes } from "../../lib/Routes";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import { NavigationContext } from "./HeroLayoutWithTabs";

enum ZIndices {
  BottomTab = 0,
  InactiveTab = 1,
  Shadow = 2,
  ActiveTab = 3,
  Curve = 4
}

interface NavigationTabsProps {
  currentPath: string;
  sx?: SxProps;
}

// a11y design: https://www.w3.org/TR/wai-aria-practices/#tabpanel
const NavigationTabs: React.FC<NavigationTabsProps> = ({
  currentPath,
  sx = {}
}) => {
  const { hasActiveSubscription, defaultLearner } = useCurrentSubscription({
    isLoggedIn: true
  });
  const learner = useCurrentLearner();
  const showUnlimitedTab =
    hasActiveSubscription && defaultLearner?.uid === learner?.uid;
  return (
    <Box
      sx={[
        {
          isolation: "isolate",
          position: "relative"
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <Box
        flex
        role="tablist"
        aria-label="Navigation"
        sx={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "flex-end"
        }}
      >
        <Tab
          iconImageSrc={iconSchedule}
          label={"Home"}
          targetPath={learnerRoutes.dashboardPath()}
          currentPath={currentPath}
        />
        <Tab
          iconImageSrc={iconSearch}
          label="Search"
          targetPath={learnerRoutes.findClassesPath()}
          currentPath={currentPath}
        />
        {showUnlimitedTab && (
          <Tab
            iconImageSrc={highFive}
            label="Unlimited"
            targetPath={learnerRoutes.learnerUnlimitedPath()}
            currentPath={currentPath}
          />
        )}
        <Tab
          isBottomTab
          iconImageSrc={iconHeart}
          label="Saved Classes"
          targetPath={learnerRoutes.savedClassesPath()}
          currentPath={currentPath}
        />
      </Box>
      <CurveWithShadow />
    </Box>
  );
};

const baseStyles: SxProps = {
  position: "absolute",
  bottom: 0,
  height: 40,
  width: "100%",
  marginX: "auto",
  backgroundSize: "130% 100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  pointerEvents: "none"
};

const CurveWithShadow: React.FC = () => {
  return (
    <>
      <Box
        sx={[
          {
            backgroundImage: `url(${curveShadow})`,
            zIndex: ZIndices.Shadow
          },
          ...(Array.isArray(baseStyles) ? baseStyles : [baseStyles])
        ]}
      />
      <Box
        sx={[
          {
            backgroundImage: `url(${curveNoShadow})`,
            zIndex: ZIndices.Curve
          },
          ...(Array.isArray(baseStyles) ? baseStyles : [baseStyles])
        ]}
      />
    </>
  );
};

interface TabProps {
  iconImageSrc: string;
  label: string;
  targetPath: string;
  currentPath: string;
  isBottomTab?: boolean;
}

const Tab: React.FC<TabProps> = ({
  iconImageSrc,
  label,
  targetPath,
  currentPath,
  isBottomTab = false
}) => {
  const isActive = targetPath === currentPath;
  const isMobile = useIsMobile();

  const showTabText = !isMobile || isActive;

  const hoverSx = useMotionSx({
    "&:hover": {
      marginBottom: isActive ? undefined : 5
    }
  });

  const activeTabWidth = isMobile ? 310 : 400;
  const activeTabMarginX = isMobile ? "-34px" : "-45px";

  const { activeTabId, activeTabPanelId } = React.useContext(NavigationContext);

  return (
    <Box
      sx={{
        flexShrink: isActive ? 1 : 10,
        marginX: showTabText ? activeTabMarginX : "-20px",
        width: showTabText ? activeTabWidth : "150px",
        height: showTabText ? "100%" : "95px",

        backgroundImage: `url(${
          isActive
            ? tabNavShapeActive
            : isMobile
            ? tabNavShapeMobile
            : tabNavShape
        })`,

        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        cursor: "pointer",
        pointerEvents: "none",
        position: "relative",
        zIndex: isActive
          ? ZIndices.ActiveTab
          : isBottomTab
          ? ZIndices.BottomTab
          : ZIndices.InactiveTab,
        transition: "margin-bottom 0.15s ease-out",
        ...hoverSx
      }}
    >
      <Box
        component="button"
        role="tab"
        id={isActive ? activeTabId : undefined}
        aria-controls={isActive ? activeTabPanelId : undefined}
        aria-selected={isActive}
        sx={{
          all: "unset",
          border: "none",
          background: "transparent",
          position: "absolute",
          bottom: "10%",
          left: "10%",
          width: "80%",
          height: showTabText ? "60%" : "85%",
          pointerEvents: "auto",
          cursor: "pointer"
        }}
        // TODO(sam): for a11y, we're supposed to focus the active tabpanel
        onClick={() => navigate(targetPath)}
      >
        <Box
          flex
          sx={{
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Image src={iconImageSrc} width="56" />
          {showTabText ? (
            <Typography
              variant="h4"
              sx={{
                color: isActive ? "primary.800" : "common.white",
                marginLeft: "0.25em",
                width: "max-content"
              }}
            >
              {label}
            </Typography>
          ) : (
            <span style={visuallyHidden}>{label}</span>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationTabs;
