import { Box, Button, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { TEACHER_COLOR, TOPIC_COLOR } from "@outschool/ui-components-classroom";
import {
  SearchItem,
  SearchSuggestionsMenu
} from "@outschool/ui-components-website";
import { Input } from "@outschool/ui-legacy-component-library";
import { Screen, responsive } from "@outschool/ui-utils";
import { navigate } from "@patched/hookrouter";
import {
  GetPropsCommonOptions,
  UseComboboxGetInputPropsOptions,
  useCombobox
} from "downshift";
import React, { useState } from "react";
import { useAnalytics } from "use-analytics";
import { useDebouncedCallback } from "use-debounce";

import {
  useSearchSuggestions,
  useSelectInitialSearchItem,
  useSetSearchSuggestions
} from "../../../../shared/client/queries/LearnerSearchSuggestionsQuery";
import { keywordSearchResultsPath, learnerRoutes } from "../../lib/Routes";

const KEYWORD_SEARCH_TRACKING_PREFIX = "browse.search.keyword";

const getNameColor = (
  item: SearchItem | null,
  inputValue: string | undefined
) => {
  if (!item || item.name !== inputValue) {
    return "initial";
  }
  switch (item.type) {
    case "teacher":
      return TEACHER_COLOR;
    case "topic":
      return TOPIC_COLOR;
    default:
      return "initial";
  }
};

export default function SearchBar() {
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const [searchSuggestions, setSearchSuggestions] = useState<SearchItem[]>([]);
  const [didUserFocusOnInput, setDidUserFocusOnInput] = useState(false);
  const isMobile = Screen.useIsMobile();
  const { track } = useAnalytics();
  const {
    loading: loadingSuggestions,
    fetchSuggestions,
    teacherSuggestions,
    topicSuggestions
  } = useSearchSuggestions();

  const [debouncedFetchSuggestions] = useDebouncedCallback(options => {
    fetchSuggestions(options);
  }, 300);
  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    selectedItem: selectedComboboxItem,
    inputValue,
    setInputValue,
    selectItem: selectComboboxItem,
    closeMenu
    // Beware: Omitting this generic type caused TS4.9 to crash indecipherably
  } = useCombobox<SearchItem>({
    items: searchSuggestions,
    itemToString: item => {
      if (!item) {
        return "";
      }
      if (item.type === "keyword") {
        return inputValue || "";
      }
      return item.name || "";
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) {
        return;
      }

      if (selectedItem?.type === "keyword") {
        didUserFocusOnInput &&
          track(KEYWORD_SEARCH_TRACKING_PREFIX, { query: selectedItem.name });
        navigateToKeywordSearchResulsPage(selectedItem.name);
      } else if (selectedItem?.type === "topic") {
        didUserFocusOnInput &&
          track(`browse.search.topic.${selectedItem.name}`);
        if (selectedItem.uid) {
          navigate(learnerRoutes.topicSearchResultsPath(selectedItem.uid));
        }
      } else if (selectedItem?.type === "teacher") {
        didUserFocusOnInput &&
          track(`browse.search.teacher.${selectedItem.uid}`);
        navigate(
          learnerRoutes.teacherSearchResultPath(selectedItem.leaderLink!)
        );
      }
    },
    onInputValueChange: ({ inputValue }) => {
      if (!inputValue) {
        closeMenu();
        setSearchSuggestions([]);
      } else {
        debouncedFetchSuggestions({ variables: { query: inputValue } });
      }
    }
  });

  useSelectInitialSearchItem({
    selectComboboxItem,
    selectedComboboxItem,
    setInputValue
  });

  useSetSearchSuggestions({
    setSearchSuggestions,
    inputValue,
    teacherSuggestions,
    topicSuggestions
  });

  const handleInputFocus = () => {
    setDidUserFocusOnInput(true);

    if (isMobile) {
      if (searchInputRef?.current) {
        // Scroll after the virtual keyboard animation has completed
        setTimeout(() => searchInputRef?.current?.scrollIntoView(true), 500);
      }
    }
    if (inputValue) {
      openMenu();
      debouncedFetchSuggestions({ variables: { query: inputValue } });
    }
  };

  return (
    <Box
      sx={theme => ({
        background: "white",

        [theme.breakpoints.up("md")]: {
          background: "#F0F3FF",
          border: "2px solid #A0A9F9",
          paddingX: "87px"
        },

        border: "none",
        paddingY: "1em",
        paddingX: 0,

        [theme.breakpoints.up("sm")]: {
          paddingX: "40px"
        },

        borderRadius: 8,
        width: "fit-content",
        margin: "0 auto"
      })}
    >
      <Box
        flex
        sx={theme => ({
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",

          [theme.breakpoints.up("lg")]: {
            flexDirection: "row"
          },

          gap: "16px"
        })}
      >
        {!isMobile && (
          <Typography
            variant="inherit"
            {...getLabelProps({
              style: {
                color: "gray2",
                fontSize: 24,
                fontWeight: 700
              }
            })}
          >
            Find classes &amp; groups about:
          </Typography>
        )}
        <div
          {...getComboboxProps({
            style: {
              position: "relative"
            }
          })}
        >
          <SearchInput
            searchInputRef={searchInputRef}
            selectedComboboxItem={selectedComboboxItem}
            handleInputFocus={handleInputFocus}
            getInputProps={getInputProps}
            inputValue={inputValue}
          />
          <SearchButton keyword={inputValue} />
          <SearchSuggestionsMenu
            getMenuProps={getMenuProps}
            isOpen={isOpen}
            searchSuggestions={searchSuggestions}
            loadingSuggestions={loadingSuggestions}
            highlightedIndex={highlightedIndex}
            getItemProps={getItemProps}
            keyword={inputValue}
          />
        </div>
      </Box>
    </Box>
  );
}

function SearchInput({
  searchInputRef,
  selectedComboboxItem,
  handleInputFocus,
  getInputProps,
  inputValue
}: {
  searchInputRef: React.RefObject<HTMLInputElement>;
  handleInputFocus: () => void;
  getInputProps: (
    options?: UseComboboxGetInputPropsOptions | undefined,
    otherOptions?: GetPropsCommonOptions | undefined
  ) => any;
  selectedComboboxItem: SearchItem | null;
  inputValue?: string;
}) {
  const isMobile = Screen.useIsMobile();
  const { track } = useAnalytics();

  const onKeyPress = e => {
    if (e.key === "Enter") {
      track(KEYWORD_SEARCH_TRACKING_PREFIX, { query: inputValue });
      navigateToKeywordSearchResulsPage(inputValue);
    }
  };

  return (
    <Input
      {...getInputProps({
        ref: searchInputRef,
        onFocus: handleInputFocus,
        // @ts-ignore
        sx: {
          position: "relative",
          width: responsive({
            default: 215,
            small: 350,
            large: 424
          }),
          height: "58px !important",
          fontSize: "20 !important",
          fontWeight: 500,
          borderRadius: "7px 0 0 8px !important",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2) !important",
          color: getNameColor(selectedComboboxItem, inputValue),
          "::placeholder": {
            color: "gray4"
          }
        }
      })}
      onKeyPress={onKeyPress}
      placeholder={
        isMobile ? "Search" : "Search for topics, classes or teachers"
      }
    />
  );
}

function SearchButton({ keyword }) {
  const { track } = useAnalytics();
  const onClick = () => {
    if (keyword) {
      track(KEYWORD_SEARCH_TRACKING_PREFIX, { query: keyword });
      navigateToKeywordSearchResulsPage(keyword);
    }
  };
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        borderRadius: "0px 7px 8px 0px",
        height: "58px"
      }}
    >
      GO
    </Button>
  );
}

const navigateToKeywordSearchResulsPage = keyword =>
  navigate(keywordSearchResultsPath(keyword));
