// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Button, Typography } from "@outschool/backpack";
import {
  Learner,
  RecordingAvailability
} from "@outschool/gql-backend-generated";
import { CurrentLearnerFragmentFragment } from "@outschool/gql-frontend-generated";
import { LearnerRoutes } from "@outschool/routes";
import { joinCommasAnd } from "@outschool/text";
import * as Time from "@outschool/time";
import { useMutation } from "@outschool/ui-apollo";
import {
  MeetingPost_ActivityFragment,
  MeetingPost_LearnerFragment,
  MeetingPost_MeetingFragment,
  MeetingPost_SectionFragment,
  RequestMeetingRecordingsShown,
  RequestMeetingRecordingsShownMutation,
  RequestMeetingRecordingsShownMutationVariables,
  SetMeetingRecordingsShown,
  SetMeetingRecordingsShownMutation,
  SetMeetingRecordingsShownMutationVariables
} from "@outschool/ui-components-classroom";
import { ErrorMessage } from "@outschool/ui-components-shared";
import {
  CollapsedText,
  Modal,
  Space,
  TextArea
} from "@outschool/ui-legacy-component-library";
import { Screen, useBooleanState } from "@outschool/ui-utils";
import { navigate } from "@patched/hookrouter";
import compact from "lodash/compact";
import React from "react";

import { User } from "../lib/utils";
import { useCurrentLearner } from "../providers/CurrentLearnerProvider";
import { useLearnerClassroomContext } from "../providers/LearnerClassroomProvider";

export default function LearnerMeetingPost({
  meeting,
  isNew,
  canViewRecordings
}: {
  meeting: MeetingPost_MeetingFragment;
  isNew: boolean;
  canViewRecordings: boolean;
}) {
  const currentLearner = useCurrentLearner()!;
  const { timeZone } = currentLearner;

  const { activity, section } = useLearnerClassroomContext();
  const isMobile = Screen.useIsMobile();
  const recordingAvailability = meeting?.recordingAvailability;

  const isRecordingUnavailable =
    recordingAvailability === RecordingAvailability.Unavailable;

  const postTitle = Time.dayjs(meeting.start_time).isAfter(Time.dayjs())
    ? "Live meeting is starting soon"
    : Time.dayjs(meeting.end_time).isAfter(Time.dayjs())
    ? "Live meeting is in progress"
    : isRecordingUnavailable
    ? "Scheduled meeting did not happen"
    : "Class met over video chat";

  const hasAnyRecordings = meeting?.classRecordings?.length > 0;
  const meetingHasEnded = Time.dayjs().isAfter(Time.dayjs(meeting.end_time));

  const [highlight, _setHighlight, clearHighlight] = useBooleanState(isNew);
  React.useEffect(() => {
    if (highlight) {
      const timer = setTimeout(() => {
        clearHighlight();
      }, 5000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [highlight, clearHighlight]);

  return (
    <Box
      sx={{
        backgroundColor: highlight ? "warning.50" : undefined,
        transition: "background-color 2s ease"
      }}
    >
      <Box
        flex
        sx={{
          flexDirection: "column"
        }}
      >
        <Box
          flex
          sx={{
            flexDirection: "row",
            marginBottom: "0.5em",
            alignItems: "start",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }}
        >
          <Box flex>
            <Box>
              <Box
                sx={{
                  color: "grey.500"
                }}
              >
                {Time.formatDateTimeWithFullWeekday(
                  meeting.start_time,
                  timeZone ?? undefined
                )}
              </Box>
              <Box
                sx={{
                  fontWeight: "fontWeightBold"
                }}
                data-test-id="MeetingPost-title"
              >
                {postTitle}
              </Box>
            </Box>
          </Box>
          {!isMobile && (
            <Box>
              {meetingHasEnded && hasAnyRecordings && canViewRecordings && (
                <>
                  {meeting.classRecordings.map(recording => (
                    <Box key={recording.uid}>
                      <ViewRecordingButton
                        url={LearnerRoutes.recordingPath(
                          section!.uid,
                          recording.uid
                        )}
                      />
                    </Box>
                  ))}
                </>
              )}
              <MeetingPostActionButton
                activity={activity!}
                meeting={meeting}
                learner={currentLearner!}
                canManage={false}
              />
            </Box>
          )}
        </Box>
        {meetingHasEnded && (
          <React.Fragment>
            <Box data-test-id="MeetingPost-attendance">
              <MeetingPostAttendance meeting={meeting} section={section!} />
            </Box>
            <MeetingPostRecordingsDescription
              activity={activity!}
              meeting={meeting}
              hasAnyRecordings={hasAnyRecordings}
            />
            <MeetingPostRequests meeting={meeting} canManage={false} />
          </React.Fragment>
        )}
        {isMobile && (
          <Box>
            {meetingHasEnded && hasAnyRecordings && (
              <>
                {meeting.classRecordings.map(recording => (
                  <Box key={recording.uid}>
                    <ViewRecordingButton
                      url={LearnerRoutes.recordingPath(
                        section!.uid,
                        recording.uid
                      )}
                    />
                  </Box>
                ))}
              </>
            )}
            <MeetingPostActionButton
              activity={activity!}
              meeting={meeting}
              learner={currentLearner!}
              canManage={false}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

function HideRecordingButton({
  meeting
}: {
  meeting: MeetingPost_MeetingFragment;
}) {
  const [isModalShown, setIsModalShown] = React.useState(false);
  const [reasonToHide, setReasonToHide] = React.useState("");
  const [validationErrorMessage, setValidationErrorMessage] = React.useState<
    string | null
  >(null);
  const [mutation, { loading, error }] = useMutation<
    SetMeetingRecordingsShownMutation,
    SetMeetingRecordingsShownMutationVariables
  >(SetMeetingRecordingsShown);
  const combinedError = validationErrorMessage || error;
  const hideModal = () => {
    setIsModalShown(false);
    setReasonToHide("");
    setValidationErrorMessage(null);
  };
  const handleSubmit = React.useCallback(async () => {
    if (!reasonToHide || reasonToHide.length === 0) {
      setValidationErrorMessage("You must enter a reason");
    } else {
      await mutation({
        variables: {
          meetingUid: meeting.uid,
          isShown: false,
          reason: reasonToHide
        }
      });
      hideModal();
    }
  }, [mutation, meeting.uid, reasonToHide]);

  return (
    <React.Fragment>
      <Button
        sx={{
          marginTop: "0.25em"
        }}
        onClick={() => setIsModalShown(true)}
      >
        Hide recording
      </Button>
      <Modal open={isModalShown} onClose={hideModal}>
        <Typography
          variant="h3"
          sx={{
            marginBottom: "1em"
          }}
        >
          Hide recording
        </Typography>
        <Box
          sx={{
            marginBottom: "1em"
          }}
        >
          Please tell Outschool why you are choosing not to share this recording
        </Box>
        <ErrorMessage value={combinedError} />
        <Box
          sx={{
            marginBottom: "1em"
          }}
        >
          <TextArea
            maxRows={10}
            showCount={false}
            value={reasonToHide}
            placeholder="Enter a reason (visible only to Outschool)"
            onChange={e =>
              setReasonToHide((e.target as HTMLTextAreaElement).value)
            }
          />
        </Box>
        <Box
          flex
          sx={{
            justifyContent: "space-between"
          }}
        >
          <Button variant="contained" disabled={loading} onClick={handleSubmit}>
            Hide recording
          </Button>
          <Button onClick={hideModal}>Cancel</Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

function ShowRecordingButton({
  meeting
}: {
  meeting: MeetingPost_MeetingFragment;
}) {
  const [mutation, { loading }] = useMutation<
    SetMeetingRecordingsShownMutation,
    SetMeetingRecordingsShownMutationVariables
  >(SetMeetingRecordingsShown, {
    variables: {
      meetingUid: meeting.uid,
      isShown: true,
      reason: null
    }
  });
  return (
    <Button disabled={loading} onClick={() => mutation()}>
      Share recording
    </Button>
  );
}

function RequestRecordingButton({
  activity,
  meeting,
  learner
}: {
  activity: MeetingPost_ActivityFragment;
  meeting: MeetingPost_MeetingFragment;
  learner: MeetingPost_LearnerFragment | CurrentLearnerFragmentFragment;
}) {
  const hasUserRequested =
    meeting.recordingAvailability === RecordingAvailability.Requested;
  const lastUserRequest = hasUserRequested
    ? meeting.recordingRequests.find(
        request => request.requestedBy.uid === learner.uid
      )
    : null;
  const defaultMessage = (lastUserRequest && lastUserRequest.message) || "";
  const [isModalShown, setIsModalShown] = React.useState(false);
  const [requestMessage, setRequestMessage] = React.useState<string | null>(
    null
  );
  const [validationErrorMessage, setValidationErrorMessage] = React.useState<
    string | null
  >(null);
  const [mutation, { loading, error }] = useMutation<
    RequestMeetingRecordingsShownMutation,
    RequestMeetingRecordingsShownMutationVariables
  >(RequestMeetingRecordingsShown);
  const combinedError = validationErrorMessage || error;
  const hideModal = () => {
    setIsModalShown(false);
    setRequestMessage(null);
    setValidationErrorMessage(null);
  };
  const message = requestMessage !== null ? requestMessage : defaultMessage;
  const handleSubmit = React.useCallback(async () => {
    await mutation({
      variables: {
        learnerUid: learner.uid,
        meetingUid: meeting.uid,
        message: message || undefined
      }
    });
    hideModal();
  }, [mutation, meeting.uid, message, learner.uid]);
  return (
    <React.Fragment>
      <Button onClick={() => setIsModalShown(true)}>
        {!hasUserRequested ? "Request recording" : "Edit recording request"}
      </Button>
      <Modal open={isModalShown} onClose={hideModal}>
        <Typography
          variant="h3"
          sx={{
            marginBottom: "1em"
          }}
        >
          Message to {User.shortenedLeaderName(activity.leader.name)}
        </Typography>
        <Box
          sx={{
            marginBottom: "0.5em"
          }}
        >
          Optional: tell your teacher why you find recordings useful
        </Box>
        <ErrorMessage value={combinedError} />
        <Box
          sx={{
            marginBottom: "1em"
          }}
        >
          <TextArea
            maxRows={10}
            showCount={false}
            value={message}
            placeholder="Enter a message"
            onChange={e =>
              setRequestMessage((e.target as HTMLTextAreaElement).value)
            }
          />
        </Box>
        <Box
          flex
          sx={{
            justifyContent: "space-between"
          }}
        >
          <Button variant="contained" disabled={loading} onClick={handleSubmit}>
            {!hasUserRequested ? "Request recording" : "Save recording request"}
          </Button>
          <Button onClick={hideModal}>Cancel</Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

function MeetingPostActionButton({
  activity,
  meeting,
  learner,
  canManage
}: {
  activity: MeetingPost_ActivityFragment;
  meeting: MeetingPost_MeetingFragment;
  learner: MeetingPost_LearnerFragment | CurrentLearnerFragmentFragment;
  canManage: boolean;
}) {
  const recordingAvailability = meeting && meeting.recordingAvailability;
  const canRequestOrShowRecording = canDisplayShowOrRequestRecordingButton(
    recordingAvailability
  );
  return canManage ? (
    canRequestOrShowRecording ? (
      <ShowRecordingButton meeting={meeting} />
    ) : canDisplayHideRecordingButton(recordingAvailability) ? (
      <HideRecordingButton meeting={meeting} />
    ) : null
  ) : canRequestOrShowRecording ? (
    <RequestRecordingButton
      learner={learner}
      activity={activity}
      meeting={meeting}
    />
  ) : null;
}

function MeetingPostRecordingsDescription({
  activity,
  meeting,
  hasAnyRecordings
}: {
  activity: MeetingPost_ActivityFragment;
  meeting: MeetingPost_MeetingFragment;
  hasAnyRecordings: boolean;
}) {
  if (hasAnyRecordings) {
    return null;
  }

  const recordingAvailability = meeting?.recordingAvailability;
  const element =
    recordingAvailability === RecordingAvailability.Shown ? (
      <React.Fragment>No recordings available.</React.Fragment>
    ) : recordingAvailability === RecordingAvailability.Processing ? (
      <React.Fragment>
        Recording will appear here once {"it's"} available.
      </React.Fragment>
    ) : recordingAvailability === RecordingAvailability.Requested ? (
      <React.Fragment>You have requested the recording</React.Fragment>
    ) : recordingAvailability === RecordingAvailability.Hidden ? (
      <React.Fragment>
        {User.shortenedLeaderName(activity.leader.name)} chose not to share this
        recording.
      </React.Fragment>
    ) : null;

  return !element ? null : (
    <Box
      data-test-id="MeetingPost-recordings"
      sx={{
        marginTop: "0.25em"
      }}
    >
      {element}
    </Box>
  );
}

function MeetingPostAttendance({
  meeting,
  section
}: {
  meeting: MeetingPost_MeetingFragment;
  section: MeetingPost_SectionFragment;
}) {
  const recordingAvailability = meeting && meeting.recordingAvailability;
  const isRecordingUnavailable =
    recordingAvailability === RecordingAvailability.Unavailable;

  type TeacherAttendance = { teacher: { name: string } };
  type LearnerAttendance = { __typename?: "Attendance" } & {
    learner: { __typename?: "Learner" } & Pick<Learner, "name">;
  };

  let sortedLearners = meeting.attendances?.slice(0) ?? [];
  sortedLearners.sort((learner1, learner2) => {
    if (learner1.learner.name && learner2.learner.name) {
      return learner1.learner.name.localeCompare(learner2.learner.name);
    } else if (learner1.learner.name && !learner2.learner.name) {
      return -1;
    } else {
      return 1;
    }
  });

  type Attendance = TeacherAttendance | LearnerAttendance;
  const attendances = isRecordingUnavailable
    ? sortedLearners
    : [{ teacher: { name: section?.leader?.name } }, ...sortedLearners];
  return (
    <React.Fragment>
      {attendances.length > 0
        ? joinCommasAnd(
            compact(
              attendances.map((attendance: Attendance) =>
                // https://www.typescriptlang.org/docs/handbook/advanced-types.html#intersection-types
                "learner" in attendance
                  ? attendance.learner.name
                  : attendance.teacher.name
              )
            )
          ) +
          " joined" +
          (isRecordingUnavailable ? ", but the teacher did not" : "") +
          "."
        : "No one joined."}
    </React.Fragment>
  );
}

function ViewRecordingButton({ url }: { url: string }) {
  return <Button onClick={() => navigate(url)}>View recording</Button>;
}

function MeetingPostRequests({
  meeting,
  canManage
}: {
  meeting: MeetingPost_MeetingFragment;
  canManage: boolean;
}) {
  const currentLearner = useCurrentLearner()!;
  const { timeZone } = currentLearner;

  const recordingAvailability = meeting && meeting.recordingAvailability;
  const canSeeRequests =
    canManage && recordingAvailability !== RecordingAvailability.Shown;
  if (!canSeeRequests) {
    return null;
  }
  const requests =
    meeting.recordingRequests.length > 0 ? (
      <React.Fragment>
        {meeting.recordingRequests.map(request => (
          <Box
            key={request.requestedBy.uid}
            sx={{
              marginBottom: "1em"
            }}
          >
            <Box
              sx={{
                color: "grey.500"
              }}
            >
              {Time.formatDateTimeWithFullWeekday(
                request.requestedAt,
                timeZone ?? undefined
              )}
            </Box>
            <Box
              sx={{
                fontWeight: "fontWeightBold"
              }}
            >
              {request.requestedBy.name} requested recording
            </Box>
            <Space y="small" />
            <CollapsedText maxCharacters={180}>{request.message}</CollapsedText>
          </Box>
        ))}
      </React.Fragment>
    ) : null;
  return (
    <Box
      data-test-id="MeetingPost-requests"
      sx={{
        marginTop: "0.5em",
        marginLeft: "1em"
      }}
    >
      {requests}
    </Box>
  );
}

// When a teacher can "Show" a recording, a parent can "request" it.
function canDisplayShowOrRequestRecordingButton(
  recordingAvailability: RecordingAvailability
) {
  return (
    recordingAvailability === RecordingAvailability.Hidden ||
    recordingAvailability === RecordingAvailability.Requestable ||
    recordingAvailability === RecordingAvailability.Requested
  );
}

// Once a recording is shown, a teacher can hide it. Parents don't need
// an action button in this case.
function canDisplayHideRecordingButton(
  recordingAvailability: RecordingAvailability
) {
  return (
    !canDisplayShowOrRequestRecordingButton(recordingAvailability) &&
    recordingAvailability === RecordingAvailability.Shown
  );
}

export function MeetingPostTeacherPreview({
  meeting
}: {
  meeting: MeetingPost_MeetingFragment;
}) {
  const recordingAvailability = meeting && meeting.recordingAvailability;
  if (canDisplayShowOrRequestRecordingButton(recordingAvailability)) {
    return (
      <Box
        sx={{
          marginBottom: "1em"
        }}
      >
        <Typography variant="h3">Share in classroom</Typography>
        This recording was made during a scheduled class meeting. The following
        meeting post already appears in the classroom. Press{" "}
        {'"Share recording"'} below to include a link to the recording in the
        meeting post.
      </Box>
    );
  } else if (canDisplayHideRecordingButton(recordingAvailability)) {
    return (
      <Box
        sx={{
          marginBottom: "1em"
        }}
      >
        <Typography variant="h3">Shared in classroom</Typography>
        This recording was made during a scheduled class meeting. The following
        meeting post already appears in the classroom and includes a link to the
        recording.
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          marginBottom: "1em"
        }}
      >
        <Typography variant="h3">Classroom preview</Typography>
        This recording was made during a scheduled class meeting. The meeting
        appears in the classroom like this:
      </Box>
    );
  }
}
