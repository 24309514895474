import { Box } from "@outschool/backpack";
import { ErrorMessage } from "@outschool/ui-components-shared";
import { Loading } from "@outschool/ui-legacy-component-library";
import { useLoadMoreIfEndOfScreen } from "@outschool/ui-utils";
import { useTitle } from "@patched/hookrouter";
import React, { useState } from "react";
import { useAnalytics } from "use-analytics";

import { useSearchSuggestionsContext } from "../../../../shared/client/providers/SearchSuggestionsProvider";
import { ClassCardsGrid } from "../../components/ClassCards";
import LearnerSearchFilters, {
  useActivityExperiencesFilter
} from "../../components/LearnerSearchFilters";
import * as Env from "../../Env";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { defaultTitle } from "../../lib/PageMetadata";
import { useLearnerActivityByTopicSearchResults } from "../../queries/LearnerActivityByTopicSearchResultsQuery";

type TopicSearchResultsProps = {
  topicUid: string;
  onReachedEndOfResults?(): void;
};

function TopicSearchResults({
  topicUid,
  onReachedEndOfResults
}: TopicSearchResultsProps) {
  const searchSuggestionsContext = useSearchSuggestionsContext();
  const [endOfScreenRef, setEndOfScreenRef] = useState<HTMLDivElement>();
  const { track } = useAnalytics();
  const activityExperiences = useActivityExperiencesFilter();
  const {
    activities,
    topicLabel,
    isFetchingInitial,
    isFetchingMore,
    fetchMoreActivities,
    isInfiniteScrollEnabled,
    error,
    canFetchMore
  } = useLearnerActivityByTopicSearchResults({
    topicUid,
    activityExperiences
  });
  useTitle(
    topicLabel ? `Classes about ${topicLabel} | Outschool` : defaultTitle
  );
  useLoadMoreIfEndOfScreen({
    fetchMore: () => fetchMoreActivities(),
    endOfScreenRef,
    isInfiniteScrollEnabled,
    trackLoadMore: () => track(`browse.search.load_more.topic.${topicLabel}`)
  });

  React.useEffect(() => {
    if (!canFetchMore && !isFetchingMore && !!onReachedEndOfResults) {
      onReachedEndOfResults();
    }
  }, [canFetchMore, isFetchingMore, onReachedEndOfResults]);

  if (isFetchingInitial) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorMessage value={error} showStatusPageLink={Env.IS_READ_ONLY_MODE} />
    );
  }

  if (searchSuggestionsContext?.initialSelectedItem?.uid !== topicUid) {
    searchSuggestionsContext?.setInitialSelectedItem({
      uid: topicUid,
      type: "topic",
      name: topicLabel ?? undefined
    });
  }

  return (
    <>
      <LearnerSearchFilters />
      <Box
        flex
        sx={{
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <ClassCardsGrid
          activities={activities}
          trackingPrefix="browse.search"
        />
        {isFetchingMore && <Loading delay={0} />}
        <Box ref={setEndOfScreenRef}></Box>
      </Box>
    </>
  );
}

export default TopicSearchResults;
