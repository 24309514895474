import { gql } from "@outschool/ui-apollo";

export default gql`
  fragment LearnerDashboard_section on Section {
    uid
    start_time
    end_time
    activity {
      uid
      title
      subject
      duration_weeks
      summary
      weekly_meetings
      is_ongoing_weekly
      isClub
      isFlexSchedule
      details {
        photo
      }
    }
    leader {
      uid
      name
    }
  }
`;
