import { gql, useLazyQuery } from "@outschool/ui-apollo";
import { useEffect } from "react";

import {
  SearchSuggestionsQuery,
  SearchSuggestionsQueryVariables
} from "../../generated/graphql";
import { useSearchSuggestionsContext } from "../providers/SearchSuggestionsProvider";

import type { SearchItem } from "@outschool/ui-components-website";

export const LearnerSearchSuggestions = gql`
  query SearchSuggestions($query: String!, $size: Int) {
    searchSuggestions(search: { query: $query }, size: $size) {
      topicSuggestions {
        uid
        label
        category
      }
      teacherSuggestions {
        uid
        name
        photo
        leader_link
      }
    }
  }
`;

export function useSearchSuggestions(size?: number) {
  const [fetchSuggestions, { data, loading, error }] = useLazyQuery<
    SearchSuggestionsQuery,
    SearchSuggestionsQueryVariables
  >(LearnerSearchSuggestions, { variables: { query: "", size } });

  return {
    loading,
    error,
    fetchSuggestions,
    topicSuggestions: data?.searchSuggestions?.topicSuggestions,
    teacherSuggestions: data?.searchSuggestions?.teacherSuggestions
  };
}

export function useSetSearchSuggestions({
  topicSuggestions,
  teacherSuggestions,
  setSearchSuggestions,
  inputValue
}: {
  topicSuggestions?: SearchSuggestionsQuery["searchSuggestions"]["topicSuggestions"];
  teacherSuggestions?: SearchSuggestionsQuery["searchSuggestions"]["teacherSuggestions"];
  setSearchSuggestions: (value: React.SetStateAction<SearchItem[]>) => void;
  inputValue?: string;
}) {
  useEffect(() => {
    let suggestions: SearchItem[] = [];
    topicSuggestions?.forEach(t => {
      suggestions.push({
        uid: t.uid,
        name: t.label,
        category: t.category ?? undefined,
        type: "topic"
      });
    });
    teacherSuggestions?.forEach(t => {
      suggestions.push({
        uid: t.uid,
        name: t.name || "",
        type: "teacher",
        photo: t.photo,
        leaderLink: t.leader_link
      });
    });
    suggestions.push({
      uid: "keyword",
      name: inputValue,
      type: "keyword"
    });
    setSearchSuggestions(suggestions);
  }, [topicSuggestions, teacherSuggestions, inputValue, setSearchSuggestions]);
}

export function useSelectInitialSearchItem({
  selectComboboxItem,
  selectedComboboxItem,
  setInputValue
}: {
  selectComboboxItem: (item: SearchItem) => void;
  selectedComboboxItem: SearchItem | null;
  setInputValue: (inputValue: string) => void;
}) {
  const searchSuggestionsContext = useSearchSuggestionsContext();
  useEffect(() => {
    if (
      !selectedComboboxItem &&
      searchSuggestionsContext?.initialSelectedItem
    ) {
      const comboboxItemToSelect = searchSuggestionsContext.initialSelectedItem;
      selectComboboxItem(comboboxItemToSelect);
      if (comboboxItemToSelect && comboboxItemToSelect.name) {
        setInputValue(comboboxItemToSelect.name);
      }
    }
  }, [
    searchSuggestionsContext,
    selectComboboxItem,
    selectedComboboxItem,
    setInputValue
  ]);
}
