import {
  LearnerClassroomAssignmentClassPostsBySectionAndLessonQuery,
  LearnerClassroomAssignmentClassPostsBySectionAndLessonQueryVariables
} from "@outschool/gql-frontend-generated";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { MessageContentFragment } from "@outschool/ui-components-classroom";
import { useFeatureFlag } from "@outschool/ui-components-shared";

const classroomAssignmentsQuery = gql`
  query LearnerClassroomAssignmentClassPostsBySectionAndLesson(
    $sectionUid: ID!
    $lessonUid: ID!
  ) {
    assignmentClassPosts(sectionUid: $sectionUid, lessonUid: $lessonUid) {
      uid
      user {
        uid
        name
      }
      title
      messageContent {
        ...MessageContentFragment
      }
      submissionRequirement
      publishAt
      dueDate
      comments {
        uid
        sender {
          uid
          name
        }
        sentAt
        messageContent {
          ...MessageContentFragment
        }
        isSubmission
      }
    }
  }
  ${MessageContentFragment}
`;

export const useLearnerClassroomAssignmentsBySectionAndLessonQuery = (
  variables: LearnerClassroomAssignmentClassPostsBySectionAndLessonQueryVariables
) => {
  const isAssignmentsLearnerUiEnabled = useFeatureFlag(
    "lexi-assignments-learner"
  );

  return useQueryWithPreviousData<
    LearnerClassroomAssignmentClassPostsBySectionAndLessonQuery,
    LearnerClassroomAssignmentClassPostsBySectionAndLessonQueryVariables
  >(classroomAssignmentsQuery, {
    variables,
    skip: !isAssignmentsLearnerUiEnabled
  });
};
