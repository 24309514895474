import { Box, Button, Icon, Typography } from "@outschool/backpack";
import { LearnerActivityDetailsQuery } from "@outschool/gql-frontend-generated";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { faChevronLeft } from "@outschool/icons";
import { getIdFromSlug } from "@outschool/routes";
import { isUuid } from "@outschool/text";
import {
  DemoClubProvider,
  ErrorMessage,
  SampleClubPostsCarousel,
  useClubPostAndCommentDefaultBackgroundColor
} from "@outschool/ui-components-shared";
import { Loading, Space } from "@outschool/ui-legacy-component-library";
import { Screen } from "@outschool/ui-utils";
import React from "react";

import BackToTopButton from "../../components/BackToTopButton";
import { LottieReact, loadLottieMap } from "../../components/Lottie";
import * as Env from "../../Env";
import SimpleLayout from "../../layout/SimpleLayout";
import { useLearnerActivityDetailsQuery } from "../../queries/LearnerActivityDetailsQuery";
import AboutTeacherSection from "./AboutTeacherSection";
import ActivityDetailsHeader from "./ActivityDetailsHeader";
import ClassDescriptionSection from "./ClassDescriptionSection";
import ClassDetailsSection from "./ClassDetailsSection";
import SimilarClassesCarousel from "./SimilarClassesCarousel";

const MAX_WIDTH = 1050;

function LearnerClassDetails({ activitySlugOrUid }) {
  React.useEffect(() => Screen.scrollToTop(), []);

  const slugIdOrUid = getIdFromSlug(activitySlugOrUid);
  const queryVariableName = isUuid(slugIdOrUid) ? "uid" : "slugId";

  const { loading, error, data } = useLearnerActivityDetailsQuery({
    [queryVariableName]: slugIdOrUid
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <ErrorMessage value={error} showStatusPageLink={Env.IS_READ_ONLY_MODE} />
    );
  }
  if (!data || !data.activity) {
    return (
      <ErrorMessage
        value={"Class not found"}
        showStatusPageLink={Env.IS_READ_ONLY_MODE}
      />
    );
  }
  const activity: LearnerActivityDetailsQuery["activity"] = data.activity;

  return (
    <SimpleLayout
      sx={{ backgroundColor: "white !important", overflowX: "hidden" }}
      title={activity.title}
    >
      <Box
        flex
        sx={theme => ({
          padding: "32px 20px",
          pb: "90px",
          background: "none",

          [theme.breakpoints.up("md")]: {
            background: "linear-gradient(#F0F3FF, transparent);"
          }
        })}
      >
        <Box
          sx={{
            maxWidth: MAX_WIDTH,
            margin: "0 auto"
          }}
        >
          <Button
            sx={{
              display: "flex",
              marginBottom: "16px",
              alignItems: "center"
            }}
            onClick={() => window.history.go(-1)}
          >
            <Icon
              icon={faChevronLeft}
              sx={{
                marginRight: "8px"
              }}
            />
            <Typography variant="inherit">Back</Typography>
          </Button>
          <ActivityDetailsHeader activity={activity} />
        </Box>
      </Box>
      <AboutTeacherSection activity={activity} />
      {activity.isClub && (
        <Box
          sx={{
            maxWidth: MAX_WIDTH,
            margin: "0 auto"
          }}
        >
          <Space y="large" />
          <Typography
            variant="h3"
            sx={{
              textAlign: "center"
            }}
          >
            What can learners do in this group?
          </Typography>
          <DemoClubProvider
            LottieReact={LottieReact}
            loadLottieMap={loadLottieMap}
            useClubPostAndCommentDefaultBackgroundColor={
              useClubPostAndCommentDefaultBackgroundColor
            }
            useClubPostAndCommentBackgroundColors={() => ({
              data: { clubPostAndCommentBackgroundColors: ["#255DAD"] },
              loading: false
            })}
          >
            <Box sx={{ marginX: "1em" }}>
              <SampleClubPostsCarousel
                activity={{
                  ...activity,
                  sampleClubPosts: activity.sampleClubPosts || []
                }}
              />
            </Box>
          </DemoClubProvider>
        </Box>
      )}
      <ClassDescriptionSection activity={activity} />
      <ClassDetailsSection activity={activity} />
      <SimilarClassesCarousel activityUid={activity.uid} />
      <Box
        flex
        sx={{
          width: "100%",
          justifyContent: "center"
        }}
      >
        <BackToTopButton
          trackingName={"browse.class.back_to_top"}
          sx={{ marginTop: "2em" }}
        />
      </Box>
      <Space y="large" />
    </SimpleLayout>
  );
}

export default LearnerClassDetails;
