import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { AttachmentFragment } from "@outschool/ui-components-classroom";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  LearnerTeacherPublicProfileQuery,
  LearnerTeacherPublicProfileQueryVariables
} from "../generated/graphql";

const publicProfileQuery = gql`
  query LearnerTeacherPublicProfile($leaderLink: String) {
    publicProfileNew(leader_link: $leaderLink) {
      uid
      leader {
        uid
        name
        photo
        leader_link
        details {
          about
        }
        video {
          ...AttachmentFragment
        }
      }
    }
  }
  ${AttachmentFragment}
`;

export function useLearnerTeacherProfileQuery(
  variables: LearnerTeacherPublicProfileQueryVariables
) {
  const { loading, error, data } =
    useQueryWithPreviousData<LearnerTeacherPublicProfileQuery>(
      publicProfileQuery,
      {
        fetchPolicy: "cache-and-network",
        variables
      }
    );
  const teacher = data?.publicProfileNew?.leader;
  const teacherNotFoundError =
    !loading &&
    !error &&
    !teacher &&
    "Oops! This teacher profile doesn't exist";
  return {
    loading,
    error: error || teacherNotFoundError,
    teacher
  };
}
