import { usePath } from "@patched/hookrouter";
import { useEffect, useState } from "react";
import { useAnalytics } from "use-analytics";

export enum PageType {
  Classroom = "classroom",
  SectionJoin = "classroom/join",
  PrivateClassMessages = "classroom/private-class-messages",
  ClassroomSinglePost = "classroom/posts",
  Lessons = "classroom/lessons",
  Recording = "classroom/recording",
  Club = "group",
  PrivateClubMessages = "group/private-group-messages",
  ClubPost = "group/posts",
  DashboardAll = "dashboard/all",
  DashboardSchedule = "dashboard/schedule",
  DashboardCalendar = "dashboard/calendar",
  FindClasses = "find-classes",
  Profile = "profile",
  ReportIssue = "report-issue",
  SavedActivities = "saved-classes",
  Settings = "settings",
  Assignments = "classroom/assignments",
  Unlimited = "unlimited/classroom"
}

export default function useAnalyticsPage({
  pageName,
  pageParams
}: {
  pageName: PageType;
  pageParams?: {
    sectionUid: string;
    enrollmentUid?: string;
    classPostUid?: string;
    recordingUid?: string;
    classPostCommentUid?: string;
  };
}) {
  const { page } = useAnalytics();
  const currentPath = usePath();
  const [previousPath, setPreviousPath] = useState<string | null>(null);
  const [tracked, setTracked] = useState(false);

  useEffect(() => {
    if (!tracked) {
      page({
        path: currentPath,
        from: previousPath,
        page_name: pageName,
        ...pageParams
      });
      setPreviousPath(currentPath);
      setTracked(true);
    }
  }, [tracked, currentPath, page, pageName, pageParams, previousPath]);
}
