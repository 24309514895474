// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import React from "react";

import {
  CurrentLearnerSavedActivitiesQuery,
  CurrentLearnerSavedActivitiesQueryVariables
} from "../generated/graphql";
import LearnerActivitySearchResultFragment from "./LearnerActivitySearchResultFragment";

export const CurrentLearnerSavedActivities = gql`
  query CurrentLearnerSavedActivities {
    currentLearner {
      uid
      savedActivities {
        uid
        activity_uid
        created_at
        listing {
          activity {
            ...LearnerActivitySearchResultFragment
          }
        }
      }
    }
  }
  ${LearnerActivitySearchResultFragment}
`;

export function useCurrentLearnerSavedActivities() {
  const { loading, error, data } = useQueryWithPreviousData<
    CurrentLearnerSavedActivitiesQuery,
    CurrentLearnerSavedActivitiesQueryVariables
  >(CurrentLearnerSavedActivities);
  const savedActivities = data?.currentLearner?.savedActivities || [];
  return {
    loading,
    error,
    savedActivities
  };
}

function useCurrentLearnerSavedActivityUids() {
  const { savedActivities } = useCurrentLearnerSavedActivities();
  return React.useMemo(
    () => new Set(savedActivities?.map(sa => sa.activity_uid)),
    [savedActivities]
  );
}

export function useIsActivitySavedByCurrentLearner(activity: { uid: string }) {
  const savedActivityUids = useCurrentLearnerSavedActivityUids();
  return Boolean(savedActivityUids.has(activity?.uid));
}
