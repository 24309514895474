import {
  LEARNER_REFRESH_TOKEN_LOCAL_STORAGE_NAME,
  LEARNER_SESSION_TOKEN_LOCAL_STORAGE_NAME,
  LearnerAuth,
  decodeLearnerToken
} from "@outschool/auth-shared";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  useLocalStorageReference,
  useWindowReference
} from "@outschool/local-storage";
import Cookies from "js-cookie";
import React, { useCallback, useContext } from "react";

import { learnerRoutes, websiteRoutes } from "../lib/Routes";

export type { LearnerAuth };

type SetSessionTokenProps =
  | {
      sessionToken: string;
      refreshToken?: string;
    }
  | boolean;

type LearnerAuthContextProps = {
  setSessionToken: (props: SetSessionTokenProps) => void;
  learnerAuth: LearnerAuth | null;
  sessionTokenStore: SessionTokenStore | null;
};

type SetSessionToken = LearnerAuthContextProps["setSessionToken"];

export const LearnerAuthContext = React.createContext<LearnerAuthContextProps>({
  setSessionToken: () => {},
  learnerAuth: null,
  sessionTokenStore: null
});

export const useLearnerAuth = (): LearnerAuthContextProps => {
  return useContext(LearnerAuthContext);
};

type Token = string | null;
// We need a way to store session token without triggering state updates,
// so that we don't recreate apolloClient and refetch queries everytime jwt state changes. (Take a look at createApolloClient)
// If we were storing the session token in cookies, we wouldn't need this solution.
// However since we use localStorage, and there are some devices that block it (e.g. safari incognito mode) we need an alternative
export class SessionTokenStore {
  sessionToken!: Token;
  refreshToken!: Token;
  learnerAuth!: LearnerAuth | null;
  constructor(initialValue: Token, refreshToken: Token) {
    this.set(initialValue);
    this.setRefreshToken(refreshToken);
  }
  set(sessionToken: Token) {
    this.sessionToken = sessionToken;
    this.learnerAuth = sessionToken ? decodeLearnerToken(sessionToken) : null;
  }
  remove() {
    this.sessionToken = null;
    this.learnerAuth = null;
  }
  getSessionToken() {
    return this.sessionToken;
  }
  getRefreshToken() {
    return this.refreshToken;
  }
  setRefreshToken(token: Token) {
    this.refreshToken = token;
  }
  getLearnerAuth() {
    return this.learnerAuth;
  }
}

export function LearnerAuthProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const { window } = useWindowReference();
  const localStorageReference = useLocalStorageReference();
  const params = new URLSearchParams(window?.location.search);
  const enrollmentUid = params.get("enrollmentUid");
  const initialSessionToken =
    Cookies.get("learnerToken") ||
    (localStorageReference.canUseLocalStorage
      ? localStorageReference.localStorage!.getItem(
          LEARNER_SESSION_TOKEN_LOCAL_STORAGE_NAME
        )
      : null);
  const initialRefreshToken = localStorageReference.canUseLocalStorage
    ? localStorageReference.localStorage!.getItem(
        LEARNER_REFRESH_TOKEN_LOCAL_STORAGE_NAME
      )
    : null;

  const sessionTokenStore = new SessionTokenStore(
    initialSessionToken,
    initialRefreshToken
  );
  setTokenToLocalStorage(
    initialSessionToken,
    initialRefreshToken,
    localStorageReference
  );
  const publicPaths = [
    learnerRoutes.loginPath(),
    learnerRoutes.changePasswordPath(),
    "/users/redirect-after-login"
  ];

  const isPublicPath = useCallback(() => {
    return (
      publicPaths.includes(window?.location.pathname || "") ||
      window?.location.pathname.includes("auth-code")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);

  const sessionToken = sessionTokenStore?.getSessionToken();
  // If we have a token or path is /users/login, render the app
  // Otherwise, we don't have a token and we return to the main app
  // - If there is enrollmentUid parameter in the url, that means this link
  // is coming from an email. In this case we redirect user to a special redirect url in the main app
  // which triggers learner app auth flow.
  // - If there is no enrollment uid in the url, simply redirect user to the main app
  if (sessionToken || isPublicPath()) {
    const setSessionToken: SetSessionToken = props => {
      if (typeof props === "boolean") {
        sessionTokenStore.remove();
        removeTokenRemoveLocalStorage(localStorageReference);
      } else {
        const { sessionToken: newToken, refreshToken } = props;
        sessionTokenStore.set(newToken);
        refreshToken && sessionTokenStore.setRefreshToken(refreshToken);
        setTokenToLocalStorage(newToken, refreshToken, localStorageReference);
      }
    };
    const providerValue = {
      sessionTokenStore,
      setSessionToken,
      learnerAuth: sessionTokenStore.getLearnerAuth()
    };
    return (
      <LearnerAuthContext.Provider value={providerValue}>
        {children}
      </LearnerAuthContext.Provider>
    );
  } else if (enrollmentUid) {
    window?.location.replace(
      websiteRoutes.redirectToLearnerUrl({
        learnerAppUrl: window.location.href,
        enrollmentUid
      })
    );
    return null;
  } else {
    window?.location.replace(learnerRoutes.loginPath(window.location.pathname));
    return null;
  }
}

const removeTokenRemoveLocalStorage = ({
  canUseLocalStorage,
  localStorage
}: {
  canUseLocalStorage: boolean;
  localStorage: Storage | undefined;
}) => {
  if (canUseLocalStorage) {
    localStorage?.removeItem(LEARNER_SESSION_TOKEN_LOCAL_STORAGE_NAME);
    localStorage?.removeItem(LEARNER_REFRESH_TOKEN_LOCAL_STORAGE_NAME);
  }
  Cookies.remove("learnerToken");
};

const setTokenToLocalStorage = (
  sessionToken: string | null,
  refreshToken: string | null | undefined,
  {
    canUseLocalStorage,
    localStorage
  }: { canUseLocalStorage: boolean; localStorage: Storage | undefined }
) => {
  if (!sessionToken) {
    removeTokenRemoveLocalStorage({ canUseLocalStorage, localStorage });
    return;
  }
  if (canUseLocalStorage) {
    localStorage?.setItem(
      LEARNER_SESSION_TOKEN_LOCAL_STORAGE_NAME,
      sessionToken
    );
    Cookies.remove("learnerToken");

    if (!!refreshToken) {
      localStorage?.setItem(
        LEARNER_REFRESH_TOKEN_LOCAL_STORAGE_NAME,
        refreshToken
      );
    }
  } else {
    Cookies.set("learnerToken", sessionToken);
  }
};
