// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, SxProps, Typography } from "@outschool/backpack";
import {
  ErrorBoundary,
  LegacyPopover,
  Loading,
  Modal,
  Space
} from "@outschool/ui-legacy-component-library";
import {
  Screen,
  responsive,
  useClickOutsideRefEventListener,
  usePreviousValue
} from "@outschool/ui-utils";
import React from "react";
import { useAnalytics } from "use-analytics";

import useLearnerNotifications from "../../hooks/useLearnerNotifications";
import { useLearnerNotificationsContext } from "../../providers/LearnerNotificationsProvider";
import NotificationListBackground from "./notification-list-bg.png";
import NotificationBellButton from "./NotificationBellButton";
import NotificationGroup from "./NotificationGroup";
import NotificationsReadBanner from "./NotificationsReadBanner";
import ViewMoreNotificationsButton from "./ViewMoreNotificationsButton";

type SharedNotificationPanelProps = ReturnType<
  typeof useLearnerNotifications
> & {
  hidePanel: () => void;
  isPanelOpen: boolean;
};

function NotificationList() {
  const learnerNotificationsContext = useLearnerNotificationsContext();
  const { track } = useAnalytics();
  const [isPanelOpen, setIsPanelOpen] = React.useState<boolean>(false);
  const lastPanelOpenedState = usePreviousValue(isPanelOpen);
  const isMobile = Screen.useIsMobile();

  React.useEffect(() => {
    const eventName = isPanelOpen
      ? "inAppNotifications.panelOpened"
      : "inAppNotifications.panelClosed";
    if (isPanelOpen !== lastPanelOpenedState) {
      track(eventName, {
        hasUnreadNotifications:
          learnerNotificationsContext?.hasUnreadNotifications
      });
    }
  }, [
    track,
    learnerNotificationsContext?.hasUnreadNotifications,
    isPanelOpen,
    lastPanelOpenedState
  ]);

  const hidePanel = () => {
    if (isPanelOpen) {
      setIsPanelOpen(false);
    }
  };

  const onBellButtonClick = () => {
    setIsPanelOpen(isOpen => !isOpen);
  };

  if (!learnerNotificationsContext) {
    return null;
  }

  const {
    notificationGroups,
    hasUnreadNotifications,
    ...useLearnerNotificationsProps
  } = learnerNotificationsContext;

  const sharedPanelProps: SharedNotificationPanelProps = {
    isPanelOpen,
    hidePanel,
    notificationGroups,
    hasUnreadNotifications,
    ...useLearnerNotificationsProps
  };
  return (
    <ErrorBoundary>
      {isMobile ? (
        <Box flex>
          <NotificationBellButton
            isPanelOpen={isPanelOpen}
            onClick={onBellButtonClick}
            hasUnreadNotifications={hasUnreadNotifications}
          />
          <NotificationsModal {...sharedPanelProps} />
        </Box>
      ) : (
        <NotificationsPopover
          {...sharedPanelProps}
          onBellButtonClick={onBellButtonClick}
        />
      )}
    </ErrorBoundary>
  );
}

const BACKGROUND_SX = {
  backgroundImage: `url("${NotificationListBackground}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%"
};

const NotificationsPopover = ({
  hasUnreadNotifications,
  hidePanel,
  isPanelOpen,
  onBellButtonClick,
  ...props
}: SharedNotificationPanelProps & { onBellButtonClick: () => void }) => {
  const [popoverRef, setPopoverRef] = React.useState<HTMLElement | null>(null);

  // this wants a ref but I don't have one, so fake it
  useClickOutsideRefEventListener(document, { current: popoverRef }, hidePanel);

  return (
    <LegacyPopover
      ref={setPopoverRef}
      placement="hide"
      mainPlacement="bottom-end"
      modifiers={{ offset: { offset: "16, 40" } }}
      innerProps={{
        sx: {
          borderRadius: 30,
          height: "calc(100vh - 110px)",
          maxHeight: 1000,
          overflowY: "auto",
          ...BACKGROUND_SX
        }
      }}
      renderButton={ref => (
        <NotificationBellButton
          isPanelOpen={isPanelOpen}
          onClick={onBellButtonClick}
          hasUnreadNotifications={hasUnreadNotifications}
          ref={ref}
        />
      )}
      isHidden={!isPanelOpen}
    >
      <Box
        sx={{
          width: "438px"
        }}
      >
        {isPanelOpen ? (
          <NotificationListContent
            hasUnreadNotifications={hasUnreadNotifications}
            sx={{ width: "100%" }}
            {...props}
          />
        ) : null}
      </Box>
    </LegacyPopover>
  );
};

const NotificationsModal = ({
  hidePanel,
  isPanelOpen,
  ...props
}: SharedNotificationPanelProps) => (
  <Modal
    open={isPanelOpen}
    onClose={hidePanel}
    fullBleed={false}
    sx={{
      width: responsive({ default: "initial", small: "calc(100% - 42px)" }),
      height: responsive({ default: "initial", small: "calc(100% - 120px)" }),
      overflowY: "auto",
      borderRadius: 8,
      ...BACKGROUND_SX
    }}
  >
    <NotificationListContent {...props} />
  </Modal>
);

type NotificationListContentProps = ReturnType<
  typeof useLearnerNotifications
> & {
  sx?: SxProps;
};

const NotificationListContent = ({
  hasUnreadNotifications,
  notificationGroups,
  loadingPastNotifications,
  hasMorePastNotifications,
  loading,
  fetchMorePastNotifications,
  sx = {}
}: NotificationListContentProps) => {
  return (
    <Box
      sx={[
        theme => ({
          paddingX: 0,

          [theme.breakpoints.up("md")]: {
            paddingX: "28px",
            paddingY: "2em"
          },

          paddingY: "1em"
        }),
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: 800
        }}
      >
        Notifications
      </Typography>
      <Space y={20} />
      {!hasUnreadNotifications && <NotificationsReadBanner />}
      {notificationGroups.map((notificationGroup, index) => (
        <React.Fragment key={notificationGroup[0].uid}>
          <NotificationGroup notificationGroup={notificationGroup} />
          {index !== notificationGroups.length - 1 && <Space y="medium" />}
        </React.Fragment>
      ))}
      {hasMorePastNotifications &&
        (loadingPastNotifications ? (
          <Loading />
        ) : (
          <ViewMoreNotificationsButton
            onClick={() => fetchMorePastNotifications()}
            disabled={loading}
          />
        ))}
    </Box>
  );
};

export default NotificationList;
