import { Box, Icon, SxProps, Typography } from "@outschool/backpack";
import { LearnerActivityDetailsQuery } from "@outschool/gql-frontend-generated";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  IconDefinition,
  faBirthdayCake,
  faCalendarAlt,
  faClock
} from "@outschool/icons";
import { displayPerStudentPrice } from "@outschool/localization";
import { dayjs } from "@outschool/time";
import { useActivityString } from "@outschool/ui-components-website";
import _ from "lodash";
import React from "react";

import * as Activity from "../../../../shared/Activity";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import ClassDetailsActionButtons from "./ClassDetailsActionButtons";

const DAYS = [
  "Sundays",
  "Mondays",
  "Tuesdays",
  "Wednesdays",
  "Thursdays",
  "Fridays",
  "Saturdays"
];

const MAX_WIDTH = 1050;

const DEFAULT_TIME_ZONE = "America/Los_Angeles";

function ClassDetail({
  icon,
  text,
  sx = {},
  children
}: {
  icon?: IconDefinition;
  text: React.ReactNode;
  sx?: SxProps;
  children?: React.ReactNode;
}) {
  return (
    <Box
      flex
      sx={[
        {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "grey.700",
          px: "1em",
          py: "0.5em",
          background: "rgba(255, 255, 255, 0.5)",
          border: "2px solid rgba(137, 186, 201, 0.4)",
          borderRadius: 8
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {icon && (
        <Icon
          sx={{
            mr: "0.5em",
            fontSize: "24"
          }}
          icon={icon}
        />
      )}
      {text}
      {children}
    </Box>
  );
}

const ClassDetailsSection = React.forwardRef(
  (
    {
      activity
    }: {
      activity: NonNullable<LearnerActivityDetailsQuery["activity"]>;
    },
    ref
  ) => {
    const currentLearner = useCurrentLearner();
    const { ageRangeString } = useActivityString();

    const { duration_minutes, promotableSections: sections } = activity;

    const timeZone = currentLearner?.timeZone || DEFAULT_TIME_ZONE;

    const format = _.capitalize(Activity.format(activity));
    const course =
      format === Activity.ACTIVITY_FORMAT.ONE_TIME ? "class" : "course";
    const scheduleText = activity.isClub ? "24/7 Group" : `${format} ${course}`;

    const upcomingSections = sections.filter(
      s =>
        (!!activity.is_ongoing_weekly &&
          dayjs(s.end_time).tz(timeZone).isAfter(dayjs().tz(timeZone))) ||
        dayjs(s.start_time).tz(timeZone).isAfter(dayjs().tz(timeZone))
    );

    const upcomingSectionsDays = upcomingSections
      .map(s => dayjs(s.start_time).tz(timeZone).format("d"))
      .sort()
      .map(day => DAYS[day]);

    const uniqueDays = Array.from(new Set(upcomingSectionsDays));

    return (
      <Box
        ref={ref}
        sx={theme => ({
          textAlign: "center",
          paddingY: 0,

          [theme.breakpoints.up("md")]: {
            paddingY: "48px",
            background: "linear-gradient(#F0F3FF, transparent);"
          },

          paddingX: "20px",
          background: "none"
        })}
      >
        <Typography
          id="class-details"
          variant="h2"
          sx={{
            marginBottom: "24px",
            color: "grey.900"
          }}
        >
          {activity.isClub ? "Group" : "Class"} Details
        </Typography>
        <Box
          sx={theme => ({
            margin: "auto",
            columnGap: "0.5em",
            rowGap: "0.5em",
            maxWidth: MAX_WIDTH,
            display: "grid",
            gridAutoFlow: "row",

            [theme.breakpoints.up("lg")]: {
              gridAutoFlow: "column"
            }
          })}
        >
          {!activity.isClub && (
            <ClassDetail
              icon={faClock}
              text={
                <Box
                  sx={{
                    display: "block"
                  }}
                >
                  <Box>{`${duration_minutes} minutes`}</Box>
                  <Box>per class</Box>
                </Box>
              }
            />
          )}
          <ClassDetail icon={faCalendarAlt} text={scheduleText} />
          <ClassDetail icon={faBirthdayCake} text={ageRangeString(activity)} />
          <ClassDetail text={displayPerStudentPrice(activity, null, null)} />
        </Box>
        {!activity.isClub && (
          <ClassDetail
            sx={{
              flexDirection: "column",
              maxWidth: MAX_WIDTH,
              margin: "auto",
              mt: "0.5em",
              py: "1em"
            }}
            text={
              uniqueDays.length > 0
                ? `Available on ${uniqueDays.join(", ")}`
                : "No upcoming sessions"
            }
          ></ClassDetail>
        )}
        <Box
          flex
          sx={{
            my: "32px",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <ClassDetailsActionButtons activity={activity} />
        </Box>
      </Box>
    );
  }
);

export default ClassDetailsSection;
