import { Button } from "@outschool/backpack";
import {
  MarkLessonCompleteButtonMutation,
  MarkLessonCompleteButtonMutationVariables
} from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import {
  DocumentNode,
  getQueryNames,
  gql,
  useMutation
} from "@outschool/ui-apollo";
import { Loading } from "@outschool/ui-legacy-component-library";
import React from "react";

import { ClassroomQuery } from "../../../queries/LearnerClassroomQuery";

export const MarkLessonCompleteForLearner = gql`
  mutation MarkLessonCompleteButton($lessonUid: ID!, $sectionUid: ID!) {
    markLessonCompleteForLearner(lessonUid: $lessonUid, sectionUid: $sectionUid)
  }
`;

export default function MarkLessonCompleteButton({
  lessonUid,
  sectionUid,
  onCompleted,
  queriesToRefetch = [ClassroomQuery]
}: {
  lessonUid: string;
  sectionUid: string;
  onCompleted?: () => void;
  queriesToRefetch?: DocumentNode[];
}) {
  const { t } = useTranslation(
    "learnerApp\\src\\routes\\LearnerClassroom\\lessonsPage\\MarkLessonCompleteButton"
  );
  const [markLessonComplete, { loading }] = useMutation<
    MarkLessonCompleteButtonMutation,
    MarkLessonCompleteButtonMutationVariables
  >(MarkLessonCompleteForLearner, {
    variables: { lessonUid, sectionUid },
    refetchQueries: getQueryNames(queriesToRefetch),
    awaitRefetchQueries: true,
    onCompleted
  });

  return loading ? (
    <Loading sx={{ margin: "auto" }} />
  ) : (
    <Button variant="contained" onClick={() => markLessonComplete()}>
      {t("Mark Lesson as Completed")}
    </Button>
  );
}
