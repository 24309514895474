import { Box, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  ErrorMessage,
  UserHeadshotImage
} from "@outschool/ui-components-shared";
import { Loading } from "@outschool/ui-legacy-component-library";
import { useTitle } from "@patched/hookrouter";
import React from "react";

import { useSearchSuggestionsContext } from "../../../../shared/client/providers/SearchSuggestionsProvider";
import { ClassCardsGrid } from "../../components/ClassCards";
import TeacherDetails from "../../components/TeacherDetails";
import * as Env from "../../Env";
import { defaultTitle } from "../../lib/PageMetadata";
import { useLearnerSearchTeacherActivitiesQuery } from "../../queries/TeacherActivitiesQuery";
import { useLearnerTeacherProfileQuery } from "../../queries/TeacherProfileQuery";

function TeacherSearchResult({ leaderLink }) {
  const { loading, error, teacher } = useLearnerTeacherProfileQuery({
    leaderLink
  });

  useTitle(teacher ? `${teacher.name} | Outschool` : defaultTitle);
  const searchSuggestions = useSearchSuggestionsContext();
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorMessage value={error} showStatusPageLink={Env.IS_READ_ONLY_MODE} />
    );
  }

  if (!teacher) {
    return <ErrorMessage value="Oops! This teacher profile doesn't exist." />;
  }

  if (searchSuggestions?.initialSelectedItem?.uid !== teacher.uid) {
    searchSuggestions?.setInitialSelectedItem({
      uid: teacher.uid,
      type: "teacher",
      photo: teacher?.photo,
      name: teacher?.name ?? undefined,
      leaderLink: teacher.leader_link
    });
  }

  return (
    <Box>
      <Box
        flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "32px"
        }}
      >
        <UserHeadshotImage
          user={teacher}
          size={64}
          sx={{ marginRight: "16px" }}
        />
        <Typography variant="h2">{teacher?.name}</Typography>
      </Box>
      {teacher && (
        <TeacherDetails
          teacher={teacher}
          videoSx={{ maxHeight: "300px" }}
          trackingPrefix={`browse.search.teacher.${teacher.uid}`}
          showMoreClassesButton={false}
        />
      )}
      <Box
        flex
        sx={{
          paddingTop: "48px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <TeacherActivities teacher={teacher} />
      </Box>
    </Box>
  );
}

export function TeacherActivities({ teacher }) {
  const { loading, error, activities } = useLearnerSearchTeacherActivitiesQuery(
    {
      teacherUid: teacher?.uid
    }
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorMessage value={error} showStatusPageLink={Env.IS_READ_ONLY_MODE} />
    );
  }

  if (!activities || activities?.length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant="h2">Classes taught by {teacher?.name}</Typography>
      <ClassCardsGrid activities={activities} trackingPrefix="browse.search" />
    </>
  );
}

export default TeacherSearchResult;
