import {
  BackpackThemeProvider,
  Box,
  Grid,
  Icon,
  IconColor,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  Theme,
  Typography
} from "@outschool/backpack";
import { LearningStatementsVerb } from "@outschool/gql-frontend-generated";
import {
  IconDefinition,
  faCheckCircle,
  faChevronRightRegular,
  faLockRegular
} from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { LearnerRoutes } from "@outschool/routes";
import { LegacyThemeProvider } from "@outschool/ui-legacy-component-library";
import { Redirect, useIsMobile, useNavigation } from "@outschool/ui-utils";
import React from "react";

import useAnalyticsPage, {
  PageType
} from "../../../lib/analytics/useAnalyticsPage";
import {
  Lesson,
  useUnlimitedClassroomContext
} from "../../../providers/UnlimitedClassroomProvider";
import {
  AllLessonsCompletedView,
  LessonView,
  LessonViewHeading
} from "./LessonView";

const ALL_LESSONS_COMPLETE_TAB_PANEL_KEY = "lessons-complete";

interface ClassroomLessonsPageProps {
  lessonUid?: string;
}
export const UnlimitedLessonsPage = ({
  lessonUid
}: ClassroomLessonsPageProps) => {
  const { t } = useTranslation("learnerApp\\UnlimitedLessonsPage");
  const { sectionUid, syllabus, hasLessons, allLessonsCompleted } =
    useUnlimitedClassroomContext();
  useAnalyticsPage({
    pageName: PageType.Unlimited,
    pageParams: { sectionUid }
  });

  if (!hasLessons) {
    return <Redirect to={LearnerRoutes.unlimitedLessonsPath(sectionUid)} />;
  }

  const lessons: Lesson[] = syllabus?.lessons || [];

  if (!lessonUid) {
    if (allLessonsCompleted) {
      lessonUid = ALL_LESSONS_COMPLETE_TAB_PANEL_KEY;
    } else {
      const currentLesson = syllabus?.currentLesson;
      const firstLesson = currentLesson || lessons[0];
      return (
        <Redirect
          to={
            firstLesson
              ? LearnerRoutes.unlimitedLessonsPath(sectionUid, firstLesson.uid)
              : // TODO: This is error, maybe redirect back to parent
                LearnerRoutes.classroomPath(sectionUid)
          }
        />
      );
    }
  }

  // LegacyThemeProvider injects breakpoints which completely breaks how Grid works in MUI
  // Make BackpackThemeProvider wrap it immediately to avoid even referencing the LegacyThemeProvider
  return (
    <BackpackThemeProvider>
      <TabContext value={lessonUid}>
        <Grid container spacing={48} sx={{ flex: 1, paddingTop: 36 }}>
          <Grid xs={12} md={4}>
            <LessonsNavigation selectedLessonUid={lessonUid} />
          </Grid>
          <Grid
            xs={12}
            md={8}
            sx={(theme: Theme) => ({
              [theme.breakpoints.down("md")]: {
                marginX: 16
              }
            })}
          >
            <LegacyThemeProvider>
              {lessons.map((lesson: Lesson) => (
                <TabPanel key={lesson.uid} value={lesson.uid}>
                  <SelectedLessonView lesson={lesson} />
                </TabPanel>
              ))}
              <TabPanel
                key={ALL_LESSONS_COMPLETE_TAB_PANEL_KEY}
                value={ALL_LESSONS_COMPLETE_TAB_PANEL_KEY}
              >
                <AllLessonsCompletedView
                  heading={
                    <LessonViewHeading
                      text={t("You've Completed This Course")}
                    />
                  }
                />
              </TabPanel>
            </LegacyThemeProvider>
          </Grid>
        </Grid>
      </TabContext>
    </BackpackThemeProvider>
  );
};

interface SelectedLessonViewProps {
  lesson: Lesson;
}
const SelectedLessonView = ({ lesson }: SelectedLessonViewProps) => {
  const { t } = useTranslation("learnerApp\\UnlimitedLessonsPage");
  const { syllabus } = useUnlimitedClassroomContext();

  // Learner can only select unlocked lessons for now and either they are completed or upcoming but nothing in between until we have a missed class view
  let heading = t("What's Next");
  if (lesson.lessonStatus === LearningStatementsVerb.Completed) {
    heading = t("Review What You’ve Learned");
  }

  if (syllabus?.currentLesson?.uid === lesson.uid) {
    // Current lesson view
    return (
      <LessonView
        heading={<Typography variant="h3">{heading}</Typography>}
        lesson={lesson}
      />
    );
  }

  return (
    <LessonView
      heading={<Typography variant="h3">{heading}</Typography>}
      lesson={lesson}
    />
  );
};

type LessonsNavigationProps = {
  selectedLessonUid: string;
};
const LessonsNavigation = React.memo(
  ({ selectedLessonUid }: LessonsNavigationProps) => {
    const { t } = useTranslation("learnerApp\\UnlimitedLessonsPage");
    const { sectionUid, syllabus } = useUnlimitedClassroomContext();
    const { lessons: sectionLessons } = syllabus ?? { lessons: [] };

    const navigate = useNavigation();
    const isMobile = useIsMobile();

    const onLessonClick = (_event: React.SyntheticEvent, lessonUid: string) => {
      // TODO make URL change without page reload
      navigate(LearnerRoutes.unlimitedLessonsPath(sectionUid, lessonUid));
    };

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 24 }}>
        <Typography variant="h4">{t("Lessons")}</Typography>
        <TabList
          onChange={onLessonClick}
          /* eslint-disable i18next/no-literal-string */
          orientation={isMobile ? "horizontal" : "vertical"}
          scrollButtons={isMobile ? true : "auto"}
          variant={isMobile ? "scrollable" : "standard"}
          TabIndicatorProps={{
            sx: {
              width: "3px"
            }
          }}
          /* eslint-enable i18next/no-literal-string */
        >
          {sectionLessons.map((lesson: Lesson) => {
            const lessonIsSelected = selectedLessonUid === lesson.uid;

            return (
              <Tab
                key={lesson.uid}
                value={lesson.uid}
                label={
                  <LessonNavigationLabel
                    title={
                      <>
                        {t("Lesson {{lessonNumber}}", {
                          lessonNumber: lesson.lessonNumber ?? ""
                        })}
                        <LessonStatusIcon
                          isDisabled={lesson.isLocked}
                          lessonStatus={lesson.lessonStatus}
                        />
                      </>
                    }
                    description={lesson.title}
                    selected={lessonIsSelected}
                  />
                }
                icon={
                  isMobile ? undefined : lessonIsSelected ? (
                    <Icon
                      icon={faChevronRightRegular}
                      color={"primary"}
                      size="small"
                    />
                  ) : (
                    <></>
                  )
                }
                // eslint-disable-next-line i18next/no-literal-string
                iconPosition="end"
                disabled={lesson.isLocked}
                sx={{
                  // Put Tab content on left, and icon on right
                  justifyContent: "space-between",
                  padding: 16,
                  ...(lessonIsSelected
                    ? {
                        color: "primary.main",
                        backgroundColor: "primary.50"
                      }
                    : {
                        borderBottom: "1px solid",
                        borderColor: "neutral.200"
                      })
                }}
              />
            );
          })}
        </TabList>
      </Box>
    );
  }
);

interface LessonNavigationLabelProps {
  title: React.ReactNode;
  description?: string | null;
  selected?: boolean;
}
const LessonNavigationLabel = ({
  title,
  description,
  selected
}: LessonNavigationLabelProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    }}
  >
    <Typography
      variant="h6"
      sx={{
        color: selected ? "primary.main" : "text.primary"
      }}
      gutterBottom
    >
      {title}
    </Typography>
    {description && (
      <Typography variant="body2" sx={{ textAlign: "left" }}>
        {description}
      </Typography>
    )}
  </Box>
);

const LessonStatusIcon = ({ isDisabled, lessonStatus }) => {
  const statusIcon: { icon: IconDefinition; color: IconColor } | null =
    isDisabled
      ? { icon: faLockRegular, color: "error" }
      : lessonStatus === LearningStatementsVerb.Completed
      ? {
          icon: faCheckCircle,
          color: "success"
        }
      : null;
  if (!statusIcon) {
    return null;
  }

  return (
    <Icon
      icon={statusIcon.icon}
      color={statusIcon.color}
      sx={{ marginLeft: 8 }}
    />
  );
};
