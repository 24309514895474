// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, SxProps } from "@outschool/backpack";
import { useTitle } from "@patched/hookrouter";
import React from "react";

import { defaultTitle } from "../lib/PageMetadata";
import Footer from "./nav/Footer";
import Header from "./nav/Header";

type SimpleLayoutProps = {
  children: React.ReactNode;
  title?: string;
  sx?: SxProps;
};

const SimpleLayout = React.forwardRef<React.ReactNode, SimpleLayoutProps>(
  ({ children, title, sx }, ref) => {
    useTitle(title ? `${title} | Outschool` : defaultTitle);
    return (
      <Box
        flex
        sx={[
          {
            flexDirection: "column",
            minHeight: "100vh",
            isolation: "isolate"
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
        ref={ref}
      >
        <Header
          sx={{
            position: "relative",
            backgroundColor: "white",
            borderColor: "#f1f2f3",
            minHeight: 60,
            boxShadow: "0px 2px 5px rgba(23, 25, 28, 0.1)"
          }}
          includeFindClasses
        />
        <Box>{children}</Box>
        <Footer sx={{ marginTop: "2em" }} />
      </Box>
    );
  }
);

export default SimpleLayout;
