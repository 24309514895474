import { gql } from "@outschool/ui-apollo";

export const requestPasswordChangeMutation = gql`
  mutation requestPasswordChange($emailOrUsername: String!) {
    requestPasswordChange(emailOrUsername: $emailOrUsername)
  }
`;

export const requestPinChangeMutation = gql`
  mutation requestPinChange($email: String!) {
    requestPinChange(email: $email)
  }
`;

export const verifyParentTransferToken = gql`
  mutation VerifyParentTransfer($token: String!, $isParentLogin: Boolean!) {
    verifyParentTransfer(token: $token, isParentLogin: $isParentLogin) {
      sessionToken
      ... on AuthenticationV2 {
        refreshToken
      }
    }
  }
`;
