// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

export const LottieReact = React.lazy(
  () => import(/* webpackChunkName: "Lottie" */ "lottie-react")
);

export const loadLottieMap = async () => {
  // Note that `import` will return the same instance on subsequent calls, see
  // bullet point in https://tc39.es/ecma262/#sec-hostresolveimportedmodule
  const { default: lottieMap } = await import(
    /* webpackChunkName: "Lottie" */
    "@outschool/animation-resources/dist-esm/lotties"
  );
  return lottieMap;
};
