import { INotification } from "@outschool/gql-backend-generated";
import { AssignmentNotificationFragmentFragment } from "@outschool/gql-frontend-generated";
import { assignmentsPath } from "@outschool/routes";
import { dayjs } from "@outschool/time";
import _ from "lodash";

import {
  AssignmentNotificationAction,
  ClassPostNotificationAction,
  ClassPostNotificationFragmentFragment,
  ClubNotificationAction,
  ClubNotificationFragmentFragment,
  LearnerNotificationsQuery,
  PrivateClassMessageNotificationFragmentFragment
} from "../../generated/graphql";
import exhaustiveCheck from "../../lib/exhaustiveCheck";
import {
  classroomPostPath,
  clubPostCommentPath,
  clubPostPath,
  privateClassMessagesPath,
  privateClubMessagesPath
} from "../../lib/Routes";

export type Notification = NonNullable<
  LearnerNotificationsQuery["learnerNotifications"]
>["results"][0];
export type NotificationActionGroup = "Classroom" | "Group" | "Messages";
export type NotificationGroups = Notification[][];

/*
User story: As a learner, if I receive multiple notifications for similar content,
they will be batched together. Ex: “1 notification for 10 comments on this thread”
instead of “10 notifications for same thread”
*/

export function getGroupedNotifications(
  notifications: Notification[]
): NotificationGroups {
  const notificationGroups = Object.values(
    _.groupBy(notifications, notification => {
      const groupByUid = isReplyToClubPostAction(notification)
        ? notification.clubClassPostUid
        : isReplyToClubCommentAction(notification)
        ? notification.threadParentCommentUid
        : isNewClassPostCommentAction(notification)
        ? notification.classroomClassPostUid
        : isNewAssignmentCommentNotification(notification)
        ? notification.assignmentUid
        : isPrivateClassMessageNotification(notification)
        ? notification.privateClassMessageSectionUid
        : // use notification.uid to indicate no grouping
          (notification as INotification).uid;
      return [
        getGroupKeyPrefix(notification),
        notification.status,
        groupByUid
      ].join(" ");
    })
  );
  const sortedNotificationGroups = _.orderBy(
    notificationGroups,
    [notificationGroup => notificationGroup[0].createdAt],
    "desc"
  );

  return sortedNotificationGroups;
}

/**
 * Get a unique string based on a notification's type and action
 */
function getGroupKeyPrefix(notification: Notification) {
  return isReplyToClubCommentAction(notification)
    ? "reply-to-club-comment"
    : isReplyToClubPostAction(notification)
    ? "reply-to-club-post"
    : isNewClassPostCommentAction(notification)
    ? "new-class-post-comment"
    : isPrivateClassMessageNotification(notification)
    ? "new-private-class-message"
    : "ungrouped";
}

const isClubNotification = (
  notification: Notification
): notification is ClubNotificationFragmentFragment =>
  notification.__typename === "ClubNotification";

const isClassPostNotification = (
  notification: Notification
): notification is ClassPostNotificationFragmentFragment =>
  notification.__typename === "ClassPostNotification";

const isAssignmentNotification = (
  notification: Notification
): notification is AssignmentNotificationFragmentFragment =>
  notification.__typename === "AssignmentNotification";

type ReplyToClubCommentNotificationFragment =
  ClubNotificationFragmentFragment & {
    clubNotificationAction: ClubNotificationAction.RepliedToComment;
  };
const isReplyToClubCommentAction = (
  notification: Notification
): notification is ReplyToClubCommentNotificationFragment =>
  isClubNotification(notification) &&
  notification.clubNotificationAction ===
    ClubNotificationAction.RepliedToComment;

type ReplyToPostClubNotificationFragment = ClubNotificationFragmentFragment & {
  clubNotificationAction: ClubNotificationAction.RepliedToPost;
};
const isReplyToClubPostAction = (
  notification: Notification
): notification is ReplyToPostClubNotificationFragment =>
  isClubNotification(notification) &&
  notification.clubNotificationAction === ClubNotificationAction.RepliedToPost;

const isNewClassPostCommentAction = (
  notification: Notification
): notification is ClassPostNotificationFragmentFragment =>
  isClassPostNotification(notification) &&
  notification.classPostNotificationAction ===
    ClassPostNotificationAction.NewClassPostComment;

const isNewAssignmentCommentNotification = (
  notification: Notification
): notification is AssignmentNotificationFragmentFragment =>
  isAssignmentNotification(notification) &&
  notification.assignmentNotificationAction ===
    AssignmentNotificationAction.NewAssignmentComment;

const isPrivateClassMessageNotification = (
  notification: Notification
): notification is PrivateClassMessageNotificationFragmentFragment =>
  notification.__typename === "PrivateClassMessageNotification";

export function getNotificationActionGroup(
  typename: Notification["__typename"]
): NotificationActionGroup {
  switch (typename) {
    case "ClassPostNotification":
      return "Classroom";
    case "AssignmentNotification":
      return "Classroom";
    case "ClubNotification":
      return "Group";
    case "PrivateClassMessageNotification":
      return "Messages";
    default:
      return exhaustiveCheck<NotificationActionGroup>(typename);
  }
}

export function getNotificationMessage(
  notification: Notification,
  senderNames: string
): string {
  switch (notification.__typename) {
    case "ClassPostNotification":
      switch (notification.classPostNotificationAction) {
        case ClassPostNotificationAction.Mentioned:
          return `${senderNames} mentioned you in a post`;
        case ClassPostNotificationAction.NewClassPost:
          return `${senderNames} created a post`;
        case ClassPostNotificationAction.NewClassPostComment:
          return `${senderNames} replied to a post you participated in`;
        default:
          return exhaustiveCheck<ClassPostNotificationAction>(
            notification.classPostNotificationAction
          );
      }
    case "AssignmentNotification":
      switch (notification.assignmentNotificationAction) {
        case AssignmentNotificationAction.NewAssignment:
          return `${senderNames} added a new assignment`;
        case AssignmentNotificationAction.NewAssignmentComment:
          return `${senderNames} gave feedback on your assignment`;
        default:
          return exhaustiveCheck<AssignmentNotificationAction>(
            notification.assignmentNotificationAction
          );
      }
    case "ClubNotification":
      switch (notification.clubNotificationAction) {
        case ClubNotificationAction.Mentioned:
          return `${senderNames} mentioned you in a post`;
        case ClubNotificationAction.PostPromoted:
          return `${senderNames} promoted a post`;
        case ClubNotificationAction.RepliedToPost:
          return `${senderNames} commented on your post`;
        case ClubNotificationAction.RepliedToComment:
          return `${senderNames} replied to your comment`;
        default:
          return exhaustiveCheck<ClubNotificationAction>(
            notification.clubNotificationAction
          );
      }
    case "PrivateClassMessageNotification":
      return `${senderNames} sent you a message`;
    default:
      return exhaustiveCheck(notification);
  }
}

export function getSenderNamesString(notificationGroup: Notification[]) {
  const senderNames = _.uniqBy(notificationGroup, n => n.sender.uid).map(
    notification => notification.sender.name
  );
  return senderNames.length > 2
    ? `${senderNames[0]} and ${senderNames.length - 1} others`
    : senderNames.join(" & ");
}

const HOUR_IN_MINUTES = 60;
const DAY_IN_MINUTES = 60 * 24;

export function getNotificationTimeFromNow(createdAt: Date) {
  const minutes = Math.max(1, dayjs().diff(createdAt, "minute"));
  if (minutes < HOUR_IN_MINUTES) {
    return `${minutes}m`;
  } else if (minutes < DAY_IN_MINUTES) {
    return `${Math.floor(minutes / HOUR_IN_MINUTES)}h`;
  } else {
    return `${Math.floor(minutes / DAY_IN_MINUTES)}d`;
  }
}

export function getNotificationRoute(
  notificationGroup: Notification[]
): string {
  const notification = notificationGroup[0];
  switch (notification.__typename) {
    case "ClassPostNotification":
      return classroomPostPath(
        notification.classroomSectionUid,
        notification.classroomClassPostUid
      );
    case "AssignmentNotification":
      return assignmentsPath(notification.assignmentSectionUid);
    case "ClubNotification":
      switch (notification.clubNotificationAction) {
        case ClubNotificationAction.Mentioned:
        case ClubNotificationAction.PostPromoted:
          const threadUid =
            notification.threadParentCommentUid ??
            notification.classPostCommentUid;
          return threadUid
            ? clubPostCommentPath({
                sectionUid: notification.clubSectionUid,
                classPostUid: notification.clubClassPostUid,
                classPostCommentUid: threadUid
              })
            : clubPostPath({
                sectionUid: notification.clubSectionUid,
                classPostUid: notification.clubClassPostUid
              });
        case ClubNotificationAction.RepliedToPost:
          return notificationGroup.length === 1
            ? clubPostCommentPath({
                sectionUid: notification.clubSectionUid,
                classPostUid: notification.clubClassPostUid,
                classPostCommentUid: notification.uid
              })
            : clubPostPath({
                sectionUid: notification.clubSectionUid,
                classPostUid: notification.clubClassPostUid
              });

        case ClubNotificationAction.RepliedToComment:
          return clubPostCommentPath({
            sectionUid: notification.clubSectionUid,
            classPostUid: notification.clubClassPostUid,
            classPostCommentUid: notification.threadParentCommentUid!
          });
        default:
          return exhaustiveCheck(notification.clubNotificationAction);
      }
    case "PrivateClassMessageNotification":
      return notification.isClub
        ? privateClubMessagesPath({
            sectionUid: notification.privateClassMessageSectionUid
          })
        : privateClassMessagesPath(notification.privateClassMessageSectionUid);
    default:
      return exhaustiveCheck(notification);
  }
}
