// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { learnerSavedClassesPath } from "@outschool/routes";
import { useTitle } from "@patched/hookrouter";
import React from "react";

import { HeroLayoutWithTabs } from "../../layout/HeroLayoutWithTabs";
import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import SavedActivitiesList from "./SavedActivitiesList";

const SavedActivities = () => {
  useTitle("Saved Classes | Outschool");
  useAnalyticsPage({ pageName: PageType.SavedActivities });

  return (
    <HeroLayoutWithTabs currentPath={learnerSavedClassesPath()}>
      <SavedActivitiesList />
    </HeroLayoutWithTabs>
  );
};

export default SavedActivities;
