// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { FilestackImage } from "@outschool/ui-components-shared";
import { Avatar } from "@outschool/ui-legacy-component-library";
import React from "react";

const DEFAULT_PROFILE_IMAGE =
  "https://cdn.filestackcontent.com/26Xc9cgQFmpVTv8h268V";

export default function ParentImage({ user, size = 125, sx, ...props }) {
  const src = user && user.photo ? user.photo : DEFAULT_PROFILE_IMAGE;

  return (
    <FilestackImage
      as={Avatar}
      src={src}
      imageWidth={size}
      imageHeight={size}
      size={size}
      sx={{
        flexShrink: 0,
        ...sx
      }}
      alt=""
      {...props}
    />
  );
}
