import {
  ProfilePage,
  useLearnerProfileQuery
} from "@outschool/ui-components-classroom";
import { ErrorMessage } from "@outschool/ui-components-shared";
import { Loading } from "@outschool/ui-legacy-component-library";
import { navigate } from "@patched/hookrouter";
import React, { useEffect, useMemo } from "react";

import { hasAccess } from "../../../../shared/Learner";
import VideoPlayer from "../../components/VideoPlayer";
import * as Env from "../../Env";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { LearnerAccessLevel } from "../../generated/graphql";
import Footer from "../../layout/nav/Footer";
import Header from "../../layout/nav/Header";
import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import { learnerRoutes } from "../../lib/Routes";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";

interface LearnerProfileProps {
  learnerUid: string;
}

function LearnerProfile({ learnerUid }: LearnerProfileProps) {
  useAnalyticsPage({ pageName: PageType.Profile });
  const currentLearner = useCurrentLearner();
  const { profile, loading, error } = useLearnerProfileQuery(learnerUid);
  const doesLearnerHaveAccess = useMemo(
    () =>
      currentLearner &&
      hasAccess({
        currentLearner,
        minimumAccessLevel: LearnerAccessLevel.Full
      }),
    [currentLearner]
  );

  useEffect(() => {
    if (currentLearner && !doesLearnerHaveAccess) {
      navigate("/");
    }
  }, [currentLearner, doesLearnerHaveAccess]);

  const isProfileEditable = useMemo(() => {
    if (!currentLearner || !profile) {
      return false;
    }
    return currentLearner.uid === profile.uid;
  }, [currentLearner, profile]);

  if (error) {
    return (
      <ErrorMessage value={error} showStatusPageLink={Env.IS_READ_ONLY_MODE} />
    );
  }

  if (!profile || loading) {
    return <Loading />;
  }

  if (doesLearnerHaveAccess) {
    return (
      <ProfilePage
        header={<Header includeBlob />}
        footer={<Footer />}
        learner={profile}
        isEditable={isProfileEditable}
        activityUrl={learnerRoutes.activityPath}
        VideoPlayer={VideoPlayer}
      />
    );
  }

  return null;
}

export default LearnerProfile;
