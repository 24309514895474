import { gql } from "@outschool/ui-apollo";
import { MeetingFragment } from "@outschool/ui-components-classroom";

export const SectionFragment = gql`
  fragment LearnerSectionFragment on Section {
    uid
    activity_uid
    start_time
    end_time
    startsAtNight
    is_cross_listed
    size_max
    size_min
    isPublished
    price_cents
    published_at
    updated_at
    canceled_at
    deleted_at
    ongoing_stopped_at
    duration_minutes
    usesOutschoolVideoChat
    usersCanSendPrivateMessage
    currentUserCanManage
    isBuyable
    allowsTeacherTransfer
    nextOngoingMeeting {
      ...MeetingFragment
    }
    leader {
      uid
      name
    }
    details {
      autoScheduledDraft
    }
    prepaidOngoingPeriodOptions {
      startOfIsoWeek
      endOfIsoWeek
    }
  }
  ${MeetingFragment}
`;

export const SectionEnrolledFragment = gql`
  fragment SectionEnrolledFragment on Section {
    details {
      onlineClassroom {
        type
        url
      }
    }
  }
`;
