import { Box, Typography } from "@outschool/backpack";
import {
  LearnerBannerQuery,
  LearnerBannerQueryVariables
} from "@outschool/gql-frontend-generated";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useLocalStorageState } from "@outschool/local-storage";
import { dayjs } from "@outschool/time";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { useFeatureFlag } from "@outschool/ui-components-shared";
import { ReadOnlyBanner } from "@outschool/ui-components-website";
import { Container, Loading } from "@outschool/ui-legacy-component-library";
import { UserAgent, useIsMobile, useIsSmall } from "@outschool/ui-utils";
import React from "react";

import defaultBanner from "../../../../images/banners/welcome/banner_bg.png";
import * as Env from "../../Env";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import Footer from "../nav/Footer";
import Header from "../nav/Header";
import CommunityBanner from "./CommunityBanner";
import IosAppPromoBanner from "./IosAppPromoBanner";
import NavigationTabs from "./NavigationTabs";

type HeroLayoutWithTabsProps = {
  currentPath: string;
  children: React.ReactNode;
};

const defaultNavigationContext = {
  activeTabId: "learner-spaces-active-tab",
  activeTabPanelId: "learner-spaces-main-container"
};
export const NavigationContext = React.createContext(defaultNavigationContext);

const LOCAL_STORAGE_KEY = "iOS.PromoBanner.FirstShownAt";
const DAYS_TO_SHOW_IOS_BANNER = 7;

export const learnerBannerQuery = gql`
  query LearnerBanner {
    contentfulLearnerBannerCollection(limit: 1) {
      items {
        name
        campaign {
          name
          startAt
          endAt
        }
        title
        subtitle
        ctaText
        ctaUrl
        image {
          title
          url
        }
      }
    }
  }
`;

function useCommunityBanner() {
  const shouldShowBanner = useFeatureFlag("habit-contentful-learner-banner");
  const { data, loading } = useQueryWithPreviousData<
    LearnerBannerQuery,
    LearnerBannerQueryVariables
  >(learnerBannerQuery, {
    skip: !shouldShowBanner
  });

  const communityBanner = data?.contentfulLearnerBannerCollection?.items?.[0];
  const isInDate =
    communityBanner?.campaign?.startAt &&
    dayjs().isAfter(communityBanner.campaign.startAt) &&
    communityBanner?.campaign?.endAt &&
    dayjs().isBefore(communityBanner.campaign.endAt);

  return {
    loading,
    communityBannerData: isInDate ? communityBanner : undefined
  };
}

function useIosPromoBanner(): boolean | undefined {
  const [iosPromoBannerFirstShownAt, _setIosPromoBannerFirstShownAt] =
    useLocalStorageState(LOCAL_STORAGE_KEY, new Date().toISOString());
  if (
    (UserAgent.isIPhone() || UserAgent.isIPad()) &&
    dayjs().diff(iosPromoBannerFirstShownAt, "day") <= DAYS_TO_SHOW_IOS_BANNER
  ) {
    return true;
  } else {
    return false;
  }
}

function HeroLayoutWithTabs(
  { currentPath, children }: HeroLayoutWithTabsProps,
  ref: React.Ref<HTMLDivElement>
) {
  const isMobile = useIsMobile();
  const showIosPromoBanner = useIosPromoBanner();
  const { loading, communityBannerData } = useCommunityBanner();
  const banner: "default" | "ios" | "community" = showIosPromoBanner
    ? "ios"
    : !!communityBannerData
    ? "community"
    : "default";

  if (loading) {
    return <Loading />;
  }

  return (
    <Box
      flex
      sx={{
        minHeight: "100vh",
        flexDirection: "column",
        backgroundColor: "common.white"
      }}
      ref={ref}
    >
      <Header
        sx={{
          background: isMobile || showIosPromoBanner ? "white" : undefined,
          borderColor: isMobile || showIosPromoBanner ? "#535353" : undefined,
          position: showIosPromoBanner ? "relative" : "absolute"
        }}
        includeBlob={!showIosPromoBanner}
      />
      {banner === "default" && <DefaultBanner />}
      {banner === "ios" && <IosAppPromoBanner />}
      {banner === "community" && communityBannerData && (
        <CommunityBanner bannerData={communityBannerData} />
      )}
      {Env.IS_READ_ONLY_MODE && <ReadOnlyBanner />}
      <NavigationContext.Provider value={defaultNavigationContext}>
        <Box
          sx={theme => ({
            position: "absolute",
            left: 0,
            top: showIosPromoBanner ? 55 : 0,
            height: banner === "community" ? 335 : 245,
            [theme.breakpoints.up("sm")]: {
              height: 344
            },
            width: "100%",
            overflowX: "hidden",

            "& *:focus": {
              outline: "3px dotted black",
              outlineOffset: 5
            }
          })}
        >
          <NavigationTabs
            currentPath={currentPath}
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: 130
            }}
          />
        </Box>
        <Container
          role="tabpanel"
          aria-labelledby={defaultNavigationContext.activeTabId}
          id={defaultNavigationContext.activeTabPanelId}
          sx={{
            paddingBottom: "large",
            paddingTop: 0
          }}
        ></Container>
      </NavigationContext.Provider>
      <Container>{children}</Container>
      <Footer />
    </Box>
  );
}

function DefaultBanner() {
  const isSmall = useIsSmall();
  const currentLearner = useCurrentLearner();
  return (
    <Box
      sx={theme => ({
        backgroundImage: `url(${defaultBanner})`,
        backgroundSize: "cover",
        backgroundPosition: isSmall ? "left top" : "center top",
        height: 223,

        [theme.breakpoints.up("sm")]: {
          height: 339
        }
      })}
      data-test-id="default-banner"
    >
      {!isSmall && (
        <Box
          flex
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "80px"
          }}
        >
          <Typography variant="h1" component="div">
            Welcome back,
          </Typography>
          <Typography variant="h1" component="div">
            {currentLearner?.name || "learner"}!
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default React.forwardRef(HeroLayoutWithTabs);
