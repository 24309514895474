import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";

import {
  LearnerPrivateClassMessagesPageQuery,
  LearnerPrivateClassMessagesPageQueryVariables
} from "../generated/graphql";
import { CurrentLearnerFragment } from "../providers/CurrentLearnerProvider";
import { ActivityFragment } from "./ActivityFragment";
import { SectionEnrolledFragment, SectionFragment } from "./SectionFragments";

export const PrivateClassMessagesPageQuery = gql`
  query LearnerPrivateClassMessagesPage($sectionUid: ID!) {
    currentLearner {
      ...CurrentLearnerFragment
    }
    section(uid: $sectionUid) {
      filledSpaceCount
      ...LearnerSectionFragment
      ...SectionEnrolledFragment
      currentLearnerEnrollment {
        uid
        section_uid
        created_at
        confirmed_at
        confirmedAt
        withdrawn_at
        withdrawnAt
        usersCanCommunicate
        details {
          name
          age
          attendedAt
          message
        }
      }
      activity {
        ...LearnerAppActivityFragment
        leader {
          uid
          name
          leader_link
          created_at
          photo
          suspended_at
          cityState
          socialOptOut
          details {
            about
            headline
            headlineTranslated
            countryOfResidence
          }
          is_crosslister
          timeZone
          hasAvailabilitySet
          approved
          ownerOfSellerOrg {
            uid
          }
          teacherForSellerOrg {
            uid
            name
          }
          hasBackgroundCheckExpired
        }
      }
    }
  }
  ${ActivityFragment}
  ${SectionFragment}
  ${SectionEnrolledFragment}
  ${CurrentLearnerFragment}
`;

export function usePrivateClassMessagesQuery(
  variables: LearnerPrivateClassMessagesPageQueryVariables
) {
  const { loading, error, data } =
    useQueryWithPreviousData<LearnerPrivateClassMessagesPageQuery>(
      PrivateClassMessagesPageQuery,
      {
        fetchPolicy: "cache-and-network",
        variables
      }
    );

  return {
    loading,
    error,
    data
  };
}
