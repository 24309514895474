import { Box } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { ErrorMessage } from "@outschool/ui-components-shared";
import { Loading } from "@outschool/ui-legacy-component-library";
import { useTitle } from "@patched/hookrouter";
import React from "react";

import * as Env from "../../Env";
import { defaultTitle } from "../../lib/PageMetadata";
import { useLearnerTeacherProfileQuery } from "../../queries/TeacherProfileQuery";
import { TeacherActivities } from "./TeacherSearchResult";

function TeacherClasses({ leaderLink }) {
  const { loading, error, teacher } = useLearnerTeacherProfileQuery({
    leaderLink
  });

  useTitle(
    teacher ? `Classes taught by ${teacher.name} | Outschool` : defaultTitle
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorMessage value={error} showStatusPageLink={Env.IS_READ_ONLY_MODE} />
    );
  }

  return (
    <Box>
      <Box
        flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <TeacherActivities teacher={teacher} />
      </Box>
    </Box>
  );
}

export default TeacherClasses;
