// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Button, Typography } from "@outschool/backpack";
import { TrackedButton } from "@outschool/ui-components-shared";
import {
  Form,
  FormField,
  Input,
  Modal
} from "@outschool/ui-legacy-component-library";
import { responsive } from "@outschool/ui-utils";
import React from "react";

type PasswordModalProps = {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  switchViews: (password: string) => void;
  error?: string;
  title?: string;
};

const PasswordModal: React.FC<PasswordModalProps> = ({
  open,
  onClose,
  switchViews,
  loading,
  error,
  title = "Entering a protected space"
}) => {
  const [password, setPassword] = React.useState("");

  return (
    <Modal
      ariaLabel={title}
      open={open}
      onClose={onClose}
      sx={{
        width: "85vw",
        height: "90vh",
        maxHeight: responsive({ default: "auto", medium: "450px" }),
        minHeight: "unset",
        padding: "large"
      }}
    >
      <Box
        flex
        sx={{
          alignItems: "center",
          flexDirection: "column",
          padding: "1em"
        }}
      >
        <Typography
          variant="h2"
          sx={{
            mb: "1em"
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="inherit"
          sx={{
            mb: "1em"
          }}
        >
          This space is password protected - to enter, type in the password for
          your Outschool account:
        </Typography>
        <Form
          sx={{ display: "grid", gap: "large", justifyItems: "center" }}
          onSubmit={e => {
            e && e.preventDefault();
            switchViews(password);
          }}
        >
          <FormField label="Outschool password" error={error}>
            <Input
              required
              type="password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
              sx={{ minWidth: 280 }}
              autoComplete="off"
            />
          </FormField>
          <TrackedButton
            variant="contained"
            disabled={loading || !password}
            sx={{
              mb: "1em",
              width: "190px"
            }}
            type="submit"
            trackingName="switch_views_with_password"
          >
            Switch spaces
          </TrackedButton>
        </Form>
        <Button variant="link" onClick={onClose}>
          Back
        </Button>
      </Box>
    </Modal>
  );
};

export default PasswordModal;
