import {
  LearnerClassroomQuery,
  LearnerClassroomQueryVariables,
  UnlimitedClassroomQuery,
  UnlimitedClassroomQueryVariables
} from "@outschool/gql-frontend-generated";
import {
  QueryHookOptions,
  gql,
  useQueryWithPreviousData
} from "@outschool/ui-apollo";
import {
  ActivityOverviewFragments,
  AssignmentTracker,
  ClassroomLearnerListFragments
} from "@outschool/ui-components-classroom";

const sectionLessonFragment = gql`
  fragment LearnerClassroomSectionLessonFragment on SectionLesson {
    uid
    type
    orderInSection
    isCompleted(learnerUid: $learnerUid)
    isLocked(learnerUid: $learnerUid)
    lessonStatus(learnerUid: $learnerUid)
    isCancelled
    title
    description
    start_time
    lesson {
      uid
      isMissed(sectionUid: $sectionUid, learnerUid: $learnerUid)
    }
    unit {
      uid
      title
      unitNumber
    }
    liveMeetingDetails {
      uid
      enrollmentUid
      classRecordingUids
      sectionStartTime
      meetingStartTime
      meetingEndTime
      meetingsCount
      usersCanHaveLiveMeeting
    }
    assignmentClassPosts {
      uid
    }
    classPosts {
      uid
    }
  }
`;

const lessonFragment = gql`
  fragment LearnerClassroomLessonFragment on Lesson {
    uid
    title
    description
    week_number
    order_within_week
    lessonNumber
    unit {
      uid
      title
      unitNumber
    }
    meetings(sectionUid: $sectionUid) {
      uid
      start_time
    }
    lessonStatus(learnerUid: $learnerUid)
    isCompleted(learnerUid: $learnerUid)
    isLocked(sectionUid: $sectionUid, learnerUid: $learnerUid)

    isMissed(sectionUid: $sectionUid, learnerUid: $learnerUid)
  }
`;

const syllabusFragment = gql`
  fragment LearnerClassroomSyllabusFragment on Syllabus {
    uid
    published_at
    ...AssignmentTrackerSyllabus
    currentLesson(sectionUid: $sectionUid, learnerUid: $learnerUid) {
      ...LearnerClassroomLessonFragment
    }
    lessons {
      ...LearnerClassroomLessonFragment
      assignmentClassPosts(sectionUid: $sectionUid) {
        ...AssignmentTrackerAssignment
      }
    }
  }
  ${lessonFragment}
  ${AssignmentTracker.fragments.syllabus}
  ${AssignmentTracker.fragments.assignmentClassPost}
`;

const sectionFragment = gql`
  fragment LearnerClassroomSectionFragment on Section {
    uid
    start_time
    end_time
    canceled_at
    is_self_paced
    filledSpaceCount
    usesOutschoolVideoChat
    currentUserHasAttendances
    nextOngoingMeeting {
      uid
      start_time
    }
    leader {
      uid
      name
    }
    assignmentClassPosts {
      ...AssignmentTrackerAssignment
    }
    currentSectionLesson @include(if: $withSectionLessons) {
      ...LearnerClassroomSectionLessonFragment
    }
    sectionLessons @include(if: $withSectionLessons) {
      ...LearnerClassroomSectionLessonFragment
    }
    syllabus {
      ...LearnerClassroomSyllabusFragment
    }
  }
  ${AssignmentTracker.fragments.assignmentClassPost}
  ${sectionLessonFragment}
  ${syllabusFragment}
`;

const activityFragment = gql`
  fragment LearnerClassroomActivityFragment on Activity {
    uid
    user_uid
    title
    is_ongoing_weekly
    weekly_meetings
    is_online
    duration_weeks
    isClub
    published_at
    isSelfPaced
    details {
      photo
    }
    leader {
      uid
      name
      leader_link
    }
  }
`;

export const ClassroomQuery = gql`
  query LearnerClassroom(
    $sectionUid: ID!
    $learnerUid: ID!
    $withSectionLessons: Boolean!
  ) {
    classroom(sectionUid: $sectionUid) {
      uid
      usersCanHaveLiveMeeting
      currentLearnerEnrollments {
        uid
        ongoing_start_time
        ongoing_end_time
        isPaused
      }
      learners {
        ...ClassroomLearnerList_profile
      }
      section {
        ...LearnerClassroomSectionFragment
      }
      activity {
        ...LearnerClassroomActivityFragment
      }
    }
  }
  ${sectionFragment}
  ${activityFragment}
  ${ClassroomLearnerListFragments.profile}
`;

export const useLearnerClassroomQuery = (
  variables: LearnerClassroomQueryVariables,
  queryOptions?: Omit<
    QueryHookOptions<LearnerClassroomQuery, LearnerClassroomQueryVariables>,
    "variables"
  >
) =>
  useQueryWithPreviousData<
    LearnerClassroomQuery,
    LearnerClassroomQueryVariables
  >(ClassroomQuery, {
    ...queryOptions,
    variables
  });

export const ClassroomAsUnlimitedQuery = gql`
  query UnlimitedClassroom($sectionUid: ID!, $learnerUid: ID!) {
    classroom(sectionUid: $sectionUid) {
      uid
      activity {
        ...LearnerClassroomActivityFragment
        ...ActivityOverview_activity
        slug_id
        summaryTranslated
        leader {
          ...ActivityOverview_leader
        }
        syllabus {
          ...LearnerClassroomSyllabusFragment
        }
      }
    }
  }
  ${ActivityOverviewFragments.activity}
  ${ActivityOverviewFragments.leader}
  ${activityFragment}
  ${syllabusFragment}
`;

export const useUnlimitedClassroomQuery = (
  variables: UnlimitedClassroomQueryVariables,
  queryOptions?: Omit<
    QueryHookOptions<UnlimitedClassroomQuery, UnlimitedClassroomQueryVariables>,
    "variables"
  >
) =>
  useQueryWithPreviousData<
    UnlimitedClassroomQuery,
    UnlimitedClassroomQueryVariables
  >(ClassroomAsUnlimitedQuery, {
    ...queryOptions,
    variables
  });
