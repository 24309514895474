import { Typography } from "@outschool/backpack";
import { isNullOrEmpty } from "@outschool/text";
import { useShouldShowKakao, useShouldShowLine } from "@outschool/ui-auth";
import { Card, HeroLayout } from "@outschool/ui-components-classroom";
import {
  FormField,
  Input,
  Loading
} from "@outschool/ui-legacy-component-library";
import React from "react";

import { hasAccess } from "../../../../shared/Learner";
import BackButton from "../../components/BackButton";
import { FF_KAKAO_AUTH, FF_LINE_AUTH } from "../../Env";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { LearnerAccessLevel } from "../../generated/graphql";
import Footer from "../../layout/nav/Footer";
import Header from "../../layout/nav/Header";
import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import ChangePasswordSection from "./ChangePassword";
import ConnectApple from "./ConnectThirdPartyAccounts/ConnectApple";
import ConnectFacebook from "./ConnectThirdPartyAccounts/ConnectFacebook";
import ConnectGoogle from "./ConnectThirdPartyAccounts/ConnectGoogle";
import ConnectKakao from "./ConnectThirdPartyAccounts/ConnectKakao";
import ConnectLine from "./ConnectThirdPartyAccounts/ConnectLine";

const Settings = () => {
  useAnalyticsPage({ pageName: PageType.Settings });
  const showKakao = useShouldShowKakao();
  const showLine = useShouldShowLine();
  const currentLearner = useCurrentLearner();

  if (!currentLearner) {
    return <Loading />;
  }

  const isLearner13 = currentLearner.age && currentLearner.age >= 13;

  return (
    <HeroLayout
      header={<Header includeBlob />}
      footer={<Footer />}
      includeBackgroundGradient
    >
      <BackButton />
      <Card
        title="Account Settings"
        sx={{ maxWidth: "800px", margin: "0 auto 40px" }}
      >
        {!currentLearner.isLoginEnabled ? (
          <>
            <Typography
              variant="h3"
              sx={{
                marginTop: "1em",
                marginBottom: "0.5em"
              }}
            >
              Login Information
            </Typography>
            <Typography variant="inherit">
              Logging in directly is not enabled for you.
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant="h3"
              sx={{
                marginTop: "1em",
                marginBottom: "0.5em"
              }}
            >
              Login Information
            </Typography>
            <FormField
              label={
                isNullOrEmpty(currentLearner.username) ? "Email" : "Username"
              }
              sx={{ maxWidth: "40ch" }}
              labelSx={{ marginBottom: 0 }}
            >
              <Input
                value={
                  (isNullOrEmpty(currentLearner.username)
                    ? currentLearner.email
                    : currentLearner.username) ?? ""
                }
                disabled
              />
            </FormField>
            <ChangePasswordSection />
            {hasAccess({
              currentLearner,
              minimumAccessLevel: LearnerAccessLevel.Limited
            }) && (
              <>
                <Typography
                  variant="h3"
                  sx={{
                    marginTop: "1em",
                    marginBottom: "0.5em"
                  }}
                >
                  Connect with your third-party accounts to log in
                </Typography>
                {isLearner13 && <ConnectGoogle />}
                <ConnectApple />
                {isLearner13 && <ConnectFacebook />}
                {isLearner13 && FF_LINE_AUTH && showLine && <ConnectLine />}
                {isLearner13 && FF_KAKAO_AUTH && showKakao && <ConnectKakao />}
              </>
            )}
          </>
        )}
      </Card>
    </HeroLayout>
  );
};

export default Settings;
