// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { HeartButton } from "@outschool/ui-components-shared";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";
import { useAnalytics } from "use-analytics";

import { useIsActivitySavedByCurrentLearner } from "../queries/LearnerSavedActivitiesQuery";
import {
  useSaveActivityForLearner,
  useUnsaveActivityForLearner
} from "../queries/LearnerSavedActivityMutations";

interface SaveButtonProps {
  activity: {
    uid: string;
  };
  size?: string;
  variant?: string;
  hideLabelOnMobile?: boolean;
  simpleHeart?: boolean;
  color?: string;
  setLabel?: string;
  unsetLabel?: string;
  sx?: SystemStyleObject;
  unfilledHeartColor?: string;
  trackingPrefix: string;
  trackingProperties?: { [key: string]: string | number };
  onClick?(hearted: boolean): void;
}

export function SaveButton({
  activity,
  size = "medium",
  variant = "link",
  hideLabelOnMobile = false,
  simpleHeart = false,
  color = "primary",
  setLabel = "Saved",
  unsetLabel = "Save",
  sx = {},
  unfilledHeartColor,
  trackingPrefix,
  trackingProperties = {},
  onClick
}: SaveButtonProps) {
  const { track } = useAnalytics();
  const isSavedByLearner = useIsActivitySavedByCurrentLearner(activity);

  const { saveActivity, unsaveActivity } = {
    saveActivity: useSaveActivityForLearner(activity),
    unsaveActivity: useUnsaveActivityForLearner(activity)
  };

  const handleHeartClick = React.useCallback(
    async hearted => {
      if (hearted) {
        await saveActivity(null, null);
        track(
          `${trackingPrefix}.favorite_class.${activity.uid}`,
          trackingProperties
        );
      } else {
        await unsaveActivity();
        track(
          `${trackingPrefix}.unfavorite_class.${activity.uid}`,
          trackingProperties
        );
      }
      onClick && onClick(hearted);
    },
    [
      saveActivity,
      unsaveActivity,
      trackingPrefix,
      track,
      activity,
      trackingProperties,
      onClick
    ]
  );

  const heartButtonProps = {
    color,
    size,
    variant,
    "data-test-id": "save-button",
    isSet: isSavedByLearner,
    "aria-label": isSavedByLearner
      ? "Remove from favorites"
      : "Save as favorite",
    onChange: handleHeartClick,
    unfilledColor: unfilledHeartColor
  };
  return simpleHeart ? (
    <HeartButton
      {...heartButtonProps}
      sx={{
        height: "16px !important",
        textDecoration: "none !important"
      }}
    />
  ) : (
    <HeartButton
      {...heartButtonProps}
      setLabel={setLabel}
      unsetLabel={unsetLabel}
      hideLabelOnMobile={hideLabelOnMobile}
      sx={sx}
    />
  );
}

export default SaveButton;
