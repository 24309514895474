// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { lazyWithRetry } from "@outschool/ui-utils";
import { useRoutes } from "@patched/hookrouter";
import React from "react";

const ClubHome = lazyWithRetry(() => import("./ClubHome"));
const ClubPost = lazyWithRetry(() => import("./ClubPost"));

const clubRoutes = {
  "/:sectionUid/posts/:classPostUid/comments/:classPostCommentUid": ({
    sectionUid,
    classPostUid,
    classPostCommentUid
  }) => (
    <ClubPost
      sectionUid={sectionUid}
      classPostUid={classPostUid}
      classPostCommentUid={classPostCommentUid}
    />
  ),
  "/:sectionUid/posts/:classPostUid": ({ sectionUid, classPostUid }) => (
    <ClubPost sectionUid={sectionUid} classPostUid={classPostUid} />
  ),
  /* 
    Creating a new route due to hookrouter limitations. 
    Using a url param (e.g. "?openMessagePanel=true") would be a lighter and a better solution.
    However hookrouter doesn't trigger navigation intent when navigation from a path without 
    url params to the same path with a param. (e.g. `/groups/123` to `/groups/123?param=true`)
    This would cause issues with notifications — if you are in a group and click a 
    private message notification for that group, the navigation wouldn't work because 
    hookrouter don't trigger re-render. 
    https://github.com/Paratron/hookrouter/blob/e0ceb4a6ed495a59754351aff6ad54ce5c124512/src/router.js#L78-L82
    */
  "/:sectionUid/private-group-messages": ({ sectionUid }) => (
    <ClubHome
      sectionUid={sectionUid}
      initialIsPrivateClassMessageThreadOpen={true}
    />
  ),
  "/:sectionUid": ({ sectionUid }) => <ClubHome sectionUid={sectionUid} />
};

const LearnerClub: React.FC = () => {
  const currentRoute = useRoutes(clubRoutes);
  return currentRoute;
};

export default LearnerClub;
