import { Theme } from "@outschool/backpack";
import {
  ClassPost,
  ClassPostContent,
  ClassPostType
} from "@outschool/ui-components-classroom";
import React from "react";

import { useCurrentLearner } from "../../../providers/CurrentLearnerProvider";

type ClassPostProps = {
  post: ClassPostType;
  usersCanComment: boolean;
  isNew?: boolean;
};

function LearnerClassPost({ post, isNew = false }: ClassPostProps) {
  const currentLearner = useCurrentLearner()!;
  const trackingMetadata = {
    userUid: currentLearner?.parent?.uid,
    learnerUid: currentLearner.uid
  };

  const postTrackingMetadata = {
    ...trackingMetadata,
    postUid: post.uid
  };

  return (
    <ClassPost
      isNew={isNew}
      sx={(theme: Theme) => ({
        border: "1px solid",
        borderColor: "neutral.200",
        borderRadius: 16,
        padding: 24,
        background: post.isWelcomePost
          ? theme.gradients?.primarySecondary
          : undefined
      })}
    >
      <ClassPostContent
        allowRichText
        sender={post?.sender}
        messageContent={post.messageContent}
        trackingLabel="classroom"
        trackingMetadata={postTrackingMetadata}
      />
    </ClassPost>
  );
}

export default LearnerClassPost;
