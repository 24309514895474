import { Box, Icon, Typography } from "@outschool/backpack";
import { faNoteSticky, faPaperclip } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { PostSummaryItem } from "@outschool/ui-components-classroom";
import React from "react";

import { ClassPost } from "../../../providers/ClassroomPostsProvider";
import { LessonViewAccordionSection } from "../../LearnerClassroom/lessonsPage/LessonViewAccordionSection";
import LearnerClassPost from "./ClassPost";

interface LessonClassPostAccordionSectionProps {
  classPost: ClassPost;
}
export const LessonClassPostAccordionSection = ({
  classPost
}: LessonClassPostAccordionSectionProps) => {
  return (
    <LessonViewAccordionSection
      summary={<LessonClassPostSummary classPost={classPost} />}
    >
      <LearnerClassPost post={classPost} usersCanComment={false} />
    </LessonViewAccordionSection>
  );
};

interface LessonClassPostSummaryProps {
  classPost: ClassPost;
}
const LessonClassPostSummary = ({ classPost }: LessonClassPostSummaryProps) => {
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\LessonClassPostAccordionSection"
  );

  const { messageContent } = classPost;
  const hasAttachments = messageContent.attachments.length > 0;

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: 24
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 8
          }}
        >
          <Icon icon={faNoteSticky} size="small" />
          <Typography variant="h6">
            {classPost.title || t("Lesson post")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 24 }}>
        {hasAttachments && (
          <PostSummaryItem
            label={t("{{numberOfAttachments}} attachment", {
              numberOfAttachments: messageContent.attachments.length,
              count: messageContent.attachments.length,
              defaultValue_plural: "{{numberOfAttachments}} attachments"
            })}
            icon={faPaperclip}
          />
        )}
      </Box>
    </Box>
  );
};
