import { gql } from "@outschool/ui-apollo";

export const GetLearnerToParentTransferToken = gql`
  mutation GetLearnerToParentTransferToken($password: String) {
    learnerToParentTransferToken(password: $password) {
      transferToken
      learnerModeSwitchAuthRequirement
    }
  }
`;

export const GetLearnerToSiblingTransferToken = gql`
  mutation GetLearnerToSiblingTransferToken($siblingUid: ID!) {
    learnerToSiblingTransferToken(siblingUid: $siblingUid) {
      transferToken
    }
  }
`;
