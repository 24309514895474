import { readNonZeroNaturalNumber } from "@outschool/environment";
import { I18nLocale } from "@outschool/localization";

// TODO: ensure this is typed from the server to client
type OutschoolServer = {
  ENABLED_FEATURE_FLAGS: string[];
  OUTSCHOOL_ENV: string;
  EXPERIMENT_ASSIGNMENTS: Record<string, string>;
  CLIENT_VERSION: number;
  NOTIFICATIONS_SERVICE_URL: string | undefined;
  LOCALE: I18nLocale;
  APP_HOSTNAME: string;
  IS_REVIEW_APP?: boolean;
  EXPERIMENTS_SERVICE_URL: string;
  EXPERIMENTS_API_KEY: string;
  FEATURE_FLAGS: {
    LOG_MISSING_REFRESH_TOKEN: boolean;
  };
  IS_READ_ONLY_MODE: boolean;
  FF_LINE_AUTH: boolean;
  FF_KAKAO_AUTH: boolean;
};

// OUTSCHOOL_SERVER is set in default.tsx, and used by the client.
const OUTSCHOOL_SERVER: OutschoolServer = (global as any).OUTSCHOOL_SERVER;

export const ENABLED_FEATURE_FLAGS = OUTSCHOOL_SERVER.ENABLED_FEATURE_FLAGS;

export const OUTSCHOOL_ENV = OUTSCHOOL_SERVER.OUTSCHOOL_ENV;

export const isProduction = OUTSCHOOL_ENV === "production";
export const isStaging = OUTSCHOOL_ENV === "staging";
export const isTest = OUTSCHOOL_ENV === "test";
export const isDevelopment = OUTSCHOOL_ENV === "development";

export const LEARNER_TRACKING_HOSTNAME = isProduction
  ? "https://learner-tracking.outschool.com"
  : isStaging
  ? "https://learner-tracking.outschool.dev"
  : "http://learner-tracking.outschool.test";

export const EXPERIMENT_ASSIGNMENTS = OUTSCHOOL_SERVER.EXPERIMENT_ASSIGNMENTS;

export const CLIENT_VERSION = OUTSCHOOL_SERVER.CLIENT_VERSION;

export const NOTIFICATIONS_SERVICE_URL =
  OUTSCHOOL_SERVER.NOTIFICATIONS_SERVICE_URL ?? "http://localhost:3023";

export const LOCALE = OUTSCHOOL_SERVER.LOCALE;

export const APP_HOSTNAME = OUTSCHOOL_SERVER.APP_HOSTNAME;
export const IS_REVIEW_APP = !!OUTSCHOOL_SERVER.IS_REVIEW_APP;

export const KAFKA_REST_PROXY = isProduction
  ? "https://events.outschool.com"
  : "https://kafka-rest-proxy-stage.outschool.dev";

export const EXPERIMENTS_SERVICE_URL = OUTSCHOOL_SERVER.EXPERIMENTS_SERVICE_URL;

export const EXPERIMENTS_API_KEY = OUTSCHOOL_SERVER.EXPERIMENTS_API_KEY;

const experimentAssignmentsMaxReqTimeMs = readNonZeroNaturalNumber(
  process.env.EXPERIMENT_ASSIGNMENTS_MAX_REQ_TIME_MS
);
export const EXPERIMENT_ASSIGNMENTS_MAX_REQ_TIME_MS =
  experimentAssignmentsMaxReqTimeMs ?? 500;

export const LOG_MISSING_REFRESH_TOKEN =
  (isTest ||
    isDevelopment ||
    OUTSCHOOL_SERVER?.FEATURE_FLAGS?.LOG_MISSING_REFRESH_TOKEN) ??
  false;

export const IS_READ_ONLY_MODE =
  OUTSCHOOL_SERVER.IS_READ_ONLY_MODE ??
  process.env.READ_ONLY_MODE === "enabled";

export const FF_LINE_AUTH =
  OUTSCHOOL_SERVER.FF_LINE_AUTH ??
  (process.env.FF_LINE_AUTH === "enabled" || isDevelopment || isTest);
export const FF_KAKAO_AUTH =
  OUTSCHOOL_SERVER.FF_KAKAO_AUTH ??
  (process.env.FF_KAKAO_AUTH === "enabled" || isDevelopment || isTest);
