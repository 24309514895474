// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
/** @jsx jsxPragma */

import { Box } from "@outschool/backpack";
import { jsxPragma } from "@outschool/ui-legacy-component-library";
import { responsive } from "@outschool/ui-utils";
import React from "react";

interface LearnerSubTabsProps {
  tabs: TabInfo[];
}

const LearnerSubTabs: React.FC<LearnerSubTabsProps> = ({ tabs }) => {
  const firstTab = tabs[0];
  const lastTab = tabs[tabs.length - 1];
  const middleTabs = tabs.slice(1, -1);

  return (
    <Box
      sx={theme => ({
        display: "flex",
        justifyContent: "center",
        fontSize: 16,

        [theme.breakpoints.up("sm")]: {
          fontSize: 24
        },

        marginX: "auto"
      })}
      role="tablist"
    >
      <LearnerSubTab tab={firstTab} isFirstTab />

      {middleTabs.map(tab => (
        <LearnerSubTab key={tab.label} tab={tab} />
      ))}

      <LearnerSubTab tab={lastTab} isLastTab />
    </Box>
  );
};

interface LearnerSubTabProps {
  tab: TabInfo;
  isFirstTab?: boolean;
  isLastTab?: boolean;
}

interface TabInfo {
  label: string;
  isActive: () => boolean;
  onSelect?: (e: React.SyntheticEvent) => void;
}

const LearnerSubTab: React.FC<LearnerSubTabProps> = ({
  tab,
  isFirstTab,
  isLastTab
}) => {
  const activeLinkSx = {
    borderColor: "blue3",
    backgroundColor: "blue3",
    color: "white",
    cursor: "default"
  };

  const positionalStyles = isFirstTab
    ? { borderRadius: "8px 0 0 8px", borderRightWidth: 1 }
    : isLastTab
    ? { borderRadius: "0 8px 8px 0", borderLeftWidth: 1 }
    : { borderRightWidth: 1, borderLeftWidth: 1 };

  const isActive = tab.isActive();

  return (
    <button
      /* eslint-disable react/no-unknown-property */
      // @ts-ignore
      sx={{
        /* eslint-enable react/no-unknown-property */
        minWidth: responsive({ default: 130, medium: "200px" }),
        textAlign: "center",
        backgroundColor: "white",
        lineHeight: "30px",
        paddingY: "8px",
        color: "blue3",
        border: "2px solid #A0A9F9",
        fontFamily: "heading",
        fontWeight: "bold",
        cursor: "pointer",
        ...positionalStyles,
        ...(isActive ? activeLinkSx : {})
      }}
      onClick={e => tab.onSelect?.(e)}
      role="tab"
      aria-selected={isActive}
      aria-label={tab.label}
    >
      {tab.label}
    </button>
  );
};

export default LearnerSubTabs;
