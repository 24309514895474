// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React, { PropsWithChildren, useContext } from "react";

import useLearnerNotifications from "../hooks/useLearnerNotifications";

const LearnerNotificationsContext = React.createContext<
  ReturnType<typeof useLearnerNotifications> | undefined
>(undefined);

const LearnerNotificationsProvider: React.FC<PropsWithChildren<{}>> = ({
  children
}) => {
  const useLearnerNotificationsProps = useLearnerNotifications();

  return (
    <LearnerNotificationsContext.Provider value={useLearnerNotificationsProps}>
      {children}
    </LearnerNotificationsContext.Provider>
  );
};

export const useLearnerNotificationsContext = ():
  | ReturnType<typeof useLearnerNotifications>
  | undefined => {
  return useContext(LearnerNotificationsContext);
};

export default LearnerNotificationsProvider;
