import { Box, Typography } from "@outschool/backpack";
import { Loading } from "@outschool/ui-legacy-component-library";
import React from "react";
import { useAnalytics } from "use-analytics";

import { ClassCardsGrid } from "../../components/ClassCards";
import ShuffleButton from "../../components/ShuffleButton";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useLearnerListingRecommendationsQuery } from "../../queries/LearnerActivityRecommendationsQuery";

export default function RecommendedClasses() {
  const { track } = useAnalytics();
  const { recommendedListings, loading, shuffleListings, isShufflingListings } =
    useLearnerListingRecommendationsQuery({
      limit: 3,
      shuffle: false
    });
  const LoadingComponent = () => (
    <Loading style={{ marginTop: "200px", marginBottom: "200px" }} delay={0} />
  );
  if (loading && !isShufflingListings) {
    return <LoadingComponent />;
  }
  if (recommendedListings.length === 0) {
    return null;
  }

  function handleShuffleListings() {
    track("browse.recommended_classes.shuffle_listings");
    shuffleListings();
  }

  return (
    <Box
      flex
      sx={{
        flexDirection: "column",
        maxWidth: "100vw",
        width: "100%",
        paddingY: "1em"
      }}
      data-test-id="recommended-classes"
    >
      <Box
        flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Typography
          variant="h2"
          sx={{
            margin: 0
          }}
        >
          More ideas to check out!
        </Typography>
        <ShuffleButton
          title={"Shuffle Classes"}
          onClick={handleShuffleListings}
        />
      </Box>
      {isShufflingListings ? (
        <LoadingComponent />
      ) : (
        <ClassCardsGrid
          activities={recommendedListings}
          trackingPrefix="browse.recommended"
        />
      )}
    </Box>
  );
}
