import {
  ClassroomPostFilter,
  LearnerClassroomPostsQuery
} from "@outschool/gql-frontend-generated";
import * as Time from "@outschool/time";
import { ApolloError } from "@outschool/ui-apollo";
import { PageOption, Pagination } from "@outschool/ui-components-classroom";
import { max, min } from "lodash";
import React from "react";

import { useLearnerClassroomAddClassPostCommentMutation } from "../hooks/useLearnerClassroomAddClassPostCommentMutation";
import {
  updateLearnerClassroomPostsCache,
  useLearnerClassroomPostsQuery
} from "../queries/LearnerClassroomPostsQuery";
import { useLearnerClassroomContext } from "./LearnerClassroomProvider";

export { ClassroomPostFilter };

type Classroom = LearnerClassroomPostsQuery["classroom"];
type Section = Classroom["section"];
export type ClassPost = Classroom["posts"][number];
type MentionNames = Classroom["mentionNames"];

interface ClassroomPostsContext {
  loading: boolean;
  error?: ApolloError;
  posts?: ClassPost[];
  handleAddComment: ReturnType<
    typeof useLearnerClassroomAddClassPostCommentMutation
  >;
  section?: Section;
  timeBounds: Pagination.TimeBounds | null;
  setTimeBounds(timeBounds: Pagination.TimeBounds): void;
  pageOptions: PageOption<Pagination.TimeBounds>[] | null;
  usersCanComment?: boolean;
  mentionNames?: MentionNames;
}
const ClassroomPostsContext = React.createContext<ClassroomPostsContext>({
  loading: false,
  error: undefined,
  posts: [],
  handleAddComment: (
    ..._args: Parameters<
      ReturnType<typeof useLearnerClassroomAddClassPostCommentMutation>
    >
  ) => ({} as any),
  section: undefined,
  timeBounds: null,
  setTimeBounds: () => {},
  pageOptions: [],
  usersCanComment: false,
  mentionNames: []
});

interface ClassroomPostsProviderProps {
  /**
   * If provided, only posts for this lesson will be returned.
   */
  lessonUid?: string;
  children: React.ReactNode;
  /**
   * @default ClassroomPostFilter.All
   */
  postsFilter?: ClassroomPostFilter;
}
export const ClassroomPostsProvider = ({
  lessonUid,
  postsFilter = ClassroomPostFilter.All,
  children
}: ClassroomPostsProviderProps) => {
  const {
    sectionUid,
    currentLearnerEnrollments,
    section: classroomSection
  } = useLearnerClassroomContext();

  const [pageOptions] = React.useState<
    PageOption<Pagination.TimeBounds>[] | null
  >(() => {
    // Lazy init pageOptions, in state because it's never changing
    const start =
      min(
        currentLearnerEnrollments?.map(
          enrollment => enrollment.ongoing_start_time as Date
        )
      ) ?? (classroomSection?.start_time as Date | undefined);
    const end =
      max(
        currentLearnerEnrollments?.map(
          enrollment => enrollment.ongoing_end_time as Date
        )
      ) ?? (classroomSection?.end_time as Date | undefined);

    return start && end && !lessonUid
      ? Pagination.monthOptionsForRange(
          Time.dayjs(start).toDate(),
          Time.dayjs(end).toDate()
        )
      : null;
  });

  const [timeBounds, setTimeBounds] =
    React.useState<Pagination.TimeBounds | null>(() => {
      return pageOptions ? pageOptions[0].value : null;
    });

  const { loading, data, error } = useLearnerClassroomPostsQuery({
    sectionUid,
    lessonUid,
    postsFilter,
    start: timeBounds?.start,
    end: timeBounds?.end
  });

  const { classroom } = data || ({} as LearnerClassroomPostsQuery);
  const { usersCanComment, mentionNames, posts, section } =
    classroom ?? ({} as Classroom);

  const handleAddComment = useLearnerClassroomAddClassPostCommentMutation({
    updateClassroomCache: updateLearnerClassroomPostsCache
  });

  const value: ClassroomPostsContext = {
    loading,
    error,
    pageOptions,
    posts,
    handleAddComment,
    section,
    timeBounds,
    setTimeBounds,
    usersCanComment,
    mentionNames
  };

  return (
    <ClassroomPostsContext.Provider value={value}>
      {children}
    </ClassroomPostsContext.Provider>
  );
};

export const useClassroomPostsContext = () =>
  React.useContext(ClassroomPostsContext);
