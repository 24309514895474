import { Box } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { fasBell } from "@outschool/icons";
import { IconButton } from "@outschool/ui-legacy-component-library";
import React from "react";

interface NotificationBellButtonProps {
  onClick: () => void;
  isPanelOpen: boolean;
  hasUnreadNotifications: boolean;
  buttonRef?: React.Ref<HTMLElement>;
}

function NotificationBellButton(
  {
    onClick,
    isPanelOpen,
    buttonRef,
    hasUnreadNotifications
  }: NotificationBellButtonProps,
  containerRef?: React.Ref<HTMLElement>
) {
  return (
    <Box
      ref={containerRef}
      sx={{
        position: "relative"
      }}
    >
      {hasUnreadNotifications && !isPanelOpen && (
        <Box
          data-test-id={"new-notification-indicator"}
          sx={{
            position: "absolute",
            right: 8,
            top: "10px",
            zIndex: 1,
            backgroundColor: "#FF0C0C",
            borderRadius: "50%",
            width: "12px",
            height: "12px",
            pointerEvents: "none"
          }}
        />
      )}

      <IconButton
        data-test-id={"notification-bell-button"}
        trackingName="inAppNotifications.bellButton"
        trackingSharedProperties={{
          hasUnreadNotifications,
          isPanelOpen,
          ignoreNetworkError: true
        }}
        onClick={onClick}
        icon={fasBell}
        ref={buttonRef}
        sx={{
          fontSize: 32,
          color: isPanelOpen ? "primary" : "#191C20",
          backgroundColor: isPanelOpen ? "#EAF5FC" : "gray6",
          transition: "color 0.2s ease-in, background-color 0.2s ease-in",
          borderRadius: "50%",
          "&:hover": {
            color: "primary",
            backgroundColor: "#EAF5FC"
          }
        }}
      />
    </Box>
  );
}

export default React.forwardRef<HTMLElement, NotificationBellButtonProps>(
  NotificationBellButton
);
