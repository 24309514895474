import { Box } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Component } from "@outschool/ownership-areas";
import {
  ContinueWithGoogle,
  LoginUserFeedback,
  useAuthError
} from "@outschool/ui-auth";
import _ from "lodash";
import React from "react";

import { GOOGLE_CLIENT_ID } from "../../../../../shared/Env";
import { useLearnerLoginWithRedirect } from "../../../hooks/authentication";
import { useLearnerAuth } from "../../../providers/LearnerAuthProvider";

export default function ConnectGoogle() {
  const { learnerAuth } = useLearnerAuth();
  const hasGoogle = React.useMemo(
    () => (learnerAuth ? _.keyBy(learnerAuth.providers)["Google"] : false),
    [learnerAuth]
  );
  const [userError, setUserError] = React.useState<string | null>(null);
  const generateLoginError = useAuthError("Google");
  const url = React.useMemo(() => {
    if (typeof window !== "undefined") {
      return window.location.href;
    }
    return "";
  }, []);
  const handleAuthError = React.useCallback(
    (error: any, message: string = "Sorry that didn't work, try again") => {
      OsPlatform.captureError(error, {
        tags: { component: Component.LearnerMode }
      });
      setUserError(message);
    },
    []
  );

  const handleLoginError = React.useCallback(
    error => {
      const errorObject = new Error(error);
      handleAuthError(errorObject, generateLoginError(error) as any);
    },
    [handleAuthError, generateLoginError]
  );

  useLearnerLoginWithRedirect(handleLoginError, url);

  return (
    <Box
      sx={{
        marginBottom: "0.5em"
      }}
    >
      {hasGoogle ? (
        <Box>Your Google account is linked and you may use it to log in.</Box>
      ) : (
        <Box>
          {userError && (
            <Box
              sx={{
                marginBottom: "0.5em"
              }}
            >
              <LoginUserFeedback
                userFeedbackMessage={userError}
                isError={true}
              />
            </Box>
          )}
          <ContinueWithGoogle
            setAuthStrategy={() => {}}
            trackingName="learner_login.learner_settings.connect_with_google"
            clientId={GOOGLE_CLIENT_ID!}
          />
        </Box>
      )}
    </Box>
  );
}
