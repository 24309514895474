import {
  Box,
  Chip,
  Icon,
  SxProps,
  Theme,
  Typography
} from "@outschool/backpack";
import {
  LearnerClassroomQuery,
  LearningStatementsVerb
} from "@outschool/gql-frontend-generated";
import {
  IconDefinition,
  faBell,
  faCheckCircle,
  faDiamondExclamation,
  faLockRegular
} from "@outschool/icons";
import { dayjs } from "@outschool/time";
import * as Time from "@outschool/time";
import {
  SectionLessonHeader,
  UnitLessonHeader
} from "@outschool/ui-components-classroom";
import React from "react";

import {
  CurrentLesson,
  Lesson,
  SectionLesson
} from "../../providers/LearnerClassroomProvider";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

export const LessonCard = ({
  learnerUid,
  lesson,
  timeZone,
  liveMeeting,
  syllabus,
  isSubscription = false,
  isSelfPaced = false,
  sx
}: {
  learnerUid: string;
  lesson?: Lesson | CurrentLesson | null;
  timeZone: string;
  liveMeeting?: { meetingStartTime?: Date };
  syllabus: LearnerClassroomQuery["classroom"]["section"]["syllabus"];
  isSubscription?: boolean;
  isSelfPaced?: boolean;
  sx?: SxProps;
}) => {
  const { meetingStartTime } = liveMeeting || {};

  if (!learnerUid) {
    return null;
  }

  const chipMessage = getLessonChipMessage({
    meetingStartTime,
    timeZone,
    lessonStatus: lesson!.lessonStatus,
    isLocked: lesson!.isLocked,
    isMissed: lesson!.isMissed && !isSubscription
  });

  const isCurrentLesson = lesson?.uid === syllabus?.currentLesson?.uid;
  const isLocked = lesson?.isLocked;
  const isMissed = lesson?.isMissed && !isSubscription;
  const isCompleted = lesson?.lessonStatus === LearningStatementsVerb.Completed;

  const showChip = isCurrentLesson || isLocked || isCompleted || isMissed;

  return (
    <Box sx={sx}>
      <UnitLessonHeader
        sx={{ marginBottom: 8 }}
        lessonNumber={lesson?.lessonNumber}
        unitNumber={lesson?.unit?.unitNumber}
        unitTitle={lesson?.unit?.title}
        timeZone={timeZone}
        showMeetingDate={!isSubscription && !isSelfPaced}
        meetingDate={meetingStartTime!}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16
        }}
      >
        <Box
          sx={(theme: Theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            [theme.breakpoints.up("sm")]: {
              alignItems: "center"
            },
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
              justifyContent: "space-between"
            },
            [theme.breakpoints.up("lg")]: {
              flexDirection: "row",
              justifyContent: "space-between"
            }
          })}
        >
          <Typography variant="h5">{lesson?.title}</Typography>{" "}
          {showChip && (
            <LessonChip
              message={chipMessage}
              isLocked={lesson?.isLocked}
              lessonStatus={lesson?.lessonStatus}
              isMissed={lesson?.isMissed && !isSubscription}
            />
          )}
        </Box>
      </Box>
      <Typography>{lesson?.description}</Typography>
    </Box>
  );
};

export const SectionLessonCard = ({
  learnerUid,
  sectionLesson,
  timeZone,
  section,
  isSubscription = false,
  sx
}: {
  learnerUid: string;
  sectionLesson?: SectionLesson | null;
  timeZone: string;
  section: LearnerClassroomQuery["classroom"]["section"];
  isSubscription?: boolean;
  sx?: SxProps;
}) => {
  if (!learnerUid || !sectionLesson) {
    return null;
  }

  const { meetingStartTime } = sectionLesson.liveMeetingDetails || {};

  const chipMessage = getLessonChipMessage({
    meetingStartTime: meetingStartTime || sectionLesson.start_time,
    timeZone,
    lessonStatus: sectionLesson.lessonStatus,
    isLocked: sectionLesson.isLocked,
    isMissed: (sectionLesson?.lesson?.isMissed && !isSubscription) ?? false
  });

  const isCurrentLesson =
    sectionLesson.uid === section.currentSectionLesson?.uid;
  const isLocked = sectionLesson.isLocked;
  const isCompleted = sectionLesson.isCompleted;
  const isMissed = sectionLesson?.lesson?.isMissed && !isSubscription;

  const showChip =
    isCurrentLesson || isLocked || isCompleted || (isMissed && !isSubscription);

  return (
    <Box sx={sx}>
      <SectionLessonHeader
        sx={{ marginBottom: 8 }}
        title={sectionLesson?.title}
        chipComponent={
          showChip ? (
            <LessonChip
              message={chipMessage}
              isLocked={sectionLesson?.isLocked}
              lessonStatus={sectionLesson?.lessonStatus}
              isMissed={sectionLesson?.lesson?.isMissed && !isSubscription}
            />
          ) : null
        }
        meetingDate={meetingStartTime || sectionLesson.start_time}
        timeZone={timeZone}
        isCancelled={sectionLesson.isCancelled}
        unitNumber={sectionLesson.unit?.unitNumber}
        unitTitle={sectionLesson.unit?.title}
        showMeetingDate={!isSubscription && !section.is_self_paced}
      />
      <Typography>{sectionLesson?.description}</Typography>
    </Box>
  );
};

export const MakeupMeetingCard = ({
  meetingStartTime,
  timeZone,
  lesson,
  sx
}) => {
  const chipMessage = getLessonChipMessage({
    meetingStartTime,
    timeZone,
    lessonStatus: lesson?.lessonStatus,
    isLocked: lesson?.isLocked,
    isMissed: lesson?.isMissed
  });
  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16
        }}
      >
        <Box
          sx={(theme: Theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            [theme.breakpoints.up("sm")]: {
              alignItems: "center"
            },
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
              justifyContent: "space-between"
            },
            [theme.breakpoints.up("lg")]: {
              flexDirection: "row",
              justifyContent: "space-between"
            }
          })}
        >
          <Typography variant="h5">{lesson?.title}</Typography>{" "}
          <LessonChip
            message={chipMessage}
            isLocked={lesson?.isLocked}
            lessonStatus={lesson?.lessonStatus}
            isMissed={lesson?.isMissed}
          />
        </Box>
      </Box>
      <Typography>{lesson?.description}</Typography>
    </Box>
  );
};
enum LessonChipStatus {
  InProgress = "in_progress",
  Locked = "locked",
  Completed = "completed",
  Missed = "missed"
}

const lessonChipStatuses: {
  [key in LessonChipStatus]: {
    icon: IconDefinition;
    iconColor: `primary` | `error` | `success`;
  };
} = {
  [LessonChipStatus.InProgress]: {
    icon: faBell,
    iconColor: "primary"
  },
  [LessonChipStatus.Locked]: {
    icon: faLockRegular,
    iconColor: "error"
  },
  [LessonChipStatus.Completed]: {
    icon: faCheckCircle,
    iconColor: "success"
  },
  [LessonChipStatus.Missed]: {
    icon: faDiamondExclamation,
    iconColor: "error"
  }
};

const LessonChip = ({ message, lessonStatus, isLocked, isMissed }) => {
  let status: LessonChipStatus = LessonChipStatus.InProgress;
  if (isLocked) {
    status = LessonChipStatus.Locked;
  } else if (isMissed) {
    status = LessonChipStatus.Missed;
  } else if (lessonStatus === LearningStatementsVerb.Completed) {
    status = LessonChipStatus.Completed;
  }
  const { icon, iconColor } = lessonChipStatuses[status];
  return (
    <Chip
      icon={<Icon icon={icon} />}
      sx={(theme: Theme) => ({
        [theme.breakpoints.up("sm")]: {
          marginLeft: 8
        }
      })}
      label={message}
      color={iconColor}
      size="small"
    />
  );
};

const getLessonChipMessage = ({
  meetingStartTime,
  timeZone,
  lessonStatus = LearningStatementsVerb.NotStarted,
  isLocked,
  isMissed = false
}: {
  timeZone: string;
  lessonStatus: LearningStatementsVerb | null;
  isLocked: boolean;
  meetingStartTime?: Date | string | null;
  isMissed: boolean | null;
}) => {
  if (isLocked) {
    return "Locked";
  }
  if (isMissed) {
    return "Missed Class";
  }
  if (lessonStatus === LearningStatementsVerb.Completed) {
    return "Completed";
  }
  if (meetingStartTime) {
    if (dayjs(meetingStartTime).isBefore(dayjs().startOf("day"))) {
      return "Past Meeting";
    }
    return `Up Next: ${
      dayjs(meetingStartTime).isToday()
        ? "today"
        : Time.startDayShortString(meetingStartTime, timeZone)
    }`;
  }
  return "Up Next";
};
