import { Icon, Theme } from "@outschool/backpack";
import { faChevronLeft } from "@outschool/icons";
import { HeroLayout } from "@outschool/ui-components-classroom";
import { TrackedButton } from "@outschool/ui-components-shared";
import { Space } from "@outschool/ui-legacy-component-library";
import { navigate, useTitle } from "@patched/hookrouter";
import React, { useEffect, useMemo } from "react";

import { hasAccess } from "../../../../shared/Learner";
import ReportIssue, {
  REPORT_ISSUE_TRACKING_PREFIX
} from "../../components/ReportIssue";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { LearnerAccessLevel } from "../../generated/graphql";
import Footer from "../../layout/nav/Footer";
import Header from "../../layout/nav/Header";
import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";

function LearnerReportIssue() {
  useTitle("Report an Issue | Outschool");
  useAnalyticsPage({ pageName: PageType.ReportIssue });
  return (
    <HeroLayout
      header={<Header includeBlob />}
      footer={<Footer />}
      sx={{ backgroundColor: "common.white" }}
    >
      <TrackedButton
        onClick={() => window.history.back()}
        trackingName={`${REPORT_ISSUE_TRACKING_PREFIX}.back`}
        sx={(theme: Theme) => ({
          position: "relative",
          right: 0,

          [theme.breakpoints.up("xl")]: {
            right: 32
          }
        })}
      >
        <Icon
          icon={faChevronLeft}
          sx={{
            marginRight: "0.5em"
          }}
        />
        Back
      </TrackedButton>
      <div data-test-id="report-issue">
        <ReportIssue />
      </div>
      <Space y="xlarge" />
    </HeroLayout>
  );
}

function LearnerReportIssuePage() {
  const currentLearner = useCurrentLearner();
  const doesLearnerHaveAccess = useMemo(
    () =>
      currentLearner &&
      hasAccess({
        currentLearner,
        minimumAccessLevel: LearnerAccessLevel.Limited
      }),
    [currentLearner]
  );

  useEffect(() => {
    if (currentLearner && !doesLearnerHaveAccess) {
      navigate("/");
    }
  }, [currentLearner, doesLearnerHaveAccess]);

  if (doesLearnerHaveAccess) {
    return <LearnerReportIssue />;
  }

  return null;
}

export default LearnerReportIssuePage;
