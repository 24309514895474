import { LearnerRoutes, WebsiteRoutes, initRoutes } from "@outschool/routes";

import { APP_HOSTNAME } from "../Env";

const routeConfig = {
  appHostName: APP_HOSTNAME
};

export const websiteRoutes = new WebsiteRoutes(routeConfig);
export const learnerRoutes = new LearnerRoutes(routeConfig);

/**
 * Init routes so we can break away from `websiteRoutes` etc..
 *
 */
initRoutes({
  appHostName: APP_HOSTNAME
});

export {
  CLUB_PATH_NAME,
  CLUB_POST_PATH_NAME,
  CLUB_POST_COMMENT_UID_PARAM_NAME
} from "@outschool/routes";

/**
 * @deprecated use learnerRoutes.homePath
 */
export const homePath = learnerRoutes.homePath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.findClassesPath
 */
export const findClassesPath =
  learnerRoutes.findClassesPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.recommendedClassesPath
 */
export const recommendedClassesPath =
  learnerRoutes.recommendedClassesPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.findClassesUrl
 */
export const findClassesUrl = learnerRoutes.findClassesUrl.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.topicSearchResultsPath
 */
export const topicSearchResultsPath =
  learnerRoutes.topicSearchResultsPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.keywordSearchResultsPath
 */
export const keywordSearchResultsPath =
  learnerRoutes.keywordSearchResultsPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.dashboardPath
 */
export const dashboardPath = learnerRoutes.dashboardPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.teacherSearchResultPath
 */
export const teacherSearchResultPath =
  learnerRoutes.teacherSearchResultPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.teacherClassesPath
 */
export const teacherClassesPath =
  learnerRoutes.teacherClassesPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.dashboardUrl
 */
export const dashboardUrl = learnerRoutes.dashboardUrl.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.calendarViewPath
 */
export const calendarViewPath =
  learnerRoutes.calendarViewPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.calendarViewUrl
 */
export const calendarViewUrl =
  learnerRoutes.calendarViewUrl.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.dashboardSchedulePath
 */
export const dashboardSchedulePath =
  learnerRoutes.dashboardSchedulePath.bind(learnerRoutes);

/**
 * @deprecated use `learnerRoutes.classroomPath`
 */
export const classroomPath = learnerRoutes.classroomPath.bind(learnerRoutes);

/**
 * @deprecated use `learnerRoutes.classroomSinglePostPath`
 */
export const classroomPostPath =
  learnerRoutes.classroomSinglePostPath.bind(learnerRoutes);

/**
 * @deprecated use `learnerRoutes.classroomUrl`
 */
export const classroomUrl = learnerRoutes.classroomUrl.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.sectionJoinPath
 */
export const sectionJoinPath =
  learnerRoutes.sectionJoinPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.privateClassMessagesPath
 */
export const privateClassMessagesPath =
  learnerRoutes.privateClassMessagesPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.recordingPath
 */
export const recordingPath = learnerRoutes.recordingPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.privateClassMessagesUrl
 */
export const privateClassMessagesUrl =
  learnerRoutes.privateClassMessagesUrl.bind(learnerRoutes);

/**
 * @deprecated use `learnerRoutes.activityPath`
 */
export const activityPath = learnerRoutes.activityPath.bind(learnerRoutes);

/**
 * @deprecated use `learnerRoutes.savedClassesPath`
 */
export const savedClassesPath =
  learnerRoutes.savedClassesPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.clubPath
 */
export const clubPath = learnerRoutes.clubPath.bind(learnerRoutes);

/**
 * @deprecated use `learnerRoutes.clubPostPath`
 */
export const clubPostPath = learnerRoutes.clubPostPath.bind(learnerRoutes);

/**
 * @deprecated use `learnerRoutes.clubPostCommentPath`
 */
export const clubPostCommentPath =
  learnerRoutes.clubPostCommentPath.bind(learnerRoutes);

/**
 * @deprecated use `learnerRoutes.changePasswordPath`
 */
export const changePasswordPath =
  learnerRoutes.changePasswordPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.loginPath
 */
export const loginPath = learnerRoutes.loginPath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.loginUrl
 */
export const learnerLoginUrl = learnerRoutes.loginUrl.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.profilePath
 */
export const profilePath = learnerRoutes.profilePath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.redirectAfterLoginUrl
 */
export const redirectAfterLoginUrl =
  learnerRoutes.redirectAfterLoginUrl.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.welcomePath
 */
export const welcomePath = learnerRoutes.welcomePath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.settingsPath
 */
export const settingsPath = learnerRoutes.settingsPath.bind(learnerRoutes);

export const REPORT_ISSUE_REFERRER_PATH_NAME = "referrerPath";

/**
 * @deprecated use `learnerRoutes.reportIssuePath`
 */
export const reportIssuePath =
  learnerRoutes.reportIssuePath.bind(learnerRoutes);

/**
 * @deprecated use learnerRoutes.privateClubMessagesPath
 */
export const privateClubMessagesPath =
  learnerRoutes.privateClubMessagesPath.bind(learnerRoutes);

export function crisisTextLineUrl() {
  return "https://www.crisistextline.org/";
}
