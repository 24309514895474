import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  SxProps,
  Theme,
  accordionSummaryClasses
} from "@outschool/backpack";
import { faChevronDownRegular } from "@outschool/icons";
import React from "react";

interface LessonSectionProps {
  summary: React.ReactNode;
  summarySx?: SxProps;
  /**
   * @default true
   */
  startExpanded?: boolean;
  children: React.ReactNode;
}
export const LessonViewAccordionSection = ({
  summary,
  summarySx,
  startExpanded = true,
  children
}: LessonSectionProps) => (
  <Accordion defaultExpanded={startExpanded}>
    <AccordionSummary
      expandIcon={<Icon icon={faChevronDownRegular} size="small" />}
      sx={[
        (theme: Theme) => ({
          [`& .${accordionSummaryClasses.content}`]: {
            margin: theme.spacing(16, 0),
            [`&.${accordionSummaryClasses.expanded}`]: {
              margin: theme.spacing(16, 0)
            }
          }
        }),
        ...(Array.isArray(summarySx) ? summarySx : [summarySx])
      ]}
    >
      {summary}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);
