// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import { Box, Button, Icon, SxProps, Typography } from "@outschool/backpack";
import { faCheckCircle } from "@outschool/icons";
import { LearnerRoutes } from "@outschool/routes";
import { dayjs } from "@outschool/time";
import { useConfetti } from "@outschool/ui-components-website";
import { Loading } from "@outschool/ui-legacy-component-library";
import { useNavigation, usePrefersReducedMotion } from "@outschool/ui-utils";
import React from "react";

import scribbleSketchBg from "../../../../../images/lessons/scribble-sketch-texture-bg-translucent.png";
import rocketThumbsUps from "../../../../../images/lessons/thumbs-up-rocket-complete-success.png";
import Link from "../../../components/Link";
import {
  ClassPost,
  ClassroomPostFilter
} from "../../../providers/ClassroomPostsProvider";
import { useCurrentLearner } from "../../../providers/CurrentLearnerProvider";
import {
  Lesson,
  useUnlimitedClassroomContext
} from "../../../providers/UnlimitedClassroomProvider";
import { useLearnerClassroomPostsQuery } from "../../../queries/LearnerClassroomPostsQuery";
import { ClassroomAsUnlimitedQuery } from "../../../queries/LearnerClassroomQuery";
import { LessonCard } from "../../LearnerClassroom/LessonCard";
import MarkLessonCompleteButton from "../../LearnerClassroom/lessonsPage/MarkLessonCompleteButton";
import { LessonClassPostAccordionSection } from "./LessonClassPostAccordionSection";

interface LessonViewProps {
  heading?: React.ReactNode;
  lesson: Lesson;
  sx?: SxProps;
}

export const LessonView = ({ heading, lesson, sx }: LessonViewProps) => {
  const {
    loading: classroomLoading,
    error: classroomError,
    sectionUid,
    syllabus,
    allLessonsCompleted,
    activity
  } = useUnlimitedClassroomContext();

  const currentLearner = useCurrentLearner();

  const loading = classroomLoading;
  const error = classroomError;

  // Redirect user to "all lessons complete" view when they complete their final lesson.
  const navigate = useNavigation();
  const reduceMotion = usePrefersReducedMotion();
  // Track the "marked complete" state here because the button unrenders after the mutation finishes
  const [markedCompleted, setMarkedCompleted] = React.useState(false);
  React.useEffect(() => {
    if (markedCompleted && allLessonsCompleted) {
      setTimeout(() => {
        setMarkedCompleted(false);
        navigate(LearnerRoutes.unlimitedLessonsPath(sectionUid));
        window.scrollTo({
          top: 0,
          behavior: reduceMotion ? "auto" : "smooth"
        });
      }, 3000);
    }
  }, [
    markedCompleted,
    allLessonsCompleted,
    navigate,
    sectionUid,
    reduceMotion
  ]);

  const showMarkCompleteButton =
    activity?.isSelfPaced && !lesson.isCompleted && !lesson.isLocked;

  if (!loading && error) {
    return null;
  }

  return (
    <Box
      sx={[
        { display: "flex", flexDirection: "column", gap: 16 },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {heading}
      <Box
        sx={{
          border: "1px solid",
          borderColor: "neutral.200",
          borderRadius: 16,
          paddingX: 36,
          paddingY: 24
        }}
      >
        {loading ? (
          <Box sx={{ height: 100 }}>
            <Loading delay={0} />
          </Box>
        ) : (
          <>
            <LessonCard
              lesson={lesson}
              learnerUid={currentLearner!.uid}
              timeZone={currentLearner!.timeZone}
              syllabus={syllabus!}
              isSubscription
              isSelfPaced={!!activity?.isSelfPaced}
              sx={{ marginBottom: 16 }}
            />
          </>
        )}
        <Box>
          <LessonPostsAndAssignments lessonUid={lesson.uid} />
          {showMarkCompleteButton && (
            <MarkLessonCompleteButton
              lessonUid={lesson.uid}
              sectionUid={sectionUid}
              onCompleted={() => setMarkedCompleted(true)}
              queriesToRefetch={[ClassroomAsUnlimitedQuery]}
            />
          )}
          {lesson.isCompleted && (
            <LessonCompleteIcon triggerConfetti={markedCompleted} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const LessonPostsAndAssignments = ({ lessonUid }) => {
  const { sectionUid } = useUnlimitedClassroomContext();
  const { data } = useLearnerClassroomPostsQuery({
    sectionUid,
    lessonUid,
    postsFilter: ClassroomPostFilter.All
  });

  const posts = data?.classroom.posts;

  const postsAndAssignments: ClassPost[] = [...(posts || [])].sort((a, b) =>
    dayjs(a.publishAt).diff(dayjs(b.publishAt))
  );

  return (
    <>
      {posts ? (
        postsAndAssignments.map((postOrAssignment: ClassPost) => (
          <LessonPostOrAssignment
            key={postOrAssignment.uid}
            postOrAssignment={postOrAssignment}
          />
        ))
      ) : (
        <Loading delay={0} />
      )}
    </>
  );
};

const LessonPostOrAssignment = ({
  postOrAssignment
}: {
  postOrAssignment: ClassPost;
}) =>
  postOrAssignment.__typename === "ClassPost" ? (
    <LessonClassPostAccordionSection classPost={postOrAssignment} />
  ) : null;

export const LessonViewHeading = ({
  text,
  sectionUid,
  showLessonsLink
}: {
  text: string;
  sectionUid?: string;
  showLessonsLink?: boolean;
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    }}
  >
    <Typography variant="h3">{text}</Typography>
    {showLessonsLink && sectionUid && (
      <Button
        variant="text"
        sx={{ marginRight: -28 }}
        component={Link}
        to={LearnerRoutes.lessonsPath(sectionUid)}
      >
        View All Lessons
      </Button>
    )}
  </Box>
);

export const AllLessonsCompletedView = ({
  heading,
  sx
}: {
  heading: React.ReactNode;
  sx?: SxProps;
}) => {
  const { loading } = useUnlimitedClassroomContext();

  return (
    <Box
      sx={[
        { display: "flex", flexDirection: "column", gap: 16 },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {heading}
      {loading ? (
        <Box sx={{ height: 100 }}>
          <Loading delay={0} />
        </Box>
      ) : (
        <Box
          sx={{
            color: "white",
            border: "1px solid",
            borderColor: "neutral.200",
            background: `url(${rocketThumbsUps}), url(${scribbleSketchBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "40%, cover",
            backgroundPosition: "right, center",
            backgroundBlendMode: "normal, overlay",
            backgroundColor: "secondary.600",
            borderRadius: 6,
            padding: 24,
            overflow: "hidden"
          }}
        >
          <Typography variant="h3" component="h4" sx={{ paddingBottom: 16 }}>
            It&apos;s time to celebrate!
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const LessonCompleteIcon = ({
  triggerConfetti
}: {
  triggerConfetti: boolean;
}) => {
  const { fireConfetti, confettiCannon } = useConfetti({ spread: 90 });

  const [animateIcon, setAnimateIcon] = React.useState(false);

  React.useEffect(() => {
    if (triggerConfetti) {
      fireConfetti();
      setAnimateIcon(true);
    }
  }, [triggerConfetti, fireConfetti, setAnimateIcon]);

  return (
    <Box sx={{ margin: "auto" }}>
      {confettiCannon}
      <Icon
        icon={faCheckCircle}
        color="disabled"
        size="large"
        sx={{
          "@keyframes icon-animation": {
            "20%": {
              color: "success.main",
              transform: "scale(1.5)"
            },
            "40%, 70%": { transform: "scale(1)" },
            "60%": { transform: "scale(1.2)" },
            "80%": { transform: "scale(1.1)" }
          },
          animation: animateIcon ? "icon-animation 500ms" : "none"
        }}
      />
    </Box>
  );
};
