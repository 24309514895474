import {
  BackpackThemeProvider,
  Box,
  Grid,
  Icon,
  IconColor,
  ProgressBar,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  Theme,
  Typography
} from "@outschool/backpack";
import { LearningStatementsVerb } from "@outschool/gql-frontend-generated";
import {
  IconDefinition,
  faCalendar,
  faCalendarXmark,
  faCheckCircle,
  faChevronDownRegular,
  faChevronRightRegular,
  faDiamondExclamation,
  faLockRegular
} from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { LearnerRoutes } from "@outschool/routes";
import * as Time from "@outschool/time";
import { useFeatureFlag } from "@outschool/ui-components-shared";
import { LegacyThemeProvider } from "@outschool/ui-legacy-component-library";
import { Redirect, useIsMobile, useNavigation } from "@outschool/ui-utils";
import React from "react";

import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import {
  Lesson,
  SectionLesson,
  useLearnerClassroomContext
} from "../../providers/LearnerClassroomProvider";
import {
  AllLessonsCompletedView,
  LessonView,
  LessonViewHeading,
  SectionLessonView
} from "./lessonsPage/LessonView";

const ALL_LESSONS_COMPLETE_TAB_PANEL_KEY = "lessons-complete";

interface ClassroomLessonsPageProps {
  lessonUid?: string;
}

export const ClassroomLessonsPage = ({
  lessonUid
}: ClassroomLessonsPageProps) => {
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\ClassroomLessonsPage"
  );
  const {
    sectionUid,
    section,
    syllabus,
    hasLessons,
    allLessonsCompleted,
    sectionLessons
  } = useLearnerClassroomContext();
  const isSectionLessonsEnabled = useFeatureFlag(
    "lexi-class-nav-section-lessons"
  );
  useAnalyticsPage({ pageName: PageType.Lessons, pageParams: { sectionUid } });

  if (!hasLessons) {
    return <Redirect to={LearnerRoutes.classroomPath(sectionUid)} />;
  }

  const lessons: Lesson[] = syllabus?.lessons || [];
  if (!lessonUid) {
    if (allLessonsCompleted) {
      lessonUid = ALL_LESSONS_COMPLETE_TAB_PANEL_KEY;
    } else {
      const currentLesson =
        section?.currentSectionLesson || syllabus?.currentLesson;
      const firstLesson = currentLesson || sectionLessons?.[0] || lessons[0];
      return (
        <Redirect
          to={
            firstLesson
              ? LearnerRoutes.lessonsPath(sectionUid, firstLesson.uid)
              : LearnerRoutes.classroomPath(sectionUid)
          }
        />
      );
    }
  }

  // LegacyThemeProvider injects breakpoints which completely breaks how Grid works in MUI
  // Make BackpackThemeProvider wrap it immediately to avoid even referencing the LegacyThemeProvider
  return (
    <BackpackThemeProvider>
      <TabContext value={lessonUid}>
        <Grid container spacing={48} sx={{ flex: 1, paddingTop: 36 }}>
          <Grid xs={12} md={4}>
            {isSectionLessonsEnabled ? (
              <LessonsNavigationForSectionLessons
                selectedLessonUid={lessonUid}
              />
            ) : (
              <LessonsNavigation selectedLessonUid={lessonUid} />
            )}
          </Grid>
          <Grid
            xs={12}
            md={8}
            sx={(theme: Theme) => ({
              [theme.breakpoints.down("md")]: {
                marginX: 16
              }
            })}
          >
            <LegacyThemeProvider>
              {(isSectionLessonsEnabled ? sectionLessons! : lessons).map(
                lessonOrSectionLesson => (
                  <TabPanel
                    key={lessonOrSectionLesson.uid}
                    value={lessonOrSectionLesson.uid}
                  >
                    {isSectionLessonsEnabled ? (
                      <SelectedSectionLessonView
                        sectionLesson={
                          lessonOrSectionLesson as unknown as SectionLesson
                        }
                      />
                    ) : (
                      <SelectedLessonView
                        lesson={lessonOrSectionLesson as unknown as Lesson}
                      />
                    )}
                  </TabPanel>
                )
              )}
              <TabPanel
                key={ALL_LESSONS_COMPLETE_TAB_PANEL_KEY}
                value={ALL_LESSONS_COMPLETE_TAB_PANEL_KEY}
              >
                <AllLessonsCompletedView
                  heading={
                    <LessonViewHeading
                      text={t("You've Completed This Course")}
                    />
                  }
                />
              </TabPanel>
            </LegacyThemeProvider>
          </Grid>
        </Grid>
      </TabContext>
    </BackpackThemeProvider>
  );
};

interface SelectedLessonViewProps {
  lesson: Lesson;
}
const SelectedLessonView = ({ lesson }: SelectedLessonViewProps) => {
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\ClassroomLessonsPage"
  );
  const { syllabus } = useLearnerClassroomContext();

  // Learner can only select unlocked lessons for now and either they are completed or upcoming but nothing in between until we have a missed class view
  let heading = t("What's Next");
  if (lesson.isMissed) {
    heading = heading = t("Catch Up On Your Lessons");
  }
  if (lesson.lessonStatus === LearningStatementsVerb.Completed) {
    heading = t("Review What You’ve Learned");
  }

  if (syllabus?.currentLesson?.uid === lesson.uid) {
    // Current lesson view
    return (
      <LessonView
        heading={<Typography variant="h3">{heading}</Typography>}
        lesson={lesson}
      />
    );
  }

  return (
    <LessonView
      heading={<Typography variant="h3">{heading}</Typography>}
      lesson={lesson}
    />
  );
};

interface SelectedSectionLessonViewProps {
  sectionLesson: SectionLesson;
}

const SelectedSectionLessonView = ({
  sectionLesson
}: SelectedSectionLessonViewProps) => {
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\ClassroomLessonsPage"
  );
  const { section } = useLearnerClassroomContext();

  // Learner can only select unlocked lessons for now and either they are completed or upcoming but nothing in between until we have a missed class view
  let heading = t("What's Next");
  if (sectionLesson.lesson?.isMissed) {
    heading = heading = t("Catch Up On Your Lessons");
  }
  if (sectionLesson?.isCompleted) {
    heading = t("Review What You’ve Learned");
  }

  if (section?.currentSectionLesson?.uid === sectionLesson.uid) {
    // Current lesson view
    return (
      <SectionLessonView
        heading={<Typography variant="h3">{heading}</Typography>}
        sectionLesson={sectionLesson}
      />
    );
  }

  return (
    <SectionLessonView
      heading={<Typography variant="h3">{heading}</Typography>}
      sectionLesson={sectionLesson}
    />
  );
};

type LessonsNavigationProps = {
  selectedLessonUid: string;
};
const LessonsNavigation = React.memo(
  ({ selectedLessonUid }: LessonsNavigationProps) => {
    const { t } = useTranslation(
      "learnerApp\\LearnerClassroom\\ClassroomLessonsPage"
    );
    const { sectionUid, syllabus } = useLearnerClassroomContext();
    const { lessons } = syllabus ?? { lessons: [] };

    const navigate = useNavigation();
    const isMobile = useIsMobile();

    const onLessonClick = (_event: React.SyntheticEvent, lessonUid: string) => {
      // TODO make URL change without page reload
      navigate(LearnerRoutes.lessonsPath(sectionUid, lessonUid));
    };
    const completedLessons = lessons.filter(
      (l: Lesson) => l.isCompleted
    ).length;
    // Calculate the percentage of completed lessons
    let percent = (completedLessons / lessons.length) * 100;
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 24 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 8 }}>
          <Box width="100%">
            <ProgressBar
              value={percent}
              variant="determinate"
              color="inherit"
            />
          </Box>

          <Typography
            variant="overline"
            sx={theme => ({
              whiteSpace: "nowrap",
              fontWeight: 500, // Specified as "Weight"
              fontSize: "12", // Specified as "Size"
              lineHeight: theme.spacing(20), // Specified as "Line height"
              letterSpacing: "1", // Specified as "Letter"
              color: "neutral.dark"
            })}
          >
            {t("{{numCompletedLessons}} of {{numTotalLessons}} Lessons", {
              numCompletedLessons: completedLessons,
              numTotalLessons: lessons.length
            })}
          </Typography>
        </Box>
        <TabList
          onChange={onLessonClick}
          /* eslint-disable i18next/no-literal-string */
          orientation={isMobile ? "horizontal" : "vertical"}
          scrollButtons={isMobile ? true : "auto"}
          variant={isMobile ? "scrollable" : "standard"}
          TabIndicatorProps={{
            sx: {
              width: "3px"
            }
          }}
          /* eslint-enable i18next/no-literal-string */
        >
          {lessons.map((lesson: Lesson) => {
            const lessonIsSelected = selectedLessonUid === lesson.uid;

            return (
              <Tab
                key={lesson.uid}
                value={lesson.uid}
                label={
                  <LessonNavigationLabel
                    title={
                      <>
                        {t("Lesson {{lessonNumber}}", {
                          lessonNumber: lesson.lessonNumber ?? ""
                        })}
                        <LessonStatusIcon
                          isDisabled={lesson.isLocked}
                          lessonStatus={lesson.lessonStatus}
                          isMissed={lesson.isMissed}
                        />
                      </>
                    }
                    description={lesson.title}
                    selected={lessonIsSelected}
                  />
                }
                icon={
                  isMobile ? undefined : (
                    <Icon
                      icon={
                        lessonIsSelected
                          ? faChevronRightRegular
                          : faChevronDownRegular
                      }
                      color={lessonIsSelected ? "primary" : "inherit"}
                      size="small"
                    />
                  )
                }
                // eslint-disable-next-line i18next/no-literal-string
                iconPosition="end"
                disabled={lesson.isLocked}
                sx={{
                  // Put Tab content on left, and icon on right
                  justifyContent: "space-between",
                  padding: 16,
                  ...(lessonIsSelected
                    ? {
                        color: "primary.main",
                        backgroundColor: "primary.50"
                      }
                    : {
                        borderBottom: "1px solid",
                        borderColor: "neutral.200"
                      })
                }}
              />
            );
          })}
        </TabList>
      </Box>
    );
  }
);

const LessonsNavigationForSectionLessons = React.memo(
  ({ selectedLessonUid }: LessonsNavigationProps) => {
    const { t } = useTranslation(
      "learnerApp\\LearnerClassroom\\ClassroomLessonsPage"
    );
    const currentLearner = useCurrentLearner();
    const { sectionUid, section, sectionLessons } =
      useLearnerClassroomContext();
    const { timeZone } = currentLearner || {};
    const navigate = useNavigation();
    const isMobile = useIsMobile();

    const onLessonClick = (
      _event: React.SyntheticEvent,
      sectionLessonUid: string
    ) => {
      // TODO make URL change without page reload
      navigate(LearnerRoutes.lessonsPath(sectionUid, sectionLessonUid));
    };
    const completedLessons = (sectionLessons || []).filter(
      (l: SectionLesson) => l.isCompleted
    ).length;
    // Calculate the percentage of completed lessons
    let percent = (completedLessons / (sectionLessons?.length || 1)) * 100;

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 24 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 8 }}>
          <Box width="100%">
            <ProgressBar
              value={percent}
              variant="determinate"
              color="inherit"
            />
          </Box>

          <Typography
            variant="overline"
            sx={theme => ({
              whiteSpace: "nowrap",
              fontWeight: 500, // Specified as "Weight"
              fontSize: "12", // Specified as "Size"
              lineHeight: theme.spacing(20), // Specified as "Line height"
              letterSpacing: "1", // Specified as "Letter"
              color: "neutral.dark"
            })}
          >
            {t("{{numCompletedLessons}} of {{numTotalLessons}} Lessons", {
              numCompletedLessons: completedLessons,
              numTotalLessons: sectionLessons?.length
            })}
          </Typography>
        </Box>
        <TabList
          onChange={onLessonClick}
          /* eslint-disable i18next/no-literal-string */
          orientation={isMobile ? "horizontal" : "vertical"}
          scrollButtons={isMobile ? true : "auto"}
          variant={isMobile ? "scrollable" : "standard"}
          TabIndicatorProps={{
            sx: {
              width: "3px"
            }
          }}
          /* eslint-enable i18next/no-literal-string */
        >
          {sectionLessons?.map((sectionLesson: SectionLesson) => {
            const lessonIsSelected = selectedLessonUid === sectionLesson.uid;
            const title = trimSectionLessonTitle(sectionLesson.title);

            const isLocked = sectionLesson?.isLocked;
            const status = sectionLesson?.lessonStatus;
            return (
              <Tab
                onClick={e => {
                  e.stopPropagation();
                  onLessonClick(e, sectionLesson.uid);
                }}
                key={sectionLesson.uid}
                value={sectionLesson.uid}
                label={
                  <LessonNavigationLabelForSectionLessons
                    title={
                      <>
                        {title}
                        {!isLocked && (
                          <LessonStatusIcon
                            isDisabled={isLocked}
                            lessonStatus={status}
                            isMissed={sectionLesson.lesson?.isMissed}
                            forSectionLessons
                          />
                        )}
                      </>
                    }
                    meetingDate={sectionLesson?.start_time}
                    timeZone={timeZone}
                    isCancelled={
                      !section!.is_self_paced && sectionLesson.isCancelled
                    }
                    selected={lessonIsSelected}
                  />
                }
                icon={
                  isMobile ? undefined : (
                    <Icon
                      icon={
                        isLocked
                          ? faLockRegular
                          : lessonIsSelected
                          ? faChevronRightRegular
                          : faChevronDownRegular
                      }
                      color={
                        isLocked
                          ? "error"
                          : lessonIsSelected
                          ? "primary"
                          : "inherit"
                      }
                    />
                  )
                }
                // eslint-disable-next-line i18next/no-literal-string
                iconPosition="end"
                disabled={isLocked}
                sx={{
                  // Put Tab content on left, and icon on right
                  justifyContent: "space-between",
                  width: "100%",
                  padding: 16,
                  ...(lessonIsSelected
                    ? {
                        color: "primary.main",
                        backgroundColor: "primary.50"
                      }
                    : {
                        borderBottom: "1px solid",
                        borderColor: "neutral.200"
                      })
                }}
              />
            );
          })}
        </TabList>
      </Box>
    );
  }
);

interface LessonNavigationLabelProps {
  title: React.ReactNode;
  description?: string | null;
  selected?: boolean;
}
const LessonNavigationLabel = ({
  title,
  description,
  selected
}: LessonNavigationLabelProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    }}
  >
    <Typography
      variant="h6"
      sx={{
        color: selected ? "primary.main" : "text.primary"
      }}
      gutterBottom
    >
      {title}
    </Typography>
    {description && (
      <Typography variant="body2" sx={{ textAlign: "left" }}>
        {description}
      </Typography>
    )}
  </Box>
);

interface LessonNavigationLabelForSectionLessonsProps {
  title: React.ReactNode;
  meetingDate?: Date | null;
  isCancelled?: boolean;
  timeZone?: string;
  selected?: boolean;
}
const LessonNavigationLabelForSectionLessons = ({
  title,
  meetingDate,
  timeZone,
  isCancelled = false,
  selected
}: LessonNavigationLabelForSectionLessonsProps) => {
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\ClassroomLessonsPage"
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        alignItems: "flex-start"
      }}
    >
      <Typography
        variant="h6"
        emphasized
        sx={{
          textAlign: "left",
          color: selected ? "primary.main" : "text.subdue"
        }}
        gutterBottom
      >
        {title}
      </Typography>
      {(meetingDate || isCancelled) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 8
          }}
        >
          <Icon
            icon={isCancelled ? faCalendarXmark : faCalendar}
            color={isCancelled ? "error" : undefined}
            size="inherit"
            fixedWidth
          />
          {isCancelled ? (
            <Typography
              variant="subtitle1"
              sx={{ textAlign: `left`, color: `error.main` }}
            >
              {t("Meeting Cancelled")}
            </Typography>
          ) : meetingDate ? (
            <Typography
              variant={`subtitle1`}
              sx={{ textAlign: `left`, color: `default` }}
            >
              {Time.fullDurationStringWithoutEndTime(meetingDate, timeZone)}
            </Typography>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

// TODO IMPLEMENT THIS - for now ts ignore unused function
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LessonNavigationSectionLessonPosts = ({ sectionLesson }) => {
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\ClassroomLessonsPage"
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 8
      }}
    >
      <Typography variant="body1">
        {t("Class Posts: {{numClassPosts}}", {
          numClassPosts: sectionLesson.classPosts?.length
        })}
      </Typography>
      <Typography variant="body1">
        {t("Assignments: {{numAssignments}}", {
          numAssignments: sectionLesson.assignmentClassPosts?.length
        })}
      </Typography>
    </Box>
  );
};

const LessonStatusIcon = ({
  isDisabled,
  lessonStatus,
  isMissed,
  forSectionLessons = false
}) => {
  const statusIcon: { icon: IconDefinition; color: IconColor } | null =
    isDisabled
      ? { icon: faLockRegular, color: "error" }
      : lessonStatus === LearningStatementsVerb.Completed
      ? {
          icon: faCheckCircle,
          color: "success"
        }
      : isMissed
      ? { icon: faDiamondExclamation, color: "error" }
      : null;
  if (!statusIcon) {
    return null;
  }

  return (
    <Icon
      icon={statusIcon.icon}
      color={statusIcon.color}
      {...(forSectionLessons ? { fixedWidth: true } : {})}
      sx={{
        marginLeft: 8,
        ...(forSectionLessons
          ? { width: 15, height: 15, verticalAlign: 0 }
          : {}),
        ...(isMissed ? { backgroundColor: "error.light" } : {})
      }}
    />
  );
};

const trimSectionLessonTitle = (title: string) => {
  const maxLength = 30;
  return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
};
