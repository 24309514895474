import { Button, Icon } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { faRandom } from "@outschool/icons";
import React from "react";

export default function ShuffleButton({ onClick, title }) {
  return (
    <Button
      variant="contained"
      sx={{
        padding: "0.5em",
        minWidth: "60px",
        color: "common.white",
        size: "large"
      }}
      onClick={onClick}
      title={title}
    >
      <Icon
        icon={faRandom}
        sx={{
          marginX: "0.5em"
        }}
      />
    </Button>
  );
}
