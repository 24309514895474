import { isLocalStorageSupported } from "@outschool/local-storage";
import { useInterceptor } from "@patched/hookrouter";
import React from "react";

import { VISITED_CLUB_POST_PAGE_FROM_CLUB_HOME_PAGE_STORE_KEY } from "../lib/constants";
import * as Routes from "../lib/Routes";

// Used to determine whether to retain scroll state when going back from club post page to club home page
export default function useClubRouteState() {
  React.useEffect(() => {
    const localStorage = isLocalStorageSupported();
    // reset group route state on page load
    localStorage?.removeItem(
      VISITED_CLUB_POST_PAGE_FROM_CLUB_HOME_PAGE_STORE_KEY
    );
  }, []);

  useInterceptor((currentPath, nextPath) => {
    const localStorage = isLocalStorageSupported();
    if (
      currentPath.startsWith(`/${Routes.CLUB_PATH_NAME}/`) &&
      nextPath.includes(`/${Routes.CLUB_POST_PATH_NAME}/`) &&
      nextPath.startsWith(currentPath)
    ) {
      localStorage?.setItem(
        VISITED_CLUB_POST_PAGE_FROM_CLUB_HOME_PAGE_STORE_KEY,
        "true"
      );
    } else {
      localStorage?.setItem(
        VISITED_CLUB_POST_PAGE_FROM_CLUB_HOME_PAGE_STORE_KEY,
        "false"
      );
    }
    return nextPath;
  });
}
