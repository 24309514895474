import { Label } from "@outschool/ui-legacy-component-library";
import React from "react";

import useSectionString from "../../hooks/useSectionString";
import * as Section from "../../Section";

export default function SectionStatusLabel({
  section,
  hasEnrollment = false,
  ...props
}) {
  const { statusLabel } = useSectionString();
  const isComplete = Section.isComplete(section);
  const isCanceled = Section.isCanceled(section);
  const isInProgress = Section.isInProgress(section);

  let color;
  if (isCanceled) {
    color = "red";
  } else if (!isCanceled && !isComplete) {
    color = isInProgress ? "confirm" : "primary";
  } else {
    color = "gray3";
  }

  return (
    <Label color={color} {...props}>
      {statusLabel(section, section.filledSpaceCount, hasEnrollment)}
    </Label>
  );
}
