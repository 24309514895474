// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useRedirect, useRoutes } from "@patched/hookrouter";
import React from "react";

import ChangePassword from "./ChangePassword";
import Login from "./Login";
import RedirectAfterLogin from "./RedirectAfterLogin";

const usersRoutes = {
  "/login": () => <Login />,
  "/change-password": () => <ChangePassword />,
  "/redirect-after-login": () => <RedirectAfterLogin />
};

const Users: React.FC = () => {
  const currentRoute = useRoutes(usersRoutes);
  useRedirect("/change_password", "/change-password");

  return currentRoute;
};

export default Users;
