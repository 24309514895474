import { Box, Image, Theme, Typography } from "@outschool/backpack";
import { TEACHER_MENTION_ID } from "@outschool/text";
import * as Time from "@outschool/time";
import { useLazyQuery } from "@outschool/ui-apollo";
import {
  AddClassPostCommentInput,
  AllClassPostCommentsQueryQuery,
  AllClassPostCommentsQueryQueryVariables,
  ClassPost,
  ClassPostComment,
  ClassPostCommentContent,
  ClassPostCommentList,
  ClassPostCommentSenderPhotoAndPublishDate,
  ClassPostContent,
  ClassPostSenderPhotoAndPublishDate,
  ClassPostType,
  ReplyButton,
  allClassPostCommentsQuery,
  isClassPostFragment,
  useValidMentions
} from "@outschool/ui-components-classroom";
import { ErrorMessage } from "@outschool/ui-components-shared";
import React from "react";

import WelcomePostIllustration from "../../../../images/learner/welcome_post_illustration.png";
import { useLearnerClassroomAddClassPostCommentMutation } from "../../hooks/useLearnerClassroomAddClassPostCommentMutation";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useLearnerClassroomContext } from "../../providers/LearnerClassroomProvider";
import { updateLearnerClassroomSinglePostsCache } from "../../queries/LearnerSinglePostQuery";

type Mention = {
  id: string;
  display: string;
  startTime: string | null;
};

type ClassPostProps = {
  post: ClassPostType;
  mentionNames?: Mention[];
  usersCanComment: boolean;
  handleAddComment: ReturnType<
    typeof useLearnerClassroomAddClassPostCommentMutation
  >;
  isNew?: boolean;
};

function LearnerClassPost({
  post,
  mentionNames,
  usersCanComment,
  handleAddComment,
  isNew = false
}: ClassPostProps) {
  const { sectionUid, activity, section } = useLearnerClassroomContext();

  const currentLearner = useCurrentLearner()!;
  const trackingMetadata = {
    userUid: currentLearner?.parent?.uid,
    learnerUid: currentLearner.uid
  };

  const { timeZone } = currentLearner;

  const [fetchRestOfComments, { data, loading }] = useLazyQuery<
    AllClassPostCommentsQueryQuery,
    AllClassPostCommentsQueryQueryVariables
  >(allClassPostCommentsQuery, {
    variables: {
      sectionUid,
      classPostUid: post.uid,
      plainText: false
    }
  });
  const [error, setError] = React.useState<boolean>(false);

  const hasFetchedAllComments = !!data?.classroom.postByUid?.comments?.length;
  const comments =
    (hasFetchedAllComments
      ? data?.classroom.postByUid?.comments
      : isClassPostFragment(post)
      ? post.commentsPreview.comments
      : post.comments) ?? [];

  const postTrackingMetadata = {
    ...trackingMetadata,
    postUid: post.uid
  };

  const handleCommentSubmit = ({ text, attachments, video, mentions }) => {
    handleAddComment({
      classPostUid: post.uid,
      text,
      attachments,
      video,
      learner: currentLearner,
      mentions,
      overrides: {
        // Use alternative cache update function when all comments have been fetched
        // This is because the shape of the data will be changed, and we still want
        // user comments to appear immediately without waiting for the next poll
        updateClassroomCache: hasFetchedAllComments
          ? updateLearnerClassroomSinglePostsCache
          : undefined
      }
    }).then(
      _data => {},
      error => {
        setError(error);
      }
    );
  };

  const educatorUid = section?.leader.uid ?? activity?.leader.uid ?? "";
  const remainingCommentsCount =
    isClassPostFragment(post) && !data
      ? post.commentsPreview.remainingCommentsCount
      : 0;

  const mentionableNames = useValidMentions(post, mentionNames);

  return (
    <ClassPost
      isNew={isNew}
      sx={(theme: Theme) => ({
        border: "1px solid",
        borderColor: "neutral.200",
        borderRadius: 16,
        padding: 24,
        background: post.isWelcomePost
          ? theme.gradients?.primarySecondary
          : undefined
      })}
    >
      {post.isWelcomePost ? (
        <WelcomePostHeader publishAt={post.publishAt} timeZone={timeZone} />
      ) : (
        <ClassPostSenderPhotoAndPublishDate
          sender={post.sender!}
          publishedAt={post.publishAt}
          dateHref={`/classroom/${section?.uid}/posts/${post.uid}`}
          timeZone={timeZone}
        />
      )}

      <ClassPostContent
        allowRichText
        sender={post.sender}
        messageContent={post.messageContent}
        trackingLabel="classroom"
        trackingMetadata={postTrackingMetadata}
      />
      <ClassPostCommentList
        remainingCommentsCount={remainingCommentsCount}
        onLoadMore={fetchRestOfComments}
      >
        {loading
          ? null
          : comments.map(comment => {
              const isEducator = comment.sender.uid === educatorUid;
              const isSender = currentLearner?.uid === comment.sender.uid;
              const replyToUser = {
                id: isEducator ? TEACHER_MENTION_ID : comment.sender.uid,
                display: comment.sender.name ?? "Someone"
              };
              const isReplyEnabled = usersCanComment && !isSender;
              return (
                <ClassPostComment key={comment.uid}>
                  <ClassPostCommentSenderPhotoAndPublishDate
                    sender={comment.sender}
                    publishedAt={comment.sendAt}
                    timeZone={timeZone}
                  />
                  <ClassPostCommentContent
                    sender={comment.sender}
                    messageContent={comment.messageContent}
                    isEducator={isEducator}
                    isSender={isSender}
                    trackingLabel="classroom_comment"
                    trackingMetadata={{
                      commentUid: comment.uid,
                      ...trackingMetadata
                    }}
                  />
                  {isReplyEnabled && <ReplyButton replyToUser={replyToUser} />}
                </ClassPostComment>
              );
            })}
      </ClassPostCommentList>
      {usersCanComment && (
        <>
          {error && <ErrorMessage value={error} />}
          <AddClassPostCommentInput
            placeholder={`Write a comment as ${currentLearner?.name}`}
            onSubmit={handleCommentSubmit}
            mentionNames={mentionableNames}
          />
        </>
      )}
    </ClassPost>
  );
}

export default LearnerClassPost;

// publishAt is literally type any from the generated types :facepalm:
const WelcomePostHeader = ({
  publishAt,
  timeZone
}: {
  publishAt: any;
  timeZone: string;
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      gap: 16,
      alignItems: "center"
    }}
  >
    <Image src={WelcomePostIllustration} height={60} />
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h5">Welcome Post</Typography>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        Posted on {Time.formatDateTimeWithFullWeekday(publishAt, timeZone)}
      </Typography>
    </Box>
  </Box>
);
