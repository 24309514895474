import { Box, SxProps, TabProps, Tabs, Theme } from "@outschool/backpack";
import React from "react";

import Footer from "./nav/Footer";
import Header from "./nav/Header";

interface TabbedLayoutProps {
  sx?: SxProps;
  /**
   * Callback for when the tab changes. Can be used for routing.
   * @param value
   * @returns
   */
  onChangeTab?: (value: string) => void;
  /**
   * The value of the currently selected `Tab`. Defaults to no tab selected.
   */
  currentTab?: string | boolean;
  /**
   * Header for the page - not to be confused with the nav header
   */
  header: React.ReactNode;
  /**
   * Rendered Tabs elements.
   */
  tabs: React.ReactElement<TabProps>[];
  /**
   * Contents of opened tab.
   */
  children?: React.ReactNode;

  /**
   * Outschool Unlimited mode
   */
  outschoolUnlimitedMode?: boolean;
}

const TabbedLayout = (
  {
    sx,
    header,
    currentTab = false,
    tabs,
    onChangeTab,
    children,
    outschoolUnlimitedMode = false
  }: TabbedLayoutProps,
  ref
) => (
  <Box
    flex
    sx={[
      {
        position: "relative",
        flexDirection: "column",
        minHeight: "100vh"
      },
      ...(Array.isArray(sx) ? sx : [sx])
    ]}
    ref={ref}
  >
    <Header
      sx={{
        position: "relative",
        backgroundColor: "white",
        borderColor: "#f1f2f3",
        minHeight: 60,
        boxShadow: "0px 2px 5px rgba(23, 25, 28, 0.1)"
      }}
      includeFindClasses={!outschoolUnlimitedMode}
    />

    <Box
      sx={{
        width: "100%",
        ...(outschoolUnlimitedMode
          ? {
              background:
                "linear-gradient(118deg, #F0F3FF 31.38%, #FFF0F9 162.62%)"
            }
          : { backgroundColor: "grey.100", borderBottom: "1px solid" }),
        borderColor: "grey.300"
      }}
    >
      <Box
        sx={(theme: Theme) => ({
          maxWidth: 1120,
          margin: "0 auto",
          [theme.breakpoints.down("md")]: {
            paddingX: "1rem"
          }
        })}
      >
        <Box sx={{ width: "100%" }}>{header}</Box>
        <Tabs
          value={currentTab}
          onChange={(_e: React.SyntheticEvent, value: string) =>
            onChangeTab?.(value)
          }
          sx={{ width: "100%" }}
          variant="scrollable"
          // eslint-disable-next-line i18next/no-literal-string
          scrollButtons="auto"
        >
          {tabs}
        </Tabs>
      </Box>
    </Box>
    <Box sx={{ width: "100%", maxWidth: 1120, margin: "0 auto" }}>
      {children}
    </Box>
    <Footer sx={{ marginTop: "2rem" }} />
  </Box>
);

export default React.forwardRef(TabbedLayout);
