import { UnlimitedClassroomQuery } from "@outschool/gql-frontend-generated";
import { ApolloError } from "@outschool/ui-apollo";
import React from "react";

import { useUnlimitedClassroomQuery } from "../queries/LearnerClassroomQuery";
import { useCurrentLearner } from "./CurrentLearnerProvider";

export type Classroom = UnlimitedClassroomQuery["classroom"];
export type Activity = Classroom["activity"];
export type Syllabus = Activity["syllabus"];
export type Lesson = NonNullable<NonNullable<Syllabus>["lessons"][number]>;
export type Unit = NonNullable<Lesson["unit"]>;

interface UnlimitedClassroomContext {
  loading: boolean;
  error?: ApolloError;
  sectionUid: string;
  classroom?: Omit<
    Classroom,
    "currentLearnerEnrollments" | "learners" | "section" | "activity"
  >;
  activity?: Omit<Activity, "syllabus">;
  syllabus?: Syllabus;
  hasLessons: boolean;
  allLessonsCompleted: boolean;
}

const UnlimitedClassroomContext =
  React.createContext<UnlimitedClassroomContext>({
    loading: false,
    error: undefined,
    sectionUid: "",
    classroom: undefined,
    activity: undefined,
    syllabus: undefined,
    hasLessons: false,
    allLessonsCompleted: false
  });

interface LearnerClassroomProviderProps {
  sectionUid: string;
  children: React.ReactNode;
}

export const UnlimitedClassroomProvider = ({
  sectionUid,
  children
}: LearnerClassroomProviderProps) => {
  const learner = useCurrentLearner();
  const { loading, data, error } = useUnlimitedClassroomQuery(
    {
      sectionUid,
      learnerUid: learner?.uid!
    },
    {
      skip: !learner?.uid
    }
  );

  const { classroom: originalClassroom } =
    data ?? ({} as UnlimitedClassroomQuery);
  const { activity, ...classroom } = originalClassroom ?? ({} as Classroom);
  const { syllabus } = activity ?? ({} as Activity);

  const hasLessons =
    !!syllabus?.published_at &&
    Boolean(
      activity.isSelfPaced || syllabus?.lessons?.some(l => l.meetings?.length)
    );

  const allLessonsCompleted = Boolean(
    syllabus?.lessons?.every(lesson => lesson.isCompleted)
  );

  const value: UnlimitedClassroomContext = {
    loading,
    error,
    sectionUid,
    classroom,
    activity,
    syllabus,
    hasLessons,
    allLessonsCompleted
  };

  return (
    <UnlimitedClassroomContext.Provider value={value}>
      {children}
    </UnlimitedClassroomContext.Provider>
  );
};

export const useUnlimitedClassroomContext = () =>
  React.useContext(UnlimitedClassroomContext);
