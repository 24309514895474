export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Date: any;
  /** A field whose value is an existing 2 or 3 letter ISO 639 language code https://iso639-3.sil.org/code_tables/639/data */
  Iso639: any;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  ContentfulDateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  ContentfulJSON: any;
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  ContentfulHexColor: any;
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  ContentfulDimension: any;
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  ContentfulQuality: any;
  JSON: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  Url: any;
};

export enum LearnerModeSwitchAuthRequirement {
  Password = "password",
  Pin = "pin"
}

export enum LearningStatementsVerb {
  Completed = "Completed",
  NotStarted = "NotStarted",
  Started = "Started",
  Submitted = "Submitted",
  Viewed = "Viewed",
  Voided = "Voided"
}

export enum TextMimeType {
  TextHtml = "TEXT_HTML",
  TextMarkdown = "TEXT_MARKDOWN",
  TextPlain = "TEXT_PLAIN",
  TextPlainWithMentions = "TEXT_PLAIN_WITH_MENTIONS"
}

export enum SubmissionRequirementType {
  Optional = "optional",
  Recommended = "recommended",
  Required = "required"
}

export type CommentsFilterInput = {
  creationDateSortOrder?: InputMaybe<SortOrder>;
};

export enum SortOrder {
  Ascending = "ascending",
  Descending = "descending"
}

export type SearchFilters = {
  activityRatingMin?: InputMaybe<Scalars["Float"]>;
  /**
   * A list of strings that are matched without analysis to normalized values
   * from the activities.tags column
   */
  adminTags?: InputMaybe<Array<Scalars["String"]>>;
  age?: InputMaybe<Scalars["String"]>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  curriculums?: InputMaybe<Array<Scalars["String"]>>;
  delivery?: InputMaybe<Scalars["String"]>;
  dow?: InputMaybe<Scalars["String"]>;
  durationWeeksMax?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin?: InputMaybe<Scalars["Int"]>;
  enabledBooleanFilters?: InputMaybe<Scalars["String"]>;
  endBy?: InputMaybe<Scalars["String"]>;
  endByTime?: InputMaybe<Scalars["Float"]>;
  englishProficiencyLevel?: InputMaybe<EnglishProficiencyLevel>;
  /**
   * Boolean to exclude activities with the new 1-1 format in search results
   * Default: false
   */
  excludeNewOneOnOneFormat?: InputMaybe<Scalars["Boolean"]>;
  excludeTimeWindows?: InputMaybe<Array<SearchFilterExcludeTimeWindow>>;
  format?: InputMaybe<Scalars["String"]>;
  fundingPrograms?: InputMaybe<Array<Scalars["String"]>>;
  gradeLevel?: InputMaybe<Scalars["String"]>;
  hasAssessment?: InputMaybe<Scalars["Boolean"]>;
  hasGrades?: InputMaybe<Scalars["Boolean"]>;
  hasHomework?: InputMaybe<Scalars["Boolean"]>;
  includeInProgressFixedLengthLiveFormat?: InputMaybe<Scalars["Boolean"]>;
  includeSelfPaced?: InputMaybe<Scalars["Boolean"]>;
  isAvailable?: InputMaybe<Scalars["Boolean"]>;
  languageOfInstruction?: InputMaybe<Scalars["String"]>;
  listUid?: InputMaybe<Scalars["String"]>;
  /**
   * A list of strings, to be matched against the activity title.
   *
   * Example: to search for winter holiday classes, a multiTermQuery could be:
   * ["Hanukkah", "Christmas", "Winter Solstice"]
   */
  multiTermQuery?: InputMaybe<Array<Scalars["String"]>>;
  order?: InputMaybe<Scalars["String"]>;
  pricePerCourseMax?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin?: InputMaybe<Scalars["Int"]>;
  /** A string query, matched against various fields in the activites ES index */
  q?: InputMaybe<Scalars["String"]>;
  /**
   * A number used to be able to reproduce randomized search results on
   * no query searches
   */
  seed?: InputMaybe<Scalars["Int"]>;
  showListings?: InputMaybe<Scalars["Int"]>;
  standards?: InputMaybe<Array<Scalars["String"]>>;
  starred?: InputMaybe<Scalars["Boolean"]>;
  startAfter?: InputMaybe<Scalars["String"]>;
  startAfterTime?: InputMaybe<Scalars["Float"]>;
  startBefore?: InputMaybe<Scalars["String"]>;
  teacherRatingMin?: InputMaybe<Scalars["Float"]>;
  theme?: InputMaybe<Scalars["String"]>;
  time?: InputMaybe<Scalars["String"]>;
  timeOfDay?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
  topics?: InputMaybe<Array<Scalars["String"]>>;
  userName?: InputMaybe<Scalars["String"]>;
  userUid?: InputMaybe<Scalars["String"]>;
  weeklyMeetingsMax?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin?: InputMaybe<Scalars["Int"]>;
};

export enum EnglishProficiencyLevel {
  A0 = "A0",
  A05 = "A05",
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
  PreA1 = "PRE_A1"
}

export type SearchFilterExcludeTimeWindow = {
  dow: Scalars["Int"];
  endHour: Scalars["Int"];
  endMinute: Scalars["Int"];
  startHour: Scalars["Int"];
  startMinute: Scalars["Int"];
};

export enum TeacherCertificateDiscipline {
  ElementaryEducation = "ElementaryEducation",
  EnglishOrLanguageArts = "EnglishOrLanguageArts",
  EnglishToSpeakersOfOtherLanguages = "EnglishToSpeakersOfOtherLanguages",
  ForeignLanguage = "ForeignLanguage",
  HealthOrPe = "HealthOrPE",
  Mathematics = "Mathematics",
  MusicOrTheaterOrArts = "MusicOrTheaterOrArts",
  Other = "Other",
  Science = "Science",
  SecondaryEducation = "SecondaryEducation",
  SocialStudiesOrHistory = "SocialStudiesOrHistory",
  SpecialEducation = "SpecialEducation"
}

export enum TeacherCertificateState {
  Alabama = "Alabama",
  Alaska = "Alaska",
  Arizona = "Arizona",
  Arkansas = "Arkansas",
  California = "California",
  Colorado = "Colorado",
  Connecticut = "Connecticut",
  Delaware = "Delaware",
  DistrictOfColumbia = "DistrictOfColumbia",
  Florida = "Florida",
  Georgia = "Georgia",
  Hawaii = "Hawaii",
  Idaho = "Idaho",
  Illinois = "Illinois",
  Indiana = "Indiana",
  Iowa = "Iowa",
  Kansas = "Kansas",
  Kentucky = "Kentucky",
  Louisiana = "Louisiana",
  Maine = "Maine",
  Maryland = "Maryland",
  Massachusetts = "Massachusetts",
  Michigan = "Michigan",
  Minnesota = "Minnesota",
  Mississippi = "Mississippi",
  Missouri = "Missouri",
  Montana = "Montana",
  Nebraska = "Nebraska",
  Nevada = "Nevada",
  NewHampshire = "NewHampshire",
  NewJersey = "NewJersey",
  NewMexico = "NewMexico",
  NewYork = "NewYork",
  NorthCarolina = "NorthCarolina",
  NorthDakota = "NorthDakota",
  NotInUs = "NotInUS",
  Ohio = "Ohio",
  Oklahoma = "Oklahoma",
  Oregon = "Oregon",
  Pennsylvania = "Pennsylvania",
  RhodeIsland = "RhodeIsland",
  SouthCarolina = "SouthCarolina",
  SouthDakota = "SouthDakota",
  Tennessee = "Tennessee",
  Texas = "Texas",
  Utah = "Utah",
  Vermont = "Vermont",
  Virginia = "Virginia",
  Washington = "Washington",
  WestVirginia = "WestVirginia",
  Wisconsin = "Wisconsin",
  Wyoming = "Wyoming"
}

export enum CredentialType {
  CollegeDegree = "CollegeDegree",
  Other = "Other",
  TeachingCertificate = "TeachingCertificate"
}

export enum DegreeSubject {
  Accounting = "Accounting",
  ActuarialScience = "ActuarialScience",
  Advertising = "Advertising",
  AerospaceEngineering = "AerospaceEngineering",
  Agriculture = "Agriculture",
  AmericanStudies = "AmericanStudies",
  AnimalScience = "AnimalScience",
  Anthropology = "Anthropology",
  Architecture = "Architecture",
  ArtAndDesign = "ArtAndDesign",
  ArtHistoryCriticismAndConservation = "ArtHistoryCriticismAndConservation",
  AsianStudiesCivilization = "AsianStudiesCivilization",
  BehavioralScience = "BehavioralScience",
  Biochemistry = "Biochemistry",
  BiologyBiologicalSciences = "BiologyBiologicalSciences",
  BiomedicalSciences = "BiomedicalSciences",
  BotanyPlantBiology = "BotanyPlantBiology",
  BusinessAdministrationAndManagement = "BusinessAdministrationAndManagement",
  BusinessCommerce = "BusinessCommerce",
  ChemicalEngineering = "ChemicalEngineering",
  Chemistry = "Chemistry",
  CivilEngineering = "CivilEngineering",
  Communications = "Communications",
  ComputerEngineering = "ComputerEngineering",
  ComputerScience = "ComputerScience",
  ConstructionManagement = "ConstructionManagement",
  CriminalJustice = "CriminalJustice",
  Dance = "Dance",
  EarlyChildhoodEducation = "EarlyChildhoodEducation",
  Economics = "Economics",
  Education = "Education",
  ElectricalEngineering = "ElectricalEngineering",
  ElementaryEducation = "ElementaryEducation",
  EngineeringGeneral = "EngineeringGeneral",
  English = "English",
  EnglishLanguageAndLiterature = "EnglishLanguageAndLiterature",
  EnvironmentalScience = "EnvironmentalScience",
  EthnicStudies = "EthnicStudies",
  ExerciseScience = "ExerciseScience",
  FamilyAndConsumerSciencesHumanSciences = "FamilyAndConsumerSciencesHumanSciences",
  FashionMerchandising = "FashionMerchandising",
  FilmCinemaVideoStudies = "FilmCinemaVideoStudies",
  Finance = "Finance",
  FineStudioArts = "FineStudioArts",
  ForeignLanguage = "ForeignLanguage",
  ForensicScienceAndTechnology = "ForensicScienceAndTechnology",
  Forestry = "Forestry",
  Genetics = "Genetics",
  GeologyEarthScience = "GeologyEarthScience",
  GraphicDesign = "GraphicDesign",
  HealthAndPhysicalEducationFitness = "HealthAndPhysicalEducationFitness",
  HealthOrPe = "HealthOrPE",
  HealthProfessionsAndRelatedPrograms = "HealthProfessionsAndRelatedPrograms",
  HealthServicesAlliedHealthHealthSciences = "HealthServicesAlliedHealthHealthSciences",
  History = "History",
  Horticulture = "Horticulture",
  HospitalAndHealthCareFacilitiesAdministrationManagement = "HospitalAndHealthCareFacilitiesAdministrationManagement",
  HospitalityManagement = "HospitalityManagement",
  HumanDevelopmentFamilyStudies = "HumanDevelopmentFamilyStudies",
  HumanResourcesManagement = "HumanResourcesManagement",
  InformationTechnology = "InformationTechnology",
  InteriorDesign = "InteriorDesign",
  InternationalBusiness = "InternationalBusiness",
  InternationalRelations = "InternationalRelations",
  Journalism = "Journalism",
  Kinesiology = "Kinesiology",
  LiberalArtsAndSciences = "LiberalArtsAndSciences",
  Linguistics = "Linguistics",
  ManagementInformationSystems = "ManagementInformationSystems",
  MarineBiology = "MarineBiology",
  Marketing = "Marketing",
  MaterialsScience = "MaterialsScience",
  Math = "Math",
  MechanicalEngineering = "MechanicalEngineering",
  MolecularBiology = "MolecularBiology",
  Music = "Music",
  MusicOrTheaterOrArts = "MusicOrTheaterOrArts",
  Nursing = "Nursing",
  NutritionSciences = "NutritionSciences",
  OccupationalTherapy = "OccupationalTherapy",
  OrganizationalLeadership = "OrganizationalLeadership",
  Other = "Other",
  Philosophy = "Philosophy",
  Physics = "Physics",
  PoliticalScienceAndGovernment = "PoliticalScienceAndGovernment",
  PreMedicinePreMedicalStudies = "PreMedicinePreMedicalStudies",
  Psychology = "Psychology",
  PublicAdministration = "PublicAdministration",
  PublicHealth = "PublicHealth",
  PublicRelations = "PublicRelations",
  RealEstate = "RealEstate",
  ReligiousStudies = "ReligiousStudies",
  Science = "Science",
  SecondaryEducation = "SecondaryEducation",
  SecondarySchoolAdministrationPrincipalship = "SecondarySchoolAdministrationPrincipalship",
  SocialSciences = "SocialSciences",
  SocialWork = "SocialWork",
  Sociology = "Sociology",
  SpanishLanguageAndLiterature = "SpanishLanguageAndLiterature",
  SpecialEducation = "SpecialEducation",
  SpeechCommunicationAndRhetoric = "SpeechCommunicationAndRhetoric",
  SpeechLanguagePathologyPathologist = "SpeechLanguagePathologyPathologist",
  SportsManagement = "SportsManagement",
  SupplyChainManagementLogistics = "SupplyChainManagementLogistics",
  TheatreArts = "TheatreArts",
  UrbanAndRegionalPlanning = "UrbanAndRegionalPlanning",
  UrbanStudies = "UrbanStudies",
  VeterinaryTechnology = "VeterinaryTechnology",
  ZoologyAnimalBiology = "ZoologyAnimalBiology"
}

export enum DegreeType {
  AssociatesDegree = "AssociatesDegree",
  BachelorsDegree = "BachelorsDegree",
  DoctoralDegree = "DoctoralDegree",
  DoctorOfMedicine = "DoctorOfMedicine",
  JurisDoctor = "JurisDoctor",
  MastersDegree = "MastersDegree"
}

export enum EducatorTier {
  NewOnOutschool = "new_on_outschool",
  Popular = "popular",
  RisingStar = "rising_star",
  Star = "star"
}

export enum MainToolbarPinnedTabs {
  Availability = "availability",
  Classes = "classes",
  Conversations = "conversations",
  Coupons = "coupons",
  Credentials = "credentials",
  Groups = "groups",
  Insights = "insights",
  Marketing = "marketing",
  Profile = "profile",
  Schedule = "schedule",
  Sections = "sections"
}

export enum ClassFormat {
  Club = "CLUB",
  Flex = "FLEX",
  MultiDay = "MULTI_DAY",
  OneOnOne = "ONE_ON_ONE",
  OneTime = "ONE_TIME",
  Ongoing = "ONGOING",
  SelfPaced = "SELF_PACED"
}

export enum SavedClassListStatus {
  LearnerProfile = "learnerProfile",
  Private = "private",
  Unlisted = "unlisted"
}

export type TopicsConnectionCursorInput = {
  created_at: Scalars["DateTime"];
  popularity: Scalars["Int"];
  uid: Scalars["ID"];
};

export enum RaceEthnicityType {
  African = "AFRICAN",
  AfricanAmericanOrCaribbean = "AFRICAN_AMERICAN_OR_CARIBBEAN",
  ApacHawaiian = "APAC_HAWAIIAN",
  EastAsian = "EAST_ASIAN",
  European = "EUROPEAN",
  HispanicLatin = "HISPANIC_LATIN",
  IndigenousAmerican = "INDIGENOUS_AMERICAN",
  MiddleEastern = "MIDDLE_EASTERN",
  Other = "OTHER",
  PreferNotToAnswer = "PREFER_NOT_TO_ANSWER",
  SouthAsian = "SOUTH_ASIAN",
  SoutheastAsian = "SOUTHEAST_ASIAN",
  White = "WHITE"
}

/** Type of enrollment/order payment. Keep this in sync with knex migration constraint. */
export enum TenderType {
  AppleIap = "apple_iap",
  ClassWallet = "class_wallet",
  Coupon = "coupon",
  PricingOffer = "pricing_offer",
  PurchaseOrder = "purchase_order",
  StoredCredit = "stored_credit",
  Stripe = "stripe"
}

export enum RecordingAvailability {
  Hidden = "hidden",
  Processing = "processing",
  Requestable = "requestable",
  Requested = "requested",
  Shown = "shown",
  Unavailable = "unavailable"
}

export enum MeetingRequestStatus {
  /** The request has been approved by the parent */
  Confirmed = "Confirmed",
  /** The request has been declined by the parent */
  Declined = "Declined",
  /** The request expired before parent approved or declined */
  Expired = "Expired",
  /** The request must be approved by the parent */
  Pending = "Pending"
}

export enum MeetingStartStatus {
  LateNoShow = "LateNoShow",
  NotStarted = "NotStarted",
  StartedLate = "StartedLate",
  StartedOnTime = "StartedOnTime",
  Unknown = "Unknown"
}

export enum SectionLessonType {
  Lesson = "Lesson",
  Meeting = "Meeting",
  MeetingWithLesson = "MeetingWithLesson"
}

export enum CurrencyCode {
  Aud = "AUD",
  Cad = "CAD",
  Eur = "EUR",
  Gbp = "GBP",
  Hkd = "HKD",
  Inr = "INR",
  Jpy = "JPY",
  Krw = "KRW",
  Mxn = "MXN",
  Nzd = "NZD",
  Thb = "THB",
  Twd = "TWD",
  Usd = "USD"
}

export enum CouponClassFormats {
  Course = "Course",
  EnrichmentOrClub = "EnrichmentOrClub",
  Group = "Group",
  MultiDay = "MultiDay",
  OneOnOne = "OneOnOne",
  OneTime = "OneTime",
  Ongoing = "Ongoing",
  SelfPaced = "SelfPaced",
  Semester = "Semester"
}

export enum CouponType {
  Outschool = "outschool",
  Teacher = "teacher"
}

export enum PricingOfferAdminFilter {
  Active = "Active",
  All = "All",
  Deleted = "Deleted"
}

export enum OrganizationType {
  CharterSchool = "charter_school",
  CommunityPartner = "community_partner",
  Company = "company",
  EsaOrMicrogrant = "esa_or_microgrant",
  OrgCommunityPartner = "org_community_partner",
  School = "school"
}

export enum GiftCardStatus {
  Canceled = "Canceled",
  Incomplete = "Incomplete",
  Purchased = "Purchased",
  Redeemed = "Redeemed",
  Sent = "Sent"
}

export enum CreditReferralTransactionType {
  UserWasReferred = "UserWasReferred",
  UserWasReferrer = "UserWasReferrer"
}

export enum CreditTransactionType {
  Adjustment = "adjustment",
  Admin = "admin",
  AllowanceWithdrawal = "allowance_withdrawal",
  Purchase = "purchase",
  Redemption = "redemption",
  Referral = "referral",
  Refund = "refund",
  Urbansitter = "urbansitter",
  Voucher = "voucher"
}

export enum PaymentScheduleTypes {
  MonthlyFirst = "MonthlyFirst",
  WeeklySunday = "WeeklySunday"
}

export enum PaymentScheduleStatus {
  AlreadyPaid = "AlreadyPaid",
  AwaitingPayment = "AwaitingPayment",
  Ended = "Ended",
  NotYetStarted = "NotYetStarted"
}

export enum RefundPolicyType {
  Flexible = "flexible",
  Limited = "limited",
  Moderate = "moderate",
  Variable = "variable"
}

export enum EnrollmentFilter {
  OneOnOne = "OneOnOne"
}

export enum EnrollmentStatus {
  Absent = "Absent",
  Attended = "Attended",
  Confirmed = "Confirmed",
  Ended = "Ended",
  Enrolled = "Enrolled",
  Paused = "Paused",
  Waitlisted = "Waitlisted",
  Withdrawn = "Withdrawn"
}

export type MeetingRequestFilters = {
  /**
   * Only return meeting requests whose start_time is after Date.
   * Defaults to "now()"
   */
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  /**
   * Only return meeting requests whose start_time before Date
   * Defaults to no filter
   */
  startBefore?: InputMaybe<Scalars["DateTime"]>;
  /**
   * Only return meetings whose status matches this.
   * N.B., Meetings are always "confirmed" so other statuses will exclude all Meetings
   */
  status?: InputMaybe<MeetingRequestStatus>;
};

export enum MeetingFilter {
  All = "All",
  Enrolled = "Enrolled"
}

export enum ParticipantRole {
  /** The learner */
  Learner = "Learner",
  /** None of the above; possibly an org Group moderator */
  Other = "Other",
  /** The parent */
  Parent = "Parent",
  /** The owner of the seller org this message's section belongs to, if any */
  SellerOrgOwner = "SellerOrgOwner",
  /** The teacher of this message's section */
  Teacher = "Teacher"
}

export enum WaitlistSource {
  UnpauseFailure = "unpause_failure",
  UserInitiated = "user_initiated"
}

export enum ApprovalCriterionValue {
  Negative = "negative",
  Neutral = "neutral",
  Positive = "positive"
}

export enum Audience {
  Learners = "learners",
  Parents = "parents"
}

export type ConversationFiltersInput = {
  activityUid?: InputMaybe<Scalars["ID"]>;
  participantUids?: InputMaybe<Array<Scalars["ID"]>>;
  searchTerms?: InputMaybe<Array<Scalars["String"]>>;
};

export enum MessageSelection {
  All = "all",
  Spam = "spam",
  Unread = "unread"
}

export enum CreditAccountStatus {
  Available = "Available",
  RequiresConfirmedPrimaryEmail = "RequiresConfirmedPrimaryEmail"
}

export enum UserEnrollmentsFilters {
  All = "All",
  Confirmed = "Confirmed"
}

export enum IdvStatus {
  Accept = "ACCEPT",
  NotStarted = "NOT_STARTED",
  Reject = "REJECT",
  RejectMany = "REJECT_MANY",
  Review = "REVIEW",
  Started = "STARTED"
}

export enum SellerOrgApplicationStatus {
  Applied = "applied",
  Approved = "approved",
  Interested = "interested",
  Rejected = "rejected"
}

export enum TeacherApplicationStatus {
  Approved = "approved",
  Created = "created",
  Declined = "declined",
  Submitted = "submitted"
}

export enum CreateActivityType {
  Class = "Class",
  Club = "Club"
}

export enum EntityAvailabilityEntityType {
  Learner = "learner"
}

export enum UserLocale {
  En = "en",
  Es = "es",
  /** @deprecated Use es instead */
  Es_419 = "es_419",
  Ja = "ja",
  Ko = "ko",
  ZhTw = "zh_TW"
}

export enum PaymentMethodType {
  Card = "Card"
}

export enum ParentReferralStatus {
  CreditClaimed = "credit_claimed",
  CreditEarned = "credit_earned",
  SignedUp = "signed_up"
}

export enum TeacherReferralStatus {
  ApplicationDeclined = "application_declined",
  EligibleForPayOut = "eligible_for_pay_out",
  PaidOut = "paid_out",
  TeacherApplied = "teacher_applied",
  TeacherApproved = "teacher_approved"
}

export enum FfapApplicationStatus {
  Approved = "approved",
  Declined = "declined",
  Pending = "pending"
}

export enum SubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  Paused = "paused",
  Trialing = "trialing",
  Unpaid = "unpaid"
}

export enum EducatorSelectedFormat {
  Course = "Course",
  EnrichmentOrClub = "EnrichmentOrClub",
  OneTime = "OneTime",
  Tutoring = "Tutoring"
}

/** Defines how the classroom appears */
export enum ClassroomType {
  Group = "Group",
  OneOnOne = "OneOnOne",
  SelfPaced = "SelfPaced"
}

export enum DeliveryType {
  Camp = "CAMP",
  Club = "CLUB",
  OneOnOneClass = "ONE_ON_ONE_CLASS",
  OneTimeClass = "ONE_TIME_CLASS",
  OngoingClass = "ONGOING_CLASS",
  SemesterCourse = "SEMESTER_COURSE",
  ShortCourse = "SHORT_COURSE"
}

export type EnrollmentParamsInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  orderByNextMeeting?: InputMaybe<Scalars["Boolean"]>;
};

export enum EsaStatus {
  Approved = "Approved",
  NotApproved = "NotApproved"
}

export enum ExternalResourcePolicy {
  /** Allowed for all ages without parental consent */
  Allowed = "ALLOWED",
  /** Allowed for all ages, parental consent required under age 13 */
  AllowedWithConsent = "ALLOWED_WITH_CONSENT",
  /** Allowed for all ages, parental consent required under age 16 */
  AllowedWithConsentUnder_16 = "ALLOWED_WITH_CONSENT_UNDER_16",
  /** Disallowed for all ages */
  Disallowed = "DISALLOWED",
  /** Allowed for ages 13+, disallowed under age 13 */
  DisallowedUnder_13 = "DISALLOWED_UNDER_13",
  /** Allowed for ages 16+, disallowed under age 16 */
  DisallowedUnder_16 = "DISALLOWED_UNDER_16",
  /** This resource hasn't been reviewed */
  Unvetted = "UNVETTED"
}

export enum SectionFilter {
  Bookable = "Bookable",
  BookableOrSoldOut = "BookableOrSoldOut",
  Cancelled = "Cancelled",
  Past = "Past",
  Promotable = "Promotable",
  Upcoming = "Upcoming"
}

export type SectionSearchInput = {
  daysOfWeek?: InputMaybe<Array<DaysOfWeek>>;
  endBy?: InputMaybe<Scalars["DateTime"]>;
  endByTime?: InputMaybe<Scalars["Float"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startAfterTime?: InputMaybe<Scalars["Float"]>;
  startBefore?: InputMaybe<Scalars["DateTime"]>;
  userTimeZone?: InputMaybe<Scalars["String"]>;
};

export enum DaysOfWeek {
  Fri = "Fri",
  Mon = "Mon",
  Sat = "Sat",
  Sun = "Sun",
  Thu = "Thu",
  Tue = "Tue",
  Wed = "Wed"
}

export type PricingBreakdownInput = {
  activityUid?: InputMaybe<Scalars["String"]>;
  buyingLearnerUids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buyingUserUid?: InputMaybe<Scalars["String"]>;
  charterAdminEnrollmentInput?: InputMaybe<CharterAdminEnrollmentInput>;
  couponCode?: InputMaybe<Scalars["String"]>;
  donationCents?: InputMaybe<Scalars["Int"]>;
  enrollmentCount?: InputMaybe<Scalars["Int"]>;
  isAcceptingGiftEnrollment?: InputMaybe<Scalars["Boolean"]>;
  isUsingPurchaseOrder?: InputMaybe<Scalars["Boolean"]>;
  isWeeklyPayment?: InputMaybe<Scalars["Boolean"]>;
  ongoingEndTime?: InputMaybe<Scalars["DateTime"]>;
  ongoingStartTime?: InputMaybe<Scalars["DateTime"]>;
  paymentOption?: InputMaybe<PaymentOption>;
  prepayDurationWeeks?: InputMaybe<Scalars["Int"]>;
  purchaseOrderCoverage?: InputMaybe<Scalars["DateTime"]>;
  sectionUid?: InputMaybe<Scalars["String"]>;
  teacherUid?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
};

export type CharterAdminEnrollmentInput = {
  email: Scalars["String"];
  learnerName: Scalars["String"];
  name: Scalars["String"];
};

export type PaymentOption = {
  pricingOfferName?: InputMaybe<Scalars["String"]>;
  type: TenderType;
};

export enum CouponState {
  InvalidActivityFormatNotAllowed = "InvalidActivityFormatNotAllowed",
  InvalidActivityNotEligible = "InvalidActivityNotEligible",
  InvalidCode = "InvalidCode",
  InvalidExpired = "InvalidExpired",
  InvalidNoDiscount = "InvalidNoDiscount",
  InvalidNotStarted = "InvalidNotStarted",
  InvalidTooManyCouponCodeUses = "InvalidTooManyCouponCodeUses",
  InvalidTooManyUsesByUser = "InvalidTooManyUsesByUser",
  InvalidTooManyUsesTotal = "InvalidTooManyUsesTotal",
  InvalidUserNotConfirmed = "InvalidUserNotConfirmed",
  InvalidUserNotEligible = "InvalidUserNotEligible",
  InvalidUserNotEligibleClassFormat = "InvalidUserNotEligibleClassFormat",
  NoCoupon = "NoCoupon",
  Valid = "Valid"
}

export enum CreditAccountState {
  InvalidNoCreditAccount = "InvalidNoCreditAccount",
  InvalidNoDiscount = "InvalidNoDiscount",
  InvalidNoOpenBalance = "InvalidNoOpenBalance",
  InvalidUnconfirmedEmail = "InvalidUnconfirmedEmail",
  InvalidWhenAcceptingGiftEnrollment = "InvalidWhenAcceptingGiftEnrollment",
  Valid = "Valid"
}

export enum PricingOfferState {
  InvalidBuyoutSection = "InvalidBuyoutSection",
  InvalidDate = "InvalidDate",
  InvalidNoDiscount = "InvalidNoDiscount",
  InvalidOverTotalCap = "InvalidOverTotalCap",
  InvalidOverUserCap = "InvalidOverUserCap",
  InvalidUnconfirmedEmail = "InvalidUnconfirmedEmail",
  InvalidWhenAcceptingGiftEnrollment = "InvalidWhenAcceptingGiftEnrollment",
  InvalidWithDonationPurchase = "InvalidWithDonationPurchase",
  NoPricingOffer = "NoPricingOffer",
  ValidBuyerOrg = "ValidBuyerOrg",
  ValidFinancialAid = "ValidFinancialAid"
}

export enum BulkPricingBreakdownItemStatus {
  ErrorInvalidActivityType = "ERROR_INVALID_ACTIVITY_TYPE",
  ErrorNotAvailable = "ERROR_NOT_AVAILABLE",
  ErrorUnknown = "ERROR_UNKNOWN",
  Success = "SUCCESS"
}

export type MeetingConflictsQueryInput = {
  ignoreMeetingUids?: InputMaybe<Array<Scalars["ID"]>>;
  range: StartEndTime;
  /** A unique ID that the client can use to identify which range matched this */
  uid: Scalars["ID"];
};

export type StartEndTime = {
  end_time: Scalars["DateTime"];
  start_time: Scalars["DateTime"];
};

export enum ClassroomPostFilter {
  /** Both posts associated with lessons and not */
  All = "All",
  /** Only posts not associated with a lesson */
  Announcements = "Announcements",
  /** Only posts associated with a lesson */
  OnlyForLessons = "OnlyForLessons"
}

export enum ClassroomPostOrder {
  Chronological = "Chronological",
  ReverseChronological = "ReverseChronological"
}

export enum ContentfulAuthorLinkingCollectionsMagnetPageCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulAuthorFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAuthorFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  description?: InputMaybe<Scalars["String"]>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_not?: InputMaybe<Scalars["String"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isTeacher?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_exists?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_not?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAuthorFilter>>>;
  profileImage_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  uid?: InputMaybe<Scalars["String"]>;
  uid_contains?: InputMaybe<Scalars["String"]>;
  uid_exists?: InputMaybe<Scalars["Boolean"]>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uid_not?: InputMaybe<Scalars["String"]>;
  uid_not_contains?: InputMaybe<Scalars["String"]>;
  uid_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulSysFilter = {
  firstPublishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  firstPublishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  firstPublishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  publishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  publishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  publishedVersion?: InputMaybe<Scalars["Float"]>;
  publishedVersion_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedVersion_gt?: InputMaybe<Scalars["Float"]>;
  publishedVersion_gte?: InputMaybe<Scalars["Float"]>;
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  publishedVersion_lt?: InputMaybe<Scalars["Float"]>;
  publishedVersion_lte?: InputMaybe<Scalars["Float"]>;
  publishedVersion_not?: InputMaybe<Scalars["Float"]>;
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
};

export type ContentfulCategoryLabelFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCategoryLabelFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCategoryLabelFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  value?: InputMaybe<Scalars["String"]>;
  value_contains?: InputMaybe<Scalars["String"]>;
  value_exists?: InputMaybe<Scalars["Boolean"]>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  value_not?: InputMaybe<Scalars["String"]>;
  value_not_contains?: InputMaybe<Scalars["String"]>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulCategoryLabelLinkingCollectionsClpEmailCaptureCollectionOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  ErrorMessageAsc = "errorMessage_ASC",
  ErrorMessageDesc = "errorMessage_DESC",
  IterableLinkAsc = "iterableLink_ASC",
  IterableLinkDesc = "iterableLink_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  ProdIterableAudienceIdAsc = "prodIterableAudienceId_ASC",
  ProdIterableAudienceIdDesc = "prodIterableAudienceId_DESC",
  StagingIterableAudienceIdAsc = "stagingIterableAudienceId_ASC",
  StagingIterableAudienceIdDesc = "stagingIterableAudienceId_DESC",
  SuccessMessageAsc = "successMessage_ASC",
  SuccessMessageDesc = "successMessage_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulClpEmailCaptureClpPagesCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  ValueAsc = "value_ASC",
  ValueDesc = "value_DESC"
}

export enum ContentfulCategoryLabelLinkingCollectionsMagnetPageCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulMagnetPageLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulCategoryPageJumpPillLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC"
}

export type ContentfulVisualLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText?: InputMaybe<Scalars["String"]>;
  imageAltText_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAltText_not?: InputMaybe<Scalars["String"]>;
  imageAltText_not_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAspectRatio_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio_not?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB?: InputMaybe<Scalars["String"]>;
  pathB_contains?: InputMaybe<Scalars["String"]>;
  pathB_exists?: InputMaybe<Scalars["Boolean"]>;
  pathB_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB_not?: InputMaybe<Scalars["String"]>;
  pathB_not_contains?: InputMaybe<Scalars["String"]>;
  pathB_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  text?: InputMaybe<Scalars["String"]>;
  text_contains?: InputMaybe<Scalars["String"]>;
  text_exists?: InputMaybe<Scalars["Boolean"]>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  text_not?: InputMaybe<Scalars["String"]>;
  text_not_contains?: InputMaybe<Scalars["String"]>;
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulVisualLinkLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulVisualLinkLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulHomePage2HeroSlidesCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulHeroSlideFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulHeroSlideFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulHeroSlideFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulHeroSlideLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulHomePage2PopularSearchLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC"
}

export enum ContentfulHomePage2ValuePropsCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulValuePropFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulValuePropFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulValuePropFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulValuePropLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulHomePage2VisualLinkGridsCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulVisualLinkGridFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkGridFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkGridFilter>>>;
  style?: InputMaybe<Scalars["String"]>;
  style_contains?: InputMaybe<Scalars["String"]>;
  style_exists?: InputMaybe<Scalars["Boolean"]>;
  style_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  style_not?: InputMaybe<Scalars["String"]>;
  style_not_contains?: InputMaybe<Scalars["String"]>;
  style_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  visualLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  visualLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfVisualLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText?: InputMaybe<Scalars["String"]>;
  imageAltText_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAltText_not?: InputMaybe<Scalars["String"]>;
  imageAltText_not_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAspectRatio_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio_not?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkNestedFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB?: InputMaybe<Scalars["String"]>;
  pathB_contains?: InputMaybe<Scalars["String"]>;
  pathB_exists?: InputMaybe<Scalars["Boolean"]>;
  pathB_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB_not?: InputMaybe<Scalars["String"]>;
  pathB_not_contains?: InputMaybe<Scalars["String"]>;
  pathB_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  text?: InputMaybe<Scalars["String"]>;
  text_contains?: InputMaybe<Scalars["String"]>;
  text_exists?: InputMaybe<Scalars["Boolean"]>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  text_not?: InputMaybe<Scalars["String"]>;
  text_not_contains?: InputMaybe<Scalars["String"]>;
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulVisualLinkGridLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulVisualLinkGridLinkingCollectionsHomePageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulHomePageVisualLinkGridsCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulVisualLinkGridLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulTutoringPageArticlesCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulMagnetPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulMagnetPageFilter>>>;
  author?: InputMaybe<ContentfulcfAuthorNestedFilter>;
  author_exists?: InputMaybe<Scalars["Boolean"]>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  categoryLabel?: InputMaybe<ContentfulcfCategoryLabelNestedFilter>;
  categoryLabel_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  magnetPageSlug_not?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulMagnetPageFilter>>>;
  summary_contains?: InputMaybe<Scalars["String"]>;
  summary_exists?: InputMaybe<Scalars["Boolean"]>;
  summary_not_contains?: InputMaybe<Scalars["String"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellClassCarousel?: InputMaybe<ContentfulcfUpsellClassCardNestedFilter>;
  upsellClassCarousel_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfAuthorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfAuthorNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  description?: InputMaybe<Scalars["String"]>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_not?: InputMaybe<Scalars["String"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isTeacher?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_exists?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_not?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfAuthorNestedFilter>>>;
  profileImage_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  uid?: InputMaybe<Scalars["String"]>;
  uid_contains?: InputMaybe<Scalars["String"]>;
  uid_exists?: InputMaybe<Scalars["Boolean"]>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uid_not?: InputMaybe<Scalars["String"]>;
  uid_not_contains?: InputMaybe<Scalars["String"]>;
  uid_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfCategoryLabelNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfCategoryLabelNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfCategoryLabelNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  value?: InputMaybe<Scalars["String"]>;
  value_contains?: InputMaybe<Scalars["String"]>;
  value_exists?: InputMaybe<Scalars["Boolean"]>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  value_not?: InputMaybe<Scalars["String"]>;
  value_not_contains?: InputMaybe<Scalars["String"]>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfUpsellClassCardNestedFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfUpsellClassCardNestedFilter>>>;
  callToActionText?: InputMaybe<Scalars["String"]>;
  callToActionText_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionText_not?: InputMaybe<Scalars["String"]>;
  callToActionText_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl?: InputMaybe<Scalars["String"]>;
  callToActionUrl_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl_not?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  category_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents?: InputMaybe<Scalars["Int"]>;
  minPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  minPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfUpsellClassCardNestedFilter>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName?: InputMaybe<Scalars["String"]>;
  upsellName_contains?: InputMaybe<Scalars["String"]>;
  upsellName_exists?: InputMaybe<Scalars["Boolean"]>;
  upsellName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName_not?: InputMaybe<Scalars["String"]>;
  upsellName_not_contains?: InputMaybe<Scalars["String"]>;
  upsellName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulTutoringPageCarouselSubcategoriesCollectionOrder {
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  NameTitleCasedAsc = "nameTitleCased_ASC",
  NameTitleCasedDesc = "nameTitleCased_DESC",
  SearchPathAsc = "searchPath_ASC",
  SearchPathDesc = "searchPath_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SubjectAsc = "subject_ASC",
  SubjectDesc = "subject_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulTopicFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulTopicFilter>>>;
  categorySlug?: InputMaybe<Scalars["String"]>;
  categorySlug_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_exists?: InputMaybe<Scalars["Boolean"]>;
  categorySlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug_not?: InputMaybe<Scalars["String"]>;
  categorySlug_not_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  nameTitleCased?: InputMaybe<Scalars["String"]>;
  nameTitleCased_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_exists?: InputMaybe<Scalars["Boolean"]>;
  nameTitleCased_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  nameTitleCased_not?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulTopicFilter>>>;
  searchPath?: InputMaybe<Scalars["String"]>;
  searchPath_contains?: InputMaybe<Scalars["String"]>;
  searchPath_exists?: InputMaybe<Scalars["Boolean"]>;
  searchPath_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  searchPath_not?: InputMaybe<Scalars["String"]>;
  searchPath_not_contains?: InputMaybe<Scalars["String"]>;
  searchPath_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulTopicLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulTutoringPagePopularSearchLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC"
}

export enum ContentfulTutoringPagePopularTutoringSubjectsCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC"
}

export enum ContentfulTutoringPageTutoringCarouselCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulTutoringPageVisualLinkGridsCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulVisualLinkGridVisualLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC"
}

export enum ContentfulVisualLinkLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulVisualLinkLinkingCollectionsVisualLinkGridCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulCategoryPageJumpPillsCollectionOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulJumpPillsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulJumpPillsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulJumpPillsFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulJumpPillsLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulCategoryPageMagnetPagesCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulCategoryPageRelatedCategoriesCollectionOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  CallToActionUrlAsc = "callToActionUrl_ASC",
  CallToActionUrlDesc = "callToActionUrl_DESC",
  CapacityMaxAsc = "capacityMax_ASC",
  CapacityMaxDesc = "capacityMax_DESC",
  CapacityMinAsc = "capacityMin_ASC",
  CapacityMinDesc = "capacityMin_DESC",
  MaxPriceCentsAsc = "maxPriceCents_ASC",
  MaxPriceCentsDesc = "maxPriceCents_DESC",
  MinPriceCentsAsc = "minPriceCents_ASC",
  MinPriceCentsDesc = "minPriceCents_DESC",
  MultiTermQueryAsc = "multiTermQuery_ASC",
  MultiTermQueryDesc = "multiTermQuery_DESC",
  QueryAsc = "query_ASC",
  QueryDesc = "query_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpsellNameAsc = "upsellName_ASC",
  UpsellNameDesc = "upsellName_DESC"
}

export type ContentfulUpsellClassCardFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulUpsellClassCardFilter>>>;
  callToActionText?: InputMaybe<Scalars["String"]>;
  callToActionText_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionText_not?: InputMaybe<Scalars["String"]>;
  callToActionText_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl?: InputMaybe<Scalars["String"]>;
  callToActionUrl_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl_not?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  category_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents?: InputMaybe<Scalars["Int"]>;
  minPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  minPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulUpsellClassCardFilter>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName?: InputMaybe<Scalars["String"]>;
  upsellName_contains?: InputMaybe<Scalars["String"]>;
  upsellName_exists?: InputMaybe<Scalars["Boolean"]>;
  upsellName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName_not?: InputMaybe<Scalars["String"]>;
  upsellName_not_contains?: InputMaybe<Scalars["String"]>;
  upsellName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulUpsellClassCardLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulUpsellClassCardLinkingCollectionsMagnetPageCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulMagnetPageLinkingCollectionsFeaturedPagesCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulFeaturedPagesPagesCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulMagnetPageLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulMagnetPageOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulCampaignFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCampaignFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  country?: InputMaybe<ContentfulcfCampaignCountryNestedFilter>;
  countryCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  maxAge?: InputMaybe<Scalars["Int"]>;
  maxAge_exists?: InputMaybe<Scalars["Boolean"]>;
  maxAge_gt?: InputMaybe<Scalars["Int"]>;
  maxAge_gte?: InputMaybe<Scalars["Int"]>;
  maxAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxAge_lt?: InputMaybe<Scalars["Int"]>;
  maxAge_lte?: InputMaybe<Scalars["Int"]>;
  maxAge_not?: InputMaybe<Scalars["Int"]>;
  maxAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge?: InputMaybe<Scalars["Int"]>;
  minAge_exists?: InputMaybe<Scalars["Boolean"]>;
  minAge_gt?: InputMaybe<Scalars["Int"]>;
  minAge_gte?: InputMaybe<Scalars["Int"]>;
  minAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge_lt?: InputMaybe<Scalars["Int"]>;
  minAge_lte?: InputMaybe<Scalars["Int"]>;
  minAge_not?: InputMaybe<Scalars["Int"]>;
  minAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  onlyForTreatmentInExperiment?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_exists?: InputMaybe<Scalars["Boolean"]>;
  onlyForTreatmentInExperiment_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  onlyForTreatmentInExperiment_not?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCampaignFilter>>>;
  schoolingApproaches_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  stateOrProvince?: InputMaybe<ContentfulcfCampaignStateOrProvinceNestedFilter>;
  stateOrProvinceCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfCampaignCountryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignCountryNestedFilter>>>;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  matchingText_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignCountryNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfCampaignStateOrProvinceNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfCampaignStateOrProvinceNestedFilter>>
  >;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfCampaignStateOrProvinceNestedFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulCampaignCountryCollectionOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulCampaignCountryFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCampaignCountryFilter>>>;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  matchingText_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCampaignCountryFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulCampaignCountryLinkingCollectionsCampaignCollectionOrder {
  EndAtAsc = "endAt_ASC",
  EndAtDesc = "endAt_DESC",
  MaxAgeAsc = "maxAge_ASC",
  MaxAgeDesc = "maxAge_DESC",
  MinAgeAsc = "minAge_ASC",
  MinAgeDesc = "minAge_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  OnlyForTreatmentInExperimentAsc = "onlyForTreatmentInExperiment_ASC",
  OnlyForTreatmentInExperimentDesc = "onlyForTreatmentInExperiment_DESC",
  StartAtAsc = "startAt_ASC",
  StartAtDesc = "startAt_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulCampaignLinkingCollectionsBannerCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC"
}

export enum ContentfulCampaignLinkingCollectionsLearnerBannerCollectionOrder {
  CtaTextAsc = "ctaText_ASC",
  CtaTextDesc = "ctaText_DESC",
  CtaUrlAsc = "ctaUrl_ASC",
  CtaUrlDesc = "ctaUrl_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulCampaignLinkingCollectionsTopicTickerCollectionOrder {
  QueryAsc = "query_ASC",
  QueryDesc = "query_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TopicAsc = "topic_ASC",
  TopicDesc = "topic_DESC"
}

export enum ContentfulCampaignStateOrProvinceCollectionOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulCampaignStateOrProvinceFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCampaignStateOrProvinceFilter>>>;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCampaignStateOrProvinceFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulCampaignStateOrProvinceLinkingCollectionsCampaignCollectionOrder {
  EndAtAsc = "endAt_ASC",
  EndAtDesc = "endAt_DESC",
  MaxAgeAsc = "maxAge_ASC",
  MaxAgeDesc = "maxAge_DESC",
  MinAgeAsc = "minAge_ASC",
  MinAgeDesc = "minAge_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  OnlyForTreatmentInExperimentAsc = "onlyForTreatmentInExperiment_ASC",
  OnlyForTreatmentInExperimentDesc = "onlyForTreatmentInExperiment_DESC",
  StartAtAsc = "startAt_ASC",
  StartAtDesc = "startAt_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulBannerLinkingCollectionsBannerPlacementCollectionOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulBannerPlacementBannersCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC"
}

export type ContentfulBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulBannerFilter>>>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  campaign?: InputMaybe<ContentfulcfCampaignNestedFilter>;
  campaign_exists?: InputMaybe<Scalars["Boolean"]>;
  content_contains?: InputMaybe<Scalars["String"]>;
  content_exists?: InputMaybe<Scalars["Boolean"]>;
  content_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulBannerFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url?: InputMaybe<Scalars["String"]>;
  url_contains?: InputMaybe<Scalars["String"]>;
  url_exists?: InputMaybe<Scalars["Boolean"]>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url_not?: InputMaybe<Scalars["String"]>;
  url_not_contains?: InputMaybe<Scalars["String"]>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfCampaignNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  countryCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  maxAge?: InputMaybe<Scalars["Int"]>;
  maxAge_exists?: InputMaybe<Scalars["Boolean"]>;
  maxAge_gt?: InputMaybe<Scalars["Int"]>;
  maxAge_gte?: InputMaybe<Scalars["Int"]>;
  maxAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxAge_lt?: InputMaybe<Scalars["Int"]>;
  maxAge_lte?: InputMaybe<Scalars["Int"]>;
  maxAge_not?: InputMaybe<Scalars["Int"]>;
  maxAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge?: InputMaybe<Scalars["Int"]>;
  minAge_exists?: InputMaybe<Scalars["Boolean"]>;
  minAge_gt?: InputMaybe<Scalars["Int"]>;
  minAge_gte?: InputMaybe<Scalars["Int"]>;
  minAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge_lt?: InputMaybe<Scalars["Int"]>;
  minAge_lte?: InputMaybe<Scalars["Int"]>;
  minAge_not?: InputMaybe<Scalars["Int"]>;
  minAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  onlyForTreatmentInExperiment?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_exists?: InputMaybe<Scalars["Boolean"]>;
  onlyForTreatmentInExperiment_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  onlyForTreatmentInExperiment_not?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignNestedFilter>>>;
  schoolingApproaches_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  stateOrProvinceCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulLandingPageSectionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageSectionsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageSectionsFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageClassCarouselLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulSearchFiltersFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulSearchFiltersFilter>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMax_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMin_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  endBy?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_exists?: InputMaybe<Scalars["Boolean"]>;
  endBy_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endBy_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  filtersName?: InputMaybe<Scalars["String"]>;
  filtersName_contains?: InputMaybe<Scalars["String"]>;
  filtersName_exists?: InputMaybe<Scalars["Boolean"]>;
  filtersName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  filtersName_not?: InputMaybe<Scalars["String"]>;
  filtersName_not_contains?: InputMaybe<Scalars["String"]>;
  filtersName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulSearchFiltersFilter>>>;
  pricePerCourseMax?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startAfter?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_exists?: InputMaybe<Scalars["Boolean"]>;
  startAfter_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAfter_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  startBefore?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_exists?: InputMaybe<Scalars["Boolean"]>;
  startBefore_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startBefore_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  weeklyMeetingsMax?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMax_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMax_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMin_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export enum ContentfulSearchFiltersLinkingCollectionsLandingPageClassCarouselCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulSearchFiltersLinkingCollectionsListingsPromoBannerButtonCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  ExploreMoreButtonTextAsc = "exploreMoreButtonText_ASC",
  ExploreMoreButtonTextDesc = "exploreMoreButtonText_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulListingsPromoBannerButtonLinkingCollectionsListingsPromoBannerCollectionOrder {
  ExperimentVersionAsc = "experimentVersion_ASC",
  ExperimentVersionDesc = "experimentVersion_DESC",
  HeaderSubtitleAsc = "headerSubtitle_ASC",
  HeaderSubtitleDesc = "headerSubtitle_DESC",
  HeaderTitleAsc = "headerTitle_ASC",
  HeaderTitleDesc = "headerTitle_DESC",
  IsLiveOnSearchAsc = "isLiveOnSearch_ASC",
  IsLiveOnSearchDesc = "isLiveOnSearch_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SidebarTitleAsc = "sidebarTitle_ASC",
  SidebarTitleDesc = "sidebarTitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulListingsPromoBannerButtonsCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  ExploreMoreButtonTextAsc = "exploreMoreButtonText_ASC",
  ExploreMoreButtonTextDesc = "exploreMoreButtonText_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulListingsPromoBannerButtonFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulListingsPromoBannerButtonFilter>>
  >;
  buttonSearchFilters?: InputMaybe<ContentfulcfSearchFiltersNestedFilter>;
  buttonSearchFilters_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  exploreMoreButtonText?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_exists?: InputMaybe<Scalars["Boolean"]>;
  exploreMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  exploreMoreButtonText_not?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulListingsPromoBannerButtonFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfSearchFiltersNestedFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfSearchFiltersNestedFilter>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMax_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMin_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  endBy?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_exists?: InputMaybe<Scalars["Boolean"]>;
  endBy_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endBy_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  filtersName?: InputMaybe<Scalars["String"]>;
  filtersName_contains?: InputMaybe<Scalars["String"]>;
  filtersName_exists?: InputMaybe<Scalars["Boolean"]>;
  filtersName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  filtersName_not?: InputMaybe<Scalars["String"]>;
  filtersName_not_contains?: InputMaybe<Scalars["String"]>;
  filtersName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfSearchFiltersNestedFilter>>>;
  pricePerCourseMax?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startAfter?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_exists?: InputMaybe<Scalars["Boolean"]>;
  startAfter_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAfter_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  startBefore?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_exists?: InputMaybe<Scalars["Boolean"]>;
  startBefore_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startBefore_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  weeklyMeetingsMax?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMax_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMax_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMin_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type ContentfulListingsPromoBannerHeaderTitleFontDefinitionFilter = {
  AND?: InputMaybe<
    Array<
      InputMaybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionFilter>
    >
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  fontFamily?: InputMaybe<Scalars["String"]>;
  fontFamily_contains?: InputMaybe<Scalars["String"]>;
  fontFamily_exists?: InputMaybe<Scalars["Boolean"]>;
  fontFamily_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fontFamily_not?: InputMaybe<Scalars["String"]>;
  fontFamily_not_contains?: InputMaybe<Scalars["String"]>;
  fontFamily_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fontUrl?: InputMaybe<Scalars["String"]>;
  fontUrl_contains?: InputMaybe<Scalars["String"]>;
  fontUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  fontUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fontUrl_not?: InputMaybe<Scalars["String"]>;
  fontUrl_not_contains?: InputMaybe<Scalars["String"]>;
  fontUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<
      InputMaybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionFilter>
    >
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulListingsPromoBannerHeaderTitleFontDefinitionLinkingCollectionsListingsPromoBannerCollectionOrder {
  ExperimentVersionAsc = "experimentVersion_ASC",
  ExperimentVersionDesc = "experimentVersion_DESC",
  HeaderSubtitleAsc = "headerSubtitle_ASC",
  HeaderSubtitleDesc = "headerSubtitle_DESC",
  HeaderTitleAsc = "headerTitle_ASC",
  HeaderTitleDesc = "headerTitle_DESC",
  IsLiveOnSearchAsc = "isLiveOnSearch_ASC",
  IsLiveOnSearchDesc = "isLiveOnSearch_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SidebarTitleAsc = "sidebarTitle_ASC",
  SidebarTitleDesc = "sidebarTitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulLandingPageHeroSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulLandingPageIconInfoSectionIconInfosCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulLandingPageIconInfoFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageIconInfoFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  iconImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageIconInfoFilter>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageIconInfoLinkingCollectionsLandingPageIconInfoSectionCollectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulLandingPageIconInfoSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulLandingPageImageTextCtaSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulLandingPageJourneySectionJourneyItemsCollectionOrder {
  AnimationUrlAsc = "animationUrl_ASC",
  AnimationUrlDesc = "animationUrl_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NumberColorAsc = "numberColor_ASC",
  NumberColorDesc = "numberColor_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulLandingPageJourneyItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageJourneyItemFilter>>>;
  animationUrl?: InputMaybe<Scalars["String"]>;
  animationUrl_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  animationUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  animationUrl_not?: InputMaybe<Scalars["String"]>;
  animationUrl_not_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor?: InputMaybe<Scalars["String"]>;
  numberColor_contains?: InputMaybe<Scalars["String"]>;
  numberColor_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  numberColor_not?: InputMaybe<Scalars["String"]>;
  numberColor_not_contains?: InputMaybe<Scalars["String"]>;
  numberColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageJourneyItemFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageJourneyItemLinkingCollectionsLandingPageJourneySectionCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulLandingPageJourneySectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulLandingPageSingleReviewSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulLandingPageStatBoxSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulLandingPageStatBoxSectionStatBoxesCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulLandingPageStatBoxFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageStatBoxFilter>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  hoverBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageStatBoxFilter>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageStatBoxLinkingCollectionsLandingPageStatBoxSectionCollectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulLandingPageTextOnlyStackedCtaSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars["ContentfulHexColor"]>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars["Int"]>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ContentfulImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars["ContentfulDimension"]>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars["ContentfulQuality"]>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ContentfulImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ContentfulImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars["ContentfulDimension"]>;
};

export enum ContentfulImageFormat {
  Avif = "AVIF",
  /** JPG image format. */
  Jpg = "JPG",
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = "JPG_PROGRESSIVE",
  /** PNG image format */
  Png = "PNG",
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = "PNG8",
  /** WebP image format. */
  Webp = "WEBP"
}

export enum ContentfulImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = "BOTTOM",
  /** Focus the resizing on the bottom left. */
  BottomLeft = "BOTTOM_LEFT",
  /** Focus the resizing on the bottom right. */
  BottomRight = "BOTTOM_RIGHT",
  /** Focus the resizing on the center. */
  Center = "CENTER",
  /** Focus the resizing on the largest face. */
  Face = "FACE",
  /** Focus the resizing on the area containing all the faces. */
  Faces = "FACES",
  /** Focus the resizing on the left. */
  Left = "LEFT",
  /** Focus the resizing on the right. */
  Right = "RIGHT",
  /** Focus the resizing on the top. */
  Top = "TOP",
  /** Focus the resizing on the top left. */
  TopLeft = "TOP_LEFT",
  /** Focus the resizing on the top right. */
  TopRight = "TOP_RIGHT"
}

export enum ContentfulImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = "CROP",
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = "FILL",
  /** Resizes the image to fit into the specified dimensions. */
  Fit = "FIT",
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = "PAD",
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = "SCALE",
  /** Creates a thumbnail from the image. */
  Thumb = "THUMB"
}

export enum ContentfulAccordionPageSectionsCollectionOrder {
  HeadingAsc = "heading_ASC",
  HeadingDesc = "heading_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulAccordionSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAccordionSectionFilter>>>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars["String"]>;
  heading_contains?: InputMaybe<Scalars["String"]>;
  heading_exists?: InputMaybe<Scalars["Boolean"]>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heading_not?: InputMaybe<Scalars["String"]>;
  heading_not_contains?: InputMaybe<Scalars["String"]>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAccordionSectionFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulAccordionSectionLinkingCollectionsAccordionPageCollectionOrder {
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  ShowTocAsc = "showToc_ASC",
  ShowTocDesc = "showToc_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulAccordionPageOrder {
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  ShowTocAsc = "showToc_ASC",
  ShowTocDesc = "showToc_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulAccordionPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAccordionPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAccordionPageFilter>>>;
  preamble_contains?: InputMaybe<Scalars["String"]>;
  preamble_exists?: InputMaybe<Scalars["Boolean"]>;
  preamble_not_contains?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  publishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  sections?: InputMaybe<ContentfulcfAccordionSectionNestedFilter>;
  sectionsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  showToc?: InputMaybe<Scalars["Boolean"]>;
  showToc_exists?: InputMaybe<Scalars["Boolean"]>;
  showToc_not?: InputMaybe<Scalars["Boolean"]>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfAccordionSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfAccordionSectionNestedFilter>>>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars["String"]>;
  heading_contains?: InputMaybe<Scalars["String"]>;
  heading_exists?: InputMaybe<Scalars["Boolean"]>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heading_not?: InputMaybe<Scalars["String"]>;
  heading_not_contains?: InputMaybe<Scalars["String"]>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfAccordionSectionNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulAccordionSectionOrder {
  HeadingAsc = "heading_ASC",
  HeadingDesc = "heading_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulAnnouncementBannerOrder {
  CopyAsc = "copy_ASC",
  CopyDesc = "copy_DESC",
  DateToRemoveAsc = "dateToRemove_ASC",
  DateToRemoveDesc = "dateToRemove_DESC",
  LinkCopyAsc = "linkCopy_ASC",
  LinkCopyDesc = "linkCopy_DESC",
  LinkUrlAsc = "linkUrl_ASC",
  LinkUrlDesc = "linkUrl_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulAnnouncementBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAnnouncementBannerFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  copy?: InputMaybe<Scalars["String"]>;
  copy_contains?: InputMaybe<Scalars["String"]>;
  copy_exists?: InputMaybe<Scalars["Boolean"]>;
  copy_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  copy_not?: InputMaybe<Scalars["String"]>;
  copy_not_contains?: InputMaybe<Scalars["String"]>;
  copy_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  dateToRemove?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_exists?: InputMaybe<Scalars["Boolean"]>;
  dateToRemove_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  dateToRemove_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  linkCopy?: InputMaybe<Scalars["String"]>;
  linkCopy_contains?: InputMaybe<Scalars["String"]>;
  linkCopy_exists?: InputMaybe<Scalars["Boolean"]>;
  linkCopy_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  linkCopy_not?: InputMaybe<Scalars["String"]>;
  linkCopy_not_contains?: InputMaybe<Scalars["String"]>;
  linkCopy_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  linkUrl?: InputMaybe<Scalars["String"]>;
  linkUrl_contains?: InputMaybe<Scalars["String"]>;
  linkUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  linkUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  linkUrl_not?: InputMaybe<Scalars["String"]>;
  linkUrl_not_contains?: InputMaybe<Scalars["String"]>;
  linkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAnnouncementBannerFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulAssetOrder {
  ContentTypeAsc = "contentType_ASC",
  ContentTypeDesc = "contentType_DESC",
  FileNameAsc = "fileName_ASC",
  FileNameDesc = "fileName_DESC",
  HeightAsc = "height_ASC",
  HeightDesc = "height_DESC",
  SizeAsc = "size_ASC",
  SizeDesc = "size_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
  WidthAsc = "width_ASC",
  WidthDesc = "width_DESC"
}

export type ContentfulAssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAssetFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  contentType?: InputMaybe<Scalars["String"]>;
  contentType_contains?: InputMaybe<Scalars["String"]>;
  contentType_exists?: InputMaybe<Scalars["Boolean"]>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentType_not?: InputMaybe<Scalars["String"]>;
  contentType_not_contains?: InputMaybe<Scalars["String"]>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description?: InputMaybe<Scalars["String"]>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_not?: InputMaybe<Scalars["String"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fileName?: InputMaybe<Scalars["String"]>;
  fileName_contains?: InputMaybe<Scalars["String"]>;
  fileName_exists?: InputMaybe<Scalars["Boolean"]>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fileName_not?: InputMaybe<Scalars["String"]>;
  fileName_not_contains?: InputMaybe<Scalars["String"]>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  height?: InputMaybe<Scalars["Int"]>;
  height_exists?: InputMaybe<Scalars["Boolean"]>;
  height_gt?: InputMaybe<Scalars["Int"]>;
  height_gte?: InputMaybe<Scalars["Int"]>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  height_lt?: InputMaybe<Scalars["Int"]>;
  height_lte?: InputMaybe<Scalars["Int"]>;
  height_not?: InputMaybe<Scalars["Int"]>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAssetFilter>>>;
  size?: InputMaybe<Scalars["Int"]>;
  size_exists?: InputMaybe<Scalars["Boolean"]>;
  size_gt?: InputMaybe<Scalars["Int"]>;
  size_gte?: InputMaybe<Scalars["Int"]>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  size_lt?: InputMaybe<Scalars["Int"]>;
  size_lte?: InputMaybe<Scalars["Int"]>;
  size_not?: InputMaybe<Scalars["Int"]>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url?: InputMaybe<Scalars["String"]>;
  url_contains?: InputMaybe<Scalars["String"]>;
  url_exists?: InputMaybe<Scalars["Boolean"]>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url_not?: InputMaybe<Scalars["String"]>;
  url_not_contains?: InputMaybe<Scalars["String"]>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  width?: InputMaybe<Scalars["Int"]>;
  width_exists?: InputMaybe<Scalars["Boolean"]>;
  width_gt?: InputMaybe<Scalars["Int"]>;
  width_gte?: InputMaybe<Scalars["Int"]>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  width_lt?: InputMaybe<Scalars["Int"]>;
  width_lte?: InputMaybe<Scalars["Int"]>;
  width_not?: InputMaybe<Scalars["Int"]>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export enum ContentfulAuthorOrder {
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  IsTeacherAsc = "isTeacher_ASC",
  IsTeacherDesc = "isTeacher_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  UidAsc = "uid_ASC",
  UidDesc = "uid_DESC"
}

export enum ContentfulBannerOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC"
}

export enum ContentfulBannerPlacementOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulBannerPlacementFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulBannerPlacementFilter>>>;
  banners?: InputMaybe<ContentfulcfBannerNestedFilter>;
  bannersCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulBannerPlacementFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfBannerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfBannerNestedFilter>>>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  campaign_exists?: InputMaybe<Scalars["Boolean"]>;
  content_contains?: InputMaybe<Scalars["String"]>;
  content_exists?: InputMaybe<Scalars["Boolean"]>;
  content_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfBannerNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url?: InputMaybe<Scalars["String"]>;
  url_contains?: InputMaybe<Scalars["String"]>;
  url_exists?: InputMaybe<Scalars["Boolean"]>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url_not?: InputMaybe<Scalars["String"]>;
  url_not_contains?: InputMaybe<Scalars["String"]>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulBasicPageOrder {
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulBasicPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulBasicPageFilter>>>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulBasicPageFilter>>>;
  publishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  publishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulCampaignOrder {
  EndAtAsc = "endAt_ASC",
  EndAtDesc = "endAt_DESC",
  MaxAgeAsc = "maxAge_ASC",
  MaxAgeDesc = "maxAge_DESC",
  MinAgeAsc = "minAge_ASC",
  MinAgeDesc = "minAge_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  OnlyForTreatmentInExperimentAsc = "onlyForTreatmentInExperiment_ASC",
  OnlyForTreatmentInExperimentDesc = "onlyForTreatmentInExperiment_DESC",
  StartAtAsc = "startAt_ASC",
  StartAtDesc = "startAt_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulCampaignCountryOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulCampaignStateOrProvinceOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulCategoryLabelOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  ValueAsc = "value_ASC",
  ValueDesc = "value_DESC"
}

export enum ContentfulCategoryPageOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulCategoryPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCategoryPageFilter>>>;
  belowTheFoldDescription_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldDescription_exists?: InputMaybe<Scalars["Boolean"]>;
  belowTheFoldDescription_not_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  belowTheFoldTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  belowTheFoldTitle_not?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_not_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug?: InputMaybe<Scalars["String"]>;
  categorySlug_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_exists?: InputMaybe<Scalars["Boolean"]>;
  categorySlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug_not?: InputMaybe<Scalars["String"]>;
  categorySlug_not_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  featuredClassesOverrideSlug?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_contains?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_exists?: InputMaybe<Scalars["Boolean"]>;
  featuredClassesOverrideSlug_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  featuredClassesOverrideSlug_not?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_not_contains?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  footerCtaPath?: InputMaybe<Scalars["String"]>;
  footerCtaPath_contains?: InputMaybe<Scalars["String"]>;
  footerCtaPath_exists?: InputMaybe<Scalars["Boolean"]>;
  footerCtaPath_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  footerCtaPath_not?: InputMaybe<Scalars["String"]>;
  footerCtaPath_not_contains?: InputMaybe<Scalars["String"]>;
  footerCtaPath_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  footerCtaTitle?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_contains?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  footerCtaTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  footerCtaTitle_not?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_not_contains?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heroDescription_contains?: InputMaybe<Scalars["String"]>;
  heroDescription_exists?: InputMaybe<Scalars["Boolean"]>;
  heroDescription_not_contains?: InputMaybe<Scalars["String"]>;
  heroImage_exists?: InputMaybe<Scalars["Boolean"]>;
  heroTitle?: InputMaybe<Scalars["String"]>;
  heroTitle_contains?: InputMaybe<Scalars["String"]>;
  heroTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  heroTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heroTitle_not?: InputMaybe<Scalars["String"]>;
  heroTitle_not_contains?: InputMaybe<Scalars["String"]>;
  heroTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heroValueProps_contains?: InputMaybe<Scalars["String"]>;
  heroValueProps_exists?: InputMaybe<Scalars["Boolean"]>;
  heroValueProps_not_contains?: InputMaybe<Scalars["String"]>;
  jumpPillLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  jumpPillLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  jumpPills?: InputMaybe<ContentfulcfJumpPillsNestedFilter>;
  jumpPillsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  jumpPillsTitle?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_contains?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  jumpPillsTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  jumpPillsTitle_not?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_not_contains?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  magnetPages?: InputMaybe<ContentfulcfMagnetPageNestedFilter>;
  magnetPagesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCategoryPageFilter>>>;
  relatedCategories?: InputMaybe<ContentfulcfUpsellClassCardNestedFilter>;
  relatedCategoriesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  showFeaturedClasses?: InputMaybe<Scalars["Boolean"]>;
  showFeaturedClasses_exists?: InputMaybe<Scalars["Boolean"]>;
  showFeaturedClasses_not?: InputMaybe<Scalars["Boolean"]>;
  startAfter?: InputMaybe<Scalars["String"]>;
  startAfter_contains?: InputMaybe<Scalars["String"]>;
  startAfter_exists?: InputMaybe<Scalars["Boolean"]>;
  startAfter_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startAfter_not?: InputMaybe<Scalars["String"]>;
  startAfter_not_contains?: InputMaybe<Scalars["String"]>;
  startAfter_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfJumpPillsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfJumpPillsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfJumpPillsNestedFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfMagnetPageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfMagnetPageNestedFilter>>>;
  author_exists?: InputMaybe<Scalars["Boolean"]>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  categoryLabel_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  magnetPageSlug_not?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfMagnetPageNestedFilter>>>;
  summary_contains?: InputMaybe<Scalars["String"]>;
  summary_exists?: InputMaybe<Scalars["Boolean"]>;
  summary_not_contains?: InputMaybe<Scalars["String"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellClassCarousel_exists?: InputMaybe<Scalars["Boolean"]>;
};

export enum ContentfulClpEmailCaptureOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  ErrorMessageAsc = "errorMessage_ASC",
  ErrorMessageDesc = "errorMessage_DESC",
  IterableLinkAsc = "iterableLink_ASC",
  IterableLinkDesc = "iterableLink_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  ProdIterableAudienceIdAsc = "prodIterableAudienceId_ASC",
  ProdIterableAudienceIdDesc = "prodIterableAudienceId_DESC",
  StagingIterableAudienceIdAsc = "stagingIterableAudienceId_ASC",
  StagingIterableAudienceIdDesc = "stagingIterableAudienceId_DESC",
  SuccessMessageAsc = "successMessage_ASC",
  SuccessMessageDesc = "successMessage_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulClpEmailCaptureFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulClpEmailCaptureFilter>>>;
  bodyContent_contains?: InputMaybe<Scalars["String"]>;
  bodyContent_exists?: InputMaybe<Scalars["Boolean"]>;
  bodyContent_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText?: InputMaybe<Scalars["String"]>;
  callToActionText_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionText_not?: InputMaybe<Scalars["String"]>;
  callToActionText_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  clpPages?: InputMaybe<ContentfulcfCategoryLabelNestedFilter>;
  clpPagesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  errorMessage_contains?: InputMaybe<Scalars["String"]>;
  errorMessage_exists?: InputMaybe<Scalars["Boolean"]>;
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  errorMessage_not?: InputMaybe<Scalars["String"]>;
  errorMessage_not_contains?: InputMaybe<Scalars["String"]>;
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  iterableLink?: InputMaybe<Scalars["String"]>;
  iterableLink_contains?: InputMaybe<Scalars["String"]>;
  iterableLink_exists?: InputMaybe<Scalars["Boolean"]>;
  iterableLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  iterableLink_not?: InputMaybe<Scalars["String"]>;
  iterableLink_not_contains?: InputMaybe<Scalars["String"]>;
  iterableLink_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulClpEmailCaptureFilter>>>;
  prodIterableAudienceId?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_contains?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_exists?: InputMaybe<Scalars["Boolean"]>;
  prodIterableAudienceId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  prodIterableAudienceId_not?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_not_contains?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  stagingIterableAudienceId?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_contains?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_exists?: InputMaybe<Scalars["Boolean"]>;
  stagingIterableAudienceId_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  stagingIterableAudienceId_not?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_not_contains?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  successMessage?: InputMaybe<Scalars["String"]>;
  successMessage_contains?: InputMaybe<Scalars["String"]>;
  successMessage_exists?: InputMaybe<Scalars["Boolean"]>;
  successMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  successMessage_not?: InputMaybe<Scalars["String"]>;
  successMessage_not_contains?: InputMaybe<Scalars["String"]>;
  successMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulCommunityEventAnnouncementOrder {
  CallToAction1LabelAsc = "callToAction1Label_ASC",
  CallToAction1LabelDesc = "callToAction1Label_DESC",
  CallToAction2LabelAsc = "callToAction2Label_ASC",
  CallToAction2LabelDesc = "callToAction2Label_DESC",
  DateAsc = "date_ASC",
  DateDesc = "date_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulCommunityEventAnnouncementFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulCommunityEventAnnouncementFilter>>
  >;
  callToAction1Label?: InputMaybe<Scalars["String"]>;
  callToAction1Label_contains?: InputMaybe<Scalars["String"]>;
  callToAction1Label_exists?: InputMaybe<Scalars["Boolean"]>;
  callToAction1Label_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToAction1Label_not?: InputMaybe<Scalars["String"]>;
  callToAction1Label_not_contains?: InputMaybe<Scalars["String"]>;
  callToAction1Label_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToAction2Label?: InputMaybe<Scalars["String"]>;
  callToAction2Label_contains?: InputMaybe<Scalars["String"]>;
  callToAction2Label_exists?: InputMaybe<Scalars["Boolean"]>;
  callToAction2Label_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToAction2Label_not?: InputMaybe<Scalars["String"]>;
  callToAction2Label_not_contains?: InputMaybe<Scalars["String"]>;
  callToAction2Label_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  date?: InputMaybe<Scalars["ContentfulDateTime"]>;
  date_exists?: InputMaybe<Scalars["Boolean"]>;
  date_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  date_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  date_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  date_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  date_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  date_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  description?: InputMaybe<Scalars["String"]>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_not?: InputMaybe<Scalars["String"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulCommunityEventAnnouncementFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulEntryOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulEntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulEntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulEntryFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulFeaturedPagesOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulFeaturedPagesFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulFeaturedPagesFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulFeaturedPagesFilter>>>;
  pages?: InputMaybe<ContentfulcfMagnetPageNestedFilter>;
  pagesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulHeroSlideOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulHomePage2Order {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulHomePage2Filter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulHomePage2Filter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  heroSlides?: InputMaybe<ContentfulcfHeroSlideNestedFilter>;
  heroSlidesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulHomePage2Filter>>>;
  popularSearchLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  popularSearchLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  valueProps?: InputMaybe<ContentfulcfValuePropNestedFilter>;
  valuePropsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  visualLinkGrids?: InputMaybe<ContentfulcfVisualLinkGridNestedFilter>;
  visualLinkGridsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfHeroSlideNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfHeroSlideNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfHeroSlideNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfValuePropNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfValuePropNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfValuePropNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfVisualLinkGridNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkGridNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkGridNestedFilter>>>;
  style?: InputMaybe<Scalars["String"]>;
  style_contains?: InputMaybe<Scalars["String"]>;
  style_exists?: InputMaybe<Scalars["Boolean"]>;
  style_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  style_not?: InputMaybe<Scalars["String"]>;
  style_not_contains?: InputMaybe<Scalars["String"]>;
  style_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  visualLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export enum ContentfulHomePageOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulHomePageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulHomePageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulHomePageFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  visualLinkGrids?: InputMaybe<ContentfulcfVisualLinkGridNestedFilter>;
  visualLinkGridsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export enum ContentfulJumpPillsOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulLandingPageClassCarouselOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulLandingPageClassCarouselFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageClassCarouselFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageClassCarouselFilter>>>;
  searchFilters?: InputMaybe<ContentfulcfSearchFiltersNestedFilter>;
  searchFilters_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulLandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  metaTagCanonicalUrl?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_contains?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  metaTagCanonicalUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagCanonicalUrl_not?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_not_contains?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagDescription?: InputMaybe<Scalars["String"]>;
  metaTagDescription_contains?: InputMaybe<Scalars["String"]>;
  metaTagDescription_exists?: InputMaybe<Scalars["Boolean"]>;
  metaTagDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagDescription_not?: InputMaybe<Scalars["String"]>;
  metaTagDescription_not_contains?: InputMaybe<Scalars["String"]>;
  metaTagDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagSharedLinkRichPreviewImage_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaText?: InputMaybe<Scalars["String"]>;
  navbarCtaText_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaText_not?: InputMaybe<Scalars["String"]>;
  navbarCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrl?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrl_not?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_not_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageFilter>>>;
  sections?: InputMaybe<ContentfulcfsectionsMultiTypeNestedFilter>;
  sectionsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfsectionsMultiTypeNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfsectionsMultiTypeNestedFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfsectionsMultiTypeNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageHeroSectionOrder {
  AnimationUrlAsc = "animationUrl_ASC",
  AnimationUrlDesc = "animationUrl_DESC",
  ButtonCtaTextAsc = "buttonCtaText_ASC",
  ButtonCtaTextDesc = "buttonCtaText_DESC",
  ButtonUrlAsc = "buttonUrl_ASC",
  ButtonUrlDesc = "buttonUrl_DESC",
  ButtonUrlLoggedInAsc = "buttonUrlLoggedIn_ASC",
  ButtonUrlLoggedInDesc = "buttonUrlLoggedIn_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ShouldShowTrustPilotAsc = "shouldShowTrustPilot_ASC",
  ShouldShowTrustPilotDesc = "shouldShowTrustPilot_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulLandingPageHeroSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageHeroSectionFilter>>>;
  animationUrl?: InputMaybe<Scalars["String"]>;
  animationUrl_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  animationUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  animationUrl_not?: InputMaybe<Scalars["String"]>;
  animationUrl_not_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText?: InputMaybe<Scalars["String"]>;
  buttonCtaText_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonCtaText_not?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl?: InputMaybe<Scalars["String"]>;
  buttonUrl_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl_not?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  ID?: InputMaybe<Scalars["String"]>;
  ID_contains?: InputMaybe<Scalars["String"]>;
  ID_exists?: InputMaybe<Scalars["Boolean"]>;
  ID_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ID_not?: InputMaybe<Scalars["String"]>;
  ID_not_contains?: InputMaybe<Scalars["String"]>;
  ID_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageHeroSectionFilter>>>;
  shouldShowTrustPilot?: InputMaybe<Scalars["Boolean"]>;
  shouldShowTrustPilot_exists?: InputMaybe<Scalars["Boolean"]>;
  shouldShowTrustPilot_not?: InputMaybe<Scalars["Boolean"]>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageIconInfoOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulLandingPageIconInfoSectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulLandingPageIconInfoSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageIconInfoSectionFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayOnMobile?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_not?: InputMaybe<Scalars["Boolean"]>;
  iconInfos?: InputMaybe<ContentfulcfLandingPageIconInfoNestedFilter>;
  iconInfosCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageIconInfoSectionFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfLandingPageIconInfoNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageIconInfoNestedFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  iconImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageIconInfoNestedFilter>>
  >;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageImageTextCtaSectionOrder {
  ButtonCtaTextAsc = "buttonCtaText_ASC",
  ButtonCtaTextDesc = "buttonCtaText_DESC",
  ButtonUrlAsc = "buttonUrl_ASC",
  ButtonUrlDesc = "buttonUrl_DESC",
  ButtonUrlLoggedInAsc = "buttonUrlLoggedIn_ASC",
  ButtonUrlLoggedInDesc = "buttonUrlLoggedIn_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleEndColorAsc = "titleEndColor_ASC",
  TitleEndColorDesc = "titleEndColor_DESC",
  TitleEndTextAsc = "titleEndText_ASC",
  TitleEndTextDesc = "titleEndText_DESC",
  TitleStartColorAsc = "titleStartColor_ASC",
  TitleStartColorDesc = "titleStartColor_DESC",
  TitleStartTextAsc = "titleStartText_ASC",
  TitleStartTextDesc = "titleStartText_DESC"
}

export type ContentfulLandingPageImageTextCtaSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageImageTextCtaSectionFilter>>
  >;
  buttonCtaText?: InputMaybe<Scalars["String"]>;
  buttonCtaText_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonCtaText_not?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl?: InputMaybe<Scalars["String"]>;
  buttonUrl_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl_not?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageImageTextCtaSectionFilter>>
  >;
  subtitleRt_contains?: InputMaybe<Scalars["String"]>;
  subtitleRt_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitleRt_not_contains?: InputMaybe<Scalars["String"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  titleEndColor?: InputMaybe<Scalars["String"]>;
  titleEndColor_contains?: InputMaybe<Scalars["String"]>;
  titleEndColor_exists?: InputMaybe<Scalars["Boolean"]>;
  titleEndColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleEndColor_not?: InputMaybe<Scalars["String"]>;
  titleEndColor_not_contains?: InputMaybe<Scalars["String"]>;
  titleEndColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleEndText?: InputMaybe<Scalars["String"]>;
  titleEndText_contains?: InputMaybe<Scalars["String"]>;
  titleEndText_exists?: InputMaybe<Scalars["Boolean"]>;
  titleEndText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleEndText_not?: InputMaybe<Scalars["String"]>;
  titleEndText_not_contains?: InputMaybe<Scalars["String"]>;
  titleEndText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartColor?: InputMaybe<Scalars["String"]>;
  titleStartColor_contains?: InputMaybe<Scalars["String"]>;
  titleStartColor_exists?: InputMaybe<Scalars["Boolean"]>;
  titleStartColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartColor_not?: InputMaybe<Scalars["String"]>;
  titleStartColor_not_contains?: InputMaybe<Scalars["String"]>;
  titleStartColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartText?: InputMaybe<Scalars["String"]>;
  titleStartText_contains?: InputMaybe<Scalars["String"]>;
  titleStartText_exists?: InputMaybe<Scalars["Boolean"]>;
  titleStartText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartText_not?: InputMaybe<Scalars["String"]>;
  titleStartText_not_contains?: InputMaybe<Scalars["String"]>;
  titleStartText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageJourneyItemOrder {
  AnimationUrlAsc = "animationUrl_ASC",
  AnimationUrlDesc = "animationUrl_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NumberColorAsc = "numberColor_ASC",
  NumberColorDesc = "numberColor_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulLandingPageJourneySectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulLandingPageJourneySectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageJourneySectionFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  journeyItems?: InputMaybe<ContentfulcfLandingPageJourneyItemNestedFilter>;
  journeyItemsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageJourneySectionFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfLandingPageJourneyItemNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageJourneyItemNestedFilter>>
  >;
  animationUrl?: InputMaybe<Scalars["String"]>;
  animationUrl_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  animationUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  animationUrl_not?: InputMaybe<Scalars["String"]>;
  animationUrl_not_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor?: InputMaybe<Scalars["String"]>;
  numberColor_contains?: InputMaybe<Scalars["String"]>;
  numberColor_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  numberColor_not?: InputMaybe<Scalars["String"]>;
  numberColor_not_contains?: InputMaybe<Scalars["String"]>;
  numberColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageJourneyItemNestedFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageSingleReviewSectionOrder {
  ClassNameAsc = "className_ASC",
  ClassNameDesc = "className_DESC",
  ClassUrlAsc = "classUrl_ASC",
  ClassUrlDesc = "classUrl_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  ReviewerNameAsc = "reviewerName_ASC",
  ReviewerNameDesc = "reviewerName_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulLandingPageSingleReviewSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageSingleReviewSectionFilter>>
  >;
  className?: InputMaybe<Scalars["String"]>;
  className_contains?: InputMaybe<Scalars["String"]>;
  className_exists?: InputMaybe<Scalars["Boolean"]>;
  className_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  className_not?: InputMaybe<Scalars["String"]>;
  className_not_contains?: InputMaybe<Scalars["String"]>;
  className_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  classUrl?: InputMaybe<Scalars["String"]>;
  classUrl_contains?: InputMaybe<Scalars["String"]>;
  classUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  classUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  classUrl_not?: InputMaybe<Scalars["String"]>;
  classUrl_not_contains?: InputMaybe<Scalars["String"]>;
  classUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  imageMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageSingleReviewSectionFilter>>
  >;
  reviewerName?: InputMaybe<Scalars["String"]>;
  reviewerName_contains?: InputMaybe<Scalars["String"]>;
  reviewerName_exists?: InputMaybe<Scalars["Boolean"]>;
  reviewerName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  reviewerName_not?: InputMaybe<Scalars["String"]>;
  reviewerName_not_contains?: InputMaybe<Scalars["String"]>;
  reviewerName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  reviewImageBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  reviewText_contains?: InputMaybe<Scalars["String"]>;
  reviewText_exists?: InputMaybe<Scalars["Boolean"]>;
  reviewText_not_contains?: InputMaybe<Scalars["String"]>;
  reviewTextBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageStatBoxOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulLandingPageStatBoxSectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulLandingPageStatBoxSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageStatBoxSectionFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayOnMobile?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_not?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageStatBoxSectionFilter>>>;
  statBoxes?: InputMaybe<ContentfulcfLandingPageStatBoxNestedFilter>;
  statBoxesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfLandingPageStatBoxNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageStatBoxNestedFilter>>
  >;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  hoverBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageStatBoxNestedFilter>>
  >;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageTextOnlyStackedCtaSectionOrder {
  ButtonCtaTextAsc = "buttonCtaText_ASC",
  ButtonCtaTextDesc = "buttonCtaText_DESC",
  ButtonUrlAsc = "buttonUrl_ASC",
  ButtonUrlDesc = "buttonUrl_DESC",
  ButtonUrlLoggedInAsc = "buttonUrlLoggedIn_ASC",
  ButtonUrlLoggedInDesc = "buttonUrlLoggedIn_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IncludeGradientBackgroundAsc = "includeGradientBackground_ASC",
  IncludeGradientBackgroundDesc = "includeGradientBackground_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulLandingPageTextOnlyStackedCtaSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageTextOnlyStackedCtaSectionFilter>>
  >;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText?: InputMaybe<Scalars["String"]>;
  buttonCtaText_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonCtaText_not?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl?: InputMaybe<Scalars["String"]>;
  buttonUrl_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl_not?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  includeGradientBackground?: InputMaybe<Scalars["Boolean"]>;
  includeGradientBackground_exists?: InputMaybe<Scalars["Boolean"]>;
  includeGradientBackground_not?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageTextOnlyStackedCtaSectionFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLearnerBannerOrder {
  CtaTextAsc = "ctaText_ASC",
  CtaTextDesc = "ctaText_DESC",
  CtaUrlAsc = "ctaUrl_ASC",
  CtaUrlDesc = "ctaUrl_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulLearnerBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLearnerBannerFilter>>>;
  campaign?: InputMaybe<ContentfulcfCampaignNestedFilter>;
  campaign_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  ctaText?: InputMaybe<Scalars["String"]>;
  ctaText_contains?: InputMaybe<Scalars["String"]>;
  ctaText_exists?: InputMaybe<Scalars["Boolean"]>;
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaText_not?: InputMaybe<Scalars["String"]>;
  ctaText_not_contains?: InputMaybe<Scalars["String"]>;
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaUrl?: InputMaybe<Scalars["String"]>;
  ctaUrl_contains?: InputMaybe<Scalars["String"]>;
  ctaUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaUrl_not?: InputMaybe<Scalars["String"]>;
  ctaUrl_not_contains?: InputMaybe<Scalars["String"]>;
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLearnerBannerFilter>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulListingsPromoBannerButtonOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  ExploreMoreButtonTextAsc = "exploreMoreButtonText_ASC",
  ExploreMoreButtonTextDesc = "exploreMoreButtonText_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulListingsPromoBannerOrder {
  ExperimentVersionAsc = "experimentVersion_ASC",
  ExperimentVersionDesc = "experimentVersion_DESC",
  HeaderSubtitleAsc = "headerSubtitle_ASC",
  HeaderSubtitleDesc = "headerSubtitle_DESC",
  HeaderTitleAsc = "headerTitle_ASC",
  HeaderTitleDesc = "headerTitle_DESC",
  IsLiveOnSearchAsc = "isLiveOnSearch_ASC",
  IsLiveOnSearchDesc = "isLiveOnSearch_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SidebarTitleAsc = "sidebarTitle_ASC",
  SidebarTitleDesc = "sidebarTitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export type ContentfulListingsPromoBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulListingsPromoBannerFilter>>>;
  backgroundImageLeft_exists?: InputMaybe<Scalars["Boolean"]>;
  backgroundImageMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  backgroundImageRight_exists?: InputMaybe<Scalars["Boolean"]>;
  buttons?: InputMaybe<ContentfulcfListingsPromoBannerButtonNestedFilter>;
  buttonsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  experimentVersion?: InputMaybe<Scalars["Int"]>;
  experimentVersion_exists?: InputMaybe<Scalars["Boolean"]>;
  experimentVersion_gt?: InputMaybe<Scalars["Int"]>;
  experimentVersion_gte?: InputMaybe<Scalars["Int"]>;
  experimentVersion_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  experimentVersion_lt?: InputMaybe<Scalars["Int"]>;
  experimentVersion_lte?: InputMaybe<Scalars["Int"]>;
  experimentVersion_not?: InputMaybe<Scalars["Int"]>;
  experimentVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  headerSubtitle?: InputMaybe<Scalars["String"]>;
  headerSubtitle_contains?: InputMaybe<Scalars["String"]>;
  headerSubtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  headerSubtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerSubtitle_not?: InputMaybe<Scalars["String"]>;
  headerSubtitle_not_contains?: InputMaybe<Scalars["String"]>;
  headerSubtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerTitle?: InputMaybe<Scalars["String"]>;
  headerTitle_contains?: InputMaybe<Scalars["String"]>;
  headerTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  headerTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerTitle_not?: InputMaybe<Scalars["String"]>;
  headerTitle_not_contains?: InputMaybe<Scalars["String"]>;
  headerTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerTitleFontDefinition?: InputMaybe<ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter>;
  headerTitleFontDefinition_exists?: InputMaybe<Scalars["Boolean"]>;
  isLiveOnSearch?: InputMaybe<Scalars["Boolean"]>;
  isLiveOnSearch_exists?: InputMaybe<Scalars["Boolean"]>;
  isLiveOnSearch_not?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulListingsPromoBannerFilter>>>;
  sidebarTitle?: InputMaybe<Scalars["String"]>;
  sidebarTitle_contains?: InputMaybe<Scalars["String"]>;
  sidebarTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  sidebarTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sidebarTitle_not?: InputMaybe<Scalars["String"]>;
  sidebarTitle_not_contains?: InputMaybe<Scalars["String"]>;
  sidebarTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfListingsPromoBannerButtonNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfListingsPromoBannerButtonNestedFilter>>
  >;
  buttonSearchFilters_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  exploreMoreButtonText?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_exists?: InputMaybe<Scalars["Boolean"]>;
  exploreMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  exploreMoreButtonText_not?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfListingsPromoBannerButtonNestedFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter =
  {
    AND?: InputMaybe<
      Array<
        InputMaybe<ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter>
      >
    >;
    contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
    fontFamily?: InputMaybe<Scalars["String"]>;
    fontFamily_contains?: InputMaybe<Scalars["String"]>;
    fontFamily_exists?: InputMaybe<Scalars["Boolean"]>;
    fontFamily_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    fontFamily_not?: InputMaybe<Scalars["String"]>;
    fontFamily_not_contains?: InputMaybe<Scalars["String"]>;
    fontFamily_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    fontUrl?: InputMaybe<Scalars["String"]>;
    fontUrl_contains?: InputMaybe<Scalars["String"]>;
    fontUrl_exists?: InputMaybe<Scalars["Boolean"]>;
    fontUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    fontUrl_not?: InputMaybe<Scalars["String"]>;
    fontUrl_not_contains?: InputMaybe<Scalars["String"]>;
    fontUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    OR?: InputMaybe<
      Array<
        InputMaybe<ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter>
      >
    >;
    sys?: InputMaybe<ContentfulSysFilter>;
  };

export enum ContentfulListingsPromoBannerHeaderTitleFontDefinitionOrder {
  FontFamilyAsc = "fontFamily_ASC",
  FontFamilyDesc = "fontFamily_DESC",
  FontUrlAsc = "fontUrl_ASC",
  FontUrlDesc = "fontUrl_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulPopularSearchTopicLinkOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulPopularSearchTopicLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulPopularSearchTopicLinkFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulPopularSearchTopicLinkFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulSearchFiltersOrder {
  CapacityMaxAsc = "capacityMax_ASC",
  CapacityMaxDesc = "capacityMax_DESC",
  CapacityMinAsc = "capacityMin_ASC",
  CapacityMinDesc = "capacityMin_DESC",
  DurationWeeksMaxAsc = "durationWeeksMax_ASC",
  DurationWeeksMaxDesc = "durationWeeksMax_DESC",
  DurationWeeksMinAsc = "durationWeeksMin_ASC",
  DurationWeeksMinDesc = "durationWeeksMin_DESC",
  EndByAsc = "endBy_ASC",
  EndByDesc = "endBy_DESC",
  FiltersNameAsc = "filtersName_ASC",
  FiltersNameDesc = "filtersName_DESC",
  MultiTermQueryAsc = "multiTermQuery_ASC",
  MultiTermQueryDesc = "multiTermQuery_DESC",
  PricePerCourseMaxAsc = "pricePerCourseMax_ASC",
  PricePerCourseMaxDesc = "pricePerCourseMax_DESC",
  PricePerCourseMinAsc = "pricePerCourseMin_ASC",
  PricePerCourseMinDesc = "pricePerCourseMin_DESC",
  PricePerMeetingMaxAsc = "pricePerMeetingMax_ASC",
  PricePerMeetingMaxDesc = "pricePerMeetingMax_DESC",
  PricePerMeetingMinAsc = "pricePerMeetingMin_ASC",
  PricePerMeetingMinDesc = "pricePerMeetingMin_DESC",
  QueryAsc = "query_ASC",
  QueryDesc = "query_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  StartBeforeAsc = "startBefore_ASC",
  StartBeforeDesc = "startBefore_DESC",
  SubjectAsc = "subject_ASC",
  SubjectDesc = "subject_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  WeeklyMeetingsMaxAsc = "weeklyMeetingsMax_ASC",
  WeeklyMeetingsMaxDesc = "weeklyMeetingsMax_DESC",
  WeeklyMeetingsMinAsc = "weeklyMeetingsMin_ASC",
  WeeklyMeetingsMinDesc = "weeklyMeetingsMin_DESC"
}

export enum ContentfulTopicOrder {
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  NameTitleCasedAsc = "nameTitleCased_ASC",
  NameTitleCasedDesc = "nameTitleCased_DESC",
  SearchPathAsc = "searchPath_ASC",
  SearchPathDesc = "searchPath_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SubjectAsc = "subject_ASC",
  SubjectDesc = "subject_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC"
}

export enum ContentfulTopicTickerOrder {
  QueryAsc = "query_ASC",
  QueryDesc = "query_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TopicAsc = "topic_ASC",
  TopicDesc = "topic_DESC"
}

export type ContentfulTopicTickerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulTopicTickerFilter>>>;
  campaign?: InputMaybe<ContentfulcfCampaignNestedFilter>;
  campaign_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulTopicTickerFilter>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  topic?: InputMaybe<Scalars["String"]>;
  topic_contains?: InputMaybe<Scalars["String"]>;
  topic_exists?: InputMaybe<Scalars["Boolean"]>;
  topic_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  topic_not?: InputMaybe<Scalars["String"]>;
  topic_not_contains?: InputMaybe<Scalars["String"]>;
  topic_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulTutoringPageOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulTutoringPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulTutoringPageFilter>>>;
  articles?: InputMaybe<ContentfulcfMagnetPageNestedFilter>;
  articlesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  carouselSubcategories?: InputMaybe<ContentfulcfTopicNestedFilter>;
  carouselSubcategoriesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulTutoringPageFilter>>>;
  popularSearchLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  popularSearchLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  popularTutoringSubjects?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  popularTutoringSubjectsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  tutoringCarousel?: InputMaybe<ContentfulcfVisualLinkGridNestedFilter>;
  tutoringCarouselCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  visualLinkGrids?: InputMaybe<ContentfulcfVisualLinkGridNestedFilter>;
  visualLinkGridsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfTopicNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfTopicNestedFilter>>>;
  categorySlug?: InputMaybe<Scalars["String"]>;
  categorySlug_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_exists?: InputMaybe<Scalars["Boolean"]>;
  categorySlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug_not?: InputMaybe<Scalars["String"]>;
  categorySlug_not_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  nameTitleCased?: InputMaybe<Scalars["String"]>;
  nameTitleCased_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_exists?: InputMaybe<Scalars["Boolean"]>;
  nameTitleCased_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  nameTitleCased_not?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfTopicNestedFilter>>>;
  searchPath?: InputMaybe<Scalars["String"]>;
  searchPath_contains?: InputMaybe<Scalars["String"]>;
  searchPath_exists?: InputMaybe<Scalars["Boolean"]>;
  searchPath_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  searchPath_not?: InputMaybe<Scalars["String"]>;
  searchPath_not_contains?: InputMaybe<Scalars["String"]>;
  searchPath_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulUpsellClassCardOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  CallToActionUrlAsc = "callToActionUrl_ASC",
  CallToActionUrlDesc = "callToActionUrl_DESC",
  CapacityMaxAsc = "capacityMax_ASC",
  CapacityMaxDesc = "capacityMax_DESC",
  CapacityMinAsc = "capacityMin_ASC",
  CapacityMinDesc = "capacityMin_DESC",
  MaxPriceCentsAsc = "maxPriceCents_ASC",
  MaxPriceCentsDesc = "maxPriceCents_DESC",
  MinPriceCentsAsc = "minPriceCents_ASC",
  MinPriceCentsDesc = "minPriceCents_DESC",
  MultiTermQueryAsc = "multiTermQuery_ASC",
  MultiTermQueryDesc = "multiTermQuery_DESC",
  QueryAsc = "query_ASC",
  QueryDesc = "query_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpsellNameAsc = "upsellName_ASC",
  UpsellNameDesc = "upsellName_DESC"
}

export enum ContentfulValuePropOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulVisualLinkOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC"
}

export enum ContentfulVisualLinkGridOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export enum ContentfulWhatIsOutschoolOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC"
}

export type ContentfulWhatIsOutschoolFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulWhatIsOutschoolFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  description?: InputMaybe<Scalars["String"]>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_not?: InputMaybe<Scalars["String"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulWhatIsOutschoolFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CouponCodeListFilter = {
  code?: InputMaybe<Scalars["String"]>;
  couponType: CouponType;
  couponUid?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<CouponListFilterStatus>;
};

export enum CouponListFilterStatus {
  Active = "Active",
  All = "All",
  Inactive = "Inactive"
}

export type CouponListFilter = {
  couponType: CouponType;
  createdBy?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<CouponListFilterStatus>;
};

export enum LearnerAccessLevel {
  Full = "Full",
  Limited = "Limited",
  Restricted = "Restricted"
}

export enum TeacherRecommendationType {
  CurrentLearnersFavoriteTeachers = "CurrentLearnersFavoriteTeachers",
  PopularAndCurrentLearnerFavoriteTeachers = "PopularAndCurrentLearnerFavoriteTeachers",
  PopularTeachers = "PopularTeachers",
  SimilarTeachers = "SimilarTeachers"
}

export type InterestTopicSearchFilters = {
  startAfter?: InputMaybe<Scalars["String"]>;
  startBefore?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type GetPayoutsCsvUrlInput = {
  leaderUserId?: InputMaybe<Scalars["String"]>;
  payoutMonth: Scalars["Int"];
  payoutYear: Scalars["Int"];
};

/**
 * Which direction to paginate in.
 * Past returns notifications between cursor and the start of time
 * Future returns notifications between cursor and the end of time
 */
export enum CursorDirection {
  Future = "Future",
  Past = "Past"
}

/** The status of the notification.  "Seen" is currently unused */
export enum NotificationStatus {
  New = "New",
  Read = "Read",
  Seen = "Seen"
}

/** Represents what type of class post notification happened */
export enum AssignmentNotificationAction {
  NewAssignment = "NewAssignment",
  NewAssignmentComment = "NewAssignmentComment"
}

/** Represents what type of class post notification happened */
export enum ClassPostNotificationAction {
  Mentioned = "Mentioned",
  NewClassPost = "NewClassPost",
  NewClassPostComment = "NewClassPostComment"
}

/** Represents what type of club notification happened */
export enum ClubNotificationAction {
  Mentioned = "Mentioned",
  PostPromoted = "PostPromoted",
  RepliedToComment = "RepliedToComment",
  RepliedToPost = "RepliedToPost"
}

export type LearnerActivityByTopicSearchInput = {
  activityExperiences?: InputMaybe<Array<ActivityExperience>>;
  topicUid?: InputMaybe<Scalars["ID"]>;
};

export enum ActivityExperience {
  Classroom = "Classroom",
  Club = "Club"
}

export type LearnerKeywordSearchInput = {
  activityExperiences?: InputMaybe<Array<ActivityExperience>>;
  query?: InputMaybe<Scalars["String"]>;
};

export type LearnerSearchSuggestionsInput = {
  query: Scalars["String"];
};

export enum LearnerSupportType {
  Glitch = "GLITCH",
  IFeelUnsafe = "I_FEEL_UNSAFE",
  OtherLearnerBadBehavior = "OTHER_LEARNER_BAD_BEHAVIOR",
  OtherLearnerNeedsHelp = "OTHER_LEARNER_NEEDS_HELP"
}

export enum MessageThreadAction {
  ParentToClass = "parentToClass",
  ParentToParent = "parentToParent",
  ParentToTeacher = "parentToTeacher",
  PrivateClassMessage = "privateClassMessage",
  TeacherToParent = "teacherToParent",
  TeacherToTeacher = "teacherToTeacher",
  Unknown = "unknown"
}

export enum ActivityReviewMode {
  Orgs = "orgs",
  Regular = "regular",
  Skipped = "skipped",
  Subject = "subject"
}

export enum OrganizationAdminFilter {
  Active = "Active",
  All = "All",
  Deleted = "Deleted"
}

export type DateFilterInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]>;
  toDate?: InputMaybe<Scalars["DateTime"]>;
};

export type SearchSuggestionsInput = {
  query: Scalars["String"];
};

export enum ConversationFilterType {
  Activity = "activity",
  Participant = "participant",
  Search = "search",
  Thread = "thread"
}

export enum CustomSearchUseCase {
  LearnerPlannerSearch = "LEARNER_PLANNER_SEARCH"
}

/**
 * Experimental
 * Different types of filters that could be applied on the server-side.
 * Used by the client to signal which types of auto applied filters
 * it does not want the server-side to apply.
 */
export enum AutoAppliedFilterType {
  GradeLevels = "GradeLevels"
}

export enum PromotedExperimentGroup {
  Control = "control",
  Holdout = "holdout",
  Treatment = "treatment"
}

export enum SearchObjective {
  AvailableListings = "available_listings",
  UnavailableListings = "unavailable_listings"
}

export enum SearchUseCase {
  CategoryContent = "CATEGORY_CONTENT",
  CdpAsClp = "CDP_AS_CLP",
  Custom = "CUSTOM",
  Discover = "DISCOVER",
  Feed = "FEED",
  Search = "SEARCH"
}

/**
 * Experimental
 * Only spelling suggestions for user search queries is currently supported
 */
export enum SpellingSuggestionType {
  SearchQuery = "SearchQuery"
}

/** Input representing a request for an activity based on filters */
export type SuggestActivityRequestInput = {
  /** A list of filters the suggested activity must meet */
  filters: Array<InputMaybe<SuggestActivityFilter>>;
  /**
   * A client generated unique identifier for the specific request. This is used for tracking and
   * to make it easier for the client to match suggested activity to the request.
   */
  uid: Scalars["String"];
};

/** Key value pair representing a filter for suggesting an activity */
export type SuggestActivityFilter = {
  type?: InputMaybe<SuggestActivityFilterType>;
  value: Scalars["String"];
};

/** Currently supported filters for suggesting activities */
export enum SuggestActivityFilterType {
  Age = "Age",
  CapacityMax = "CapacityMax",
  /** @deprecated Use Delivery for delivery and Format for format */
  ClassFormat = "ClassFormat",
  Delivery = "Delivery",
  Format = "Format",
  FundingPrograms = "FundingPrograms",
  GradeLevel = "GradeLevel",
  StartAfterDate = "StartAfterDate",
  StartBeforeDate = "StartBeforeDate",
  Subject = "Subject",
  Topic = "Topic"
}

export enum TransactionType {
  ClassWallet = "ClassWallet",
  Credit = "Credit",
  Order = "Order"
}

export type BulkOrderInput = {
  attribution?: InputMaybe<Attribution>;
  cartUid?: InputMaybe<Scalars["String"]>;
  charterParentInput?: InputMaybe<EnrollmentCharterParentInput>;
  currency_code?: InputMaybe<CurrencyCode>;
  enrollmentInputs: Array<BulkEnrollmentInput>;
  exchange_rate?: InputMaybe<Scalars["Float"]>;
  impressionId?: InputMaybe<Scalars["String"]>;
  learnerPlanUid?: InputMaybe<Scalars["ID"]>;
  message?: InputMaybe<Scalars["String"]>;
  organizationUid?: InputMaybe<Scalars["ID"]>;
  purchaseOrderCoverage?: InputMaybe<Scalars["DateTime"]>;
};

export type Attribution = {
  activity_uid?: InputMaybe<Scalars["String"]>;
  addressBar?: InputMaybe<Scalars["Boolean"]>;
  addressBarUserSlugId?: InputMaybe<Scalars["String"]>;
  anonymousId?: InputMaybe<Scalars["String"]>;
  awc?: InputMaybe<Scalars["String"]>;
  campaign?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  landingPage?: InputMaybe<Scalars["String"]>;
  manualReferralType?: InputMaybe<Scalars["String"]>;
  medium?: InputMaybe<Scalars["String"]>;
  referer?: InputMaybe<Scalars["String"]>;
  ruid?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  term?: InputMaybe<Scalars["String"]>;
  usid?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentCharterParentInput = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type BulkEnrollmentInput = {
  activityUid: Scalars["ID"];
  isGift?: InputMaybe<Scalars["Boolean"]>;
  isWeeklyPayment?: InputMaybe<Scalars["Boolean"]>;
  learner?: InputMaybe<EnrollmentLearnerInput>;
  ongoingEndTime?: InputMaybe<Scalars["DateTime"]>;
  ongoingStartTime?: InputMaybe<Scalars["DateTime"]>;
  pricing_offer?: InputMaybe<Scalars["String"]>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  selfPacedStartTime?: InputMaybe<Scalars["DateTime"]>;
  uid?: InputMaybe<Scalars["ID"]>;
};

export type EnrollmentLearnerInput = {
  age: Scalars["Int"];
  name: Scalars["String"];
  uid?: InputMaybe<Scalars["ID"]>;
};

export type CouponOrderInput = {
  activityUid: Scalars["ID"];
};

export type MeetingSuggestionInput = {
  endTime: Scalars["DateTime"];
  startTime: Scalars["DateTime"];
};

export type DeviceActivationInfo = {
  /** The app version of the device */
  appVersion: Scalars["String"];
  /** The token which authorizes us to send push notifications to this device */
  deviceToken: Scalars["String"];
  /**
   * If auth token is for a learner, you cannot set this value.  Learner uid is
   * implied based on the auth token provided.
   *
   * If auth token is for a user (parent) and learnerUid is set, the device
   * is associated with a specific learner, and we will only push notifications
   * for a given learner.  If it is not set, we will treat it as a "shared"
   * device and send notifications for all learners.
   */
  learnerUid?: InputMaybe<Scalars["ID"]>;
  /** The OS version of the device */
  osVersion: Scalars["String"];
  /** Which platform this device is on.  One of iOS or Android. */
  platform: DevicePlatform;
  /**
   * What categories of notifications are supported by this device.
   * The backend will only send categories of notifications that are in the provided array.
   * Defaults to [PrivateClassMessage, ClassPost, ClassPostComment, Meeting]
   */
  supportedNotifications?: InputMaybe<Array<SupportedNotifications>>;
};

export enum DevicePlatform {
  /** Device is Android, and we should use FCM */
  Android = "Android",
  /** Device is iOS, and we should use APNs */
  IOs = "iOS"
}

export enum SupportedNotifications {
  AssignmentPost = "AssignmentPost",
  AssignmentPostComment = "AssignmentPostComment",
  ClassPost = "ClassPost",
  ClassPostComment = "ClassPostComment",
  Meeting = "Meeting",
  PrivateClassMessage = "PrivateClassMessage",
  UserMessage = "UserMessage"
}

export type CreateAssignmentClassPostInput = {
  dueDate?: InputMaybe<Scalars["DateTime"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent: MessageContentInput;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid: Scalars["ID"];
  submissionRequirement?: InputMaybe<SubmissionRequirementType>;
  title: Scalars["String"];
};

export type MessageContentInput = {
  attachmentUids?: InputMaybe<Array<Scalars["ID"]>>;
  mentionsUids?: InputMaybe<Array<Scalars["String"]>>;
  text?: InputMaybe<Scalars["String"]>;
  textMimeType?: InputMaybe<TextMimeType>;
  videoUid?: InputMaybe<Scalars["ID"]>;
};

export type CreateAssignmentLessonPostInput = {
  lessonUid: Scalars["ID"];
  messageContent: MessageContentInput;
  orderInLesson: Scalars["Int"];
  title: Scalars["String"];
};

export type FileInput = {
  filename?: InputMaybe<Scalars["String"]>;
  handle?: InputMaybe<Scalars["ID"]>;
  mimetype?: InputMaybe<Scalars["String"]>;
  originalPath?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
  source?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type BulkGiftCardsInput = {
  amountCentsPerGiftCard: Scalars["Int"];
  numGiftCards: Scalars["Int"];
  paidForBy: Scalars["String"];
  purchasedForReason: Scalars["String"];
};

export type ChildInput = {
  age?: InputMaybe<Scalars["Int"]>;
  attributes?: InputMaybe<Array<LearnerAttribute>>;
  avatar?: InputMaybe<Scalars["String"]>;
  birthMonth?: InputMaybe<Scalars["Int"]>;
  birthYear?: InputMaybe<Scalars["Int"]>;
  date_of_birth?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  interestTopics?: InputMaybe<Array<Scalars["ID"]>>;
  intro?: InputMaybe<Scalars["String"]>;
  isLoginEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  optedOutOfPersonalizedRecommendationsAt?: InputMaybe<Scalars["DateTime"]>;
  password?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  raceEthnicity?: InputMaybe<Array<RaceEthnicityType>>;
  username?: InputMaybe<Scalars["String"]>;
};

export type LearnerAttribute = {
  key?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

export type ClassPostInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  messageContent: MessageContentInput;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid: Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
};

export type ClassPostCommentInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  classPostUid: Scalars["ID"];
  learnerUid?: InputMaybe<Scalars["ID"]>;
  messageContent: MessageContentInput;
};

export type ClassPostCommentReplyInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  classPostUid: Scalars["ID"];
  messageContentInput: MessageContentInput;
  threadParentCommentUid: Scalars["ID"];
};

export type ClassPostForLessonInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent: MessageContentInput;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid: Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
};

export type DonationInput = {
  amountCents: Scalars["Int"];
  orderSlugId: Scalars["ID"];
};

export type ExternalResourceInput = {
  name?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type GiftCardInputWithDeliveryDate = {
  amountCents: Scalars["Int"];
  deliverAfter?: InputMaybe<Scalars["DateTime"]>;
  deliveryTimezone: Scalars["String"];
  note?: InputMaybe<Scalars["String"]>;
  orderSlugId: Scalars["ID"];
  recipientEmail: Scalars["String"];
  recipientName: Scalars["String"];
  senderEmail: Scalars["String"];
  senderName: Scalars["String"];
};

export type CreateLessonPostInput = {
  lessonUid: Scalars["ID"];
  messageContent: MessageContentInput;
  orderInLesson: Scalars["Int"];
  title: Scalars["String"];
};

export type PrivateClassMessageInput = {
  learnerUid: Scalars["ID"];
  messageContentInput: MessageContentInput;
  sectionUid: Scalars["ID"];
  threadUid?: InputMaybe<Scalars["ID"]>;
};

export type UserQuizTypeAndValueInput = {
  type: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
  /**
   * example:
   * [
   *   [{type: "gradeLevel", value: 9}, {type: "subject", value: "math"}],
   *   [{type: "gradeLevel", value: 9}, {type: "subject", value: "english"}]
   * ]
   */
  valueLists?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<UserQuizTypeAndValue>>>>
  >;
};

export type UserQuizTypeAndValue = {
  type: Scalars["String"];
  value: Scalars["String"];
};

export type UnavailableBlockInput = {
  endTime: Scalars["DateTime"];
  startTime: Scalars["DateTime"];
};

export type TeacherTagInput = {
  uid: Scalars["ID"];
};

export type CreateAccountInput = {
  learnerAge?: InputMaybe<Scalars["Int"]>;
  learnerEmail?: InputMaybe<Scalars["String"]>;
  learnerName?: InputMaybe<Scalars["String"]>;
  learnerPassword?: InputMaybe<Scalars["String"]>;
  parentEmail?: InputMaybe<Scalars["String"]>;
  parentName?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
};

export type BulkCreateAccountOptions = {
  defaultTimeZone?: InputMaybe<Scalars["String"]>;
  learnerUsesParentMode?: InputMaybe<Scalars["Boolean"]>;
  persistChanges?: InputMaybe<Scalars["Boolean"]>;
  sendCoppaEmails?: InputMaybe<Scalars["Boolean"]>;
  unsubscribe?: InputMaybe<Scalars["Boolean"]>;
};

export type OsRefInput = {
  a8?: InputMaybe<Scalars["String"]>;
  anonymousId?: InputMaybe<Scalars["String"]>;
  attributionVersionNumber?: InputMaybe<Scalars["Int"]>;
  cookieAddressBarTrackingId?: InputMaybe<Scalars["String"]>;
  cookieAddressBarUserSlugId?: InputMaybe<Scalars["String"]>;
  couponCode?: InputMaybe<Scalars["String"]>;
  currentUrl?: InputMaybe<Scalars["String"]>;
  landingPage?: InputMaybe<Scalars["String"]>;
  noResetReason?: InputMaybe<Scalars["String"]>;
  originalReferrer?: InputMaybe<Scalars["String"]>;
  otherAddressBarTrackingId?: InputMaybe<Scalars["String"]>;
  otherAddressBarUserSlugId?: InputMaybe<Scalars["String"]>;
  pageViewNumberOfSession?: InputMaybe<Scalars["Int"]>;
  previousUrl?: InputMaybe<Scalars["String"]>;
  resetReason?: InputMaybe<Scalars["String"]>;
  resetTimestamp?: InputMaybe<Scalars["String"]>;
  ruid?: InputMaybe<Scalars["String"]>;
  trackingSessionUid?: InputMaybe<Scalars["String"]>;
  usid?: InputMaybe<Scalars["String"]>;
  utm_campaign?: InputMaybe<Scalars["String"]>;
  utm_content?: InputMaybe<Scalars["String"]>;
  utm_medium?: InputMaybe<Scalars["String"]>;
  utm_source?: InputMaybe<Scalars["String"]>;
  utm_term?: InputMaybe<Scalars["String"]>;
};

export type TimetableInput = {
  fri: TimetableTimesInput;
  mon: TimetableTimesInput;
  sat: TimetableTimesInput;
  sun: TimetableTimesInput;
  thu: TimetableTimesInput;
  tue: TimetableTimesInput;
  wed: TimetableTimesInput;
};

export type TimetableTimesInput = {
  f12_4: Scalars["Boolean"];
  f4_8: Scalars["Boolean"];
  f8_12: Scalars["Boolean"];
};

export type CouponActivityConstraintsInput = {
  activitiesUids: Array<Scalars["ID"]>;
};

export type CouponCreateInput = {
  classFormatsAllowed?: InputMaybe<Array<InputMaybe<CouponClassFormats>>>;
  couponType: CouponType;
  description?: InputMaybe<Scalars["String"]>;
  expiresAt: Scalars["DateTime"];
  isBuyerFirstPurchaseOfClassFormatOnly?: InputMaybe<Scalars["Boolean"]>;
  isFirstPurchaseOnly: Scalars["Boolean"];
  maxAmountCentsPerUse?: InputMaybe<Scalars["Int"]>;
  maxUses?: InputMaybe<Scalars["Int"]>;
  maxUsesPerUser?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  percentOff?: InputMaybe<Scalars["Int"]>;
  startsAt: Scalars["DateTime"];
  subjectsAllowed?: InputMaybe<Array<Scalars["String"]>>;
};

export type CouponCodeCreateInput = {
  code: Scalars["String"];
};

export type CreateCreditPurchaseOrderInput = {
  amountCents: Scalars["Int"];
  amountGrantedCents: Scalars["Int"];
};

export type EnrollmentMeetingPurchaseOrderInput = {
  activityUid: Scalars["ID"];
  attribution?: InputMaybe<Attribution>;
  charterParentInput?: InputMaybe<EnrollmentCharterParentInput>;
  currency_code?: InputMaybe<CurrencyCode>;
  endTime?: InputMaybe<Scalars["DateTime"]>;
  enrollmentInputs: Array<EnrollmentInput>;
  enrollmentUid?: InputMaybe<Scalars["ID"]>;
  exchange_rate?: InputMaybe<Scalars["Float"]>;
  ignoresTeacherAvailability?: InputMaybe<Scalars["Boolean"]>;
  impressionId?: InputMaybe<Scalars["String"]>;
  meetingRequestUids?: InputMaybe<Array<Scalars["String"]>>;
  message?: InputMaybe<Scalars["String"]>;
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  prepayDurationWeeks?: InputMaybe<Scalars["Int"]>;
  prepayMeetings?: InputMaybe<Array<FutureMeetingInputInfo>>;
  startTime?: InputMaybe<Scalars["DateTime"]>;
};

export type EnrollmentInput = {
  amountInLocalCurrency?: InputMaybe<Scalars["Int"]>;
  isGift?: InputMaybe<Scalars["Boolean"]>;
  learner?: InputMaybe<EnrollmentLearnerInput>;
  price_cents: Scalars["Int"];
  pricing_offer?: InputMaybe<Scalars["String"]>;
};

export type FutureMeetingInputInfo = {
  end_time: Scalars["DateTime"];
  start_time: Scalars["DateTime"];
};

export type OrderInput = {
  activityUid: Scalars["ID"];
  amount_in_local_currency?: InputMaybe<Scalars["Int"]>;
  attribution?: InputMaybe<Attribution>;
  charterParentInput?: InputMaybe<EnrollmentCharterParentInput>;
  charterSchoolUid?: InputMaybe<Scalars["ID"]>;
  currency_code?: InputMaybe<CurrencyCode>;
  enrollmentInputs: Array<EnrollmentInput>;
  exchange_rate?: InputMaybe<Scalars["Float"]>;
  impressionId?: InputMaybe<Scalars["String"]>;
  isWeeklyPayment?: InputMaybe<Scalars["Boolean"]>;
  message?: InputMaybe<Scalars["String"]>;
  ongoingEndTime?: InputMaybe<Scalars["DateTime"]>;
  ongoingStartTime?: InputMaybe<Scalars["DateTime"]>;
  organizationUid?: InputMaybe<Scalars["ID"]>;
  purchaseOrderCoverage?: InputMaybe<Scalars["DateTime"]>;
  schoolClosure?: InputMaybe<SchoolClosureInput>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  selfPacedStartTime?: InputMaybe<Scalars["DateTime"]>;
};

export type SchoolClosureInput = {
  affected?: InputMaybe<Scalars["Boolean"]>;
  city: Scalars["String"];
  covid: Scalars["Boolean"];
  household_size: Scalars["Int"];
  income: Scalars["String"];
  lunch: Scalars["Boolean"];
  name: Scalars["String"];
  noschool: Scalars["Boolean"];
  school_closed?: InputMaybe<Scalars["Boolean"]>;
  state: Scalars["String"];
};

export type CreateLearnerLearningStatementsInput = {
  learnerUid: Scalars["ID"];
  objectType: LearningStatementsObjectType;
  objectUid: Scalars["String"];
  verb: LearningStatementsVerb;
};

export enum LearningStatementsObjectType {
  AssignmentClassPost = "AssignmentClassPost",
  LearningStatement = "LearningStatement",
  Lesson = "Lesson"
}

export type CreateLearnerPlanScheduleInput = {
  entityAvailabilities?: InputMaybe<Array<EntityAvailabilityInput>>;
  learnerPlanUid: Scalars["ID"];
};

export type EntityAvailabilityInput = {
  day: Scalars["Int"];
  endHour: Scalars["Int"];
  endMinute: Scalars["Int"];
  startHour: Scalars["Int"];
  startMinute: Scalars["Int"];
};

export type MeetingDetails = {
  end_time: Scalars["DateTime"];
  lesson_uid?: InputMaybe<Scalars["ID"]>;
  start_time: Scalars["DateTime"];
};

export type CreateBundleInput = {
  bundleElements: Array<NewBundleElementInput>;
  description?: InputMaybe<Scalars["String"]>;
  isOrdered: Scalars["Boolean"];
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  title: Scalars["String"];
};

export type NewBundleElementInput = {
  activityUid: Scalars["ID"];
  orderInBundle: Scalars["Int"];
};

export type CreateBundleElementInput = {
  activityUid: Scalars["ID"];
  bundleUid: Scalars["ID"];
  orderInBundle: Scalars["Int"];
};

export type CreateOrganizationInput = {
  address?: InputMaybe<Scalars["String"]>;
  invoiceEmail?: InputMaybe<Scalars["String"]>;
  logoUrl?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  techSupportEmail?: InputMaybe<Scalars["String"]>;
  totalCapCents?: InputMaybe<Scalars["Int"]>;
  trustSafetyEmail?: InputMaybe<Scalars["String"]>;
  type: OrganizationType;
  urlName?: InputMaybe<Scalars["String"]>;
};

export type FeedbackInput = {
  activity_star_rating: Scalars["Int"];
  attachments?: InputMaybe<Array<Scalars["ID"]>>;
  attachmentUids?: InputMaybe<Array<Scalars["ID"]>>;
  attribution?: InputMaybe<Attribution>;
  leaderFeedback?: InputMaybe<Scalars["String"]>;
  outschool_rating?: InputMaybe<Scalars["Int"]>;
  outschoolFeedback?: InputMaybe<Scalars["String"]>;
  publicReview?: InputMaybe<Scalars["String"]>;
  section_uid: Scalars["ID"];
};

export type LearnerSectionReviewInput = {
  attribution?: InputMaybe<Attribution>;
  enrollmentUid: Scalars["ID"];
  name: ReviewName;
  response: LearnerReviewResponseInput;
};

export enum ReviewName {
  InterestedInTakingMoreClassesAboutTopic = "interestedInTakingMoreClassesAboutTopic",
  SoughtOutWaysToLearnMoreAboutTopic = "soughtOutWaysToLearnMoreAboutTopic"
}

export type LearnerReviewResponseInput = {
  vote?: InputMaybe<Scalars["String"]>;
};

export type CreatePaymentScheduleInput = {
  initialPaymentAmountCents?: InputMaybe<Scalars["Int"]>;
  initialPaymentRelativePeriod?: InputMaybe<RelativePeriodOptions>;
  lineItemUid: Scalars["String"];
  orderSlugId?: InputMaybe<Scalars["String"]>;
  recurringPaymentAmountCents: Scalars["Int"];
  scheduledPaymentLineItemType: ScheduledPaymentLineItemType;
  scheduleEndTime?: InputMaybe<Scalars["DateTime"]>;
  scheduleStartTime: Scalars["DateTime"];
  scheduleType: PaymentScheduleTypes;
};

export enum RelativePeriodOptions {
  Current = "Current",
  Next = "Next",
  Previous = "Previous"
}

export enum ScheduledPaymentLineItemType {
  ClubSubscriptions = "ClubSubscriptions",
  OneOnOneSchedules = "OneOnOneSchedules",
  WeeklyClassPayments = "WeeklyClassPayments"
}

export type CreatePricingOfferInput = {
  buyerOrgName: Scalars["String"];
  discount?: InputMaybe<Scalars["Float"]>;
  eligibleEmailDomains: Array<Scalars["String"]>;
  endBy: Scalars["DateTime"];
  excludedEmailAddresses: Array<Scalars["String"]>;
  includedEmailAddresses: Array<Scalars["String"]>;
  isFinancialAid: Scalars["Boolean"];
  organizationUid?: InputMaybe<Scalars["ID"]>;
  requirePaymentMethod: Scalars["Boolean"];
  startAfter: Scalars["DateTime"];
  totalCapCents: Scalars["Int"];
  userLimitCents: Scalars["Int"];
};

export type CollegeDegreeCredentialInput = {
  degreeAdditionalInfo?: InputMaybe<Scalars["String"]>;
  degreeInstitution: Scalars["String"];
  degreeIssueDate?: InputMaybe<Scalars["DateTime"]>;
  degreeSubject: DegreeSubject;
  degreeType: DegreeType;
};

export type CredentialFileInput = {
  filename?: InputMaybe<Scalars["String"]>;
  handle?: InputMaybe<Scalars["ID"]>;
  mimetype?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
  source?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type OtherCredentialInput = {
  otherDetails: Scalars["String"];
  otherType: Scalars["String"];
};

export type TeacherCertificateCredentialInput = {
  certificateDiscipline: TeacherCertificateDiscipline;
  certificateDoesNotExpire?: InputMaybe<Scalars["Boolean"]>;
  certificateExpireDate?: InputMaybe<Scalars["DateTime"]>;
  certificateIssueDate?: InputMaybe<Scalars["DateTime"]>;
  certificateNumber: Scalars["String"];
  certificateSecondaryDiscipline?: InputMaybe<TeacherCertificateDiscipline>;
  certificateState: TeacherCertificateState;
};

export type CreateWaitlistSeatInput = {
  learner: WaitlistLearner;
  parentUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
  waitlistSource?: InputMaybe<WaitlistSource>;
};

export type WaitlistLearner = {
  age: Scalars["Int"];
  name: Scalars["String"];
  uid?: InputMaybe<Scalars["String"]>;
};

export enum ReportLength {
  /** Two or three paragraphs "long" */
  FewParagraphs = "FewParagraphs",
  /** Only one paragraph "short" */
  OneParagraph = "OneParagraph"
}

export type PostLoginOption = {
  defaultLearnerAge?: InputMaybe<Scalars["Int"]>;
  isOnboardingSignup?: InputMaybe<Scalars["Boolean"]>;
  shouldCreateDefaultLearner?: InputMaybe<Scalars["Boolean"]>;
};

/** Which client is attempting to log in */
export enum LoginClient {
  IOsLearnerApp = "iOSLearnerApp",
  Web = "Web"
}

export type CreateAccountWithProviderInput = {
  attribution?: InputMaybe<Attribution>;
  browserTimeZone?: InputMaybe<Scalars["String"]>;
  defaultLearnerAge?: InputMaybe<Scalars["Int"]>;
  isGiftCardSignup?: InputMaybe<Scalars["Boolean"]>;
  isLeader?: InputMaybe<Scalars["Boolean"]>;
  isOnboardingSignup?: InputMaybe<Scalars["Boolean"]>;
  shouldCreateDefaultLearner?: InputMaybe<Scalars["Boolean"]>;
  subscribe?: InputMaybe<Scalars["Boolean"]>;
};

export enum CreateOrLoginError {
  AccountAlreadyExists = "AccountAlreadyExists",
  AccountAlreadyLinked = "AccountAlreadyLinked",
  AccountCreationNotAllowed = "AccountCreationNotAllowed",
  AccountLocked = "AccountLocked",
  AccountNotFound = "AccountNotFound",
  AccountSuspended = "AccountSuspended",
  IncorrectApp = "IncorrectApp",
  InvalidEmail = "InvalidEmail",
  InvalidName = "InvalidName",
  InvalidPermissions = "InvalidPermissions",
  LearnerAccountLinkFailed = "LearnerAccountLinkFailed",
  SocialLoginNotAllowed = "SocialLoginNotAllowed",
  UnknownError = "UnknownError"
}

export enum ExternalActivitySource {
  Mux = "mux"
}

export type PauseOngoingEnrollmentInput = {
  dates: Array<DateRange>;
  enrollmentUid: Scalars["ID"];
};

export type DateRange = {
  endDate: Scalars["DateTime"];
  startDate: Scalars["DateTime"];
};

export enum TokenRefreshSource {
  Apollo = "Apollo",
  Client = "Client",
  LearnerApp = "LearnerApp"
}

export enum RefundType {
  AddOutschoolCredit = "AddOutschoolCredit",
  StandardRefund = "StandardRefund"
}

export type LearnerProfileInput = {
  aboutMe?: InputMaybe<Scalars["String"]>;
  avatar?: InputMaybe<Scalars["String"]>;
};

export type LearnerProfileSettingsInput = {
  isProfileAgeHidden?: InputMaybe<Scalars["Boolean"]>;
  isProfileLocationHidden?: InputMaybe<Scalars["Boolean"]>;
};

export type MessageInput = {
  activityUid?: InputMaybe<Scalars["ID"]>;
  content?: InputMaybe<Scalars["String"]>;
  details: MessageInputDetails;
  messageContentInput?: InputMaybe<MessageContentInput>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
};

export type MessageInputDetails = {
  action: Scalars["String"];
  activityTitle?: InputMaybe<Scalars["String"]>;
  activityUrl?: InputMaybe<Scalars["String"]>;
  classScheduleRequestUid?: InputMaybe<Scalars["ID"]>;
  leaderName?: InputMaybe<Scalars["ID"]>;
  leaderUid?: InputMaybe<Scalars["ID"]>;
  parentName?: InputMaybe<Scalars["String"]>;
  parentUid?: InputMaybe<Scalars["ID"]>;
  section?: InputMaybe<Scalars["String"]>;
  sectionUrl?: InputMaybe<Scalars["String"]>;
  studentName?: InputMaybe<Scalars["String"]>;
  subject?: InputMaybe<Scalars["String"]>;
  teacherAdminUrl?: InputMaybe<Scalars["String"]>;
};

export type ProgressReportDetailsInput = {
  didChangeGeneratedReport: Scalars["Boolean"];
  didGenerateReport: Scalars["Boolean"];
  originalNotes: Scalars["String"];
  reportLength: ReportLength;
};

export type PurchaseRecurringMeetingsInfo = {
  /**
   * The total number of weeks this enrollment time should repeat. If omitted, the enrollment
   * time will be considered continuous and repeated until either the parent or teacher
   * cancel the enrollment time.
   */
  durationWeeks?: InputMaybe<Scalars["Int"]>;
};

export type StopPaymentScheduleInput = {
  scheduledPaymentLineItemType: ScheduledPaymentLineItemType;
  scheduleUid: Scalars["String"];
  stoppedReason: Scalars["String"];
};

export type UserSavedActivity = {
  activityUid: Scalars["ID"];
  savedAt: Scalars["DateTime"];
};

export enum SurpriseMeVariants {
  Random = "Random",
  Recommendations = "Recommendations"
}

export type TrackAnalyticsInitializedProperties = {
  analyticsLoadDuration?: InputMaybe<Scalars["Int"]>;
  analyticsMode?: InputMaybe<Scalars["String"]>;
  analyticsModeSource?: InputMaybe<Scalars["String"]>;
  analyticsModeSourceOverride?: InputMaybe<Scalars["String"]>;
  analyticsStatus?: InputMaybe<Scalars["String"]>;
  app?: InputMaybe<Scalars["String"]>;
  botCheck?: InputMaybe<Scalars["Boolean"]>;
  cloudflareBotScore?: InputMaybe<Scalars["Int"]>;
  cloudflareBotVerified?: InputMaybe<Scalars["Boolean"]>;
  cloudflareThreatScore?: InputMaybe<Scalars["Int"]>;
  deviceUid?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Int"]>;
  initializationDuration?: InputMaybe<Scalars["Int"]>;
  isBot?: InputMaybe<Scalars["Boolean"]>;
  isMobile?: InputMaybe<Scalars["Boolean"]>;
  modeResolutionDuration?: InputMaybe<Scalars["Int"]>;
  pageCategory?: InputMaybe<Scalars["String"]>;
  pageImpressionId?: InputMaybe<Scalars["String"]>;
  pageLoadId?: InputMaybe<Scalars["String"]>;
  pageName?: InputMaybe<Scalars["String"]>;
  pagePath?: InputMaybe<Scalars["String"]>;
  pluginLoadDuration?: InputMaybe<Scalars["Int"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type TrackClassPageImpressionInput = {
  activityUid: Scalars["String"];
  sectionUid?: InputMaybe<Scalars["String"]>;
};

export type TrackNewBuyerAcquiredProperties = {
  amount: Scalars["Int"];
  fbp?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
};

export type TrackOrderCompletedProperties = {
  cartUid?: InputMaybe<Scalars["String"]>;
  enrollmentConfirmedAt: Scalars["DateTime"];
  fbp?: InputMaybe<Scalars["String"]>;
  isBulkCheckout?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  orderId: Scalars["String"];
  osRef?: InputMaybe<OsRefInput>;
  price: Scalars["Int"];
  productId: Scalars["String"];
  quantity: Scalars["Int"];
  total: Scalars["Int"];
};

export type TrackPageLoadProperties = {
  anonymousId?: InputMaybe<Scalars["String"]>;
  app?: InputMaybe<Scalars["String"]>;
  cloudflare_bot_score?: InputMaybe<Scalars["Int"]>;
  cloudflare_bot_verified?: InputMaybe<Scalars["Boolean"]>;
  cloudflare_threat_score?: InputMaybe<Scalars["Int"]>;
  is_bot?: InputMaybe<Scalars["Boolean"]>;
  isMobile?: InputMaybe<Scalars["Boolean"]>;
  loggedInUserExperimentUid?: InputMaybe<Scalars["String"]>;
  loggedOutUserExperimentUid?: InputMaybe<Scalars["String"]>;
  pageLoadId?: InputMaybe<Scalars["String"]>;
  pageName?: InputMaybe<Scalars["String"]>;
  pagePath?: InputMaybe<Scalars["String"]>;
};

export type TrackUserAttributionSurveyResponseInput = {
  response: Scalars["String"];
};

export type TrackUserCreatedProperties = {
  fbp?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
};

export type UnpauseOngoingEnrollmentsPausedForPaymentFailureInput = {
  enrollmentUids: Array<Scalars["ID"]>;
  pauseEndDate?: InputMaybe<Scalars["Date"]>;
};

export type ActivityDetailsInput = {
  asyncTime?: InputMaybe<Scalars["String"]>;
  materials?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["String"]>;
  unpublishReason?: InputMaybe<Scalars["String"]>;
};

export type ActivityInput = {
  age_max?: InputMaybe<Scalars["Int"]>;
  age_min?: InputMaybe<Scalars["Int"]>;
  allows_late_enrollment?: InputMaybe<Scalars["Boolean"]>;
  allows_late_enrollments_for_fixed_length_classes?: InputMaybe<
    Scalars["Boolean"]
  >;
  allows_recurring_payment?: InputMaybe<Scalars["Boolean"]>;
  assessment?: InputMaybe<Scalars["String"]>;
  attributes?: InputMaybe<Array<EntityAttributeInput>>;
  auto_scheduling_days_notice_max?: InputMaybe<Scalars["Int"]>;
  auto_scheduling_days_notice_min?: InputMaybe<Scalars["Int"]>;
  class_experience?: InputMaybe<Scalars["String"]>;
  classFormat?: InputMaybe<EducatorSelectedFormat>;
  club_banner_image_uid?: InputMaybe<Scalars["ID"]>;
  club_welcome_video_uid?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  disclaimer?: InputMaybe<Scalars["String"]>;
  duration_minutes?: InputMaybe<Scalars["Int"]>;
  duration_weeks?: InputMaybe<Scalars["Int"]>;
  english_proficiency_level?: InputMaybe<EnglishProficiencyLevel>;
  grade_level_max?: InputMaybe<Scalars["String"]>;
  grade_level_min?: InputMaybe<Scalars["String"]>;
  grading_policy?: InputMaybe<Scalars["String"]>;
  has_building_content?: InputMaybe<Scalars["Boolean"]>;
  homework?: InputMaybe<Scalars["String"]>;
  is_auto_scheduling_enabled?: InputMaybe<Scalars["Boolean"]>;
  is_ongoing_weekly?: InputMaybe<Scalars["Boolean"]>;
  is_online?: InputMaybe<Scalars["Boolean"]>;
  is_waitlist_allowed?: InputMaybe<Scalars["Boolean"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  isSelfPaced?: InputMaybe<Scalars["Boolean"]>;
  isSelfPacedEnrollmentEnabled?: InputMaybe<Scalars["Boolean"]>;
  language_of_instruction?: InputMaybe<Scalars["Iso639"]>;
  learning_goals?: InputMaybe<Scalars["String"]>;
  parental_guidance?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["String"]>;
  prerequisites?: InputMaybe<Scalars["String"]>;
  price_cents?: InputMaybe<Scalars["Int"]>;
  pricingAdjustments?: InputMaybe<PricingAdjustmentInput>;
  proficiency_level?: InputMaybe<ProficiencyLevelInput>;
  refund_policy_type?: InputMaybe<RefundPolicyType>;
  sample_club_post?: InputMaybe<WelcomeMessageInput>;
  sampleClubPosts?: InputMaybe<Array<SampleClubPostInput>>;
  selfPacedMigration?: InputMaybe<SelfPacedMigrationStatusInput>;
  size_max?: InputMaybe<Scalars["Int"]>;
  size_min?: InputMaybe<Scalars["Int"]>;
  sources?: InputMaybe<Scalars["String"]>;
  strictAgeRange?: InputMaybe<AgeRangeInput>;
  subject?: InputMaybe<Scalars["String"]>;
  suggestedWeeklyMeetings?: InputMaybe<Scalars["Int"]>;
  summary?: InputMaybe<Scalars["String"]>;
  summary_for_learners?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  upsell_activity_uid?: InputMaybe<Scalars["ID"]>;
  video_for_learners_uid?: InputMaybe<Scalars["ID"]>;
  video_uid?: InputMaybe<Scalars["ID"]>;
  weekly_meetings?: InputMaybe<Scalars["Int"]>;
  welcome_message?: InputMaybe<WelcomeMessageInput>;
  why_me?: InputMaybe<Scalars["String"]>;
};

export type EntityAttributeInput = {
  key: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
};

export type PricingAdjustmentInput = {
  weeklySurchargePercentage?: InputMaybe<Scalars["Float"]>;
};

export type ProficiencyLevelInput = {
  level?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  text?: InputMaybe<Scalars["String"]>;
};

export type WelcomeMessageInput = {
  attachments?: InputMaybe<Array<Scalars["ID"]>>;
  text?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["ID"]>;
};

export type SampleClubPostInput = {
  attachments?: InputMaybe<Array<Scalars["ID"]>>;
  comments?: InputMaybe<Array<SampleClubPostCommentInput>>;
  text?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["ID"]>;
};

export type SampleClubPostCommentInput = {
  text: Scalars["String"];
};

export type SelfPacedMigrationStatusInput = {
  gitCommitSha: Scalars["String"];
  hasConsented: Scalars["Boolean"];
};

export type AgeRangeInput = {
  max: Scalars["Int"];
  min: Scalars["Int"];
};

export type ActivityAdminDetailsInput = {
  doNotPromote?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateAssignmentClassPostInput = {
  dueDate?: InputMaybe<Scalars["DateTime"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContentInput?: InputMaybe<MessageContentInput>;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  submissionRequirement?: InputMaybe<SubmissionRequirementType>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateAssignmentLessonPostInput = {
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent?: InputMaybe<MessageContentInput>;
  orderInLesson?: InputMaybe<Scalars["Int"]>;
  submissionRequirement?: InputMaybe<SubmissionRequirementType>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateBundleInput = {
  description?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Float"]>;
  isOrdered?: InputMaybe<Scalars["Boolean"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  thumbnail?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  /**
   * Some context for expected fields inside of updatedBundleElements:
   * if a BundleElement existed before this update mutation expects the object to have a uid, a activityUid and orderInBundle
   * if a BundleElement is new: the mutation expects the object to have a activityUid and orderInBundle
   */
  updatedBundleElements: Array<UpdateBundleElementInput>;
};

export type UpdateBundleElementInput = {
  activityUid: Scalars["ID"];
  orderInBundle: Scalars["Int"];
  uid?: InputMaybe<Scalars["ID"]>;
};

export type ClassRequestAdminInput = {
  content?: InputMaybe<Scalars["String"]>;
  uid: Scalars["ID"];
};

export type CouponUpdateInput = {
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UserDetailsInput = {
  about?: InputMaybe<Scalars["String"]>;
  browserTimeZone?: InputMaybe<Scalars["String"]>;
  countryOfResidence?: InputMaybe<Scalars["String"]>;
  headline?: InputMaybe<Scalars["String"]>;
  howFoundOutschool?: InputMaybe<Scalars["String"]>;
  howFoundOutschoolOther?: InputMaybe<Scalars["String"]>;
  introduction?: InputMaybe<Scalars["String"]>;
  socialOptOut?: InputMaybe<Scalars["Boolean"]>;
  timeRangeAfterSchool?: InputMaybe<Scalars["Boolean"]>;
  timeRangeSchool?: InputMaybe<Scalars["Boolean"]>;
  timeRangeWeekend?: InputMaybe<Scalars["Boolean"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type LocationInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  geo?: InputMaybe<Scalars["String"]>;
  lat?: InputMaybe<Scalars["Float"]>;
  lng?: InputMaybe<Scalars["Float"]>;
  placeDescription?: InputMaybe<Scalars["String"]>;
  placeName?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type TeacherAttributesInput = {
  autoSchedulingBufferMinutes?: InputMaybe<Scalars["Int"]>;
  isAutoSchedulingEnabled?: InputMaybe<Scalars["Boolean"]>;
  mainToolbarPinnedTabs?: InputMaybe<Array<MainToolbarPinnedTabs>>;
  showCalendar?: InputMaybe<Scalars["Boolean"]>;
};

export type AdminEnrollmentPaymentRefundInput = {
  action: AdminEnrollmentPaymentRefundActionType;
  adminNoteType?: InputMaybe<AdminEnrollmentPaymentRefundNoteType>;
  notes?: InputMaybe<Scalars["String"]>;
};

export enum AdminEnrollmentPaymentRefundActionType {
  ReducesTeacherPayoutFalse = "REDUCES_TEACHER_PAYOUT_FALSE",
  ReducesTeacherPayoutTrue = "REDUCES_TEACHER_PAYOUT_TRUE"
}

export enum AdminEnrollmentPaymentRefundNoteType {
  RefundUpdateAuditLog = "REFUND_UPDATE_AUDIT_LOG"
}

export type EnrollmentPaymentRefundInput = {
  reason: Scalars["String"];
  reduces_teacher_payout: Scalars["Boolean"];
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
};

/** uid - entity availability schedule uid */
export type UpdateEntityAvailabilityScheduleInput = {
  entityAvailabilities: Array<EntityAvailabilityInput>;
  uid: Scalars["ID"];
};

export type GiftCardUpdateInput = {
  deliverAfter?: InputMaybe<Scalars["DateTime"]>;
  note?: InputMaybe<Scalars["String"]>;
  recipientEmail?: InputMaybe<Scalars["String"]>;
  recipientName?: InputMaybe<Scalars["String"]>;
  senderEmail?: InputMaybe<Scalars["String"]>;
  senderName?: InputMaybe<Scalars["String"]>;
};

export type LessonInput = {
  description?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  unitUid?: InputMaybe<Scalars["ID"]>;
};

export type UpdateLessonPostInput = {
  isPostedForEnrollments?: InputMaybe<Scalars["Boolean"]>;
  isPostedForSubscriptions?: InputMaybe<Scalars["Boolean"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent?: InputMaybe<MessageContentInput>;
  orderInLesson?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateOrganizationInput = {
  address?: InputMaybe<Scalars["String"]>;
  invoiceEmail?: InputMaybe<Scalars["String"]>;
  logoUrl?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  techSupportEmail?: InputMaybe<Scalars["String"]>;
  totalCapCents?: InputMaybe<Scalars["Int"]>;
  trustSafetyEmail?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<OrganizationType>;
  urlName?: InputMaybe<Scalars["String"]>;
};

export type UpdatePricingOfferInput = {
  buyerOrgName?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Float"]>;
  eligibleEmailDomains?: InputMaybe<Array<Scalars["String"]>>;
  endBy?: InputMaybe<Scalars["DateTime"]>;
  excludedEmailAddresses?: InputMaybe<Array<Scalars["String"]>>;
  includedEmailAddresses?: InputMaybe<Array<Scalars["String"]>>;
  isFinancialAid?: InputMaybe<Scalars["Boolean"]>;
  organizationUid?: InputMaybe<Scalars["ID"]>;
  requirePaymentMethod?: InputMaybe<Scalars["Boolean"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  totalCapCents?: InputMaybe<Scalars["Int"]>;
  userLimitCents?: InputMaybe<Scalars["Int"]>;
};

export type ActivityScheduleSettingsInput = {
  maximumNotice?: InputMaybe<Scalars["String"]>;
  mininumNotice?: InputMaybe<Scalars["String"]>;
  suggestedWeeklyMeetings?: InputMaybe<Scalars["Int"]>;
};

export type LeaderSectionDetailInput = {
  date?: InputMaybe<Scalars["String"]>;
  originalEndTime?: InputMaybe<Scalars["DateTime"]>;
};

export type OnlineClassroomInput = {
  type?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type LeaderSectionInput = {
  end_time?: InputMaybe<Scalars["DateTime"]>;
  isCanceled?: InputMaybe<Scalars["Boolean"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  price_cents?: InputMaybe<Scalars["Int"]>;
  size_max?: InputMaybe<Scalars["Int"]>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  user_uid?: InputMaybe<Scalars["ID"]>;
};

export type SectionAdminInput = {
  notes?: InputMaybe<Scalars["String"]>;
};

export type UpdateSellerOrgAgreementInput = {
  agreementTemplateUid?: InputMaybe<Scalars["ID"]>;
  contractSignedBy?: InputMaybe<Scalars["String"]>;
  contractSignerTitle?: InputMaybe<Scalars["String"]>;
  legalAddress?: InputMaybe<Scalars["String"]>;
  legalEntityType?: InputMaybe<Scalars["String"]>;
  legalName?: InputMaybe<Scalars["String"]>;
  legalState?: InputMaybe<Scalars["String"]>;
  ownsBackgroundChecks?: InputMaybe<Scalars["Boolean"]>;
};

export type SellerOrgApplicationInput = {
  additionalInfo?: InputMaybe<Scalars["String"]>;
  alreadyApprovedTeacher?: InputMaybe<Scalars["Boolean"]>;
  alreadyApprovedTeacherEmail?: InputMaybe<Scalars["String"]>;
  classesInfo?: InputMaybe<Scalars["String"]>;
  countries?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  firstClassDescription?: InputMaybe<Scalars["String"]>;
  learnersInfo?: InputMaybe<Scalars["String"]>;
  learnerTypes?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  numberOfClassesPerWeek?: InputMaybe<Scalars["String"]>;
  numberOfStudentsOnAverage?: InputMaybe<Scalars["String"]>;
  numberOfTeachers?: InputMaybe<Scalars["String"]>;
  organizationPhone?: InputMaybe<Scalars["String"]>;
  primaryContactName?: InputMaybe<Scalars["String"]>;
  primaryContactTitle?: InputMaybe<Scalars["String"]>;
  publicReviews?: InputMaybe<Scalars["String"]>;
  sourcedFrom?: InputMaybe<Scalars["String"]>;
  teachersInfo?: InputMaybe<Scalars["String"]>;
  timeInBusiness?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  typeDetail?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
  whenFounded?: InputMaybe<Scalars["String"]>;
};

export type TeacherApplicationInput = {
  class_types?: InputMaybe<Scalars["String"]>;
  legal_name?: InputMaybe<Scalars["String"]>;
  online_profiles?: InputMaybe<Scalars["String"]>;
  sample_classes?: InputMaybe<Scalars["String"]>;
  teaching_credentials?: InputMaybe<Scalars["String"]>;
  teaching_experience?: InputMaybe<Scalars["String"]>;
  teaching_motivation?: InputMaybe<Scalars["String"]>;
  video_uid?: InputMaybe<Scalars["ID"]>;
};

export type AvailabilityInput = {
  hourlyTimetable: HourlyTimetableInput;
};

export type HourlyTimetableInput = {
  fri: Array<Scalars["Int"]>;
  mon: Array<Scalars["Int"]>;
  sat: Array<Scalars["Int"]>;
  sun: Array<Scalars["Int"]>;
  thu: Array<Scalars["Int"]>;
  tue: Array<Scalars["Int"]>;
  wed: Array<Scalars["Int"]>;
};

export type ScheduleInput = {
  bufferTime?: InputMaybe<Scalars["String"]>;
  meetingsStartTimeIncrementsInMinutes?: InputMaybe<Scalars["Int"]>;
};

export type AvailableTimesInput = {
  endTime: Scalars["DateTime"];
  isRecurring: Scalars["Boolean"];
  isUnavailable: Scalars["Boolean"];
  startTime: Scalars["DateTime"];
};

export type UnitInput = {
  title?: InputMaybe<Scalars["String"]>;
};

export type UserAdminInput = {
  is_crosslister?: InputMaybe<Scalars["Boolean"]>;
  leader_link?: InputMaybe<Scalars["String"]>;
};

export enum ValidateMeetingRequestsForPurchaseError {
  /** Cannot accept reschedule requests via Mutation.settleOrderEnrollmentMeetingPurchase.  Use Mutation.acceptMeetingReschedule */
  CannotAcceptRescheduleRequestsForPurchase = "CannotAcceptRescheduleRequestsForPurchase",
  /** Enrollment meeting purchase record not found */
  EnrollmentMeetingPurchaseRecordNotFound = "EnrollmentMeetingPurchaseRecordNotFound",
  /** One or more meeting requests have been deleted */
  MeetingRequestDeleted = "MeetingRequestDeleted",
  /** Meeting requests do not belong to purchased activity */
  MeetingRequestDoesNotBelongToActivity = "MeetingRequestDoesNotBelongToActivity",
  /** Meeting requests do not belong to purchased section */
  MeetingRequestDoesNotBelongToSection = "MeetingRequestDoesNotBelongToSection",
  /** Meeting requests are not pending */
  MeetingRequestIsNotPending = "MeetingRequestIsNotPending",
  /** No meeting tied to enrollment meeting purchase */
  NoMeetingFoundForEnrollmentMeetingPurchase = "NoMeetingFoundForEnrollmentMeetingPurchase",
  /** Only some meeting requests in batch are recurring */
  OnlySomeMeetingRequestsAreRecurring = "OnlySomeMeetingRequestsAreRecurring",
  /** Not supported needs fixing: See https://outschool.atlassian.net/browse/ESP-2819 */
  RecurringMeetingRequestsNotSupported = "RecurringMeetingRequestsNotSupported",
  /** Purchased time slot was not the first request chronologically */
  TimeSlotChronologyMismatch = "TimeSlotChronologyMismatch",
  /** Did not purchase a time slot that is the same as one of the meeting requests */
  TimeSlotMeetingRequestMismatch = "TimeSlotMeetingRequestMismatch"
}

export type AdminUserActionInput = {
  action: Scalars["String"];
  customValue?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  user_uid: Scalars["ID"];
};

export type AdminUserIncidentInput = {
  category?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC"
}

export enum EnrollmentPauseWindowReason {
  PaymentFailure = "payment_failure",
  UserAction = "user_action"
}

export type InterfacePreferencesInput = {
  activityFormatDescriptionFlexHidden?: InputMaybe<Scalars["Boolean"]>;
  activityFormatDescriptionMultiDayHidden?: InputMaybe<Scalars["Boolean"]>;
  activityFormatDescriptionOneTimeHidden?: InputMaybe<Scalars["Boolean"]>;
  activityFormatDescriptionOngoingHidden?: InputMaybe<Scalars["Boolean"]>;
  defaultScheduleFilters?: InputMaybe<UserScheduleFiltersInput>;
};

export type UserScheduleFiltersInput = {
  dow?: InputMaybe<Scalars["String"]>;
  endBy?: InputMaybe<Scalars["String"]>;
  endByTime?: InputMaybe<Scalars["Float"]>;
  startAfter?: InputMaybe<Scalars["String"]>;
  startAfterTime?: InputMaybe<Scalars["Float"]>;
  startBefore?: InputMaybe<Scalars["String"]>;
};

export type PhotoDimensions = {
  height?: InputMaybe<Scalars["Int"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type SchoolClosureInputV2 = {
  affected: Scalars["Boolean"];
  application_status?: InputMaybe<FfapApplicationStatus>;
  hispanic_latino: Scalars["Boolean"];
  household_size: Scalars["Int"];
  household_size_3_to_18: Scalars["Int"];
  how_did_you_hear: Scalars["String"];
  income: Scalars["String"];
  interested_in_research: Scalars["Boolean"];
  interested_in_sharing: Scalars["Boolean"];
  learning_environments: Array<Scalars["String"]>;
  primary_language: Scalars["String"];
  race_ethnicity: Array<Scalars["String"]>;
  reliable_internet: Scalars["Boolean"];
  zip_code: Scalars["String"];
};

export enum SignupInitiator {
  User = "user"
}

export enum SuspendedReason {
  AccountTermination = "AccountTermination",
  EducatorRemovalRequest = "EducatorRemovalRequest",
  FamilyTermsofServiceViolation = "FamilyTermsofServiceViolation",
  InactiveAccount = "InactiveAccount",
  PolicyViolation = "PolicyViolation",
  SuspiciousActivity = "SuspiciousActivity",
  TemporarySuspension = "TemporarySuspension",
  TooManyFailedLoginAttempts = "TooManyFailedLoginAttempts",
  TrustAndSafetySuspension = "TrustAndSafetySuspension"
}

export type TagInput = {
  key: Scalars["String"];
  value: Scalars["String"];
};

export type SubmitClubIntroductionMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
  videoUid: Scalars["ID"];
}>;

export type SubmitClubIntroductionMutation = {
  __typename?: "Mutation";
  submitClubIntroduction: boolean;
};

export type ClubMustSubmitIntroQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type ClubMustSubmitIntroQuery = {
  __typename?: "Query";
  club: {
    __typename?: "Club";
    uid: string;
    currentLearnerHasSubmittedIntroductionForClub: boolean;
  };
};

export type AcceptGuidelinesMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type AcceptGuidelinesMutation = {
  __typename?: "Mutation";
  acceptGuidelinesForSection: boolean;
};

export type ClubGuidelinesQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type ClubGuidelinesQuery = {
  __typename?: "Query";
  club: {
    __typename?: "Club";
    uid: string;
    currentLearnerMustAcceptGuidelines: boolean;
  };
};

export type LoginLearnerWithAppleMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type LoginLearnerWithAppleMutation = {
  __typename?: "Mutation";
  loginOrCreateAccountWithAppleV2:
    | { __typename: "LoginError"; error: CreateOrLoginError }
    | {
        __typename: "LoginResultV2";
        isNewUser: boolean;
        authentication: {
          __typename?: "AuthenticationV2";
          sessionToken: string;
          refreshToken: string;
        };
      };
};

export type LearnerActivityLeaderQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type LearnerActivityLeaderQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    activity: {
      __typename?: "Activity";
      uid: string;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
      };
    };
  } | null;
};

export type SetLearnerInterfacePreferenceMutationVariables = Exact<{
  name: Scalars["String"];
  value: Scalars["JSON"];
}>;

export type SetLearnerInterfacePreferenceMutation = {
  __typename?: "Mutation";
  setLearnerInterfacePreference: boolean;
};

export type CurrentLearnerRecommendedTopicsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentLearnerRecommendedTopicsQuery = {
  __typename?: "Query";
  currentLearnerRecommendedTopics: Array<{
    __typename?: "Topic";
    uid: string;
    label: string;
    image_url: string;
  }>;
};

export type EnrollmentToReviewSoonFragmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  section: {
    __typename?: "Section";
    uid: string;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      subject: string | null;
      is_ongoing_weekly: boolean;
    };
  } | null;
};

export type SubmitInterestedInTakingMoreClassesAboutTopicMutationVariables =
  Exact<{
    learnerSectionReviewInput: LearnerSectionReviewInput;
  }>;

export type SubmitInterestedInTakingMoreClassesAboutTopicMutation = {
  __typename?: "Mutation";
  createOrUpdateLearnerSectionReview: {
    __typename?: "LearnerSectionReview";
    uid: string;
    name: ReviewName | null;
    response: {
      __typename?: "LearnerReviewResponse";
      vote: string | null;
    } | null;
  };
};

export type MarkClubPostViewedMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
}>;

export type MarkClubPostViewedMutation = {
  __typename?: "Mutation";
  markClubPostViewed: boolean;
};

export type LearnerBannerQueryVariables = Exact<{ [key: string]: never }>;

export type LearnerBannerQuery = {
  __typename?: "Query";
  contentfulLearnerBannerCollection: {
    __typename?: "ContentfulLearnerBannerCollection";
    items: Array<{
      __typename?: "ContentfulLearnerBanner";
      name: string | null;
      title: string | null;
      subtitle: string | null;
      ctaText: string | null;
      ctaUrl: string | null;
      campaign: {
        __typename?: "ContentfulCampaign";
        name: string | null;
        startAt: any | null;
        endAt: any | null;
      } | null;
      image: {
        __typename?: "ContentfulAsset";
        title: string | null;
        url: string | null;
      } | null;
    } | null>;
  } | null;
};

export type CurrentLearnerFragmentFragment = {
  __typename?: "CurrentLearner";
  uid: string;
  name: string | null;
  email: string | null;
  username: string | null;
  age: number | null;
  avatar: string | null;
  unlockedAvatars: Array<string>;
  pronoun: string | null;
  accessLevel: LearnerAccessLevel;
  intro: string | null;
  timeZone: string;
  optedOutOfPersonalizedRecommendationsAt: any | null;
  hasUpcomingScheduleEvent: boolean;
  isLoginEnabled: boolean;
  isVerified: boolean;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
  };
  siblings: Array<{
    __typename?: "Child";
    uid: string;
    name: string | null;
    avatar: string | null;
    age: number | null;
  }>;
  enrollmentToReviewSoon: {
    __typename?: "Enrollment";
    uid: string;
    section: {
      __typename?: "Section";
      uid: string;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        subject: string | null;
        is_ongoing_weekly: boolean;
      };
    } | null;
  } | null;
  experiments: Array<{
    __typename?: "LearnerExperiment";
    name: string;
    variant: string;
  }>;
  interfacePreferences: Array<{
    __typename?: "LearnerInterfacePreference";
    name: string;
    value: any;
  }>;
};

export type CurrentLearnerQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentLearnerQuery = {
  __typename?: "Query";
  currentLearner: {
    __typename?: "CurrentLearner";
    uid: string;
    name: string | null;
    email: string | null;
    username: string | null;
    age: number | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    accessLevel: LearnerAccessLevel;
    intro: string | null;
    timeZone: string;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    hasUpcomingScheduleEvent: boolean;
    isLoginEnabled: boolean;
    isVerified: boolean;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    };
    siblings: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      avatar: string | null;
      age: number | null;
    }>;
    enrollmentToReviewSoon: {
      __typename?: "Enrollment";
      uid: string;
      section: {
        __typename?: "Section";
        uid: string;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          subject: string | null;
          is_ongoing_weekly: boolean;
        };
      } | null;
    } | null;
    experiments: Array<{
      __typename?: "LearnerExperiment";
      name: string;
      variant: string;
    }>;
    interfacePreferences: Array<{
      __typename?: "LearnerInterfacePreference";
      name: string;
      value: any;
    }>;
  };
};

export type FeatureFlagsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FeatureFlagsQueryQuery = {
  __typename?: "Query";
  featureFlags: Array<string>;
};

export type LearnerAppActivityFragmentFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  user_uid: string;
  title: string;
  class_experience: string | null;
  learning_goals: string | null;
  assessment: string | null;
  homework: string | null;
  prerequisites: string | null;
  published_at: any | null;
  is_online: boolean;
  age_min: number | null;
  age_max: number | null;
  size_min: number | null;
  size_max: number | null;
  duration_minutes: number | null;
  duration_weeks: number | null;
  weekly_meetings: number | null;
  price_cents: number | null;
  summary: string | null;
  is_ongoing_weekly: boolean;
  subject: string | null;
  languageOfInstruction: any | null;
  summaryForLearners: string | null;
  isClub: boolean;
  isAutoSchedulingEnabled: boolean | null;
  autoSchedulingDaysNoticeMin: number | null;
  autoSchedulingDaysNoticeMax: number | null;
  hasTeacherSchedule: boolean;
  refundPolicy: {
    __typename?: "RefundPolicy";
    name: string;
    description: string;
  };
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type ClubOnboardingBannerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ClubOnboardingBannerQuery = {
  __typename?: "Query";
  currentLearner: {
    __typename?: "CurrentLearner";
    uid: string;
    about_me: string | null;
    hasWrittenClubPost: boolean | null;
    hasWrittenClubPostComment: boolean | null;
  };
};

export type LearnerSearchActivityByTopicSearchResultsQueryVariables = Exact<{
  topicUid?: InputMaybe<Scalars["ID"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  activityExperiences?: InputMaybe<
    Array<ActivityExperience> | ActivityExperience
  >;
}>;

export type LearnerSearchActivityByTopicSearchResultsQuery = {
  __typename?: "Query";
  learnerSearchActivityByTopic: {
    __typename?: "LearnerActivityByTopicSearch";
    cursor: string | null;
    topicLabel: string | null;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      slug_id: string;
      price_cents: number | null;
      age_max: number | null;
      age_min: number | null;
      isClub: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
      };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    }>;
  };
};

export type LearnerActivityDetailsQueryVariables = Exact<{
  uid?: InputMaybe<Scalars["ID"]>;
  slugId?: InputMaybe<Scalars["String"]>;
}>;

export type LearnerActivityDetailsQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    class_experience: string | null;
    summary: string | null;
    summaryForLearners: string | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    age_min: number | null;
    age_max: number | null;
    size_max: number | null;
    hasTeacherSchedule: boolean;
    price_cents: number | null;
    weekly_meetings: number | null;
    is_ongoing_weekly: boolean;
    isClub: boolean;
    slug_id: string;
    promotableSections: Array<{
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      price_cents: number | null;
    }>;
    details: { __typename?: "ActivityDetails"; photo: string | null };
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    videoForLearners: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
      };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    sampleClubPosts: Array<{
      __typename?: "SampleClubPost";
      text: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      }> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      } | null;
      comments: Array<{
        __typename?: "SampleClubPostComment";
        text: string | null;
      }>;
    }> | null;
  };
};

export type LearnerListingRecommendationsQueryVariables = Exact<{
  shuffle?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type LearnerListingRecommendationsQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    perLearnerRecommendations: Array<{
      __typename?: "Listing";
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        slug_id: string;
        price_cents: number | null;
        age_max: number | null;
        age_min: number | null;
        isClub: boolean;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
        };
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
        videoForLearners: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    }>;
  };
};

export type LearnerActivitySearchResultFragmentFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  slug_id: string;
  price_cents: number | null;
  age_max: number | null;
  age_min: number | null;
  isClub: boolean;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
  };
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
  videoForLearners: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type LearnerClassroomAssignmentClassPostsBySectionAndLessonQueryVariables =
  Exact<{
    sectionUid: Scalars["ID"];
    lessonUid: Scalars["ID"];
  }>;

export type LearnerClassroomAssignmentClassPostsBySectionAndLessonQuery = {
  __typename?: "Query";
  assignmentClassPosts: Array<{
    __typename?: "AssignmentClassPost";
    uid: string;
    title: string;
    submissionRequirement: SubmissionRequirementType;
    publishAt: any;
    dueDate: any | null;
    user: { __typename?: "User"; uid: string; name: string | null };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    comments: Array<{
      __typename?: "AssignmentClassPostComment";
      uid: string;
      sentAt: any;
      isSubmission: boolean | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    }>;
  }> | null;
};

export type LearnerClassroomAssignmentClassPostsBySectionQueryVariables =
  Exact<{
    sectionUid: Scalars["ID"];
  }>;

export type LearnerClassroomAssignmentClassPostsBySectionQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
      title: string;
      submissionRequirement: SubmissionRequirementType;
      publishAt: any;
      dueDate: any | null;
      user: { __typename?: "User"; uid: string; name: string | null };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      comments: Array<{
        __typename?: "AssignmentClassPostComment";
        uid: string;
        sentAt: any;
        threadForLearnerUid: string;
        isSubmission: boolean | null;
        replies: Array<{
          __typename?: "AssignmentClassPostComment";
          uid: string;
          sentAt: any;
          threadForLearnerUid: string;
          isSubmission: boolean | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }> | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
      lesson: {
        __typename?: "Lesson";
        uid: string;
        lessonNumber: number | null;
      } | null;
    }>;
  } | null;
};

export type LearnerCommentOnAssignmentMutationVariables = Exact<{
  assignmentClassPostUid: Scalars["ID"];
  messageContentInput: MessageContentInput;
}>;

export type LearnerCommentOnAssignmentMutation = {
  __typename?: "Mutation";
  addAssignmentClassPostComment: {
    __typename?: "AssignmentClassPostComment";
    uid: string;
    sentAt: any;
    isSubmission: boolean | null;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type LearnerClassroomPostsQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  postsFilter?: InputMaybe<ClassroomPostFilter>;
}>;

export type LearnerClassroomPostsQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    usersCanPost: boolean;
    usersCanComment: boolean;
    posts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
    unpublishedPosts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
    activity: {
      __typename?: "Activity";
      user_uid: string;
      leader: {
        __typename?: "Leader";
        uid: string;
        leader_link: string | null;
        name: string | null;
      };
    };
    section: {
      __typename?: "Section";
      currentUserCanManage: boolean;
      uid: string;
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        recordingAvailability: RecordingAvailability;
        recordingRequests: Array<{
          __typename?: "RecordingRequest";
          requestedAt: any;
          message: string;
          requestedBy: {
            __typename?: "Learner";
            uid: string;
            name: string | null;
          };
        }>;
        classRecordings: Array<
          | {
              __typename?: "ClassRecording";
              uid: string;
              startTime: any;
              endTime: any;
            }
          | {
              __typename?: "ZoomRecording";
              uid: string;
              startTime: any;
              endTime: any;
            }
        >;
        attendances: Array<{
          __typename?: "Attendance";
          learner: { __typename?: "Learner"; name: string | null };
        }>;
      }>;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
    };
    mentionNames: Array<{
      __typename?: "MentionName";
      id: string;
      display: string;
      avatar: string | null;
      startTime: any | null;
    }>;
  };
};

export type LearnerClassroomSectionLessonFragmentFragment = {
  __typename?: "SectionLesson";
  uid: string;
  type: SectionLessonType;
  orderInSection: number;
  isCompleted: boolean;
  isLocked: boolean;
  lessonStatus: LearningStatementsVerb;
  isCancelled: boolean;
  title: string;
  description: string | null;
  start_time: any | null;
  lesson: { __typename?: "Lesson"; uid: string; isMissed: boolean } | null;
  unit: {
    __typename?: "Unit";
    uid: string;
    title: string | null;
    unitNumber: number | null;
  } | null;
  liveMeetingDetails: {
    __typename?: "LessonLiveMeeting";
    uid: string;
    enrollmentUid: string | null;
    classRecordingUids: Array<string | null>;
    sectionStartTime: any | null;
    meetingStartTime: any | null;
    meetingEndTime: any | null;
    meetingsCount: number;
    usersCanHaveLiveMeeting: boolean | null;
  } | null;
  assignmentClassPosts: Array<{
    __typename?: "AssignmentClassPost";
    uid: string;
  }>;
  classPosts: Array<{ __typename?: "ClassPost"; uid: string }>;
};

export type LearnerClassroomSectionFragmentFragment = {
  __typename?: "Section";
  uid: string;
  start_time: any | null;
  end_time: any | null;
  canceled_at: any | null;
  is_self_paced: boolean;
  filledSpaceCount: number;
  usesOutschoolVideoChat: boolean;
  currentUserHasAttendances: boolean;
  nextOngoingMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
  } | null;
  leader: { __typename?: "Leader"; uid: string; name: string | null };
  assignmentClassPosts: Array<{
    __typename?: "AssignmentClassPost";
    uid: string;
    title: string;
    submissionRequirement: SubmissionRequirementType;
    dueDate: any | null;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    comments: Array<{
      __typename?: "AssignmentClassPostComment";
      uid: string;
      sentAt: any;
      isSubmission: boolean | null;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    }>;
  }>;
  currentSectionLesson?: {
    __typename?: "SectionLesson";
    uid: string;
    type: SectionLessonType;
    orderInSection: number;
    isCompleted: boolean;
    isLocked: boolean;
    lessonStatus: LearningStatementsVerb;
    isCancelled: boolean;
    title: string;
    description: string | null;
    start_time: any | null;
    lesson: { __typename?: "Lesson"; uid: string; isMissed: boolean } | null;
    unit: {
      __typename?: "Unit";
      uid: string;
      title: string | null;
      unitNumber: number | null;
    } | null;
    liveMeetingDetails: {
      __typename?: "LessonLiveMeeting";
      uid: string;
      enrollmentUid: string | null;
      classRecordingUids: Array<string | null>;
      sectionStartTime: any | null;
      meetingStartTime: any | null;
      meetingEndTime: any | null;
      meetingsCount: number;
      usersCanHaveLiveMeeting: boolean | null;
    } | null;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
    }>;
    classPosts: Array<{ __typename?: "ClassPost"; uid: string }>;
  } | null;
  sectionLessons?: Array<{
    __typename?: "SectionLesson";
    uid: string;
    type: SectionLessonType;
    orderInSection: number;
    isCompleted: boolean;
    isLocked: boolean;
    lessonStatus: LearningStatementsVerb;
    isCancelled: boolean;
    title: string;
    description: string | null;
    start_time: any | null;
    lesson: { __typename?: "Lesson"; uid: string; isMissed: boolean } | null;
    unit: {
      __typename?: "Unit";
      uid: string;
      title: string | null;
      unitNumber: number | null;
    } | null;
    liveMeetingDetails: {
      __typename?: "LessonLiveMeeting";
      uid: string;
      enrollmentUid: string | null;
      classRecordingUids: Array<string | null>;
      sectionStartTime: any | null;
      meetingStartTime: any | null;
      meetingEndTime: any | null;
      meetingsCount: number;
      usersCanHaveLiveMeeting: boolean | null;
    } | null;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
    }>;
    classPosts: Array<{ __typename?: "ClassPost"; uid: string }>;
  }> | null;
};

export type LearnerClassroomActivityFragmentFragment = {
  __typename?: "Activity";
  uid: string;
  user_uid: string;
  title: string;
  is_ongoing_weekly: boolean;
  weekly_meetings: number | null;
  is_online: boolean;
  duration_weeks: number | null;
  isClub: boolean;
  published_at: any | null;
  isSelfPaced: boolean;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    leader_link: string | null;
  };
};

export type LearnerClassroomLessonFragmentFragment = {
  __typename?: "Lesson";
  uid: string;
  title: string | null;
  description: string | null;
  week_number: number;
  order_within_week: number;
  lessonNumber: number | null;
  lessonStatus: LearningStatementsVerb | null;
  isCompleted: boolean;
  isLocked: boolean;
  isMissed: boolean;
  unit: {
    __typename?: "Unit";
    uid: string;
    title: string | null;
    unitNumber: number | null;
  } | null;
  meetings: Array<{
    __typename?: "Meeting";
    uid: string;
    start_time: any;
  }> | null;
};

export type LearnerClassroomSyllabusFragmentFragment = {
  __typename?: "Syllabus";
  uid: string;
  published_at: string | null;
  currentLesson: {
    __typename?: "Lesson";
    uid: string;
    title: string | null;
    description: string | null;
    week_number: number;
    order_within_week: number;
    lessonNumber: number | null;
    lessonStatus: LearningStatementsVerb | null;
    isCompleted: boolean;
    isLocked: boolean;
    isMissed: boolean;
    unit: {
      __typename?: "Unit";
      uid: string;
      title: string | null;
      unitNumber: number | null;
    } | null;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    }> | null;
  } | null;
  lessons: Array<{
    __typename?: "Lesson";
    uid: string;
    lessonNumber: number | null;
    title: string | null;
    description: string | null;
    week_number: number;
    order_within_week: number;
    lessonStatus: LearningStatementsVerb | null;
    isCompleted: boolean;
    isLocked: boolean;
    isMissed: boolean;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
      title: string;
      submissionRequirement: SubmissionRequirementType;
      dueDate: any | null;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      comments: Array<{
        __typename?: "AssignmentClassPostComment";
        uid: string;
        sentAt: any;
        isSubmission: boolean | null;
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    }>;
    unit: {
      __typename?: "Unit";
      uid: string;
      title: string | null;
      unitNumber: number | null;
    } | null;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    }> | null;
  }>;
};

export type LearnerClassroomQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  withSectionLessons: Scalars["Boolean"];
}>;

export type LearnerClassroomQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    usersCanHaveLiveMeeting: boolean;
    currentLearnerEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      isPaused: boolean;
    }>;
    learners: Array<{
      __typename?: "LearnerProfile";
      uid: string;
      name: string | null;
      avatar: string | null;
    }>;
    section: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      canceled_at: any | null;
      is_self_paced: boolean;
      filledSpaceCount: number;
      usesOutschoolVideoChat: boolean;
      currentUserHasAttendances: boolean;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      assignmentClassPosts: Array<{
        __typename?: "AssignmentClassPost";
        uid: string;
        title: string;
        submissionRequirement: SubmissionRequirementType;
        dueDate: any | null;
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
        comments: Array<{
          __typename?: "AssignmentClassPostComment";
          uid: string;
          sentAt: any;
          isSubmission: boolean | null;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      }>;
      currentSectionLesson?: {
        __typename?: "SectionLesson";
        uid: string;
        type: SectionLessonType;
        orderInSection: number;
        isCompleted: boolean;
        isLocked: boolean;
        lessonStatus: LearningStatementsVerb;
        isCancelled: boolean;
        title: string;
        description: string | null;
        start_time: any | null;
        lesson: {
          __typename?: "Lesson";
          uid: string;
          isMissed: boolean;
        } | null;
        unit: {
          __typename?: "Unit";
          uid: string;
          title: string | null;
          unitNumber: number | null;
        } | null;
        liveMeetingDetails: {
          __typename?: "LessonLiveMeeting";
          uid: string;
          enrollmentUid: string | null;
          classRecordingUids: Array<string | null>;
          sectionStartTime: any | null;
          meetingStartTime: any | null;
          meetingEndTime: any | null;
          meetingsCount: number;
          usersCanHaveLiveMeeting: boolean | null;
        } | null;
        assignmentClassPosts: Array<{
          __typename?: "AssignmentClassPost";
          uid: string;
        }>;
        classPosts: Array<{ __typename?: "ClassPost"; uid: string }>;
      } | null;
      sectionLessons?: Array<{
        __typename?: "SectionLesson";
        uid: string;
        type: SectionLessonType;
        orderInSection: number;
        isCompleted: boolean;
        isLocked: boolean;
        lessonStatus: LearningStatementsVerb;
        isCancelled: boolean;
        title: string;
        description: string | null;
        start_time: any | null;
        lesson: {
          __typename?: "Lesson";
          uid: string;
          isMissed: boolean;
        } | null;
        unit: {
          __typename?: "Unit";
          uid: string;
          title: string | null;
          unitNumber: number | null;
        } | null;
        liveMeetingDetails: {
          __typename?: "LessonLiveMeeting";
          uid: string;
          enrollmentUid: string | null;
          classRecordingUids: Array<string | null>;
          sectionStartTime: any | null;
          meetingStartTime: any | null;
          meetingEndTime: any | null;
          meetingsCount: number;
          usersCanHaveLiveMeeting: boolean | null;
        } | null;
        assignmentClassPosts: Array<{
          __typename?: "AssignmentClassPost";
          uid: string;
        }>;
        classPosts: Array<{ __typename?: "ClassPost"; uid: string }>;
      }> | null;
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      user_uid: string;
      title: string;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      is_online: boolean;
      duration_weeks: number | null;
      isClub: boolean;
      published_at: any | null;
      isSelfPaced: boolean;
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        published_at: string | null;
        currentLesson: {
          __typename?: "Lesson";
          uid: string;
          title: string | null;
          description: string | null;
          week_number: number;
          order_within_week: number;
          lessonNumber: number | null;
          lessonStatus: LearningStatementsVerb | null;
          isCompleted: boolean;
          isLocked: boolean;
          isMissed: boolean;
          unit: {
            __typename?: "Unit";
            uid: string;
            title: string | null;
            unitNumber: number | null;
          } | null;
          meetings: Array<{
            __typename?: "Meeting";
            uid: string;
            start_time: any;
          }> | null;
        } | null;
        lessons: Array<{
          __typename?: "Lesson";
          uid: string;
          lessonNumber: number | null;
          title: string | null;
          description: string | null;
          week_number: number;
          order_within_week: number;
          lessonStatus: LearningStatementsVerb | null;
          isCompleted: boolean;
          isLocked: boolean;
          isMissed: boolean;
          assignmentClassPosts: Array<{
            __typename?: "AssignmentClassPost";
            uid: string;
            title: string;
            submissionRequirement: SubmissionRequirementType;
            dueDate: any | null;
            messageContent: {
              __typename?: "MessageContent";
              uid: string;
              text: string | null;
              originalMimeType: TextMimeType;
              editedAt: any | null;
              htmlText: string | null;
              attachments: Array<{
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              }>;
              video: {
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              } | null;
            };
            comments: Array<{
              __typename?: "AssignmentClassPostComment";
              uid: string;
              sentAt: any;
              isSubmission: boolean | null;
              messageContent: {
                __typename?: "MessageContent";
                uid: string;
                text: string | null;
                originalMimeType: TextMimeType;
                editedAt: any | null;
                htmlText: string | null;
                attachments: Array<{
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                }>;
                video: {
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                } | null;
              };
            }>;
          }>;
          unit: {
            __typename?: "Unit";
            uid: string;
            title: string | null;
            unitNumber: number | null;
          } | null;
          meetings: Array<{
            __typename?: "Meeting";
            uid: string;
            start_time: any;
          }> | null;
        }>;
      } | null;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
      };
    };
  };
};

export type UnlimitedClassroomQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
}>;

export type UnlimitedClassroomQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    activity: {
      __typename?: "Activity";
      slug_id: string;
      summaryTranslated: string | null;
      uid: string;
      user_uid: string;
      title: string;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      is_online: boolean;
      duration_weeks: number | null;
      isClub: boolean;
      published_at: any | null;
      isSelfPaced: boolean;
      completedEnrollmentCount: number;
      reviewCount: number;
      averageStarRating: number | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        suspended_at: any | null;
        teacher_approved_at: any | null;
        reviewCount: number;
        averageActivityStarRating: number | null;
        photo: string | null;
        educatorTiers: Array<EducatorTier>;
        details: {
          __typename?: "LeaderDetails";
          headline: string | null;
          about: string | null;
        };
        video: {
          __typename?: "Attachment";
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
          certificateDiscipline: TeacherCertificateDiscipline | null;
          otherType: string | null;
          certificateState: TeacherCertificateState | null;
          name: string | null;
          degreeInstitution: string | null;
          degreeAdditionalInfo: string | null;
        }>;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      };
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        published_at: string | null;
        currentLesson: {
          __typename?: "Lesson";
          uid: string;
          title: string | null;
          description: string | null;
          week_number: number;
          order_within_week: number;
          lessonNumber: number | null;
          lessonStatus: LearningStatementsVerb | null;
          isCompleted: boolean;
          isLocked: boolean;
          isMissed: boolean;
          unit: {
            __typename?: "Unit";
            uid: string;
            title: string | null;
            unitNumber: number | null;
          } | null;
          meetings: Array<{
            __typename?: "Meeting";
            uid: string;
            start_time: any;
          }> | null;
        } | null;
        lessons: Array<{
          __typename?: "Lesson";
          uid: string;
          lessonNumber: number | null;
          title: string | null;
          description: string | null;
          week_number: number;
          order_within_week: number;
          lessonStatus: LearningStatementsVerb | null;
          isCompleted: boolean;
          isLocked: boolean;
          isMissed: boolean;
          assignmentClassPosts: Array<{
            __typename?: "AssignmentClassPost";
            uid: string;
            title: string;
            submissionRequirement: SubmissionRequirementType;
            dueDate: any | null;
            messageContent: {
              __typename?: "MessageContent";
              uid: string;
              text: string | null;
              originalMimeType: TextMimeType;
              editedAt: any | null;
              htmlText: string | null;
              attachments: Array<{
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              }>;
              video: {
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              } | null;
            };
            comments: Array<{
              __typename?: "AssignmentClassPostComment";
              uid: string;
              sentAt: any;
              isSubmission: boolean | null;
              messageContent: {
                __typename?: "MessageContent";
                uid: string;
                text: string | null;
                originalMimeType: TextMimeType;
                editedAt: any | null;
                htmlText: string | null;
                attachments: Array<{
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                }>;
                video: {
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                } | null;
              };
            }>;
          }>;
          unit: {
            __typename?: "Unit";
            uid: string;
            title: string | null;
            unitNumber: number | null;
          } | null;
          meetings: Array<{
            __typename?: "Meeting";
            uid: string;
            start_time: any;
          }> | null;
        }>;
      } | null;
      details: { __typename?: "ActivityDetails"; photo: string | null };
    };
  };
};

export type LearnerDashboard_SectionFragment = {
  __typename?: "Section";
  uid: string;
  start_time: any | null;
  end_time: any | null;
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    subject: string | null;
    duration_weeks: number | null;
    summary: string | null;
    weekly_meetings: number | null;
    is_ongoing_weekly: boolean;
    isClub: boolean;
    isFlexSchedule: boolean;
    details: { __typename?: "ActivityDetails"; photo: string | null };
  };
  leader: { __typename?: "Leader"; uid: string; name: string | null };
};

export type LearnerKeywordSearchResultsQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  activityExperiences?: InputMaybe<
    Array<ActivityExperience> | ActivityExperience
  >;
}>;

export type LearnerKeywordSearchResultsQuery = {
  __typename?: "Query";
  learnerSearchKeyword: {
    __typename?: "LearnerKeywordSearch";
    cursor: string | null;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      slug_id: string;
      price_cents: number | null;
      age_max: number | null;
      age_min: number | null;
      isClub: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
      };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    }>;
  };
};

export type LearnerLeaderFragmentFragment = {
  __typename?: "Leader";
  uid: string;
  name: string | null;
  photo: string | null;
  leader_link: string | null;
  details: {
    __typename?: "LeaderDetails";
    about: string | null;
    headline: string | null;
  };
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type MarkLearnerNotificationsReadMutationVariables = Exact<{
  uids: Array<Scalars["ID"]> | Scalars["ID"];
  after: Scalars["DateTime"];
}>;

export type MarkLearnerNotificationsReadMutation = {
  __typename?: "Mutation";
  markNotificationRead: {
    __typename?: "MarkNotificationReadResult";
    learnerHasUnreadNotifications: boolean;
  };
};

type NotificationFragment_AssignmentNotification_Fragment = {
  __typename: "AssignmentNotification";
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

type NotificationFragment_ClassPostNotification_Fragment = {
  __typename: "ClassPostNotification";
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

type NotificationFragment_ClubNotification_Fragment = {
  __typename: "ClubNotification";
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

type NotificationFragment_PrivateClassMessageNotification_Fragment = {
  __typename: "PrivateClassMessageNotification";
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

export type NotificationFragmentFragment =
  | NotificationFragment_AssignmentNotification_Fragment
  | NotificationFragment_ClassPostNotification_Fragment
  | NotificationFragment_ClubNotification_Fragment
  | NotificationFragment_PrivateClassMessageNotification_Fragment;

export type PrivateClassMessageNotificationFragmentFragment = {
  __typename: "PrivateClassMessageNotification";
  isClub: boolean;
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  privateClassMessageSectionUid: string;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

export type ClassPostNotificationFragmentFragment = {
  __typename: "ClassPostNotification";
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  classPostNotificationAction: ClassPostNotificationAction;
  classroomClassPostUid: string;
  classroomSectionUid: string;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

export type AssignmentNotificationFragmentFragment = {
  __typename: "AssignmentNotification";
  assignmentUid: string;
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  assignmentNotificationAction: AssignmentNotificationAction;
  assignmentSectionUid: string;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

export type ClubNotificationFragmentFragment = {
  __typename: "ClubNotification";
  classPostCommentUid: string | null;
  threadParentCommentUid: string | null;
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  clubNotificationAction: ClubNotificationAction;
  clubClassPostUid: string;
  clubSectionUid: string;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

export type LearnerNotificationsQueryVariables = Exact<{
  direction: CursorDirection;
  cursor?: InputMaybe<Scalars["DateTime"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  after: Scalars["DateTime"];
  skipHasUnreadNotifications: Scalars["Boolean"];
}>;

export type LearnerNotificationsQuery = {
  __typename?: "Query";
  learnerHasUnreadNotifications?: boolean;
  learnerNotifications: {
    __typename?: "LearnerNotificationConnection";
    pageInfo: {
      __typename?: "LearnerNotificationsPageInfo";
      hasNextPage: boolean;
      direction: CursorDirection;
    };
    results: Array<
      | {
          __typename: "AssignmentNotification";
          assignmentUid: string;
          uid: string;
          createdAt: any;
          status: NotificationStatus;
          assignmentNotificationAction: AssignmentNotificationAction;
          assignmentSectionUid: string;
          sender:
            | {
                __typename?: "LearnerSender";
                uid: string;
                name: string;
                avatar: string | null;
              }
            | {
                __typename?: "UserSender";
                uid: string;
                name: string;
                photo: string | null;
              };
        }
      | {
          __typename: "ClassPostNotification";
          uid: string;
          createdAt: any;
          status: NotificationStatus;
          classPostNotificationAction: ClassPostNotificationAction;
          classroomClassPostUid: string;
          classroomSectionUid: string;
          sender:
            | {
                __typename?: "LearnerSender";
                uid: string;
                name: string;
                avatar: string | null;
              }
            | {
                __typename?: "UserSender";
                uid: string;
                name: string;
                photo: string | null;
              };
        }
      | {
          __typename: "ClubNotification";
          classPostCommentUid: string | null;
          threadParentCommentUid: string | null;
          uid: string;
          createdAt: any;
          status: NotificationStatus;
          clubNotificationAction: ClubNotificationAction;
          clubClassPostUid: string;
          clubSectionUid: string;
          sender:
            | {
                __typename?: "LearnerSender";
                uid: string;
                name: string;
                avatar: string | null;
              }
            | {
                __typename?: "UserSender";
                uid: string;
                name: string;
                photo: string | null;
              };
        }
      | {
          __typename: "PrivateClassMessageNotification";
          isClub: boolean;
          uid: string;
          createdAt: any;
          status: NotificationStatus;
          privateClassMessageSectionUid: string;
          sender:
            | {
                __typename?: "LearnerSender";
                uid: string;
                name: string;
                avatar: string | null;
              }
            | {
                __typename?: "UserSender";
                uid: string;
                name: string;
                photo: string | null;
              };
        }
    >;
  };
};

export type CurrentLearnerPastClassesQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end?: InputMaybe<Scalars["DateTime"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type CurrentLearnerPastClassesQuery = {
  __typename?: "Query";
  currentLearnerPastClasses: {
    __typename?: "PastClasses";
    pastEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        meetings: Array<{ __typename?: "Meeting"; end_time: any }>;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          subject: string | null;
          duration_weeks: number | null;
          summary: string | null;
          weekly_meetings: number | null;
          is_ongoing_weekly: boolean;
          isClub: boolean;
          isFlexSchedule: boolean;
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
  };
};

export type CurrentLearnerRecommendedTeachersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type CurrentLearnerRecommendedTeachersQuery = {
  __typename?: "Query";
  currentLearnerRecommendedTeachers: {
    __typename?: "RecommendedTeachers";
    recommendationType: TeacherRecommendationType | null;
    teachers: Array<{
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
      };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    }>;
  };
};

export type CurrentLearnerSavedActivitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentLearnerSavedActivitiesQuery = {
  __typename?: "Query";
  currentLearner: {
    __typename?: "CurrentLearner";
    uid: string;
    savedActivities: Array<{
      __typename?: "SavedActivity";
      uid: string;
      activity_uid: string;
      created_at: any;
      listing: {
        __typename?: "Listing";
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          slug_id: string;
          price_cents: number | null;
          age_max: number | null;
          age_min: number | null;
          isClub: boolean;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
          };
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
          videoForLearners: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      };
    }>;
  };
};

export type LearnerSaveActivityMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  referer?: InputMaybe<Scalars["String"]>;
  attribution?: InputMaybe<Attribution>;
}>;

export type LearnerSaveActivityMutation = {
  __typename?: "Mutation";
  saveActivity: {
    __typename?: "SavedActivity";
    uid: string;
    activity_uid: string;
    created_at: any;
    listing: {
      __typename?: "Listing";
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        slug_id: string;
        price_cents: number | null;
        age_max: number | null;
        age_min: number | null;
        isClub: boolean;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
        };
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
        videoForLearners: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    };
  };
};

export type LearnerUnsaveActivityMutationVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type LearnerUnsaveActivityMutation = {
  __typename?: "Mutation";
  unsaveActivity: boolean;
};

export type CurrentLearnerScheduleEventsQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end?: InputMaybe<Scalars["DateTime"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  withPaused?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CurrentLearnerScheduleEventsQuery = {
  __typename?: "Query";
  currentLearnerScheduleEvents: {
    __typename?: "ScheduleEvents";
    events: Array<{
      __typename?: "ScheduleEvent";
      uid: string;
      endTime: any;
      startTime: any;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          subject: string | null;
          duration_weeks: number | null;
          summary: string | null;
          weekly_meetings: number | null;
          is_ongoing_weekly: boolean;
          isClub: boolean;
          isFlexSchedule: boolean;
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      };
      enrollment: {
        __typename?: "Enrollment";
        uid: string;
        isPaused: boolean;
      } | null;
    }>;
  };
};

export type LearnerSimilarListingsByTfIdfQueryVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type LearnerSimilarListingsByTfIdfQuery = {
  __typename?: "Query";
  listing: {
    __typename?: "Listing";
    similarListings: {
      __typename?: "SimilarListings";
      tfidf: Array<{
        __typename?: "Listing";
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          slug_id: string;
          price_cents: number | null;
          age_max: number | null;
          age_min: number | null;
          isClub: boolean;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
          };
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
          videoForLearners: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  } | null;
};

export type LearnerSinglePostQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  classPostUid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type LearnerSinglePostQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    usersCanComment: boolean;
    postByUid: {
      __typename?: "ClassPost";
      uid: string;
      publishAt: any;
      isWelcomePost: boolean;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    } | null;
    mentionNames: Array<{
      __typename?: "MentionName";
      id: string;
      display: string;
      avatar: string | null;
      startTime: any | null;
    }>;
  };
};

export type LearnerSupportTopicsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LearnerSupportTopicsQuery = {
  __typename?: "Query";
  learnerSupportTopics: Array<{
    __typename?: "LearnerSupportTopic";
    title: string;
    description: string;
    type: LearnerSupportType;
    prompt: string | null;
    placeholder: string;
  }>;
};

export type SubmitLearnerSupportIssueMutationVariables = Exact<{
  issueType: LearnerSupportType;
  text?: InputMaybe<Scalars["String"]>;
}>;

export type SubmitLearnerSupportIssueMutation = {
  __typename?: "Mutation";
  submitLearnerSupportIssue: boolean;
};

export type GetLearnerToParentTransferTokenMutationVariables = Exact<{
  password?: InputMaybe<Scalars["String"]>;
}>;

export type GetLearnerToParentTransferTokenMutation = {
  __typename?: "Mutation";
  learnerToParentTransferToken: {
    __typename?: "AuthTransfer";
    transferToken: string | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
  };
};

export type GetLearnerToSiblingTransferTokenMutationVariables = Exact<{
  siblingUid: Scalars["ID"];
}>;

export type GetLearnerToSiblingTransferTokenMutation = {
  __typename?: "Mutation";
  learnerToSiblingTransferToken: {
    __typename?: "AuthTransferToken";
    transferToken: string;
  };
};

export type LearnerPrivateClassMessagesPageQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type LearnerPrivateClassMessagesPageQuery = {
  __typename?: "Query";
  currentLearner: {
    __typename?: "CurrentLearner";
    uid: string;
    name: string | null;
    email: string | null;
    username: string | null;
    age: number | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    accessLevel: LearnerAccessLevel;
    intro: string | null;
    timeZone: string;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    hasUpcomingScheduleEvent: boolean;
    isLoginEnabled: boolean;
    isVerified: boolean;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    };
    siblings: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      avatar: string | null;
      age: number | null;
    }>;
    enrollmentToReviewSoon: {
      __typename?: "Enrollment";
      uid: string;
      section: {
        __typename?: "Section";
        uid: string;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          subject: string | null;
          is_ongoing_weekly: boolean;
        };
      } | null;
    } | null;
    experiments: Array<{
      __typename?: "LearnerExperiment";
      name: string;
      variant: string;
    }>;
    interfacePreferences: Array<{
      __typename?: "LearnerInterfacePreference";
      name: string;
      value: any;
    }>;
  };
  section: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    currentLearnerEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      withdrawnAt: any | null;
      usersCanCommunicate: boolean;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      user_uid: string;
      title: string;
      class_experience: string | null;
      learning_goals: string | null;
      assessment: string | null;
      homework: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      summary: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      hasTeacherSchedule: boolean;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        suspended_at: any | null;
        cityState: string | null;
        socialOptOut: boolean;
        is_crosslister: boolean | null;
        timeZone: string | null;
        hasAvailabilitySet: boolean;
        approved: boolean;
        hasBackgroundCheckExpired: boolean | null;
        details: {
          __typename?: "LeaderDetails";
          about: string | null;
          headline: string | null;
          headlineTranslated: string | null;
          countryOfResidence: string | null;
        };
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: {
          __typename?: "SellerOrg";
          uid: string;
          name: string;
        } | null;
      };
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
      };
      details: { __typename?: "ActivityDetails"; photo: string | null };
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
  } | null;
};

export type LearnerSectionFragmentFragment = {
  __typename?: "Section";
  uid: string;
  activity_uid: string;
  start_time: any | null;
  end_time: any | null;
  startsAtNight: boolean | null;
  is_cross_listed: boolean;
  size_max: number | null;
  size_min: number | null;
  isPublished: boolean | null;
  price_cents: number | null;
  published_at: any | null;
  updated_at: any;
  canceled_at: any | null;
  deleted_at: any | null;
  ongoing_stopped_at: any | null;
  duration_minutes: number | null;
  usesOutschoolVideoChat: boolean;
  usersCanSendPrivateMessage: boolean;
  currentUserCanManage: boolean;
  isBuyable: boolean;
  allowsTeacherTransfer: boolean;
  nextOngoingMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
  } | null;
  leader: { __typename?: "Leader"; uid: string; name: string | null };
  details: {
    __typename?: "SectionDetails";
    autoScheduledDraft: boolean | null;
  };
  prepaidOngoingPeriodOptions: Array<{
    __typename?: "Period";
    startOfIsoWeek: any;
    endOfIsoWeek: any;
  }> | null;
};

export type SectionEnrolledFragmentFragment = {
  __typename?: "Section";
  details: {
    __typename?: "SectionDetails";
    onlineClassroom: {
      __typename?: "OnlineClassroom";
      type: string | null;
      url: string | null;
    } | null;
  };
};

export type LearnerSearchTeacherActivitiesQueryVariables = Exact<{
  teacherUid: Scalars["ID"];
}>;

export type LearnerSearchTeacherActivitiesQuery = {
  __typename?: "Query";
  learnerSearchTeacherActivities: {
    __typename?: "LearnerSearchTeacherActivities";
    cursor: string | null;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      slug_id: string;
      price_cents: number | null;
      age_max: number | null;
      age_min: number | null;
      isClub: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
      };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    }>;
  };
};

export type LearnerTeacherPublicProfileQueryVariables = Exact<{
  leaderLink?: InputMaybe<Scalars["String"]>;
}>;

export type LearnerTeacherPublicProfileQuery = {
  __typename?: "Query";
  publicProfileNew: {
    __typename?: "PublicProfile";
    uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      details: { __typename?: "LeaderDetails"; about: string | null };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
  } | null;
};

export type VerifyLearnerTransferMutationVariables = Exact<{
  token: Scalars["String"];
  isLearnerLogin: Scalars["Boolean"];
}>;

export type VerifyLearnerTransferMutation = {
  __typename?: "Mutation";
  verifyLearnerTransfer:
    | {
        __typename?: "AuthenticationV2";
        refreshToken: string;
        sessionToken: string;
      }
    | { __typename?: "AuthenticationV1"; sessionToken: string };
};

export type LearnerClassPostList_ClassroomFragment = {
  __typename?: "Classroom";
  usersCanPost: boolean;
  usersCanComment: boolean;
  posts: Array<{
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  }>;
  unpublishedPosts: Array<{
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  }>;
  activity: {
    __typename?: "Activity";
    user_uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      leader_link: string | null;
      name: string | null;
    };
  };
  section: {
    __typename?: "Section";
    currentUserCanManage: boolean;
    uid: string;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      recordingAvailability: RecordingAvailability;
      recordingRequests: Array<{
        __typename?: "RecordingRequest";
        requestedAt: any;
        message: string;
        requestedBy: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        };
      }>;
      classRecordings: Array<
        | {
            __typename?: "ClassRecording";
            uid: string;
            startTime: any;
            endTime: any;
          }
        | {
            __typename?: "ZoomRecording";
            uid: string;
            startTime: any;
            endTime: any;
          }
      >;
      attendances: Array<{
        __typename?: "Attendance";
        learner: { __typename?: "Learner"; name: string | null };
      }>;
    }>;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  };
  mentionNames: Array<{
    __typename?: "MentionName";
    id: string;
    display: string;
    avatar: string | null;
    startTime: any | null;
  }>;
};

export type MarkLessonCompleteMutationVariables = Exact<{
  recordingUid: Scalars["ID"];
}>;

export type MarkLessonCompleteMutation = {
  __typename?: "Mutation";
  markLessonCompleteFromClassRecording: boolean;
};

export type MarkLessonCompleteButtonMutationVariables = Exact<{
  lessonUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
}>;

export type MarkLessonCompleteButtonMutation = {
  __typename?: "Mutation";
  markLessonCompleteForLearner: boolean;
};

export type ClassCardContents_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  is_ongoing_weekly: boolean;
  isClub: boolean;
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type ClassCardContents_ScheduleEventFragment = {
  __typename?: "ScheduleEvent";
  uid: string;
  startTime: any;
  endTime: any;
  section: { __typename?: "Section"; uid: string };
  enrollment: {
    __typename?: "Enrollment";
    uid: string;
    isPaused: boolean;
  } | null;
};

export type ClubCardContents_ClubFragment = {
  __typename?: "Club";
  uid: string;
  currentLearnerPostCount: number;
  currentLearnerPostReplyCount: number;
  promotedPostCount: number;
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    details: { __typename?: "ActivityDetails"; photo: string | null };
  };
};

export type ListLearnerClubsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  countCommentsAfter?: InputMaybe<Scalars["DateTime"]>;
}>;

export type ListLearnerClubsQuery = {
  __typename?: "Query";
  currentLearnerClubs: Array<{
    __typename?: "Club";
    uid: string;
    currentLearnerPostCount: number;
    currentLearnerPostReplyCount: number;
    promotedPostCount: number;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      details: { __typename?: "ActivityDetails"; photo: string | null };
    };
  }>;
};

export type EventTime_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  isClub: boolean;
  weekly_meetings: number | null;
  duration_weeks: number | null;
};

export type EventCard_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  isClub: boolean;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type SaveNewLearnerPasswordMutationVariables = Exact<{
  currentPassword: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type SaveNewLearnerPasswordMutation = {
  __typename?: "Mutation";
  changeLoggedInLearnerPassword: boolean;
};

export type CurrentUserAttachmentFragmentFragment = {
  __typename?: "Attachment";
  uid: string;
  conversionStatus: string | null;
  created_at: any;
  file: {
    __typename?: "File";
    filename: string;
    mimetype: string | null;
    size: number;
    url: string;
    thumbUrl: string | null;
  };
};

export type CurrentUserTeacherApplicationFragmentFragment = {
  __typename?: "TeacherApplication";
  uid: string;
  legal_name: string | null;
  status: TeacherApplicationStatus;
  submitted_at: any | null;
  reviewed_at: any | null;
  teaching_experience: string | null;
  teaching_credentials: string | null;
  class_types: string | null;
  online_profiles: string | null;
  sample_classes: string | null;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type ChildFragmentFragment = {
  __typename?: "Child";
  uid: string;
  name: string | null;
  age: number | null;
  birthMonth: number | null;
  birthYear: number | null;
  email: string | null;
  avatar: string | null;
  unlockedAvatars: Array<string>;
  pronoun: string | null;
  intro: string | null;
  deleted_at: any | null;
  email_confirmed_at: any | null;
  optedOutOfPersonalizedRecommendationsAt: any | null;
  isLoginEnabled: boolean;
  username: string | null;
  hasPassword: boolean;
  attributes: Array<{
    __typename?: "EntityAttribute";
    uid: string;
    key: string;
    value: string;
  }>;
};

export type CurrentUserChildFragmentFragment = {
  __typename?: "Child";
  uid: string;
  name: string | null;
  age: number | null;
  birthMonth: number | null;
  birthYear: number | null;
  email: string | null;
  avatar: string | null;
  unlockedAvatars: Array<string>;
  pronoun: string | null;
  intro: string | null;
  deleted_at: any | null;
  email_confirmed_at: any | null;
  optedOutOfPersonalizedRecommendationsAt: any | null;
  isLoginEnabled: boolean;
  username: string | null;
  hasPassword: boolean;
  attributes: Array<{
    __typename?: "EntityAttribute";
    uid: string;
    key: string;
    value: string;
  }>;
};

export type PricingOfferFragmentFragment = {
  __typename?: "PricingOffer";
  uid: string;
  name: string;
  userLimitCents: number;
  purchaseLimitCents: number | null;
  totalCapCents: number;
  startAfter: any;
  endBy: any;
  buyerOrgName: string | null;
  isTotalCapReached: boolean;
  remainingCapCents: number | null;
  requirePaymentMethod: boolean;
  isOfferLimitReached: boolean;
  userOfferEmail: string | null;
  discount: number | null;
  isFinancialAid: boolean;
};

export type DefaultScheduleFiltersFragmentFragment = {
  __typename?: "UserScheduleFilters";
  startAfter: string | null;
  endBy: string | null;
  startAfterTime: number | null;
  endByTime: number | null;
  dow: string | null;
};

export type InterfacePreferencesFragmentFragment = {
  __typename?: "InterfacePreferences";
  activityFormatDescriptionFlexHidden: boolean;
  activityFormatDescriptionOngoingHidden: boolean;
  activityFormatDescriptionOneTimeHidden: boolean;
  activityFormatDescriptionMultiDayHidden: boolean;
  defaultScheduleFilters: {
    __typename?: "UserScheduleFilters";
    startAfter: string | null;
    endBy: string | null;
    startAfterTime: number | null;
    endByTime: number | null;
    dow: string | null;
  };
};

export type FullCurrentUserFragmentFragment = {
  __typename?: "User";
  uid: string;
  slug_id: string;
  name: string | null;
  email: string | null;
  isLearnerModeEnabled: boolean | null;
  confirmed_at: any | null;
  subscribed_at: any | null;
  email_bounced_at: any | null;
  default_currency: string | null;
  phone: string | null;
  photo: string | null;
  leader_subscribed_at: any | null;
  calendar_uid: string;
  intercomUserHash: string | null;
  payout_email: string | null;
  owner_of_seller_org_uid: string | null;
  admin_for_organization_uid: string | null;
  do_not_sell_my_personal_info: boolean;
  background_checked_at: any | null;
  background_check_started_at: any | null;
  interview_scheduled_at: any | null;
  interviewed_at: any | null;
  classroom_training_scheduled_at: any | null;
  classroom_trained_at: any | null;
  latestLegalName: string | null;
  hasCompletedClassContentPoliciesTraining: boolean;
  hasCompletedSafetyAndPrivacyTraining: boolean;
  hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
  hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
  teacher_approved_at: any | null;
  is_crosslister: boolean | null;
  introduction: string | null;
  created_at: any;
  updated_at: any;
  deleted_at: any | null;
  suspended_at: any | null;
  is_admin: boolean | null;
  learner_uses_parent_mode: boolean;
  leader_link: string | null;
  unreadMessageThreadsCount: number;
  unreadPrivateClassMessageThreadsCount: number;
  roles: Array<string> | null;
  locale: UserLocale;
  isRecordingAutopostEnabled: boolean | null;
  learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
  anonymousId: string | null;
  wasReferred: boolean;
  isEducatorBetaTester: boolean | null;
  consented_to_coppa_notice_at: any | null;
  hasVerifiedIdentity: boolean | null;
  consent_to_share_class_history: boolean | null;
  consent_to_share_favourites: boolean | null;
  privacy_state: string | null;
  profile_link_uid: string | null;
  otherEmailDetails: Array<{
    __typename?: "OtherEmailDetails";
    uid: string;
    email: string;
    confirmed_at: any | null;
  } | null> | null;
  firstPaidEnrollment: {
    __typename?: "Enrollment";
    uid: string;
    confirmed_at: any | null;
  } | null;
  details: {
    __typename?: "UserDetails";
    about: string | null;
    headline: string | null;
    countryOfResidence: string | null;
    introduction: string | null;
    browserTimeZone: string | null;
    timeRangeAfterSchool: boolean | null;
    timeRangeSchool: boolean | null;
    timeRangeWeekend: boolean | null;
    socialOptOut: boolean | null;
    howFoundOutschool: string | null;
    howFoundOutschoolOther: string | null;
    educationApproach: string | null;
    adminDashboardUrl: string | null;
    experiments: Array<string | null> | null;
  };
  location: {
    __typename?: "Location";
    address: string | null;
    city: string | null;
    lat: number | null;
    lng: number | null;
    timeZone: string | null;
    state: string | null;
  } | null;
  organizationAdmin: {
    __typename?: "OrganizationAdmin";
    organization: {
      __typename?: "Organization";
      uid: string;
      name: string;
      type: OrganizationType;
    };
  } | null;
  children: Array<{
    __typename?: "Child";
    uid: string;
    name: string | null;
    age: number | null;
    birthMonth: number | null;
    birthYear: number | null;
    email: string | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    email_confirmed_at: any | null;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    isLoginEnabled: boolean;
    username: string | null;
    hasPassword: boolean;
    attributes: Array<{
      __typename?: "EntityAttribute";
      uid: string;
      key: string;
      value: string;
    }>;
  }>;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
  interfacePreferences: {
    __typename?: "InterfacePreferences";
    activityFormatDescriptionFlexHidden: boolean;
    activityFormatDescriptionOngoingHidden: boolean;
    activityFormatDescriptionOneTimeHidden: boolean;
    activityFormatDescriptionMultiDayHidden: boolean;
    defaultScheduleFilters: {
      __typename?: "UserScheduleFilters";
      startAfter: string | null;
      endBy: string | null;
      startAfterTime: number | null;
      endByTime: number | null;
      dow: string | null;
    };
  };
  schoolClosure: {
    __typename?: "SchoolClosure";
    application_status: FfapApplicationStatus | null;
    needs_assistance: boolean | null;
  } | null;
  pricingOffer: {
    __typename?: "PricingOffer";
    uid: string;
    name: string;
    userLimitCents: number;
    purchaseLimitCents: number | null;
    totalCapCents: number;
    startAfter: any;
    endBy: any;
    buyerOrgName: string | null;
    isTotalCapReached: boolean;
    remainingCapCents: number | null;
    requirePaymentMethod: boolean;
    isOfferLimitReached: boolean;
    userOfferEmail: string | null;
    discount: number | null;
    isFinancialAid: boolean;
  } | null;
  sellerOrg: {
    __typename?: "SellerOrg";
    uid: string;
    name: string;
    leader_link: string | null;
    currentUserIsOwner: boolean | null;
    currentUserIsTeacher: boolean | null;
    hasApprovedTeacherAccountForOwner: boolean | null;
  } | null;
  latestSellerOrgApplication: {
    __typename?: "SellerOrgApplication";
    uid: string;
    status: SellerOrgApplicationStatus;
    approvedAt: any | null;
    rejectedAt: any | null;
    contractSignedAt: any | null;
    adminResponse: string | null;
  } | null;
  latestTeacherApplication: {
    __typename?: "TeacherApplication";
    uid: string;
    legal_name: string | null;
    status: TeacherApplicationStatus;
    submitted_at: any | null;
    reviewed_at: any | null;
    teaching_experience: string | null;
    teaching_credentials: string | null;
    class_types: string | null;
    online_profiles: string | null;
    sample_classes: string | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  } | null;
  leader: {
    __typename?: "Leader";
    uid: string;
    showCalendar: boolean | null;
    mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
  } | null;
  signUpCreditSummary: {
    __typename?: "SignUpCreditSummary";
    expiresAt: any | null;
    referrer: {
      __typename?: "Referrer";
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        approved: boolean;
        socialOptOut: boolean;
        hasBackgroundCheckExpired: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type CurrentUserQueryQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
    signUpCreditSummary: {
      __typename?: "SignUpCreditSummary";
      expiresAt: any | null;
      referrer: {
        __typename?: "Referrer";
        parent: {
          __typename?: "Parent";
          uid: string;
          name: string | null;
          photo: string | null;
          publicName: string | null;
          introduction: string | null;
          created_at: any | null;
          deleted_at: any | null;
          timeZone: string | null;
          socialOptOut: boolean;
        } | null;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          leader_link: string | null;
          created_at: any | null;
          photo: string | null;
          approved: boolean;
          socialOptOut: boolean;
          hasBackgroundCheckExpired: boolean | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

type LoginErrorOrResultFragment_LoginError_Fragment = {
  __typename: "LoginError";
  error: CreateOrLoginError;
};

type LoginErrorOrResultFragment_LoginResult_Fragment = {
  __typename: "LoginResult";
  isNewUser: boolean;
  authentication: { __typename?: "AuthenticationV1"; sessionToken: string };
};

export type LoginErrorOrResultFragmentFragment =
  | LoginErrorOrResultFragment_LoginError_Fragment
  | LoginErrorOrResultFragment_LoginResult_Fragment;

type LoginErrorOrResultFragmentV2_LoginError_Fragment = {
  __typename: "LoginError";
  error: CreateOrLoginError;
};

type LoginErrorOrResultFragmentV2_LoginResultV2_Fragment = {
  __typename: "LoginResultV2";
  isNewUser: boolean;
  authentication: {
    __typename?: "AuthenticationV2";
    sessionToken: string;
    refreshToken: string;
  };
};

export type LoginErrorOrResultFragmentV2Fragment =
  | LoginErrorOrResultFragmentV2_LoginError_Fragment
  | LoginErrorOrResultFragmentV2_LoginResultV2_Fragment;

export type LoginOrCreateAccountWithAppleV2MutationVariables = Exact<{
  code: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  createOptions?: InputMaybe<CreateAccountWithProviderInput>;
  osRef?: InputMaybe<OsRefInput>;
}>;

export type LoginOrCreateAccountWithAppleV2Mutation = {
  __typename?: "Mutation";
  loginOrCreateAccountWithAppleV2:
    | { __typename: "LoginError"; error: CreateOrLoginError }
    | {
        __typename: "LoginResultV2";
        isNewUser: boolean;
        authentication: {
          __typename?: "AuthenticationV2";
          sessionToken: string;
          refreshToken: string;
        };
      };
};

export type LoginWithGoogleOneTapMutationVariables = Exact<{
  idToken: Scalars["String"];
  createOptions?: InputMaybe<CreateAccountWithProviderInput>;
  osRef?: InputMaybe<OsRefInput>;
}>;

export type LoginWithGoogleOneTapMutation = {
  __typename?: "Mutation";
  loginOrCreateAccountWithGoogle:
    | { __typename: "LoginError"; error: CreateOrLoginError }
    | {
        __typename: "LoginResult";
        isNewUser: boolean;
        authentication: {
          __typename?: "AuthenticationV1";
          sessionToken: string;
        };
      };
};

export type LoginWithGoogleOneTapV2MutationVariables = Exact<{
  idToken: Scalars["String"];
  createOptions?: InputMaybe<CreateAccountWithProviderInput>;
  osRef?: InputMaybe<OsRefInput>;
}>;

export type LoginWithGoogleOneTapV2Mutation = {
  __typename?: "Mutation";
  loginOrCreateAccountWithGoogleV2:
    | { __typename: "LoginError"; error: CreateOrLoginError }
    | {
        __typename: "LoginResultV2";
        isNewUser: boolean;
        authentication: {
          __typename?: "AuthenticationV2";
          sessionToken: string;
          refreshToken: string;
        };
      };
};

export type SessionRefreshV2MutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars["String"]>;
  browserTimeZone?: InputMaybe<Scalars["String"]>;
  doNotTrack?: InputMaybe<Scalars["Boolean"]>;
  refreshSource?: InputMaybe<TokenRefreshSource>;
}>;

export type SessionRefreshV2Mutation = {
  __typename?: "Mutation";
  refreshV2: {
    __typename: "AuthenticationV2";
    refreshToken: string;
    sessionToken: string;
  };
};

export type SignupModalQueryVariables = Exact<{
  activityUid?: InputMaybe<Scalars["ID"]>;
  activitySlugId?: InputMaybe<Scalars["String"]>;
  skipActivity: Scalars["Boolean"];
  referrerSlugId?: InputMaybe<Scalars["String"]>;
  skipReferrer: Scalars["Boolean"];
  leaderUid?: InputMaybe<Scalars["ID"]>;
  leaderLink?: InputMaybe<Scalars["String"]>;
  skipLeader: Scalars["Boolean"];
}>;

export type SignupModalQuery = {
  __typename?: "Query";
  activity?: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    assessment: string | null;
    homework: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    summary: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    hasTeacherSchedule: boolean;
    allows_recurring_payment: boolean | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
    };
    details: { __typename?: "ActivityDetails"; photo: string | null };
  };
  leader: {
    __typename?: "PublicProfile";
    uid: string;
    leader: { __typename?: "Leader"; name: string | null } | null;
  } | null;
  referrer: {
    __typename?: "PublicProfile";
    uid: string;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      cityState: string | null;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      socialOptOut: boolean;
      hasBackgroundCheckExpired: boolean | null;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type ListingPhoto_ActivityFragment = {
  __typename?: "Activity";
  isClub: boolean;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  clubSection: {
    __typename?: "Section";
    filledSpaceCount: number;
    clubPostCount: number;
  } | null;
};

export type ClassRecordingStatusQueryVariables = Exact<{
  recordingUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
}>;

export type ClassRecordingStatusQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    classRecording:
      | {
          __typename?: "ClassRecording";
          readyToPlay: boolean | null;
          isExpired: boolean;
          uid: string;
          playUrl: string | null;
        }
      | { __typename?: "ZoomRecording"; uid: string; playUrl: string };
  } | null;
};

export type AssignmentCommentFragment = {
  __typename?: "AssignmentClassPostComment";
  uid: string;
  sentAt: any;
  threadForLearnerUid: string;
  isSubmission: boolean | null;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type AssignmentCommentThreadFragment = {
  __typename?: "AssignmentClassPostComment";
  uid: string;
  sentAt: any;
  threadForLearnerUid: string;
  isSubmission: boolean | null;
  replies: Array<{
    __typename?: "AssignmentClassPostComment";
    uid: string;
    sentAt: any;
    threadForLearnerUid: string;
    isSubmission: boolean | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }> | null;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type AssignmentSummaryAssignmentTitleFragment = {
  __typename?: "AssignmentClassPost";
  title: string;
  publishAt: any;
  submissionRequirement: SubmissionRequirementType;
  dueDate: any | null;
  lesson: {
    __typename?: "Lesson";
    uid: string;
    lessonNumber: number | null;
  } | null;
};

export type AssignmentSummaryAssignmentDescriptionFragment = {
  __typename?: "AssignmentClassPost";
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type AssignmentSummaryCardAssignmentFragment = {
  __typename?: "AssignmentClassPost";
  uid: string;
  title: string;
  publishAt: any;
  submissionRequirement: SubmissionRequirementType;
  dueDate: any | null;
  lesson: {
    __typename?: "Lesson";
    uid: string;
    lessonNumber: number | null;
  } | null;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type AssignmentTrackerAssignmentFragment = {
  __typename?: "AssignmentClassPost";
  uid: string;
  title: string;
  submissionRequirement: SubmissionRequirementType;
  dueDate: any | null;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
  comments: Array<{
    __typename?: "AssignmentClassPostComment";
    uid: string;
    sentAt: any;
    isSubmission: boolean | null;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
};

export type AssignmentTrackerSyllabusFragment = {
  __typename?: "Syllabus";
  uid: string;
  published_at: string | null;
  lessons: Array<{
    __typename?: "Lesson";
    uid: string;
    lessonNumber: number | null;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
      title: string;
      submissionRequirement: SubmissionRequirementType;
      dueDate: any | null;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      comments: Array<{
        __typename?: "AssignmentClassPostComment";
        uid: string;
        sentAt: any;
        isSubmission: boolean | null;
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    }>;
  }>;
};

export type CurrentLearnerAvatarFragmentFragment = {
  __typename: "CurrentLearner";
  avatar: string | null;
};

export type ActivityOverview_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  completedEnrollmentCount: number;
  reviewCount: number;
  averageStarRating: number | null;
  isClub: boolean;
  title: string;
};

export type ActivityOverview_LeaderFragment = {
  __typename?: "Leader";
  uid: string;
  name: string | null;
  suspended_at: any | null;
  teacher_approved_at: any | null;
  reviewCount: number;
  averageActivityStarRating: number | null;
  photo: string | null;
  leader_link: string | null;
  educatorTiers: Array<EducatorTier>;
  details: {
    __typename?: "LeaderDetails";
    headline: string | null;
    about: string | null;
  };
  video: {
    __typename?: "Attachment";
    file: { __typename?: "File"; url: string; thumbUrl: string | null };
  } | null;
  credentials: Array<{
    __typename?: "TeacherCredential";
    uid: string;
    degreeType: DegreeType | null;
    degreeSubject: DegreeSubject | null;
    certificateDiscipline: TeacherCertificateDiscipline | null;
    otherType: string | null;
    certificateState: TeacherCertificateState | null;
    name: string | null;
    degreeInstitution: string | null;
    degreeAdditionalInfo: string | null;
  }>;
  ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
};

export type AttachmentFragmentFragment = {
  __typename?: "Attachment";
  uid: string;
  conversionStatus: string | null;
  created_at: any;
  file: {
    __typename?: "File";
    filename: string;
    mimetype: string | null;
    size: number;
    url: string;
    thumbUrl: string | null;
  };
};

export type AttendanceFragmentFragment = {
  __typename?: "Attendance";
  uid: string;
  created_at: any;
};

export type MeetingPost_ActivityFragment = {
  __typename?: "Activity";
  leader: { __typename?: "Leader"; uid: string; name: string | null };
};

export type MeetingPost_SectionFragment = {
  __typename?: "Section";
  uid: string;
  leader: { __typename?: "Leader"; uid: string; name: string | null };
};

export type MeetingPost_MeetingFragment = {
  __typename?: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  recordingAvailability: RecordingAvailability;
  recordingRequests: Array<{
    __typename?: "RecordingRequest";
    requestedAt: any;
    message: string;
    requestedBy: { __typename?: "Learner"; uid: string; name: string | null };
  }>;
  classRecordings: Array<
    | {
        __typename?: "ClassRecording";
        uid: string;
        startTime: any;
        endTime: any;
      }
    | {
        __typename?: "ZoomRecording";
        uid: string;
        startTime: any;
        endTime: any;
      }
  >;
  attendances: Array<{
    __typename?: "Attendance";
    learner: { __typename?: "Learner"; name: string | null };
  }>;
};

export type MeetingPost_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
};

export type ClassPost_AttachmentFragment = {
  __typename?: "Attachment";
  uid: string;
  conversionStatus: string | null;
  created_at: any;
  file: {
    __typename?: "File";
    filename: string;
    mimetype: string | null;
    size: number;
    url: string;
    thumbUrl: string | null;
  };
};

export type ClassPost_SenderFragment = {
  __typename?: "MessageSender";
  uid: string;
  name: string | null;
  photo: string | null;
  avatar: string | null;
  isLearner: boolean;
};

export type ClassPost_MessageContentFragment = {
  __typename?: "MessageContent";
  uid: string;
  text: string | null;
  originalMimeType: TextMimeType;
  editedAt: any | null;
  htmlText: string | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  }>;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type ClassPost_CommentFragment = {
  __typename?: "ClassPostComment";
  uid: string;
  sendAt: any;
  mentionedEnrollmentUids: Array<string>;
  mentionedTeacherUid: string | null;
  backgroundColor: string | null;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type ClassPost_ClassPostWithOnlyCommentsFragment = {
  __typename?: "ClassPost";
  uid: string;
  comments: Array<{
    __typename?: "ClassPostComment";
    uid: string;
    sendAt: any;
    mentionedEnrollmentUids: Array<string>;
    mentionedTeacherUid: string | null;
    backgroundColor: string | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
};

export type ClassPost_ClassPostWithCommentsFragment = {
  __typename?: "ClassPost";
  publishAt: any;
  isWelcomePost: boolean;
  uid: string;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
  comments: Array<{
    __typename?: "ClassPostComment";
    uid: string;
    sendAt: any;
    mentionedEnrollmentUids: Array<string>;
    mentionedTeacherUid: string | null;
    backgroundColor: string | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
};

export type ClassPost_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
  name: string | null;
};

export type ClassPost_ClassPostFragment = {
  __typename?: "ClassPost";
  uid: string;
  isWelcomePost: boolean;
  publishAt: any;
  title: string | null;
  promotedAt: any | null;
  pinnedAt: any | null;
  mentionedEnrollmentUids: Array<string>;
  backgroundColor: string | null;
  score: number | null;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
  commentsPreview: {
    __typename?: "CommentsPreview";
    remainingCommentsCount: number;
    comments: Array<{
      __typename?: "ClassPostComment";
      uid: string;
      sendAt: any;
      mentionedEnrollmentUids: Array<string>;
      mentionedTeacherUid: string | null;
      backgroundColor: string | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    }>;
  };
};

export type ClassPost_ActivityFragment = {
  __typename?: "Activity";
  user_uid: string;
  leader: { __typename?: "Leader"; uid: string; leader_link: string | null };
};

export type ClassPost_SectionFragment = {
  __typename?: "Section";
  uid: string;
  currentUserCanManage: boolean;
  leader: { __typename?: "Leader"; uid: string };
};

export type ClassPost_MentionNamesFragment = {
  __typename?: "MentionName";
  id: string;
  display: string;
  avatar: string | null;
  startTime: any | null;
};

export type ClassPostList_MeetingFragment = {
  __typename?: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  recordingAvailability: RecordingAvailability;
  recordingRequests: Array<{
    __typename?: "RecordingRequest";
    requestedAt: any;
    message: string;
    requestedBy: { __typename?: "Learner"; uid: string; name: string | null };
  }>;
  classRecordings: Array<
    | {
        __typename?: "ClassRecording";
        uid: string;
        startTime: any;
        endTime: any;
      }
    | {
        __typename?: "ZoomRecording";
        uid: string;
        startTime: any;
        endTime: any;
      }
  >;
  attendances: Array<{
    __typename?: "Attendance";
    learner: { __typename?: "Learner"; name: string | null };
  }>;
};

export type ClassPostList_ClassroomFragment = {
  __typename?: "Classroom";
  usersCanPost: boolean;
  usersCanComment: boolean;
  posts: Array<{
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  }>;
  unpublishedPosts: Array<{
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  }>;
  activity: {
    __typename?: "Activity";
    user_uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      leader_link: string | null;
      name: string | null;
    };
  };
  section: {
    __typename?: "Section";
    currentUserCanManage: boolean;
    uid: string;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      recordingAvailability: RecordingAvailability;
      recordingRequests: Array<{
        __typename?: "RecordingRequest";
        requestedAt: any;
        message: string;
        requestedBy: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        };
      }>;
      classRecordings: Array<
        | {
            __typename?: "ClassRecording";
            uid: string;
            startTime: any;
            endTime: any;
          }
        | {
            __typename?: "ZoomRecording";
            uid: string;
            startTime: any;
            endTime: any;
          }
      >;
      attendances: Array<{
        __typename?: "Attendance";
        learner: { __typename?: "Learner"; name: string | null };
      }>;
    }>;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  };
  mentionNames: Array<{
    __typename?: "MentionName";
    id: string;
    display: string;
    avatar: string | null;
    startTime: any | null;
  }>;
};

export type ClassPostList_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
  name: string | null;
};

export type ClassroomLearnerList_ProfileFragment = {
  __typename?: "LearnerProfile";
  uid: string;
  name: string | null;
  avatar: string | null;
};

export type LearnerFragmentFragment = {
  __typename?: "Learner";
  uid: string;
  name: string | null;
  age: number | null;
  avatar: string | null;
  pronoun: string | null;
  intro: string | null;
  deleted_at: any | null;
  isVerified: boolean;
  isBlocked: boolean | null;
  blockedByUserUid: string | null;
  blockedAt: any | null;
};

export type MeetingFragmentFragment = {
  __typename?: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  deleted_at: any | null;
  created_at: any | null;
};

export type OneOnOneMeetingFragmentFragment = {
  __typename?: "Meeting";
  refundableAmountCents: number;
  paidAt: any | null;
  recurring: boolean | null;
};

export type MeetingWithAttendancesAndRecordingsFragment = {
  __typename?: "Meeting";
  recordingAvailability: RecordingAvailability;
  uid: string;
  start_time: any;
  end_time: any;
  deleted_at: any | null;
  created_at: any | null;
  attendances: Array<{
    __typename?: "Attendance";
    uid: string;
    created_at: any;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    };
  }>;
  classRecordings: Array<
    | {
        __typename?: "ClassRecording";
        uid: string;
        recordingId: string;
        startTime: any;
        endTime: any;
      }
    | {
        __typename?: "ZoomRecording";
        uid: string;
        recordingId: string;
        startTime: any;
        endTime: any;
      }
  >;
  recordingRequests: Array<{
    __typename?: "RecordingRequest";
    requestedAt: any;
    message: string;
    requestedBy: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    };
  }>;
};

export type MessageContentFragmentFragment = {
  __typename?: "MessageContent";
  uid: string;
  text: string | null;
  originalMimeType: TextMimeType;
  editedAt: any | null;
  htmlText: string | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  }>;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type MessageContentWithMentionsFragmentFragment = {
  __typename?: "MessageContent";
  uid: string;
  text: string | null;
  originalMimeType: TextMimeType;
  htmlText: string | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  }>;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type MessageSenderFragmentFragment = {
  __typename?: "MessageSender";
  uid: string;
  name: string | null;
  photo: string | null;
  avatar: string | null;
  isLearner: boolean;
};

export type PrivateClassMessageFragmentFragment = {
  __typename?: "PrivateClassMessage";
  uid: string;
  thread_uid: string;
  sentAt: any;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type RecordingRequestFragmentFragment = {
  __typename?: "RecordingRequest";
  requestedAt: any;
  message: string;
  requestedBy: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
  };
};

export type AddAttachmentMutationVariables = Exact<{
  file: FileInput;
  convert?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddAttachmentMutation = {
  __typename?: "Mutation";
  addAttachment: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  };
};

export type UpdateClassPostInClubMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
  messageContentInput?: InputMaybe<MessageContentInput>;
  title?: InputMaybe<Scalars["String"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateClassPostInClubMutation = {
  __typename?: "Mutation";
  updateClassPost: {
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  };
};

export type UpdateClassPostCommentMutationVariables = Exact<{
  classPostCommentUid: Scalars["ID"];
  messageContentInput?: InputMaybe<MessageContentInput>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateClassPostCommentMutation = {
  __typename?: "Mutation";
  updateClassPostComment: {
    __typename?: "ClassPostComment";
    uid: string;
    sendAt: any;
    mentionedEnrollmentUids: Array<string>;
    mentionedTeacherUid: string | null;
    backgroundColor: string | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type AddClassPostCommentMutationVariables = Exact<{
  classPostCommentInput: ClassPostCommentInput;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddClassPostCommentMutation = {
  __typename?: "Mutation";
  addClassPostComment: {
    __typename?: "ClassPostComment";
    uid: string;
    sendAt: any;
    mentionedEnrollmentUids: Array<string>;
    mentionedTeacherUid: string | null;
    backgroundColor: string | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type DeleteClassPostCommentMutationVariables = Exact<{
  classPostCommentUid: Scalars["ID"];
}>;

export type DeleteClassPostCommentMutation = {
  __typename?: "Mutation";
  deleteClassPostComment: boolean | null;
};

export type AddClassPostCommentReplyMutationVariables = Exact<{
  classPostCommentReplyInput: ClassPostCommentReplyInput;
}>;

export type AddClassPostCommentReplyMutation = {
  __typename?: "Mutation";
  addClassPostCommentReply: {
    __typename?: "ClassPostComment";
    uid: string;
    sendAt: any;
    mentionedEnrollmentUids: Array<string>;
    mentionedTeacherUid: string | null;
    backgroundColor: string | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type PromoteClassPostMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
}>;

export type PromoteClassPostMutation = {
  __typename?: "Mutation";
  promoteClassPost: boolean | null;
};

export type PinClassPostMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
}>;

export type PinClassPostMutation = {
  __typename?: "Mutation";
  pinClassPost: boolean;
};

export type UnpinClassPostMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
}>;

export type UnpinClassPostMutation = {
  __typename?: "Mutation";
  unpinClassPost: boolean;
};

export type SetMeetingRecordingsShownMutationVariables = Exact<{
  meetingUid: Scalars["ID"];
  isShown: Scalars["Boolean"];
  reason?: InputMaybe<Scalars["String"]>;
}>;

export type SetMeetingRecordingsShownMutation = {
  __typename?: "Mutation";
  setMeetingRecordingsShown: {
    __typename?: "Meeting";
    recordingAvailability: RecordingAvailability;
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
    attendances: Array<{
      __typename?: "Attendance";
      uid: string;
      created_at: any;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
    }>;
    classRecordings: Array<
      | {
          __typename?: "ClassRecording";
          uid: string;
          recordingId: string;
          startTime: any;
          endTime: any;
        }
      | {
          __typename?: "ZoomRecording";
          uid: string;
          recordingId: string;
          startTime: any;
          endTime: any;
        }
    >;
    recordingRequests: Array<{
      __typename?: "RecordingRequest";
      requestedAt: any;
      message: string;
      requestedBy: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
    }>;
  };
};

export type RequestMeetingRecordingsShownMutationVariables = Exact<{
  meetingUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
}>;

export type RequestMeetingRecordingsShownMutation = {
  __typename?: "Mutation";
  requestMeetingRecordingsShown: {
    __typename?: "Meeting";
    recordingAvailability: RecordingAvailability;
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
    attendances: Array<{
      __typename?: "Attendance";
      uid: string;
      created_at: any;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
    }>;
    classRecordings: Array<
      | {
          __typename?: "ClassRecording";
          uid: string;
          recordingId: string;
          startTime: any;
          endTime: any;
        }
      | {
          __typename?: "ZoomRecording";
          uid: string;
          recordingId: string;
          startTime: any;
          endTime: any;
        }
    >;
    recordingRequests: Array<{
      __typename?: "RecordingRequest";
      requestedAt: any;
      message: string;
      requestedBy: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
    }>;
  };
};

export type UpdateFilestackMetadataMutationVariables = Exact<{
  handle: Scalars["String"];
  osS3Path: Scalars["String"];
}>;

export type UpdateFilestackMetadataMutation = {
  __typename?: "Mutation";
  updateFilestackMetadata: boolean;
};

export type AddPrivateClassMessageMutationVariables = Exact<{
  privateClassMessageInput: PrivateClassMessageInput;
}>;

export type AddPrivateClassMessageMutation = {
  __typename?: "Mutation";
  addPrivateClassMessage: {
    __typename?: "PrivateClassMessage";
    uid: string;
    thread_uid: string;
    sentAt: any;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type MarkPrivateClassMessageThreadAsReadMutationVariables = Exact<{
  threadUid: Scalars["ID"];
}>;

export type MarkPrivateClassMessageThreadAsReadMutation = {
  __typename?: "Mutation";
  markPrivateClassMessageThreadAsRead: boolean;
};

export type ClubPostQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  classPostUid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
  creationDateSortOrder?: InputMaybe<SortOrder>;
}>;

export type ClubPostQuery = {
  __typename?: "Query";
  club: {
    __typename?: "Club";
    uid: string;
    currentLearnerIsVerified: boolean;
    currentLearnerMustAcceptGuidelines: boolean;
    currentLearnerHasSubmittedIntroductionForClub: boolean;
    currentUserCanParticipate: boolean;
    section: {
      __typename?: "Section";
      uid: string;
      currentUserCanManage: boolean;
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
      user_uid: string;
      isClub: boolean;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      clubGuidelines: {
        __typename?: "WelcomeMessage";
        text: string | null;
      } | null;
    };
    postByUid: {
      __typename?: "ClassPost";
      uid: string;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      backgroundColor: string | null;
      mentionedEnrollmentUids: Array<string>;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        replies: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    } | null;
    mentionNames: Array<{
      __typename?: "MentionName";
      id: string;
      display: string;
      avatar: string | null;
      startTime: any | null;
    }>;
  };
};

export type ClubPostAndCommentBackgroundColorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ClubPostAndCommentBackgroundColorsQuery = {
  __typename?: "Query";
  clubPostAndCommentBackgroundColors: Array<string>;
};

export type AllClassPostCommentsQueryQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  classPostUid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AllClassPostCommentsQueryQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    postByUid: {
      __typename?: "ClassPost";
      uid: string;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    } | null;
  };
};

export type PrivateClassMessagesQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
}>;

export type PrivateClassMessagesQuery = {
  __typename?: "Query";
  privateClassMessages: Array<{
    __typename?: "PrivateClassMessage";
    uid: string;
    thread_uid: string;
    sentAt: any;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
};

export type LiveMeetingButtonQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid?: InputMaybe<Scalars["ID"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
}>;

export type LiveMeetingButtonQuery = {
  __typename?: "Query";
  joinMeeting: {
    __typename?: "JoinMeeting";
    uid: string;
    canManage: boolean;
    meetingStartTime: any | null;
    meetingEndTime: any | null;
    usersCanHaveLiveMeeting: boolean | null;
    isFlexSchedule: boolean;
    meetingsCount: number;
    sectionStartTime: any | null;
    enrollmentUid: string | null;
    isSellerOrg: boolean | null;
  };
};

export type LearnerLiveMeetingQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  lessonUid: Scalars["ID"];
}>;

export type LearnerLiveMeetingQuery = {
  __typename?: "Query";
  lessonLiveMeeting: {
    __typename?: "LessonLiveMeeting";
    uid: string;
    enrollmentUid: string | null;
    classRecordingUids: Array<string | null>;
    sectionStartTime: any | null;
    meetingStartTime: any | null;
    meetingEndTime: any | null;
    meetingsCount: number;
    usersCanHaveLiveMeeting: boolean | null;
  };
};

export type JoinNextMeetingQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  enrollmentUid?: InputMaybe<Scalars["ID"]>;
}>;

export type JoinNextMeetingQuery = {
  __typename?: "Query";
  joinNextMeeting: {
    __typename?: "NextMeeting";
    uid: string;
    currentUserCanManage: boolean;
    url: string;
    shouldMarkAttendance: boolean;
  };
};

export type LearnerProfileQueryVariables = Exact<{
  learnerUid: Scalars["ID"];
}>;

export type LearnerProfileQuery = {
  __typename?: "Query";
  learnerProfile: {
    __typename?: "LearnerProfile";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    aboutMe: string | null;
    isProfileAgeHidden: boolean;
    isProfileLocationHidden: boolean;
    location: {
      __typename?: "ApproximateLocation";
      state: string | null;
      country: string | null;
    } | null;
    favoritedClassList: {
      __typename?: "SavedClassList";
      listings: Array<{
        __typename?: "Listing";
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          slug_id: string;
          age_min: number | null;
          age_max: number | null;
          isClub: boolean;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            details: { __typename?: "LeaderDetails"; headline: string | null };
          };
          details: { __typename?: "ActivityDetails"; photo: string | null };
          video: {
            __typename?: "Attachment";
            uid: string;
            file: { __typename?: "File"; url: string };
          } | null;
          videoForLearners: {
            __typename?: "Attachment";
            uid: string;
            file: { __typename?: "File"; url: string };
          } | null;
        };
      } | null>;
    } | null;
  };
};

export type CurrentLearnerPastActivitiesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type CurrentLearnerPastActivitiesQuery = {
  __typename?: "Query";
  currentLearnerPastActivities: {
    __typename?: "PaginatedPastActivities";
    totalResults: number;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      slug_id: string;
      age_min: number | null;
      age_max: number | null;
      isClub: boolean;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        details: { __typename?: "LeaderDetails"; headline: string | null };
      };
      details: { __typename?: "ActivityDetails"; photo: string | null };
      video: {
        __typename?: "Attachment";
        uid: string;
        file: { __typename?: "File"; url: string };
      } | null;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        file: { __typename?: "File"; url: string };
      } | null;
    }>;
  };
};

export type SaveFavoriteClassToProfileMutationVariables = Exact<{
  activityUids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type SaveFavoriteClassToProfileMutation = {
  __typename?: "Mutation";
  saveLearnerProfileClasses: {
    __typename?: "SavedClassList";
    listings: Array<{
      __typename?: "Listing";
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        age_min: number | null;
        age_max: number | null;
        isClub: boolean;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          details: { __typename?: "LeaderDetails"; headline: string | null };
        };
        details: { __typename?: "ActivityDetails"; photo: string | null };
        video: {
          __typename?: "Attachment";
          uid: string;
          file: { __typename?: "File"; url: string };
        } | null;
        videoForLearners: {
          __typename?: "Attachment";
          uid: string;
          file: { __typename?: "File"; url: string };
        } | null;
      };
    } | null>;
  };
};

export type SaveLearnerProfileMutationVariables = Exact<{
  profileInput: LearnerProfileInput;
}>;

export type SaveLearnerProfileMutation = {
  __typename?: "Mutation";
  saveLearnerProfile: {
    __typename?: "LearnerProfile";
    uid: string;
    avatar: string | null;
    aboutMe: string | null;
  };
};

export type SaveLearnerProfileSettingsMutationVariables = Exact<{
  learnerProfileSettingsInput: LearnerProfileSettingsInput;
}>;

export type SaveLearnerProfileSettingsMutation = {
  __typename?: "Mutation";
  saveLearnerProfileSettings: boolean;
};

export type ClubQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  score?: InputMaybe<Scalars["Float"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
  hasCurrentUserEnrollments: Scalars["Boolean"];
  postLimit?: InputMaybe<Scalars["Int"]>;
  scoreFrom?: InputMaybe<Scalars["DateTime"]>;
}>;

export type ClubQuery = {
  __typename?: "Query";
  club: {
    __typename?: "Club";
    uid: string;
    currentLearnerIsVerified: boolean;
    currentLearnerMustAcceptGuidelines: boolean;
    currentLearnerHasSubmittedIntroductionForClub: boolean;
    currentUserCanParticipate: boolean;
    section: {
      __typename?: "Section";
      uid: string;
      published_at: any | null;
      deleted_at: any | null;
      canceled_at: any | null;
      start_time: any | null;
      end_time: any | null;
      size_max: number | null;
      currentUserCanManage: boolean;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
      } | null;
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
      description: string | null;
      user_uid: string;
      isClub: boolean;
      published_at: any | null;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      details: { __typename?: "ActivityDetails"; photo: string | null };
      clubGuidelines: {
        __typename?: "WelcomeMessage";
        text: string | null;
      } | null;
      clubBannerImage: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      clubWelcomeVideo: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    pinnedPosts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
    posts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
    mentionNames: Array<{
      __typename?: "MentionName";
      id: string;
      display: string;
      avatar: string | null;
      startTime: any | null;
    }>;
    currentUserEnrollments?: Array<{
      __typename?: "Enrollment";
      uid: string;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      isPaused: boolean;
      isEnded: boolean;
      usersCanCommunicate: boolean;
      learner: { __typename?: "Learner"; uid: string } | null;
    }>;
  };
};

export type ClubPostsQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
  postLimit?: InputMaybe<Scalars["Int"]>;
  score?: InputMaybe<Scalars["Float"]>;
  scoreFrom?: InputMaybe<Scalars["DateTime"]>;
}>;

export type ClubPostsQuery = {
  __typename?: "Query";
  club: {
    __typename?: "Club";
    posts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
  };
};

export type AutoMarkAttendanceMutationVariables = Exact<{
  sectionUid?: InputMaybe<Scalars["ID"]>;
}>;

export type AutoMarkAttendanceMutation = {
  __typename?: "Mutation";
  markAttended: boolean;
};

export type AutoMarkTeacherAttendanceMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type AutoMarkTeacherAttendanceMutation = {
  __typename?: "Mutation";
  markTeacherAttended: boolean;
};

export type MuxClassroomRecordingQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  recordingUid: Scalars["ID"];
}>;

export type MuxClassroomRecordingQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    usersCanPost: boolean;
    activity: {
      __typename?: "Activity";
      uid: string;
      user_uid: string;
      title: string;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      is_ongoing_weekly: boolean;
      slug_id: string;
      isClub: boolean;
      published_at: any | null;
      hasTeacherSchedule: boolean;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
    };
    section: {
      __typename?: "Section";
      uid: string;
      currentUserCanManage: boolean;
      start_time: any | null;
      end_time: any | null;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      classRecording:
        | {
            __typename?: "ClassRecording";
            isExpired: boolean;
            playUrl: string | null;
            uid: string;
            startTime: any;
            endTime: any;
            meeting: {
              __typename?: "Meeting";
              uid: string;
              start_time: any;
              end_time: any;
              recordingAvailability: RecordingAvailability;
              recordingRequests: Array<{
                __typename?: "RecordingRequest";
                requestedAt: any;
                message: string;
                requestedBy: {
                  __typename?: "Learner";
                  uid: string;
                  name: string | null;
                };
              }>;
              classRecordings: Array<
                | {
                    __typename?: "ClassRecording";
                    uid: string;
                    startTime: any;
                    endTime: any;
                  }
                | {
                    __typename?: "ZoomRecording";
                    uid: string;
                    startTime: any;
                    endTime: any;
                  }
              >;
              attendances: Array<{
                __typename?: "Attendance";
                learner: { __typename?: "Learner"; name: string | null };
              }>;
            } | null;
          }
        | {
            __typename?: "ZoomRecording";
            uid: string;
            startTime: any;
            endTime: any;
            meeting: {
              __typename?: "Meeting";
              uid: string;
              start_time: any;
              end_time: any;
              recordingAvailability: RecordingAvailability;
              recordingRequests: Array<{
                __typename?: "RecordingRequest";
                requestedAt: any;
                message: string;
                requestedBy: {
                  __typename?: "Learner";
                  uid: string;
                  name: string | null;
                };
              }>;
              classRecordings: Array<
                | {
                    __typename?: "ClassRecording";
                    uid: string;
                    startTime: any;
                    endTime: any;
                  }
                | {
                    __typename?: "ZoomRecording";
                    uid: string;
                    startTime: any;
                    endTime: any;
                  }
              >;
              attendances: Array<{
                __typename?: "Attendance";
                learner: { __typename?: "Learner"; name: string | null };
              }>;
            } | null;
          };
    };
  };
};

export type SectionRibbon_SectionFragment = {
  __typename?: "Section";
  size_max: number | null;
  filledSpaceCount: number;
};

export type SectionRibbon_ActivityFragment = {
  __typename?: "Activity";
  clubSection: {
    __typename?: "Section";
    filledSpaceCount: number;
    clubPostCount: number;
  } | null;
};

export type SampleClubPost_WelcomeMessageFragment = {
  __typename?: "WelcomeMessage";
  text: string | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    file: { __typename?: "File"; url: string; mimetype: string | null };
  }> | null;
  video: {
    __typename?: "Attachment";
    uid: string;
    file: { __typename?: "File"; url: string; mimetype: string | null };
  } | null;
};
