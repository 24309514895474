import { Box, Image, SxProps, Theme, Typography } from "@outschool/backpack";
import {
  participationGuidelinesUrl,
  privacyForLearnersUrl
} from "@outschool/routes";
import { dayjs } from "@outschool/time";
import { ExternalLink } from "@outschool/ui-components-shared";
import { Container, Space } from "@outschool/ui-legacy-component-library";
import { responsive } from "@outschool/ui-utils";
import { usePath } from "@patched/hookrouter";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import heartIconPng from "../../../../images/heart-icon.png";
import loveLearningTextPng from "../../../../images/love-learning-text.png";
import Link from "../../components/Link";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { LearnerAccessLevel } from "../../generated/graphql";
import { useIsLearnerLogin } from "../../hooks/useIsLearnerLogin";
import { hasAccess } from "../../lib/LearnerAccess";
import {
  REPORT_ISSUE_REFERRER_PATH_NAME,
  learnerRoutes
} from "../../lib/Routes";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";

type FooterProps = {
  sx?: SxProps;
  innerProps?: {
    sx: SystemStyleObject;
  };
};

export default function Footer({ innerProps, sx }: FooterProps) {
  const currentLearner = useCurrentLearner()!;
  const isLearnerLogin = useIsLearnerLogin();
  const imageHeight = 24;
  const path = usePath();

  const hasFullAccess =
    isLearnerLogin &&
    hasAccess({
      currentLearner,
      minimumAccessLevel: LearnerAccessLevel.Full
    });
  return (
    <Box sx={{ marginTop: "auto" }}>
      <Box
        sx={[
          (theme: Theme) => ({
            width: "100%",
            backgroundColor: "primary.900",
            paddingY: "1em",

            [theme.breakpoints.up("sm")]: {
              paddingY: "2em"
            }
          }),
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
      >
        <Container
          sx={{
            flexDirection: responsive({ default: "column", small: "row" }),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            ...innerProps?.sx
          }}
        >
          <Box
            flex
            sx={theme => ({
              paddingBottom: "0.5em",

              [theme.breakpoints.up("sm")]: {
                paddingBottom: 0
              }
            })}
          >
            <Image
              sx={{
                width: imageHeight,
                height: imageHeight
              }}
              src={heartIconPng}
            />
            <Space x="small" />
            <Image
              sx={{
                height: imageHeight
              }}
              src={loveLearningTextPng}
            />
          </Box>

          <Box
            flex
            sx={theme => ({
              flexDirection: "column",

              [theme.breakpoints.up("sm")]: {
                flexDirection: "row"
              },

              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",

              a: {
                color: "#fff",
                margin: "0 0 8px 0",

                [theme.breakpoints.up("sm")]: {
                  margin: "0 32px 0 0"
                }
              },

              "a:hover, a:focus": {
                color: "#0fa0ce"
              }
            })}
          >
            {hasFullAccess && (
              <>
                <ExternalLink
                  url={privacyForLearnersUrl()}
                  trackingName="learner_privacy_link"
                  trackingSharedProperties={{
                    ignoreNetworkError: true
                  }}
                >
                  Privacy Guide
                </ExternalLink>
                <Link
                  to={learnerRoutes.reportIssuePath()}
                  trackingName="footer.help"
                  navigateQueryParams={{
                    [REPORT_ISSUE_REFERRER_PATH_NAME]: path
                  }}
                >
                  Help
                </Link>
              </>
            )}
            {!hasFullAccess && (
              <ExternalLink
                url={participationGuidelinesUrl()}
                trackingName="learner_participation_link"
                trackingSharedProperties={{
                  ignoreNetworkError: true
                }}
              >
                Participation Guide
              </ExternalLink>
            )}
            <Typography
              variant="inherit"
              sx={{
                color: "common.white"
              }}
            >
              © {dayjs().format("YYYY")} Outschool, Inc.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
