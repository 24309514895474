import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { ErrorMessage } from "@outschool/ui-components-shared";
import React, { useContext } from "react";

import * as Env from "../Env";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  CurrentLearnerFragmentFragment,
  CurrentLearnerQuery
} from "../generated/graphql";
import { EnrollmentToReviewSoonFragment } from "../hooks/useLearnerSectionReview";
import useRefreshSessionTokenRepeater from "../hooks/useRefreshSessionTokenRepeater";
import { useLearnerAuth } from "./LearnerAuthProvider";

export const CurrentLearnerFragment = gql`
  fragment CurrentLearnerFragment on CurrentLearner {
    uid
    name
    email
    username
    age
    avatar
    unlockedAvatars
    pronoun
    accessLevel
    intro
    timeZone
    optedOutOfPersonalizedRecommendationsAt
    hasUpcomingScheduleEvent
    isLoginEnabled
    isVerified
    parent {
      uid
      name
      photo
      publicName
      learnerModeSwitchAuthRequirement
    }
    siblings {
      uid
      name
      avatar
      age
    }
    enrollmentToReviewSoon {
      ...EnrollmentToReviewSoonFragment
    }
    experiments {
      name
      variant
    }
    interfacePreferences {
      name
      value
    }
  }
  ${EnrollmentToReviewSoonFragment}
`;

export const getCurrentLearner = gql`
  query CurrentLearner {
    currentLearner {
      ...CurrentLearnerFragment
    }
  }
  ${CurrentLearnerFragment}
`;

export type CurrentLearner = CurrentLearnerFragmentFragment;

const CurrentLearnerContext = React.createContext<CurrentLearner | undefined>(
  undefined
);

const CurrentLearnerProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children
}) => {
  useRefreshSessionTokenRepeater();
  const { sessionTokenStore } = useLearnerAuth();
  const { data, loading, error } =
    useQueryWithPreviousData<CurrentLearnerQuery>(getCurrentLearner, {
      skip: !sessionTokenStore?.getLearnerAuth()?.isLoggedIn
    });
  const currentLearner = data?.currentLearner;

  if (loading) {
    // TODO providers should not handle loading, handle in a child component.
    return null;
  }

  return (
    <CurrentLearnerContext.Provider value={currentLearner}>
      {error && (
        <ErrorMessage
          value={error}
          showStatusPageLink={Env.IS_READ_ONLY_MODE}
        />
      )}
      {children}
    </CurrentLearnerContext.Provider>
  );
};

export const useCurrentLearner = () => useContext(CurrentLearnerContext);

export default CurrentLearnerProvider;
