import { Box } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Activity } from "@outschool/gql-backend-generated";
import { createPathWithActivity } from "@outschool/routes";
import React from "react";

import SaveButton from "../../components/SaveButton";
import { useIsLearnerLogin } from "../../hooks/useIsLearnerLogin";
import { LearnerSwitcher } from "../../layout/nav/LearnerSwitcher";

type ClassDetailsActionButtonActivity = Pick<
  Activity,
  "uid" | "isClub" | "slug_id" | "title"
>;

export default function ClassDetailsActionButtons({
  activity
}: {
  activity: ClassDetailsActionButtonActivity;
}) {
  const isLearnerLogin = useIsLearnerLogin();
  return (
    <Box
      flex
      sx={{
        alignItems: "center",
        gap: "1em",
        flexWrap: "wrap",
        marginTop: "1em",
        marginBottom: "2em",
        marginX: "0.5em"
      }}
    >
      {!isLearnerLogin && (
        <LearnerSwitcher
          switcherType="button"
          redirect={createPathWithActivity(activity)}
          buttonProps={{
            title: <>Enroll now!</>,
            variant: "contained",
            trackingName: "browse.class.purchase_button"
          }}
          onlyParent={true}
          title="Find an adult to enroll!"
        />
      )}
      <SaveButton
        variant="contained"
        setLabel="Saved!"
        unsetLabel="Save as Favorite"
        activity={activity}
        unfilledHeartColor="white"
        trackingPrefix="browse.class"
      />
    </Box>
  );
}
