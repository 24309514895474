import { Box, Image, Typography } from "@outschool/backpack";
import { useLinkComponent } from "@outschool/ui-utils";
import React from "react";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { CurrentLearnerRecommendedTeachersQuery } from "../../generated/graphql";
import { teacherSearchResultPath } from "../../lib/Routes";

const DEFAULT_PROFILE_IMAGE =
  "https://cdn.filestackcontent.com/26Xc9cgQFmpVTv8h268V";

interface TeacherCardProps {
  teacher: CurrentLearnerRecommendedTeachersQuery["currentLearnerRecommendedTeachers"]["teachers"][0];
  index: number;
}

function TeacherCard({ teacher, index }: TeacherCardProps) {
  const Link = useLinkComponent();

  return (
    <Link
      to={teacherSearchResultPath(teacher.leader_link!)}
      trackingName={`browse.recommended_teacher.${teacher.uid}`}
      style={{
        position: "relative",
        top: index % 2 === 0 ? 0 : 30
      }}
    >
      <Box
        flex
        sx={theme => ({
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "center",
          paddingX: "1em",
          paddingBottom: "2em",

          [theme.breakpoints.up("md")]: {
            paddingBottom: "80px",
            flexDirection: "column"
          },

          flexDirection: index % 2 === 0 ? "row" : "row-reverse"
        })}
      >
        <TeacherPhotoWithClipPath width={"100%"} variantIndex={index % 3}>
          <Image
            src={teacher?.photo || DEFAULT_PROFILE_IMAGE}
            sx={{
              width: "100%"
            }}
            alt="Go to teacher profile page"
          />
        </TeacherPhotoWithClipPath>
        <TeacherNameAndHeadline teacher={teacher} />
      </Box>
    </Link>
  );
}

function TeacherNameAndHeadline({ teacher }) {
  return (
    <Box
      flex
      sx={theme => ({
        color: "grey.900",
        lineHeight: "30px",
        borderRadius: 16,
        marginTop: "1em",
        padding: "1em",
        marginBottom: "0.5em",
        flexDirection: "column",
        backgroundColor: "#f1f2f3",

        [theme.breakpoints.up("md")]: {
          backgroundColor: "common.white",
          maxWidth: "initial"
        },

        maxWidth: "50%"
      })}
    >
      <Typography
        variant="inherit"
        sx={{
          fontSize: 16,
          whiteSpace: "nowrap",
          overflow: "hidden",
          lineHeight: "24px",
          textOverflow: "ellipsis",
          maxWidth: "200px"
        }}
      >
        {teacher.name}
      </Typography>
      <Typography
        variant="inherit"
        sx={{
          fontSize: 12,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "grey.500",
          lineHeight: "16px",
          maxWidth: "200px"
        }}
      >
        {teacher.details?.headline}
      </Typography>
    </Box>
  );
}

function TeacherPhotoWithClipPath({
  width = "128px",
  variantIndex = 0,
  children
}) {
  return (
    <svg
      viewBox="0 0 263 263"
      style={{
        width,
        maxWidth: 240,
        minWidth: 128
      }}
    >
      <defs>
        <clipPath id={`teacherPhotoClipPath${variantIndex}`}>
          {variantIndex % 3 === 0 && (
            <path d="M37.261 48.6937C79.3995 9.80862 159.23 -10.6247 214.52 24.7479C251.949 48.6937 268.594 135.217 229.35 191.664C192.627 244.486 138.955 261.389 70.4529 232.513C1.95052 203.637 -4.87747 87.5788 37.261 48.6937Z" />
          )}
          {variantIndex % 3 === 1 && (
            <path d="M50.5401 32.902C95.2874 -11.284 191.111 3.92292 223.12 49.4619C255.13 95.0009 280.338 164.877 230.612 215.223C180.887 265.57 109.196 247.291 50.5401 215.223C-8.11584 183.156 5.79287 77.088 50.5401 32.902Z" />
          )}
          {variantIndex % 3 === 2 && (
            <path d="M227.405 58.352C196.895 20.9992 119.028 3.53739 95.5007 6.90995C42.9099 14.4487 29.1491 50.7682 21.4279 69.4924C13.5349 84.7284 11.4099 113.398 11.4099 145.181C11.4099 173.853 29.9281 202.685 58.3125 224.802C97.4739 255.316 131.323 246.427 167.448 240.857C203.574 235.287 229.948 203.832 239.244 180.568C256.396 137.645 257.914 95.7049 227.405 58.352Z" />
          )}
        </clipPath>
      </defs>
      <foreignObject
        clipPath={`url(#teacherPhotoClipPath${variantIndex})`}
        width="100%"
        height="100%"
      >
        {children}
      </foreignObject>
    </svg>
  );
}

export default TeacherCard;
