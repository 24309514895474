import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import _ from "lodash";
import { useEffect, useState } from "react";

import {
  LearnerSearchActivityByTopicSearchResultsQuery,
  LearnerSearchActivityByTopicSearchResultsQueryVariables
} from "../generated/graphql";
import LearnerActivitySearchResultFragment from "./LearnerActivitySearchResultFragment";

export const LearnerActivityByTopicSearchResults = gql`
  query LearnerSearchActivityByTopicSearchResults(
    $topicUid: ID
    $cursor: String
    $activityExperiences: [ActivityExperience!]
  ) {
    learnerSearchActivityByTopic(
      search: { topicUid: $topicUid, activityExperiences: $activityExperiences }
      cursor: $cursor
    ) {
      cursor
      topicLabel
      activities {
        ...LearnerActivitySearchResultFragment
      }
    }
  }
  ${LearnerActivitySearchResultFragment}
`;

export function useLearnerActivityByTopicSearchResults(
  variables: LearnerSearchActivityByTopicSearchResultsQueryVariables
) {
  const { loading, error, data, fetchMore } = useQueryWithPreviousData<
    LearnerSearchActivityByTopicSearchResultsQuery,
    LearnerSearchActivityByTopicSearchResultsQueryVariables
  >(LearnerActivityByTopicSearchResults, {
    variables,
    notifyOnNetworkStatusChange: true
  });
  const [canFetchMore, setCanFetchMore] = useState<boolean>(true);
  const activities = data?.learnerSearchActivityByTopic.activities || [];
  const cursor = data?.learnerSearchActivityByTopic.cursor ?? undefined;

  useEffect(() => {
    setCanFetchMore(true);
  }, [variables.topicUid]);

  const fetchMoreActivities = () =>
    fetchMore({
      variables: {
        ...variables,
        cursor
      },
      updateQuery: (
        previousResult: LearnerSearchActivityByTopicSearchResultsQuery,
        { fetchMoreResult }
      ) => {
        const moreActivities =
          fetchMoreResult?.learnerSearchActivityByTopic?.activities;
        const cursor = fetchMoreResult?.learnerSearchActivityByTopic?.cursor;
        if (!moreActivities || !cursor) {
          setCanFetchMore(false);
          return previousResult;
        }
        return {
          learnerSearchActivityByTopic: {
            ...previousResult?.learnerSearchActivityByTopic,
            activities: _.uniqBy([...activities, ...moreActivities], "uid"),
            cursor
          }
        };
      }
    });
  return {
    error,
    activities,
    topicLabel: data?.learnerSearchActivityByTopic.topicLabel,
    fetchMoreActivities,
    canFetchMore,
    isFetchingInitial: loading && activities.length === 0,
    isFetchingMore: loading && activities.length > 0,
    isInfiniteScrollEnabled: Boolean(
      canFetchMore && activities.length > 0 && cursor && !loading && !error
    )
  };
}
