import { Image } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import glyph from "../../../../images/Outschool_Glyph.svg";
import logo from "../../../../images/Outschool_Logo_Color.svg";
import logoWhite from "../../../../images/Outschool_Logo_White.svg";

enum LogoStyle {
  Default
}

interface LogoVariants {
  defaultLogo: string;
  whiteTextLogo: string;
}

const LOGO_STYLES: Record<LogoStyle, LogoVariants> = {
  [LogoStyle.Default]: {
    defaultLogo: logo,
    whiteTextLogo: logoWhite
  }
};

interface HeaderLogoProps {
  isWhiteText?: boolean;
  iconOnly?: boolean;
}
const HeaderLogo: React.FC<HeaderLogoProps> = ({
  isWhiteText = false,
  iconOnly = false
}) => {
  const logoStyle = LogoStyle.Default;
  const { defaultLogo, whiteTextLogo } = LOGO_STYLES[logoStyle];

  return (
    <Image
      alt="Outschool"
      sx={{
        width: iconOnly ? 42 : 128
      }}
      src={iconOnly ? glyph : isWhiteText ? whiteTextLogo : defaultLogo}
    />
  );
};

export default HeaderLogo;
