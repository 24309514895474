import { gql } from "@outschool/ui-apollo";
import { AttachmentFragment } from "@outschool/ui-components-classroom";

export default gql`
  fragment LearnerLeaderFragment on Leader {
    uid
    name
    photo
    leader_link
    details {
      about
      headline
    }
    video {
      ...AttachmentFragment
    }
  }
  ${AttachmentFragment}
`;
