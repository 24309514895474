import { LearnerClassroomAssignmentClassPostsBySectionAndLessonQuery } from "@outschool/gql-frontend-generated";
import { ApolloError } from "@outschool/ui-apollo";
import React from "react";

import { useLearnerClassroomAssignmentsBySectionAndLessonQuery } from "../queries/LearnerClassroomAssignmentsBySectionAndLessonQuery";
import { useLearnerClassroomContext } from "./LearnerClassroomProvider";

export type Assignment = NonNullable<
  LearnerClassroomAssignmentClassPostsBySectionAndLessonQuery["assignmentClassPosts"]
>[number];

interface ClassroomAssignmentsByLessonContext {
  loading: boolean;
  error?: ApolloError;
  assignments?: Assignment[];
}
const ClassroomAssignmentsByLessonContext =
  React.createContext<ClassroomAssignmentsByLessonContext>({
    loading: false,
    error: undefined,
    assignments: []
  });

interface ClassroomAssignmentsByLessonProviderProps {
  lessonUid: string;
  children: React.ReactNode;
}
export const ClassroomAssignmentsByLessonProvider = ({
  lessonUid,
  children
}: ClassroomAssignmentsByLessonProviderProps) => {
  const { sectionUid } = useLearnerClassroomContext();

  const { loading, data, error } =
    useLearnerClassroomAssignmentsBySectionAndLessonQuery({
      sectionUid,
      lessonUid
    });

  const { assignmentClassPosts } =
    data || ({} as LearnerClassroomAssignmentClassPostsBySectionAndLessonQuery);

  const value: ClassroomAssignmentsByLessonContext = {
    loading,
    error,
    assignments: assignmentClassPosts || []
  };

  return (
    <ClassroomAssignmentsByLessonContext.Provider value={value}>
      {children}
    </ClassroomAssignmentsByLessonContext.Provider>
  );
};

export const useClassroomAssignmentsContext = () =>
  React.useContext(ClassroomAssignmentsByLessonContext);
