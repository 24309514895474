import { Box, Button, Icon, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { faChevronLeft } from "@outschool/icons";
import { useQueryParams } from "@patched/hookrouter";
import React from "react";

import LearnerSubTabs from "./LearnerSubTabs";

const ACTIVITY_EXPERIENCE_FILTER_KEY = "activityExperience";
const ACTIVITY_EXPERIENCE_FILTER_LABELS = ["All", "Classes", "Groups"];
const ACTIVITY_EXPERIENCE_FILTER_DEFAULT = ACTIVITY_EXPERIENCE_FILTER_LABELS[0];

const usePersistedFilter = () => {
  const [queryParams, setQueryParams] = useQueryParams();

  const setFilter = React.useCallback(
    (newFilter: string) => {
      setQueryParams({
        [ACTIVITY_EXPERIENCE_FILTER_KEY]:
          newFilter === ACTIVITY_EXPERIENCE_FILTER_DEFAULT
            ? undefined
            : newFilter
      });
    },
    [setQueryParams]
  );

  const filterValue =
    queryParams[ACTIVITY_EXPERIENCE_FILTER_KEY] ??
    ACTIVITY_EXPERIENCE_FILTER_DEFAULT;

  return [filterValue, setFilter];
};

const LearnerSearchFilters: React.FC = () => {
  const [filter, setFilter] = usePersistedFilter();
  const tabs = ACTIVITY_EXPERIENCE_FILTER_LABELS.map(label => ({
    label,
    isActive: () => filter === label,
    onSelect: () => setFilter(label)
  }));
  return (
    <Box
      sx={theme => ({
        display: "grid",
        gap: "1em",
        gridTemplateColumns: "1fr",

        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "repeat(3, 1fr)"
        }
      })}
    >
      <Button
        onClick={() => window.history.go(-1)}
        sx={{
          width: "fit-content"
        }}
      >
        <Icon
          icon={faChevronLeft}
          sx={{
            marginRight: "8px"
          }}
        />
        <Typography variant="inherit">Back</Typography>
      </Button>
      <LearnerSubTabs tabs={tabs} />
    </Box>
  );
};

export const useActivityExperiencesFilter = () => {
  const [filter] = usePersistedFilter();
  const activityExperiences = {
    Classes: ["Classroom"],
    Groups: ["Club"]
  }[filter];

  return activityExperiences ?? [];
};

export default LearnerSearchFilters;
