// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Typography } from "@outschool/backpack";
import { Loading } from "@outschool/ui-legacy-component-library";
import React from "react";

import TopicCard from "../../components/TopicCard";
import useLearnerRecommendedTopicsQuery from "../../hooks/useLearnerRecommendedTopicsQuery";

function RecommendedTopics() {
  const { loading, recommendedTopics } = useLearnerRecommendedTopicsQuery();
  if (loading) {
    return <Loading style={{ marginTop: "150px", marginBottom: "150px" }} />;
  }
  if (recommendedTopics.length === 0) {
    return null;
  }

  return (
    <Box
      flex
      sx={{
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginX: "2em"
      }}
      data-test-id="recommended-topics"
    >
      <Typography
        variant="h2"
        sx={{
          marginBottom: "0.5em"
        }}
      >
        Topics you might like
      </Typography>
      <Box
        sx={{
          display: "grid",
          justifyContent: "center",
          justifyItems: "center",
          width: "100%",
          gridTemplateColumns: "repeat(auto-fill,minmax(320px, 1fr))",
          gridTemplateRows: "min-content",
          rowGap: "0.5em",
          columnGap: "2em"
        }}
      >
        {recommendedTopics.map(topic => (
          <TopicCard key={topic.uid} topic={topic} />
        ))}
      </Box>
    </Box>
  );
}

export default RecommendedTopics;
