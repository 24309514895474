// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Global, css } from "@emotion/react";
import { GlobalStyle } from "@outschool/ui-legacy-component-library";
import React from "react";

export default () => (
  <>
    <Global
      styles={css`
        ${reducedMotionAnimationStyle}
      `}
    />
    <GlobalStyle />
  </>
);

// from: https://piccalil.li/blog/a-modern-css-reset
const reducedMotionAnimationStyle = `
    /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
    @media (prefers-reduced-motion: reduce) {
      html:focus-within {
       scroll-behavior: auto;
      }

      *,
      *::before,
      *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
      }
    }
`;
