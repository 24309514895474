import { Box, Image, Typography } from "@outschool/backpack";
import { ErrorMessage } from "@outschool/ui-components-shared";
import { Loading, Space } from "@outschool/ui-legacy-component-library";
import {
  useIsMobile,
  useLoadMoreIfEndOfScreen,
  usePrefersReducedMotion
} from "@outschool/ui-utils";
import React from "react";
import { useAnalytics } from "use-analytics";

import * as searchSvg from "../../../../images/search.svg";
import { ClassCardsGrid } from "../../components/ClassCards";
import * as Env from "../../Env";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useLearnerListingRecommendationsQuery } from "../../queries/LearnerActivityRecommendationsQuery";
import RecommendedTopics from "./RecommendedTopics";

const PAGE_LIMIT = 18;

type RecommendedClassesResultsProps = {
  hideNoClassesMessage?: boolean;
  dontFetchMore?: boolean;
  headingText?: string;
  limit?: number;
};

function RecommendedClassesResults({
  hideNoClassesMessage,
  dontFetchMore,
  headingText = "All Classes",
  limit = PAGE_LIMIT
}: RecommendedClassesResultsProps) {
  const prefersReducedMotion = usePrefersReducedMotion();

  const { track } = useAnalytics();
  const isMobile = useIsMobile();
  const [endOfScreenRef, setEndOfScreenRef] = React.useState<HTMLDivElement>();
  const variables = React.useMemo(
    () => ({
      limit
    }),
    [limit]
  );
  const {
    recommendedListings,
    error,
    fetchMoreRecommendations,
    isInfiniteScrollEnabled,
    isFetchingInitial,
    isFetchingMore
  } = useLearnerListingRecommendationsQuery(variables);
  useLoadMoreIfEndOfScreen({
    fetchMore: async () => fetchMoreRecommendations(variables),
    endOfScreenRef,
    isInfiniteScrollEnabled: isInfiniteScrollEnabled && !dontFetchMore,
    trackLoadMore: () => track(`browse.search.recommended.results`, variables)
  });

  React.useEffect(() => {
    document.body.scrollTo({
      top: 0,
      behavior: prefersReducedMotion ? undefined : "smooth"
    });
  }, [prefersReducedMotion]);

  if (isFetchingInitial) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorMessage value={error} showStatusPageLink={Env.IS_READ_ONLY_MODE} />
    );
  }

  return (
    <Box
      flex
      sx={{
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
      }}
    >
      {!recommendedListings || recommendedListings.length === 0 ? (
        <>
          {!hideNoClassesMessage && <NoClassesMatch />}
          <RecommendedTopics />
        </>
      ) : (
        <>
          <Typography variant={isMobile ? "h3" : "h2"} center>
            {headingText}
          </Typography>
          <ClassCardsGrid
            activities={recommendedListings}
            trackingPrefix="browse.search"
          />
          {isFetchingMore && <Loading delay={0} />}
          <Box ref={setEndOfScreenRef}></Box>
        </>
      )}
    </Box>
  );
}

function NoClassesMatch() {
  return (
    <Box
      sx={{
        fontSize: 16,
        lineHeight: "24px",
        textAlign: "center",
        marginBottom: "3em"
      }}
    >
      <Image
        sx={{
          marginBottom: 8
        }}
        src={searchSvg}
      />
      <Box>No classes found</Box>
      <Space y="medium" />
      <Box>Try a different search, or explore the topics below.</Box>
    </Box>
  );
}

export default RecommendedClassesResults;
