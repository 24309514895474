// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Image, Typography } from "@outschool/backpack";
import { TrackedButton } from "@outschool/ui-components-shared";
import { useImpressionTracking } from "@outschool/ui-legacy-component-library";
import { UserAgent, useIsSmall, useNodeRef } from "@outschool/ui-utils";
import React from "react";

import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import phoneWithDogSmall from "./images/phone-with-dog-small.png";
import phoneWithDog from "./images/phone-with-dog.png";

const IosAppPromoBanner = () => {
  const trackingPrefix = "banner.ios";
  const [impressionNode, setImpressionNode] = useNodeRef();
  useImpressionTracking({
    node: impressionNode,
    uniqueId: trackingPrefix,
    trackingLabel: trackingPrefix,
    trackViews: true
  });

  const currentLearner = useCurrentLearner();
  const showIosAppDownloadLink = Boolean(currentLearner?.age ?? 0 >= 13);
  const isSmall = useIsSmall();
  return (
    <Box
      sx={theme => ({
        backgroundImage: `linear-gradient(360deg, #FFD544 0%, #FFF3D3 100%), linear-gradient(162.93deg, #FDFCFD 0%, #F0F6F8 104.03%)`,
        backgroundSize: "cover",
        backgroundPosition: "center top",
        height: 223,

        [theme.breakpoints.up("sm")]: {
          height: 339
        }
      })}
      data-test-id="ios-promo-banner"
      ref={setImpressionNode}
    >
      <Box
        sx={theme => ({
          display: "flex",
          height: 194,

          [theme.breakpoints.up("sm")]: {
            height: 284
          },

          justifyContent: "center",
          overflow: "hidden"
        })}
      >
        <Box
          sx={theme => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            left: 124,

            [theme.breakpoints.up("sm")]: {
              left: 230
            },

            bottom: 25
          })}
        >
          <Typography variant="h3">NEW!</Typography>
          <Typography variant="h3">
            {UserAgent.isIPhone() ? "iPhone" : "iPad"} app
          </Typography>
          {showIosAppDownloadLink ? (
            <TrackedButton
              variant="contained"
              component="a"
              href="https://apps.apple.com/us/app/outschool/id1594191740"
              sx={{
                borderRadius: "1em",
                marginTop: "0.5em",
                zIndex: 1
              }}
              trackingName={trackingPrefix}
            >
              Download Now
            </TrackedButton>
          ) : (
            <Typography
              variant="inherit"
              sx={{
                whiteSpace: "nowrap"
              }}
            >
              Ask your family to download
            </Typography>
          )}
        </Box>
        <Image
          src={isSmall ? phoneWithDogSmall : phoneWithDog}
          sx={{
            height: "100%"
          }}
        />
      </Box>
    </Box>
  );
};

export default IosAppPromoBanner;
