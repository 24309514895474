import { Box, Skeleton, Typography } from "@outschool/backpack";
import { unlimitedClassroomPath } from "@outschool/routes";
import {
  ContinueWithActivityContent,
  useCurrentSubscription,
  useSubscriptionActiveContentQuery
} from "@outschool/ui-components-website";
import { Container } from "@outschool/ui-legacy-component-library";
import { useNavigation } from "@outschool/ui-utils";
import { useTitle } from "@patched/hookrouter";
import React from "react";

import { HeroLayoutWithTabs } from "../../../layout/HeroLayoutWithTabs";
import useAnalyticsPage, {
  PageType
} from "../../../lib/analytics/useAnalyticsPage";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { learnerRoutes } from "../../../lib/Routes";
import { useCurrentLearner } from "../../../providers/CurrentLearnerProvider";
import { BrowseMoreOnUnlimited } from "../components/BackButton";

export default function UnlimitedDashboard() {
  useTitle("Outschool Unlimited | Outschool");
  useAnalyticsPage({ pageName: PageType.SavedActivities });
  const { hasActiveSubscription, loading: isLoadingUserInfo } =
    useCurrentSubscription({
      isLoggedIn: true
    });
  const learner = useCurrentLearner();
  const { loading, activities } = useSubscriptionActiveContentQuery({
    isLoadingUserInfo,
    userHasActiveSubscription: hasActiveSubscription,
    learnerUid: learner?.uid
  });
  const navigate = useNavigation();
  const navigateToContent = (_activityUid: string, sectionUid: string) => {
    navigate(unlimitedClassroomPath(sectionUid));
  };

  return (
    <HeroLayoutWithTabs currentPath={learnerRoutes.learnerUnlimitedPath()}>
      <Container>
        <Box sx={{ textAlign: "center", py: 30 }}>
          <Typography variant="h2">Continue Learning</Typography>
        </Box>

        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{ height: 420, borderRadius: 25 }}
          />
        ) : (
          <Box flex sx={{ gap: 25, overflowX: "auto", paddingBottom: 15 }}>
            {activities.map(activity => (
              <Box flex key={activity.uid}>
                <ContinueWithActivityContent
                  activity={activity}
                  navigateToContent={navigateToContent}
                />
              </Box>
            ))}
          </Box>
        )}
        <Box sx={{ textAlign: "center", py: 30 }}>
          <BrowseMoreOnUnlimited />
        </Box>
      </Container>
    </HeroLayoutWithTabs>
  );
}
