import "@outschool/ui-legacy-component-library";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Typography } from "@outschool/backpack";
import {
  CLASSROOM_BOX_MAX_WIDTH,
  PrivateClassMessages
} from "@outschool/ui-components-classroom";
import React from "react";

import * as User from "../../../../shared/User";
import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import { useLearnerClassroomContext } from "../../providers/LearnerClassroomProvider";
import { usePrivateClassMessagesQuery } from "../../queries/PrivateClassMessagesQuery";
import ClassroomError from "./ClassroomError";

const PrivateClassMessagesPage = () => {
  const { sectionUid } = useLearnerClassroomContext();

  useAnalyticsPage({
    pageName: PageType.PrivateClassMessages,
    pageParams: { sectionUid }
  });
  const currentLearner = useCurrentLearner()!;

  const { data, error } = usePrivateClassMessagesQuery({
    sectionUid
  });

  if (error) {
    return <ClassroomError error={error} />;
  }

  const activity = data?.section?.activity;
  const section = data?.section;
  const enrollment = data?.section?.currentLearnerEnrollment;

  if (!section || !activity || !enrollment) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          maxWidth: CLASSROOM_BOX_MAX_WIDTH,
          margin: "0 auto"
        }}
      >
        <Box
          sx={{
            padding: "0.75em 1em",
            borderBottom: "1px solid #E5E5E5",
            backgroundColor: "common.white"
          }}
        >
          <Typography variant="h3">
            Private messages between {currentLearner?.name} and{" "}
            {User.shortenedLeaderName(activity.leader.name)} about this class
          </Typography>
          <Typography
            variant="inherit"
            sx={{
              fontStyle: "italic"
            }}
          >
            Note: messages sent here will be visible to{" "}
            {User.parentName(currentLearner.parent)}
          </Typography>

          <PrivateClassMessages
            isTeacher={false}
            allowBodyScroll
            section={section}
            enrollment={enrollment}
            userTimeZone={currentLearner?.timeZone ?? undefined}
            placeholder={`Write a message as ${currentLearner?.name}`}
            emptyText="No messages yet"
            currentLearner={currentLearner}
            isLearnerMode={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateClassMessagesPage;
