import { Box, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { LearnerActivityDetailsQuery } from "@outschool/gql-frontend-generated";
import React from "react";

const MAX_WIDTH = 1050;

interface ClassDescriptionSectionProps {
  activity: NonNullable<LearnerActivityDetailsQuery["activity"]>;
}

function ClassDescriptionSection({ activity }: ClassDescriptionSectionProps) {
  const groupOrClass = activity.isClub ? "Group" : "Class";
  return (
    <Box
      flex
      sx={{
        alignItems: "center",
        py: "48px",
        background: "white"
      }}
    >
      <Box
        sx={{
          background: "white",
          boxShadow: "0px 2px 15px 0px #000000 6%",
          borderRadius: 16,
          margin: "auto",
          px: "1em",
          maxWidth: MAX_WIDTH
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "left",
            marginBottom: "32px",
            color: "primary.900"
          }}
        >
          {groupOrClass} Description
        </Typography>
        <Box
          flex
          sx={{
            flexDirection: "column"
          }}
        >
          <Typography variant="h4">{groupOrClass} Experience</Typography>
          <pre style={{ fontFamily: "'Helvetica Neue',sans-serif" }}>
            {activity.class_experience}
          </pre>
        </Box>
      </Box>
    </Box>
  );
}

export default ClassDescriptionSection;
