import { Component } from "@outschool/ownership-areas";
import { dayjs } from "@outschool/time";
import { useQueryWithPreviousData } from "@outschool/ui-apollo";
import { useInterval, usePreviousValue } from "@outschool/ui-utils";
import _ from "lodash";
import React from "react";

import { useWindowHasFocus } from "../../../shared/hooks";
import { getGroupedNotifications } from "../components/notifications/Notifications";
import {
  CursorDirection,
  LearnerNotificationsQuery,
  LearnerNotificationsQueryVariables,
  NotificationStatus
} from "../generated/graphql";
import { useCurrentLearner } from "../providers/CurrentLearnerProvider";
import learnerNotificationsQuery from "../queries/LearnerNotificationsQuery";

/**
 * This hook:
 * - Used in learner app headers
 * - Fetches most recent notifications on initial render
 * - Polls for new notifications every DEFAULT_POLLING_MS minutes
 * - Returns a function to allow learners to fetch more past notifications when they want to
 * - Groups similar notifications with similar content  Ex: “1 notification for 10 comments on this thread”.
 */

export const DEFAULT_POLLING_MS = 1000 * 60 * 2; // 2 minutes
const MAX_POLLING_MS = 1000 * 60 * 10; // 10 minutes
export const FETCH_NOTIFICATIONS_LIMIT = 30;
const NOTIFICATION_GROUP_PAGINATION_LIMIT = 6;

export default function useLearnerNotifications() {
  const after = React.useMemo(
    () => dayjs().subtract(30, "day").startOf("day").toISOString(),
    []
  );
  const queryVariables = {
    direction: CursorDirection.Past,
    limit: FETCH_NOTIFICATIONS_LIMIT,
    after,
    skipHasUnreadNotifications: false
  };
  const currentLearner = useCurrentLearner();
  const { data, fetchMore, loading } = useQueryWithPreviousData<
    LearnerNotificationsQuery,
    LearnerNotificationsQueryVariables
  >(learnerNotificationsQuery, {
    variables: queryVariables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: !currentLearner
  });
  const [pollingMs, setPollingMs] = React.useState<number>(DEFAULT_POLLING_MS);
  const [shouldPollNotifications, setShouldPollNotifications] =
    React.useState<boolean>(true);
  const [hasNextPastNotificationPage, setHasNextPastNotificationPage] =
    React.useState<boolean>(false);
  const [fetchType, setFetchType] = React.useState<
    "initial-fetch" | "fetch-more-past" | "fetch-more-new"
  >("initial-fetch");
  const [
    oldestDisplayedNotificationGroupUid,
    setOldestDisplayedNotificationGroupUid
  ] = React.useState<string>();
  const results = data?.learnerNotifications?.results ?? [];
  const firstCursor = results[0]?.createdAt;
  const lastCursor = _.last(results)?.createdAt;
  const windowHasFocus = useWindowHasFocus();

  const [
    notificationGroups,
    oldestDisplayedNotificationGroupIndex,
    slicedNotificationGroups
  ] = React.useMemo(() => {
    const notificationGroups = getGroupedNotifications(
      data?.learnerNotifications?.results ?? []
    );
    const oldestDisplayedNotificationGroupIndex = notificationGroups.findIndex(
      group => group[0].uid === oldestDisplayedNotificationGroupUid
    );
    const slicedNotificationGroups = notificationGroups.slice(
      0,
      oldestDisplayedNotificationGroupIndex + 1
    );

    return [
      notificationGroups,
      oldestDisplayedNotificationGroupIndex,
      slicedNotificationGroups
    ];
  }, [data, oldestDisplayedNotificationGroupUid]);

  const previousResultUids = usePreviousValue<string[]>(
    results.map(r => r.uid)
  );

  React.useEffect(() => {
    const learnerNotifications = data?.learnerNotifications;
    if (!learnerNotifications) {
      return;
    }
    if (
      fetchType === "initial-fetch" &&
      previousResultUids.length !== learnerNotifications.results.length
    ) {
      setHasNextPastNotificationPage(
        Boolean(learnerNotifications?.pageInfo.hasNextPage)
      );
      setOldestDisplayedNotificationGroupUid(
        _.last(
          notificationGroups.slice(0, NOTIFICATION_GROUP_PAGINATION_LIMIT)
        )?.[0].uid
      );
    }
    if (
      !loading &&
      previousResultUids.length !== learnerNotifications.results.length &&
      (fetchType === "fetch-more-past" ||
        // Learner may get their first notification after the initial page load
        (fetchType === "fetch-more-new" && previousResultUids.length === 0))
    ) {
      const nextOldestNotificationGroupIndex =
        fetchType === "fetch-more-new"
          ? notificationGroups.length - 1
          : Math.min(
              Math.max(notificationGroups.length - 1, 0),
              oldestDisplayedNotificationGroupIndex +
                NOTIFICATION_GROUP_PAGINATION_LIMIT
            );
      setOldestDisplayedNotificationGroupUid(
        notificationGroups[nextOldestNotificationGroupIndex][0].uid
      );
    }
  }, [
    data,
    loading,
    fetchType,
    notificationGroups,
    oldestDisplayedNotificationGroupIndex,
    previousResultUids
  ]);

  const fetchNewNotifications = React.useCallback(async () => {
    if (loading || !currentLearner) {
      return;
    }
    setFetchType("fetch-more-new");
    try {
      await fetchMore({
        variables: {
          direction: CursorDirection.Future,
          cursor: firstCursor,
          limit: FETCH_NOTIFICATIONS_LIMIT,
          skipHasUnreadNotifications: true
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const hasNextPage =
            fetchMoreResult?.learnerNotifications?.pageInfo.hasNextPage;
          setPollingMs(hasNextPage ? 1000 : DEFAULT_POLLING_MS);
          if (
            !fetchMoreResult ||
            fetchMoreResult.learnerNotifications?.results.length === 0
          ) {
            return previousResult;
          }
          const learnerHasUnreadNotifications =
            previousResult?.learnerHasUnreadNotifications ||
            fetchMoreResult.learnerNotifications?.results.some(
              r => r.status === NotificationStatus.New
            );
          const updatePath = "learnerNotifications.results";
          const previousResults = _.get(previousResult, updatePath, []);
          const newResults = _.orderBy(
            _.get(fetchMoreResult, updatePath, []),
            "createdAt",
            "desc"
          );
          const merged = _.merge({}, previousResults, fetchMoreResult, {
            learnerHasUnreadNotifications
          });
          return _.set(merged, updatePath, [...newResults, ...previousResults]);
        }
      });
    } catch (e) {
      // decrease the polling frequency when an error occurs
      setPollingMs(Math.min(pollingMs * 2, MAX_POLLING_MS));
      OsPlatform.captureError(e, {
        tags: { component: Component.Notifications }
      });
    }
  }, [fetchMore, loading, pollingMs, firstCursor, currentLearner]);

  const fetchMorePastNotifications = React.useCallback(async () => {
    if (loading) {
      return;
    }
    const displayedNotificationGroupCount = slicedNotificationGroups.length;
    // If there is at least NOTIFICATION_GROUP_PAGINATION_LIMIT more groups not shown,
    // just increase slice offset to return them
    if (
      !hasNextPastNotificationPage ||
      displayedNotificationGroupCount + NOTIFICATION_GROUP_PAGINATION_LIMIT <=
        notificationGroups.length
    ) {
      const nextOldestNotificationGroupIndex = Math.max(
        0,
        Math.min(
          notificationGroups.length,
          displayedNotificationGroupCount + NOTIFICATION_GROUP_PAGINATION_LIMIT
        ) - 1
      );
      const nextOldestNotificationGroup =
        notificationGroups[nextOldestNotificationGroupIndex];
      setOldestDisplayedNotificationGroupUid(
        nextOldestNotificationGroup[0].uid
      );
      return;
    }
    setFetchType("fetch-more-past");
    await fetchMore({
      variables: {
        direction: CursorDirection.Past,
        cursor: lastCursor,
        limit: FETCH_NOTIFICATIONS_LIMIT,
        after,
        skipHasUnreadNotifications: true
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setHasNextPastNotificationPage(
          Boolean(fetchMoreResult?.learnerNotifications?.pageInfo.hasNextPage)
        );
        if (
          !fetchMoreResult ||
          fetchMoreResult.learnerNotifications?.results.length === 0
        ) {
          return previousResult;
        }
        const updatePath = "learnerNotifications.results";
        const previousResults = _.get(previousResult, updatePath, []);
        const newResults = _.get(fetchMoreResult, updatePath, []);
        const merged = _.merge({}, previousResults, fetchMoreResult, {
          learnerHasUnreadNotifications:
            previousResult.learnerHasUnreadNotifications
        });
        return _.set(merged, updatePath, [...previousResults, ...newResults]);
      }
    });
  }, [
    fetchMore,
    after,
    lastCursor,
    setOldestDisplayedNotificationGroupUid,
    notificationGroups,
    slicedNotificationGroups,
    loading,
    hasNextPastNotificationPage
  ]);

  const updatePollingState = React.useCallback(
    (newShouldPollNotificationsValue: boolean) => {
      if (newShouldPollNotificationsValue === shouldPollNotifications) {
        return;
      }
      if (newShouldPollNotificationsValue) {
        fetchNewNotifications();
      }
      setShouldPollNotifications(newShouldPollNotificationsValue);
    },
    [fetchNewNotifications, shouldPollNotifications]
  );

  const onVisibilityChange = React.useCallback(
    () => updatePollingState(!document.hidden),
    [updatePollingState]
  );

  React.useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, [onVisibilityChange]);

  React.useEffect(() => {
    const shouldPollNotifications = windowHasFocus && !document.hidden;
    updatePollingState(shouldPollNotifications);
  }, [windowHasFocus, updatePollingState]);

  useInterval(
    () => fetchNewNotifications(),
    shouldPollNotifications ? pollingMs : null
  );

  return {
    hasUnreadNotifications: Boolean(data?.learnerHasUnreadNotifications),
    notificationGroups: slicedNotificationGroups,
    loading: loading,
    loadingPastNotifications:
      loading &&
      (fetchType === "initial-fetch" || fetchType === "fetch-more-past"),
    fetchMorePastNotifications,
    hasMorePastNotifications:
      slicedNotificationGroups.length < notificationGroups.length ||
      hasNextPastNotificationPage,
    after,
    queryVariables
  };
}
