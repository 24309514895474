import { Box, SxProps } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import { STANDARD_NAV_BUTTON_PSEUDO_CLASS_STYLE } from "./Nav";

interface HeaderNavItemProps {
  variant?: "standard" | "transparent";
  sx?: SxProps;
  onClick?: any;
  role?: string;
  as?: string;
}

const HeaderNavItem = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<HeaderNavItemProps>
>(({ variant = "standard", onClick, sx, children, ...props }, ref) => {
  const variantStyles: Record<typeof variant, SxProps> = {
    standard: {
      marginX: -1,
      paddingX: "1em",
      zIndex: 1,
      color: "#5c6f8b",
      ...STANDARD_NAV_BUTTON_PSEUDO_CLASS_STYLE
    },
    transparent: {
      color: "common.white",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.2)"
      },
      "&:active": {
        background: "rgba(255, 255, 255, 0.1)",
        boxShadow:
          "inset 0px 4px 4px rgba(23, 25, 28, 0.2), inset 0 0 0 1px rgba(23, 25, 28, 0.2)"
      }
    }
  };
  return (
    <Box
      flex
      sx={[
        {
          alignItems: "center",
          padding: "0.5em",
          fontWeight: 500,
          borderRadius: 4,
          cursor: "pointer",
          transition: "all 0.175s ease",
          whiteSpace: "nowrap",
          ...variantStyles[variant]
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      {children}
    </Box>
  );
});

export default HeaderNavItem;
