import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";

import { LearnerSupportTopicsQuery } from "../generated/graphql";

const getLearnerSupportTopics = gql`
  query LearnerSupportTopics {
    learnerSupportTopics {
      title
      description
      type
      prompt
      placeholder
    }
  }
`;

export function useLearnerSupportTopics() {
  const { loading, error, data } =
    useQueryWithPreviousData<LearnerSupportTopicsQuery>(
      getLearnerSupportTopics,
      {
        fetchPolicy: "cache-and-network"
      }
    );
  return {
    loading,
    error,
    data
  };
}

export const submitLearnerSupportIssue = gql`
  mutation SubmitLearnerSupportIssue(
    $issueType: LearnerSupportType!
    $text: String
  ) {
    submitLearnerSupportIssue(issueType: $issueType, text: $text)
  }
`;
