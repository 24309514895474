import { JoinMeeting } from "@outschool/gql-backend-generated";
import { LearnerClassroomQuery } from "@outschool/gql-frontend-generated";
import { ApolloError } from "@outschool/ui-apollo";
import React from "react";

import { useLearnerClassroomQuery } from "../queries/LearnerClassroomQuery";
import { useCurrentLearner } from "./CurrentLearnerProvider";

export type Classroom = LearnerClassroomQuery["classroom"];
export type CurrentLearnerEnrollments = Classroom["currentLearnerEnrollments"];
export type Learners = Classroom["learners"];
export type Section = Classroom["section"];
export type SectionLesson = NonNullable<
  NonNullable<NonNullable<Section>["sectionLessons"]>[number]
>;
export type Activity = Classroom["activity"];
export type Syllabus = Section["syllabus"];
export type Lesson = NonNullable<NonNullable<Syllabus>["lessons"][number]>;
export type CurrentLesson = NonNullable<NonNullable<Syllabus>["currentLesson"]>;
export type Unit = NonNullable<Lesson["unit"]>;

interface ClassroomContext {
  loading: boolean;
  error?: ApolloError;
  sectionUid: string;
  classroom?: Omit<
    Classroom,
    "currentLearnerEnrollments" | "learners" | "section" | "activity"
  >;
  currentLearnerEnrollments?: CurrentLearnerEnrollments;
  learners?: Learners;
  section?: Section;
  sectionLessons?: Section["sectionLessons"];
  activity?: Omit<Activity, "syllabus">;
  syllabus?: Syllabus;
  liveMeeting?: JoinMeeting;
  hasLessons: boolean;
  allLessonsCompleted: boolean;
  hasAssignments: boolean;
}
const LearnerClassroomContext = React.createContext<ClassroomContext>({
  loading: false,
  error: undefined,
  sectionUid: "",
  classroom: undefined,
  currentLearnerEnrollments: undefined,
  learners: undefined,
  section: undefined,
  activity: undefined,
  syllabus: undefined,
  hasLessons: false,
  allLessonsCompleted: false,
  hasAssignments: false
});

interface LearnerClassroomProviderProps {
  sectionUid: string;
  isSectionLessonsEnabled?: boolean;
  children: React.ReactNode;
}
export const LearnerClassroomProvider = ({
  sectionUid,
  isSectionLessonsEnabled = false,
  children
}: LearnerClassroomProviderProps) => {
  const learner = useCurrentLearner();
  const { loading, data, error } = useLearnerClassroomQuery(
    {
      sectionUid,
      learnerUid: learner?.uid!,
      withSectionLessons: isSectionLessonsEnabled
    },
    {
      skip: !learner?.uid
    }
  );

  const { classroom: originalClassroom } =
    data ?? ({} as LearnerClassroomQuery);
  const {
    currentLearnerEnrollments,
    learners,
    section,
    activity,
    ...classroom
  } = originalClassroom ?? ({} as Classroom);
  const syllabus = section?.syllabus;

  const sectionLessons = (section?.sectionLessons || []) as SectionLesson[];
  const hasLessons =
    !!syllabus?.published_at &&
    Boolean(
      section.is_self_paced || syllabus?.lessons?.some(l => l.meetings?.length)
    );

  const allLessonsCompleted = Boolean(
    syllabus?.lessons?.every(lesson => lesson.isCompleted)
  );

  const hasAssignments =
    syllabus?.lessons?.some(lesson => lesson.assignmentClassPosts.length) ||
    false;

  const value: ClassroomContext = {
    loading,
    error,
    sectionUid,
    classroom,
    currentLearnerEnrollments,
    learners,
    section,
    activity,
    syllabus,
    sectionLessons,
    hasLessons,
    allLessonsCompleted,
    hasAssignments
  };

  return (
    <LearnerClassroomContext.Provider value={value}>
      {children}
    </LearnerClassroomContext.Provider>
  );
};

export const useLearnerClassroomContext = () =>
  React.useContext(LearnerClassroomContext);
