// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { resolve } from "url";

import { useWindowReference } from "@outschool/local-storage";
import { ApolloProvider } from "@outschool/ui-apollo";
import { clearLearnerTokenDataAndGoToMainApp } from "@outschool/ui-components-website";
import { createApolloClient } from "@outschool/ui-gql";
import { useInterceptor } from "@patched/hookrouter";
import React, { PropsWithChildren } from "react";

import * as Env from "../Env";
import { useLearnerAuth } from "./LearnerAuthProvider";

export const GraphqlProvider: React.FC<PropsWithChildren<{}>> = ({
  children
}) => {
  const { canUseWindow, window } = useWindowReference();
  const { sessionTokenStore, setSessionToken } = useLearnerAuth();

  const expectedClientVersion = React.useRef<number>(Env.CLIENT_VERSION);
  const client = React.useMemo(
    () =>
      createApolloClient({
        name: "outschool-learner-app",
        tokenRefreshControl: {
          getTokens: () => ({
            sessionToken: sessionTokenStore?.getSessionToken() ?? null,
            refreshToken: sessionTokenStore?.getRefreshToken() ?? null
          }),
          setTokens: (sessionToken: string, refreshToken: string) => {
            setSessionToken({ sessionToken, refreshToken });
          },
          onTokensExpired: clearLearnerTokenDataAndGoToMainApp,
          shouldLogMissingRefreshToken: Env.LOG_MISSING_REFRESH_TOKEN
        },
        updateExpectedClientVersion: version => {
          expectedClientVersion.current = version;
        },
        // TODO don't rely on Env for this somehow?
        clientVersion: Env.CLIENT_VERSION,
        locale: Env.LOCALE,
        notificationServiceUrl: Env.NOTIFICATIONS_SERVICE_URL
      }),
    [sessionTokenStore, setSessionToken]
  );
  useInterceptor((from, to) => {
    if (expectedClientVersion.current !== Env.CLIENT_VERSION) {
      if (canUseWindow) {
        window!.location.href = resolve(from, to);
      }
    }
    return to;
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
