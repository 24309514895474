import { Box, SxProps, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { NotificationSender } from "@outschool/gql-backend-generated";
import {
  AvatarImage,
  DEFAULT_AVATAR,
  UserHeadshotImage
} from "@outschool/ui-components-shared";
import {
  Space,
  useImpressionTracking
} from "@outschool/ui-legacy-component-library";
import { navigate } from "@patched/hookrouter";
import React from "react";

import { NotificationStatus } from "../../generated/graphql";
import useMarkLearnerNotificationsRead from "../../hooks/useMarkLearnerNotificationsRead";
import {
  Notification,
  NotificationActionGroup,
  getNotificationActionGroup,
  getNotificationMessage,
  getNotificationRoute,
  getNotificationTimeFromNow,
  getSenderNamesString
} from "./Notifications";

interface NotificationGroupProps {
  notificationGroup: Notification[];
}
const NotificationGroup = ({ notificationGroup }: NotificationGroupProps) => {
  const [node, setNode] = React.useState(undefined);
  const { trackTouch } = useImpressionTracking({
    node,
    uniqueId: notificationGroup.map(g => g.uid).join("."),
    trackingLabel: "inAppNotifications.notificationGroup"
  });
  const senderNames = getSenderNamesString(notificationGroup);
  const mostRecentNotificationInGroup = notificationGroup[0];
  const navigateToNotificationSource = React.useCallback(() => {
    navigate(getNotificationRoute(notificationGroup));
  }, [notificationGroup]);
  const isUnreadNotificationGroup =
    mostRecentNotificationInGroup.status === NotificationStatus.New;

  const { markLearnerNotificationRead } =
    useMarkLearnerNotificationsRead(notificationGroup);

  const onNotificationGroupClick = () => {
    if (isUnreadNotificationGroup) {
      markLearnerNotificationRead();
    }
    trackTouch();
    navigateToNotificationSource();
  };

  return (
    <Box
      flex
      data-test-id={`notification-group-${mostRecentNotificationInGroup.uid}`}
      sx={{
        position: "relative",
        cursor: "pointer",
        background: isUnreadNotificationGroup ? "#F2F7FE" : "#F8F8F9",
        border: "1px solid",
        borderColor: "grey.100",
        padding: "20px 24px 20px 32px",
        borderRadius: 16,
        width: "100%",

        "&:hover": {
          boxShadow: "0px 2px 5px rgba(23, 25, 28, 0.1)"
        }
      }}
      component="button"
      onClick={onNotificationGroupClick}
      ref={setNode}
    >
      {isUnreadNotificationGroup && <NewNotificationDot />}
      <NotificationSenderImage notificationGroup={notificationGroup} />
      <Space x="small" />
      <Box
        flex
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <NotificationSenderNameAndActionGroup
          senderName={senderNames}
          status={mostRecentNotificationInGroup.status}
          actionGroup={getNotificationActionGroup(
            mostRecentNotificationInGroup.__typename
          )}
        />
        <NotificationMessageAndTime
          message={getNotificationMessage(
            mostRecentNotificationInGroup,
            senderNames
          )}
          timeFromNow={getNotificationTimeFromNow(
            mostRecentNotificationInGroup.createdAt
          )}
          status={mostRecentNotificationInGroup.status}
        />
      </Box>
    </Box>
  );
};

const NewNotificationDot: React.FC = () => (
  <Box
    sx={{
      position: "absolute",
      top: "16px",
      left: 16,
      borderRadius: "50%",
      backgroundColor: "#458FFF",
      width: "8px",
      height: "8px"
    }}
  ></Box>
);

interface NotificationSenderImageProps {
  notificationGroup: Notification[];
}
const NotificationSenderImage: React.FC<NotificationSenderImageProps> = ({
  notificationGroup
}) => {
  const hasMultipleSenders =
    new Set(notificationGroup.map(g => g.sender.uid)).size > 1;
  const IMAGE_CONTAINER_SIZE = 40;

  if (hasMultipleSenders) {
    const sharedProps: Pick<AvatarOrPhotoProps, "size" | "avatarContainerSx"> =
      {
        size: 24,
        avatarContainerSx: { border: "2px solid #FBFBFB" }
      };
    return (
      <Box
        sx={{
          position: "relative",
          minWidth: IMAGE_CONTAINER_SIZE,
          height: IMAGE_CONTAINER_SIZE
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0
          }}
        >
          <AvatarOrPhoto
            sender={notificationGroup[0].sender}
            {...sharedProps}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0
          }}
        >
          <AvatarOrPhoto
            sender={notificationGroup[1].sender}
            {...sharedProps}
          />
        </Box>
      </Box>
    );
  }

  const mostRecentSender = notificationGroup[0].sender;
  return (
    <Box
      sx={{
        width: IMAGE_CONTAINER_SIZE
      }}
    >
      <AvatarOrPhoto sender={mostRecentSender} />
    </Box>
  );
};

interface AvatarOrPhotoProps {
  sender: NotificationSender;
  avatarContainerSx?: SxProps;
  size?: number;
}
const AvatarOrPhoto = ({
  sender,
  avatarContainerSx = {},
  size = 36
}: AvatarOrPhotoProps) => {
  return sender.__typename === "LearnerSender" ? (
    <Box
      sx={[
        {
          background: "white",
          height: "fit-content",
          borderRadius: "50%",
          padding: "1px"
        },
        ...(Array.isArray(avatarContainerSx)
          ? avatarContainerSx
          : [avatarContainerSx])
      ]}
    >
      <AvatarImage width={size} avatar={sender.avatar || DEFAULT_AVATAR} />
    </Box>
  ) : sender.__typename === "UserSender" ? (
    <UserHeadshotImage user={sender} size={size} />
  ) : null;
};

interface NotificationMessageAndTimeProps {
  message: string;
  timeFromNow: string;
  status: NotificationStatus;
}
const NotificationMessageAndTime: React.FC<NotificationMessageAndTimeProps> = ({
  message,
  timeFromNow,
  status
}) => (
  <Box
    flex
    sx={{
      width: "100%",
      justifyContent: "space-between"
    }}
  >
    <Typography
      variant="inherit"
      sx={{
        textAlign: "left",
        fontSize: 16,
        lineHeight: "24px",
        color: status === NotificationStatus.New ? "#113465" : "#6A7482"
      }}
    >
      {message}
    </Typography>
    <Space x={8} />
    <Typography
      variant="inherit"
      sx={{
        fontSize: 14,
        lineHeight: "16px",
        color: "#8791A2",
        minWidth: "48px",
        textAlign: "right"
      }}
    >
      {timeFromNow}
    </Typography>
  </Box>
);

interface NotificationSenderNameAndActionGroupProps {
  senderName: string;
  actionGroup?: NotificationActionGroup;
  status: NotificationStatus;
}
const NotificationSenderNameAndActionGroup: React.FC<
  NotificationSenderNameAndActionGroupProps
> = ({ senderName, actionGroup, status }) => (
  <Box
    flex
    sx={{
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center"
    }}
  >
    <Typography
      variant="inherit"
      sx={{
        fontWeight: 800,
        fontSize: 16,
        lineHeight: "24px",
        color: status === NotificationStatus.New ? "#113465" : "#585F69",
        textAlign: "left"
      }}
    >
      {senderName}
    </Typography>
    <Space x={12} />
    <Typography
      variant="inherit"
      sx={{
        color: "#8791A2",
        fontSize: 14,
        lineHeight: "16px",
        fontWeight: "fontWeightBold"
      }}
    >
      {actionGroup}
    </Typography>
  </Box>
);

export default NotificationGroup;
