import { TrackedButton } from "@outschool/ui-components-shared";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

interface ViewMoreNotificationsButtonProps {
  onClick: () => void;
  disabled: boolean;
}

export default function ViewMoreNotificationsButton({
  onClick,
  disabled
}: ViewMoreNotificationsButtonProps) {
  return (
    <TrackedButton
      disabled={disabled}
      onClick={onClick}
      sx={{
        margin: "26px auto 0 auto",
        display: "block",
        color: "#2E3238 !important",
        border: "1px solid #8C9196 !important",
        fontWeight: 500,
        padding: "10px 16px 10px 16px !important",
        paddingX: "1em",
        paddingY: "10px",
        borderRadius: 11
      }}
      trackingName="inAppNotifications.viewMore"
    >
      View More
    </TrackedButton>
  );
}
