// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Button, Typography } from "@outschool/backpack";
import { SupportRoutes } from "@outschool/routes";
import { ExternalLink, TrackedButton } from "@outschool/ui-components-shared";
import { Input, Modal } from "@outschool/ui-legacy-component-library";
import { SystemStyleObject } from "@styled-system/css";
import _ from "lodash";
import React from "react";

type MultiplicationModalProps = {
  switchViews: () => void;
  open: boolean;
  onClose: () => void;
  loading: boolean;
  error?: string;
  title?: string;
};

function generateRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}

function checkMultiplicationAnswer(
  answer: string,
  currentX: number,
  currentY: number
): boolean {
  return parseInt(answer) === currentX * currentY;
}

const multiplicationInput: SystemStyleObject = {
  color: "gray2",
  width: "100%",
  textAlign: "center",
  border: "none !important",
  "&::placeholder": {
    color: "border"
  },
  "&:focus": {
    outline: "none",
    border: "none"
  },
  fontSize: "3.2rem !important",
  lineHeight: "0rem !important"
};

const MultiplicationModal: React.FC<MultiplicationModalProps> = ({
  switchViews,
  open,
  onClose,
  loading,
  error,
  title = "Adults only"
}) => {
  const [answer, setAnswer] = React.useState("");
  const rand = () => generateRandomNumber(4, 10);
  const randNumPool = (size: number = 5) => _.range(size).map(rand);
  const [numberOfClicks, setNumberOfClicks] = React.useState(0);
  const [x, setX] = React.useState(randNumPool());
  const [y, setY] = React.useState(randNumPool());
  const index = numberOfClicks % 5;
  const currentX = x[index];
  const currentY = y[index];
  const [modalError, setModalError] = React.useState(error);

  const onClickHandler = e => {
    e && e.preventDefault();
    if (checkMultiplicationAnswer(answer, currentX, currentY)) {
      setModalError("");
      // switch views
      switchViews();
    } else {
      setModalError("Incorrect answer, please try again");
      setNumberOfClicks(prev => prev + 1);
    }
  };

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onClickHandler(e);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(e.target.value);
    setModalError("");
  };

  return (
    <Modal
      open={open}
      ariaLabel={title}
      onClose={() => {
        onClose();
        setAnswer("");
        setX(randNumPool());
        setY(randNumPool());
      }}
      sx={{
        minHeight: "unset",
        padding: "2rem",
        marginX: "1rem"
      }}
      fullBleed={false}
    >
      <Box
        flex
        sx={{
          alignItems: "center",
          flexDirection: "column",
          paddingTop: "1em"
        }}
      >
        <Box>
          <Typography
            variant="h2"
            sx={{
              marginBottom: "0.5rem",
              width: "100%"
            }}
          >
            {title}
          </Typography>
          <Typography variant="body1" paragraph>
            This space is designed for people 13 and older - to enter, please
            enter the correct answer
          </Typography>
          <ExternalLink
            url={SupportRoutes.learnerModeMultiplicationProblemUrl()}
          >
            Why this question?
          </ExternalLink>
        </Box>
        <Box
          flex
          sx={{
            marginTop: "1em",
            marginBottom: "0.5em",
            flexDirection: "column",
            boxShadow: "0px 2px 5px rgba(23, 25, 28, 0.1)",
            borderRadius: 16,
            overflowX: "clip",
            width: "240px",
            height: "110px",
            textAlign: "center"
          }}
        >
          <Box
            sx={{
              color: "common.white",
              paddingTop: "0.5em",
              flexGrow: 1,
              fontSize: "1.5em",
              fontWeight: 700,
              fontFamily: "'Ginto Normal',sans-serif",
              backgroundColor: "info.main"
            }}
          >
            {currentX} x {currentY} = ?
          </Box>
          <Box
            sx={{
              paddingTop: "0.5em",
              backgroundColor: "common.white",
              flexGrow: 1
            }}
          >
            <Input
              sx={multiplicationInput}
              required
              placeholder={"##"}
              maxLength={2}
              type="number"
              value={answer}
              onChange={onInputChange}
              onKeyDown={onKeyDown}
              autoComplete="off"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </Box>
        </Box>
        {modalError && (
          <Typography
            variant="inherit"
            sx={{
              marginTop: "1rem",
              color: "error.500"
            }}
          >
            {modalError}
          </Typography>
        )}
        <Box
          flex
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem"
          }}
        >
          <Button
            component={ExternalLink}
            url={SupportRoutes.learnerModePasswordProtectionUrl()}
            variant="link"
          >
            Set my own password
          </Button>
          <TrackedButton
            variant="contained"
            disabled={loading || !answer}
            type="submit"
            onClick={onClickHandler}
            trackingName="switch_views_with_multiplication"
            trackingSharedProperties={{
              ignoreNetworkError: true
            }}
          >
            Submit
          </TrackedButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default MultiplicationModal;
