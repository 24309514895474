// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { NavTabs } from "@outschool/ui-components-shared";
import { responsive, useIsMobile } from "@outschool/ui-utils";
import { usePath } from "@patched/hookrouter";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import {
  calendarViewPath,
  dashboardPath,
  dashboardSchedulePath
} from "../../lib/Routes";

interface TabInfo {
  path: string;
  label: string;
}

const ScheduleNavTabs: React.FC = () => {
  const isMobile = useIsMobile();
  const path = usePath();
  const defaultBorderColor = "#A0A9F9";
  const selectedColor = "#4B01D4";
  const defaultLinkSx: SystemStyleObject = {
    minWidth: responsive({ default: 130, medium: "200px", large: 260 }),
    textAlign: "center",
    backgroundColor: "white",
    lineHeight: "30px",
    paddingY: "8px",
    color: selectedColor,
    border: "2px solid",
    borderColor: defaultBorderColor,
    // Override NavTab component style
    borderBottom: `2px solid ${defaultBorderColor} !important`,
    fontFamily: "heading",
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "none !important",
      color: `${selectedColor} !important`
    }
  };
  const activeLinkSx: SystemStyleObject = {
    border: "2px solid",
    borderColor: selectedColor,
    backgroundColor: selectedColor,
    // Override NavTab component style
    borderBottom: `2px solid ${selectedColor} !important`,
    color: "white",
    "&:hover": {
      textDecoration: "none !important",
      color: "white !important"
    }
  };

  const tabs: TabInfo[] = makeTabs(isMobile);

  const lastTabIdx = tabs.length - 1;

  return (
    <NavTabs.TabList
      sx={{
        justifyContent: "center",
        fontSize: isMobile ? 18 : 24,
        marginY: 42
      }}
    >
      <NavTabs.Tab
        linkSx={{
          borderRadius: "8px 0px 0px 8px",
          borderRightWidth: 1,
          ...defaultLinkSx,
          ...(path === tabs[0].path ? activeLinkSx : {})
        }}
        exact={false}
        label={tabs[0].label}
        to={tabs[0].path}
      />
      {!isMobile && (
        <NavTabs.Tab
          linkSx={{
            borderRadius: 0,
            borderRightWidth: 1,
            borderLeftWidth: 1,
            ...defaultLinkSx,
            ...(path === tabs[1].path ? activeLinkSx : {})
          }}
          label={tabs[1].label}
          to={tabs[1].path}
        />
      )}
      <NavTabs.Tab
        linkSx={{
          borderRadius: "0px 8px 8px 0px",
          borderLeftWidth: 1,
          ...defaultLinkSx,
          ...(path === tabs[lastTabIdx].path ? activeLinkSx : {})
        }}
        label={tabs[lastTabIdx].label}
        to={tabs[lastTabIdx].path}
      />
    </NavTabs.TabList>
  );
};

const makeTabs = (isMobile: boolean): TabInfo[] => [
  {
    path: dashboardPath(),
    label: "All"
  },
  {
    path: dashboardSchedulePath(),
    label: "Schedule"
  },
  ...(isMobile
    ? []
    : [
        {
          path: calendarViewPath(),
          label: "Calendar"
        }
      ])
];

export default ScheduleNavTabs;
