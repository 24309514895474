import { useIsMounted } from "@outschool/ui-utils";
import { navigate as routerNavigate } from "@patched/hookrouter";

/**
 * A learner-app-specific implementation of `useNavigation`. Each client-side app that uses
 * the NavigationProvider should have its own implementation of this.
 *
 * DO NOT IMPORT DIRECTLY. Import `useNavigation` from `@outschool/ui-utils` instead.
 */
export function useLearnerNavigation() {
  const isMounted = useIsMounted();

  /**
   * Navigate to the URL provided. Uses window methods (`open`, `location.assign`, etc) for internal
   * URLS, and react-router (`history` methods) for internal URLs. Retains the current site locale.
   * @param linkTo URL to visit
   * @param options Config options
   * @param options.hardNav Override to use window methods (fully reload the page) even for internal URLS
   * @param options.newTab When using window navigation, open URL in a new tab (default is same tab)
   * @param options.replace Replace the current history entry instead of adding a new one. Applies to both
   * `window` and `history` navigation. Disables using the back button to return to the previous URL.
   */
  return function navigate(
    linkTo,
    { hardNav = false, replace = false, newTab = false } = {}
  ) {
    // For external URLs, always use window navigation
    if (/^https?/.test(linkTo) || hardNav) {
      newTab
        ? window.open(linkTo, "_blank")
        : window.location[replace ? "replace" : "assign"](linkTo);
    } else {
      if (isMounted) {
        // Don't navigate if the component is unmounted
        routerNavigate(linkTo, replace);
      }
    }
  };
}
