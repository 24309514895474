import {
  AddClassPostCommentMutation,
  AddClassPostCommentMutationVariables,
  TextMimeType
} from "@outschool/gql-frontend-generated";
import { ApolloCache, useMutation } from "@outschool/ui-apollo";
import { addClassPostCommentMutation } from "@outschool/ui-components-classroom";
import { addTypeName, validOptimisticResponse } from "@outschool/ui-utils";

type ClassPostComment = AddClassPostCommentMutation["addClassPostComment"];
type Attachment = ClassPostComment["messageContent"]["attachments"][0];
type User = Pick<ClassPostComment["sender"], "uid" | "name" | "photo">;
type Learner = Pick<ClassPostComment["sender"], "uid" | "name" | "avatar">;

export type UpdateClassroomCache = (
  store: ApolloCache<any>,
  variables: AddClassPostCommentMutationVariables,
  data: AddClassPostCommentMutation | null | undefined
) => void;

interface AddClassPostCommentMutationInput {
  currentUser?: User | null;
  updateClassroomCache?: UpdateClassroomCache;
}

type HandleAddClassPostCommentArgs = {
  classPostUid: string;
  text: string;
  attachments: Attachment[];
  video: Attachment;
  learner?: Learner;
  mentions?: ({ id: string } | string)[];
  backgroundColor?: string;
  /**
   * Overrides for the mutate function call, such as custom cache updates.
   */
  overrides?: {
    /**
     * Ideally these overrides should just be passed to the hook as updateClassroomCache, but sometimes the
     * call site is used outside of where the hook is initialized.
     */
    updateClassroomCache?: UpdateClassroomCache;
  };
};

export function useLearnerClassroomAddClassPostCommentMutation({
  currentUser,
  updateClassroomCache
}: AddClassPostCommentMutationInput) {
  const [mutate] = useMutation<
    AddClassPostCommentMutation,
    AddClassPostCommentMutationVariables
  >(addClassPostCommentMutation);

  return ({
    classPostUid,
    text,
    attachments,
    video,
    learner,
    mentions,
    backgroundColor,
    overrides
  }: HandleAddClassPostCommentArgs) => {
    const attachmentUids = attachments ? attachments.map(a => a.uid) : [];
    const videoUid = video ? video.uid : undefined;
    const mentionsUids = mentions
      ? mentions.map(m => (typeof m === "string" ? m : m.id))
      : [];
    const classPostCommentInput: AddClassPostCommentMutationVariables["classPostCommentInput"] =
      {
        classPostUid,
        learnerUid: learner?.uid,
        messageContent: {
          text,
          attachmentUids,
          videoUid,
          mentionsUids
        },
        backgroundColor
      };

    const variables = { classPostCommentInput, plainText: false };

    return mutate({
      variables,
      optimisticResponse: validOptimisticResponse({
        __typename: "Mutation",
        addClassPostComment: {
          __typename: "ClassPostComment",
          uid: new Date(),
          sendAt: new Date(),
          sender: {
            __typename: "MessageSender",
            uid: learner?.uid || currentUser?.uid,
            name: learner?.name || currentUser?.name,
            photo: currentUser?.photo || null,
            avatar: learner?.avatar || null,
            isLearner: learner ? true : false
          },
          messageContent: {
            __typename: "MessageContent",
            uid: new Date(),
            text,
            attachments: addTypeName("Attachment", attachments),
            video: addTypeName("Attachment", video),
            mentionsUids,
            editedAt: null,
            htmlText: null,
            originalMimeType: TextMimeType.TextPlainWithMentions
          },
          publishAt: new Date(),
          mentionedEnrollmentUids: mentionsUids,
          mentionedTeacherUid: null,
          backgroundColor: backgroundColor || null
        }
      }),
      update(cache, { data }) {
        if (overrides?.updateClassroomCache) {
          overrides.updateClassroomCache(cache, variables, data);
        } else {
          updateClassroomCache?.(cache, variables, data);
        }
      }
    });
  };
}
