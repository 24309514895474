import {
  LearnerClassroomAssignmentClassPostsBySectionQuery,
  LearnerClassroomAssignmentClassPostsBySectionQueryVariables,
  LearnerCommentOnAssignmentMutation,
  LearnerCommentOnAssignmentMutationVariables
} from "@outschool/gql-frontend-generated";
import {
  ApolloCache,
  FetchResult,
  gql,
  useMutation,
  useQueryWithPreviousData
} from "@outschool/ui-apollo";
import {
  AssignmentSubmissionThread,
  AssignmentSummaryCard,
  EditableMessageContentSubmitType,
  MessageContentFragment
} from "@outschool/ui-components-classroom";
import { useFeatureFlag } from "@outschool/ui-components-shared";
import { addTypeName } from "@outschool/ui-utils";

import {
  CurrentLearner,
  useCurrentLearner
} from "../providers/CurrentLearnerProvider";

const classroomAssignmentsQuery = gql`
  query LearnerClassroomAssignmentClassPostsBySection($sectionUid: ID!) {
    section(uid: $sectionUid) {
      uid
      assignmentClassPosts {
        uid
        user {
          uid
          name
        }
        title
        messageContent {
          ...MessageContentFragment
        }
        submissionRequirement
        publishAt
        dueDate
        comments {
          ...AssignmentCommentThread
        }
        ...AssignmentSummaryCardAssignment
      }
    }
  }
  ${MessageContentFragment}
  ${AssignmentSubmissionThread.fragments.assignmentCommentThread}
  ${AssignmentSummaryCard.fragments.assignmentSummary}
`;

export const useLearnerClassroomAssignmentsBySectionQuery = (
  variables: LearnerClassroomAssignmentClassPostsBySectionQueryVariables
) => {
  const isAssignmentsLearnerUiEnabled = useFeatureFlag(
    "lexi-assignments-learner"
  );

  return useQueryWithPreviousData<
    LearnerClassroomAssignmentClassPostsBySectionQuery,
    LearnerClassroomAssignmentClassPostsBySectionQueryVariables
  >(classroomAssignmentsQuery, {
    variables,
    skip: !isAssignmentsLearnerUiEnabled
  });
};

const learnerCommentOnAssignmentMutation = gql`
  mutation LearnerCommentOnAssignment(
    $assignmentClassPostUid: ID!
    $messageContentInput: MessageContentInput!
  ) {
    addAssignmentClassPostComment(
      assignmentClassPostUid: $assignmentClassPostUid
      messageContent: $messageContentInput
    ) {
      uid
      sentAt
      isSubmission
      messageContent {
        ...MessageContentFragment
      }
    }
  }
  ${MessageContentFragment}
`;

export const useLearnerCommentOnAssignmentMutation = ({
  sectionUid,
  isTopLevelSubmission = false
}: {
  sectionUid: string;
  isTopLevelSubmission?: boolean;
}) => {
  const currentLearner = useCurrentLearner()!;

  const [submitComment, { error, loading }] = useMutation<
    LearnerCommentOnAssignmentMutation,
    LearnerCommentOnAssignmentMutationVariables
  >(learnerCommentOnAssignmentMutation);

  return {
    submitComment: async (input: {
      assignmentClassPostUid: string;
      messageContentInput: EditableMessageContentSubmitType;
    }) => {
      const { assignmentClassPostUid, messageContentInput } = input;
      const { mentions, attachments, text, textMimeType, video } =
        messageContentInput;
      const mentionsUids = mentions?.map(m => m.id) || [];
      const attachmentUids = attachments?.map(a => a.uid) || [];

      const variables: LearnerCommentOnAssignmentMutationVariables = {
        assignmentClassPostUid,
        messageContentInput: {
          text,
          textMimeType,
          mentionsUids,
          attachmentUids,
          videoUid: video?.uid
        }
      };

      await submitComment({
        variables,
        ...(!isTopLevelSubmission
          ? {
              update: learnerCommentSubmitCacheUpdate({
                sectionUid,
                assignmentClassPostUid,
                currentLearner
              })
            }
          : {
              // easier for now just to refetch for top level submission - will refactor
              refetchQueries: [
                {
                  query: classroomAssignmentsQuery,
                  variables: { sectionUid }
                }
              ],
              awaitRefetchQueries: true
            })
      });
    },
    error,
    loading
  };
};

const learnerCommentSubmitCacheUpdate =
  ({
    sectionUid,
    assignmentClassPostUid,
    currentLearner
  }: {
    sectionUid: string;
    assignmentClassPostUid: string;
    currentLearner: CurrentLearner;
  }) =>
  (
    cache: ApolloCache<any>,
    { data }: Omit<FetchResult<LearnerCommentOnAssignmentMutation>, "context">
  ) => {
    if (!data?.addAssignmentClassPostComment) {
      return;
    }

    const newReply = data.addAssignmentClassPostComment;

    const cacheData = cache.readQuery<
      LearnerClassroomAssignmentClassPostsBySectionQuery,
      LearnerClassroomAssignmentClassPostsBySectionQueryVariables
    >({
      query: classroomAssignmentsQuery,
      variables: { sectionUid }
    });
    if (!cacheData) {
      return;
    }

    const { section } = cacheData;
    if (!section) {
      return;
    }

    const { assignmentClassPosts } = section;
    const assignmentClassPost = assignmentClassPosts?.find(
      ({ uid }) => uid === assignmentClassPostUid
    );
    if (!assignmentClassPost) {
      return;
    }

    const sender = addTypeName("MessageSender", {
      uid: currentLearner.uid,
      name: currentLearner.name,
      photo: null,
      avatar: currentLearner.avatar,
      isLearner: true
    });

    const { comments } = assignmentClassPost;
    const comment = comments.find(
      ({ sender }) => sender.uid === currentLearner.uid
    );
    if (!comment) {
      // parent comment doesn't exist, so we create it
      const newComment = addTypeName("AssignmentClassPostComment", {
        ...newReply,
        // first comment is always the submission
        isSubmission: true,
        sender
      });

      const updatedAssignmentClassPost = {
        ...assignmentClassPost,
        comments: [newComment]
      };
      cache.writeQuery({
        query: classroomAssignmentsQuery,
        variables: { sectionUid },
        data: {
          section: {
            ...section,
            assignmentClassPosts: assignmentClassPosts?.map(existingPost =>
              existingPost.uid === assignmentClassPostUid
                ? updatedAssignmentClassPost
                : existingPost
            )
          }
        }
      });
      return;
    }

    const newThreadReply = {
      ...newReply,
      isSubmission: false,
      sender
    };
    const updatedComment = {
      ...comment,
      // placing new reply at end of thread
      replies: [...(comment.replies || []), newThreadReply]
    };

    const updatedAssignmentClassPost = {
      ...assignmentClassPost,
      comments: comments.map(existingComment =>
        existingComment.uid === comment.uid ? updatedComment : existingComment
      )
    };

    cache.writeQuery({
      query: classroomAssignmentsQuery,
      variables: { sectionUid, plainText: true },
      data: {
        section: {
          ...section,
          assignmentClassPosts: assignmentClassPosts?.map(existingPost =>
            existingPost.uid === assignmentClassPostUid
              ? updatedAssignmentClassPost
              : existingPost
          )
        }
      }
    });
  };
