import {
  NetworkStatus,
  gql,
  useQueryWithPreviousData
} from "@outschool/ui-apollo";
import { useState } from "react";

import {
  LearnerListingRecommendationsQuery,
  LearnerListingRecommendationsQueryVariables
} from "../generated/graphql";
import LearnerActivitySearchResultFragment from "./LearnerActivitySearchResultFragment";

const LearnerListingsRecommendationsQuery = gql`
  query LearnerListingRecommendations(
    $shuffle: Boolean
    $limit: Int
    $offset: Int
  ) {
    featured {
      perLearnerRecommendations(
        shuffle: $shuffle
        limit: $limit
        offset: $offset
      ) {
        activity {
          ...LearnerActivitySearchResultFragment
        }
      }
    }
  }
  ${LearnerActivitySearchResultFragment}
`;

export function useLearnerListingRecommendationsQuery(
  variables: LearnerListingRecommendationsQueryVariables
) {
  const [offset, setOffset] = useState(0);
  const { loading, error, data, refetch, fetchMore, networkStatus } =
    useQueryWithPreviousData<LearnerListingRecommendationsQuery>(
      LearnerListingsRecommendationsQuery,
      {
        variables,
        context: {
          batchKey: "LearnerRecommendedListings"
        },
        notifyOnNetworkStatusChange: true
      }
    );
  const recommendedListings =
    data?.featured?.perLearnerRecommendations.map(r => r.activity) || [];
  const shuffleListings = () => refetch({ shuffle: true });
  const isShufflingListings =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const fetchMoreRecommendations = (
    variables: LearnerListingRecommendationsQueryVariables & { limit: number }
  ) => {
    const newOffset = offset + variables.limit;
    setOffset(newOffset);
    setIsFetchingMore(true);
    fetchMore({
      variables: {
        ...variables,
        offset: newOffset
      },
      updateQuery: (
        previousResult: LearnerListingRecommendationsQuery,
        { fetchMoreResult }
      ) => {
        setIsFetchingMore(false);
        const moreRecommendations =
          fetchMoreResult?.featured?.perLearnerRecommendations;
        if (!moreRecommendations) {
          setCanFetchMore(false);
          return previousResult;
        }
        return {
          featured: {
            ...previousResult?.featured,
            ...fetchMoreResult?.featured,
            perLearnerRecommendations: [
              ...previousResult?.featured.perLearnerRecommendations,
              ...moreRecommendations
            ]
          }
        };
      }
    });
  };

  return {
    loading,
    error,
    recommendedListings,
    shuffleListings,
    isShufflingListings,
    canFetchMore,
    fetchMoreRecommendations,
    isInfiniteScrollEnabled: Boolean(
      canFetchMore && recommendedListings.length > 0 && !loading && !error
    ),
    isFetchingInitial: loading && recommendedListings.length === 0,
    isFetchingMore
  };
}
