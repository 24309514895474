import { Icon, SxProps, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { faArrowUp } from "@outschool/icons";
import { TrackedButton } from "@outschool/ui-components-shared";
import { useDimensions, usePrefersReducedMotion } from "@outschool/ui-utils";
import React from "react";

export default function BackToTopButton({
  sx = {},
  trackingName
}: {
  sx?: SxProps;
  trackingName?: string;
}) {
  const prefersReducedMotion = usePrefersReducedMotion();
  const [hasScrollbar, setHasScrollbar] = React.useState<boolean>(false);
  const { height } = useDimensions(document.body);

  React.useEffect(() => {
    setHasScrollbar(height !== null && document.body.scrollHeight > height);
  }, [height]);

  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: prefersReducedMotion ? undefined : "smooth"
    });
  };

  if (!hasScrollbar) {
    return null;
  }

  return (
    <TrackedButton onClick={onClick} sx={sx} trackingName={trackingName}>
      <Icon icon={faArrowUp} />
      <Typography
        variant="inherit"
        sx={{
          paddingLeft: "1em"
        }}
      >
        Back to the Top
      </Typography>
    </TrackedButton>
  );
}
