import {
  AddClassPostCommentMutationVariables,
  LearnerClassroomPostsQuery,
  LearnerClassroomPostsQueryVariables
} from "@outschool/gql-frontend-generated";
import {
  ApolloCache,
  Reference,
  gql,
  useQueryWithPreviousData
} from "@outschool/ui-apollo";

import { UpdateClassroomCache } from "../hooks/useLearnerClassroomAddClassPostCommentMutation";
import LearnerClassPostList from "../routes/LearnerClassroom/ClassPostList";

export const classroomPostsQuery = gql`
  query LearnerClassroomPosts(
    $sectionUid: ID!
    $start: DateTime
    $end: DateTime
    $plainText: Boolean
    $lessonUid: ID
    $postsFilter: ClassroomPostFilter
  ) {
    classroom(sectionUid: $sectionUid) {
      uid
      ...LearnerClassPostList_classroom
    }
  }
  ${LearnerClassPostList.fragments.classroom}
`;

export const useLearnerClassroomPostsQuery = (
  args: Omit<LearnerClassroomPostsQueryVariables, "plainText">
) =>
  useQueryWithPreviousData<
    LearnerClassroomPostsQuery,
    LearnerClassroomPostsQueryVariables
  >(classroomPostsQuery, {
    variables: {
      ...args,
      plainText: false
    }
  });

export const updateLearnerClassroomPostsCache: UpdateClassroomCache = (
  cache: ApolloCache<any>,
  variables: AddClassPostCommentMutationVariables,
  newClassPostComment
) => {
  // If modifying, reference shape of LearnerClassroomPostsQuery generated type for details
  cache.modify({
    id: cache.identify({
      __typename: "ClassPost",
      uid: variables.classPostCommentInput.classPostUid
    }),
    fields: {
      commentsPreview(
        commentsPreviewRef: Reference,
        { readField, toReference }
      ) {
        const comments = readField<Reference[]>("comments", commentsPreviewRef);
        if (
          comments &&
          // Sanity check comments being an array
          Array.isArray(comments) &&
          newClassPostComment?.addClassPostComment
        ) {
          return {
            ...commentsPreviewRef,
            comments: [
              ...comments,
              toReference(newClassPostComment.addClassPostComment)
            ]
          };
        }
        return commentsPreviewRef;
      }
    }
  });
};
