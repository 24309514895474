import { Alert, AlertActionButton, Box, Typography } from "@outschool/backpack";
import {
  CLASSROOM_BOX_SX,
  ClassroomLearnerList,
  LearnerProfilesBanner,
  LiveMeetingButton,
  PageSelect
} from "@outschool/ui-components-classroom";
import { DismissibleAlert } from "@outschool/ui-components-website";
import { ErrorBoundary } from "@outschool/ui-legacy-component-library";
import { Redirect, useScrollToElement } from "@outschool/ui-utils";
import { useTitle } from "@patched/hookrouter";
import { maxBy } from "lodash";
import React, { useRef } from "react";

import {
  EnrollmentToReviewSoon,
  useEnrollmentToReviewSoon
} from "../../hooks/useLearnerSectionReview";
import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import { defaultTitle } from "../../lib/PageMetadata";
import { learnerRoutes } from "../../lib/Routes";
import {
  ClassroomPostsProvider,
  useClassroomPostsContext
} from "../../providers/ClassroomPostsProvider";
import {
  CurrentLearner,
  useCurrentLearner
} from "../../providers/CurrentLearnerProvider";
import {
  LearnerAuth,
  useLearnerAuth
} from "../../providers/LearnerAuthProvider";
import {
  CurrentLesson,
  Learners,
  Section,
  useLearnerClassroomContext
} from "../../providers/LearnerClassroomProvider";
import LearnerClassPostList from "./ClassPostList";
import ClassroomError from "./ClassroomError";
import LearnerSectionReview from "./LearnerSectionReview";
import { ClassroomAnnouncements } from "./lessonsPage/ClassroomAnnouncements";
import {
  AllLessonsCompletedView,
  LessonView,
  LessonViewHeading,
  MakeupMeetingView,
  PreSectionLessonView,
  SectionLessonView
} from "./lessonsPage/LessonView";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

const LearnerClassroomPage = () => {
  const currentLearner = useCurrentLearner();
  const { error, sectionUid, activity } = useLearnerClassroomContext();

  useAnalyticsPage({
    pageName: PageType.Classroom,
    pageParams: { sectionUid }
  });

  if (!currentLearner || error) {
    return <ErrorLoadingClassroom error={error} />;
  }

  if (activity && activity.isClub) {
    return <Redirect to={learnerRoutes.clubPath(sectionUid)} />;
  }

  return <ClassroomContent />;
};

export default LearnerClassroomPage;

const ClassroomContent = () => {
  const {
    sectionUid,
    activity,
    section,
    learners,
    syllabus,
    allLessonsCompleted
  } = useLearnerClassroomContext();
  const currentLearner = useCurrentLearner()!;

  const enrollmentToReviewSoon = useEnrollmentToReviewSoon();

  const { sessionTokenStore } = useLearnerAuth();
  const learnerAuth = sessionTokenStore?.getLearnerAuth();

  useTitle(activity?.title ? `${activity?.title} | Outschool` : defaultTitle);

  if (
    syllabus?.uid &&
    syllabus?.published_at &&
    (syllabus?.lessons?.some(lesson => Number(lesson?.meetings?.length) > 0) ||
      section?.is_self_paced)
  ) {
    const currentLesson = syllabus?.currentLesson;
    return (
      <LearnerClassroomWithSyllabus
        currentLesson={currentLesson}
        allLessonsCompleted={allLessonsCompleted}
        section={section!}
        enrollmentToReviewSoon={enrollmentToReviewSoon}
        currentLearner={currentLearner}
        learners={learners}
        learnerAuth={learnerAuth}
      />
    );
  }

  return (
    <ClassroomPostsProvider>
      <LearnerClassroomWithoutSyllabus
        sectionUid={sectionUid}
        enrollmentToReviewSoon={enrollmentToReviewSoon}
        currentLearner={currentLearner}
        learners={learners}
        learnerAuth={learnerAuth}
      />
    </ClassroomPostsProvider>
  );
};

const LearnerClassroomWithoutSyllabus = ({
  sectionUid,
  enrollmentToReviewSoon,
  currentLearner,
  learners,
  learnerAuth
}) => {
  const { currentLearnerEnrollments } = useLearnerClassroomContext();

  const latestEnrollment =
    maxBy(currentLearnerEnrollments, `ongoing_start_time`) ||
    maxBy(currentLearnerEnrollments, `ongoing_end_time`);

  // In the interest of code diff, stick with prop-drilling for now
  const { pageOptions, setTimeBounds } = useClassroomPostsContext();

  const topOfClassroomRef = useRef<HTMLDivElement>();

  return (
    <Box>
      {enrollmentToReviewSoon &&
        enrollmentToReviewSoon.section!.uid === sectionUid && (
          <Box
            sx={{
              marginBottom: "1em"
            }}
          >
            <LearnerSectionReview
              enrollmentToReviewSoon={enrollmentToReviewSoon}
            />
          </Box>
        )}
      <Box
        sx={theme => ({
          position: "relative",
          display: "initial",
          [theme.breakpoints.up("md")]: {
            display: "grid"
          },
          gap: 32,
          gridTemplateColumns: "minmax(0, 1fr) min-content",
          width: "100%"
        })}
      >
        <Box>
          <Box
            sx={theme => ({
              paddingX: "1em",

              [theme.breakpoints.up("md")]: {
                paddingX: "2em"
              },

              paddingY: "2em",
              backgroundColor: "common.white"
            })}
          >
            <Typography
              variant="h4"
              sx={{
                marginBottom: "0.5em"
              }}
            >
              Hi {currentLearner.name}!
            </Typography>
            This page is for class announcements and discussions. Please refresh
            the page to see the latest attendance information.
          </Box>
          <LiveMeetingSection
            sectionUid={sectionUid}
            learnerUid={currentLearner.uid ?? learnerAuth?.learnerUid}
            timeZone={currentLearner.timeZone}
          />
          {latestEnrollment?.isPaused && (
            <Alert severity="error">
              Your Subscription for this class is paused
            </Alert>
          )}
          <Box ref={topOfClassroomRef} />
          <ErrorBoundary>
            <LearnerClassPostList sx={{ marginTop: 24 }} />
            {pageOptions && (
              <Box>
                <PageSelect
                  pageOptions={pageOptions}
                  onChange={newBounds => {
                    setTimeBounds(newBounds);
                    if (topOfClassroomRef.current) {
                      setTimeout(
                        () =>
                          window.scrollTo({
                            behavior: "smooth",
                            top: topOfClassroomRef.current!.offsetTop
                          }),
                        150
                      );
                    }
                  }}
                />
              </Box>
            )}
          </ErrorBoundary>
        </Box>
        {learners && (
          <ClassroomLearnerList
            currentLearnerUid={currentLearner?.uid ?? learnerAuth?.learnerUid}
            learners={learners}
            isCurrentLearnerPaused={latestEnrollment?.isPaused}
          />
        )}
      </Box>
    </Box>
  );
};

interface LearnerClassroomWithSyllabusProps {
  currentLesson: CurrentLesson | null;
  allLessonsCompleted: Boolean | null;
  section: Section;
  enrollmentToReviewSoon: EnrollmentToReviewSoon | null;
  currentLearner: CurrentLearner;
  learners?: Learners;
  learnerAuth?: LearnerAuth | null;
}

const LearnerClassroomWithSyllabus = ({
  currentLesson,
  allLessonsCompleted,
  section,
  enrollmentToReviewSoon,
  currentLearner,
  learners,
  learnerAuth
}: LearnerClassroomWithSyllabusProps) => {
  const announcementsRef = useRef<HTMLSpanElement>(null);

  const currentLessonOrder = React.useMemo(() => {
    return currentLesson
      ? section?.sectionLessons?.find(
          lesson => lesson.lesson?.uid === currentLesson?.uid
        )?.orderInSection
      : section?.sectionLessons?.find(
          lesson => lesson.lesson?.uid === section?.currentSectionLesson?.uid
        )?.orderInSection;
  }, [currentLesson, section?.sectionLessons, section?.currentSectionLesson]);

  const findBeforeCurrentLesson = React.useMemo(() => {
    const order = currentLessonOrder;
    return !order
      ? null
      : section.sectionLessons?.find(
          lesson => order - 1 === lesson.orderInSection
        );
  }, [section?.sectionLessons, currentLessonOrder]);
  // Function to check if the lesson before the current lesson is missed
  const isBeforeCurrentAndMissed = React.useCallback(() => {
    // Find the lesson immediately before the current lesson in the section
    const beforeCurrent = findBeforeCurrentLesson;
    return !beforeCurrent
      ? false
      : beforeCurrent?.lesson?.isMissed
      ? true
      : false;
  }, [findBeforeCurrentLesson]);
  const missedLessonUid = findBeforeCurrentLesson?.uid;
  const beforeCurrentLesson = findBeforeCurrentLesson;
  const lessonViewTitle = !!section?.is_self_paced
    ? "Up Next"
    : beforeCurrentLesson
    ? "Review and Prepare"
    : "Prepare For Your Week";

  let CurrentLessonView: React.ReactNode | null = currentLesson &&
    beforeCurrentLesson && <LessonView lesson={currentLesson} />;

  CurrentLessonView = currentLesson && !beforeCurrentLesson && (
    <LessonView
      heading={
        <LessonViewHeading
          text={lessonViewTitle}
          sectionUid={section.uid}
          showLessonsLink
        />
      }
      lesson={currentLesson}
    />
  );
  if (section.currentSectionLesson && !beforeCurrentLesson) {
    CurrentLessonView = (
      <SectionLessonView
        sectionLesson={section.currentSectionLesson}
        heading={
          <LessonViewHeading
            text={lessonViewTitle}
            sectionUid={section.uid}
            showLessonsLink
          />
        }
      />
    );
  }
  if (section.currentSectionLesson && beforeCurrentLesson) {
    CurrentLessonView = (
      <SectionLessonView sectionLesson={section.currentSectionLesson} />
    );
  }

  return (
    <Box>
      {enrollmentToReviewSoon &&
        enrollmentToReviewSoon.section!.uid === section.uid && (
          <Box
            sx={{
              marginBottom: 8
            }}
          >
            <LearnerSectionReview
              enrollmentToReviewSoon={enrollmentToReviewSoon}
            />
          </Box>
        )}

      <Box
        sx={theme => ({
          position: "relative",
          display: "initial",
          [theme.breakpoints.up("md")]: {
            display: "grid"
          },
          gap: 32,
          gridTemplateColumns: "minmax(0, 1fr) min-content",
          width: "100%"
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 48,
            // Space above Footer
            marginBottom: 64,
            paddingTop: 32
          }}
        >
          {isBeforeCurrentAndMissed() && missedLessonUid && (
            <DismissibleAlert
              severity="warning"
              title="Missed your previous lesson?"
              id={`${currentLesson?.uid}-missedLesson-alert`}
              action={
                <AlertActionButton
                  href={learnerRoutes.lessonsPath(section.uid, missedLessonUid)}
                >
                  View Lesson Details
                </AlertActionButton>
              }
              dismissible
              dismissWillMute
              disableOpenAnimation
            >
              <Typography variant="body1">
                Watch a class recording to catch up on your lesson!
              </Typography>
            </DismissibleAlert>
          )}
          <AnnouncementsAlert
            sectionUid={section.uid}
            announcementsRef={announcementsRef}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 16
            }}
          >
            <Box>
              {beforeCurrentLesson &&
                (currentLesson || section?.currentSectionLesson) && (
                  <PreSectionLessonView
                    heading={
                      <LessonViewHeading
                        text={lessonViewTitle}
                        sectionUid={section.uid}
                        showLessonsLink
                      />
                    }
                    sectionLesson={beforeCurrentLesson}
                  />
                )}
            </Box>
            <Box>
              {currentLesson ? (
                CurrentLessonView
              ) : allLessonsCompleted ? (
                <AllLessonsCompletedView
                  heading={
                    <LessonViewHeading
                      text={"You've Completed This Course"}
                      sectionUid={section.uid}
                      showLessonsLink
                    />
                  }
                />
              ) : (
                <MakeupMeetingView
                  heading={<LessonViewHeading text={lessonViewTitle} />}
                />
              )}
            </Box>
          </Box>
          <ClassroomAnnouncements titleRef={announcementsRef} />
        </Box>
        {section.is_self_paced ? (
          <Box sx={{ minWidth: "250px" }}>
            <LearnerProfilesBanner
              learner={currentLearner}
              sectionStartTime={section?.start_time}
            />
          </Box>
        ) : learners ? (
          <ClassroomLearnerList
            currentLearnerUid={currentLearner?.uid ?? learnerAuth?.learnerUid}
            learners={learners}
          />
        ) : null}
      </Box>
    </Box>
  );
};

const LiveMeetingSection = ({ sectionUid, learnerUid, timeZone }) => (
  // TODO: we need to update the background color based on if the meeting is happening right now or not
  <Box
    sx={{
      ...CLASSROOM_BOX_SX,
      textAlign: "center",
      paddingY: "1em",
      borderBottom: "1px solid",
      borderColor: "#f1f2f3",
      backgroundColor: "#f1f2f3"
    }}
  >
    <LiveMeetingButton
      sectionUid={sectionUid}
      learnerUid={learnerUid}
      userTimeZone={timeZone}
      sectionJoinPathFn={learnerRoutes.sectionJoinPath}
    />
  </Box>
);

const ErrorLoadingClassroom = ({ error }: { error?: Error }) => {
  const { sectionUid } = useLearnerClassroomContext();
  const currentLearner = useCurrentLearner();
  const { sessionTokenStore } = useLearnerAuth();
  const learnerAuth = sessionTokenStore?.getLearnerAuth();

  return (
    <>
      {error && (
        // In case of an outage we render the live meeting section with an error message
        <ClassroomError error={error} />
      )}
      <LiveMeetingSection
        sectionUid={sectionUid}
        learnerUid={currentLearner?.uid ?? learnerAuth?.learnerUid}
        timeZone={currentLearner?.timeZone}
      />
    </>
  );
};

const AnnouncementsAlert = ({
  sectionUid,
  announcementsRef
}: {
  sectionUid: string;
  announcementsRef: React.RefObject<HTMLSpanElement>;
}) => {
  const scrollToElement = useScrollToElement();

  return (
    <DismissibleAlert
      severity="info"
      title="Don't miss announcements"
      id={`${sectionUid}-announcements-alert`}
      action={
        <AlertActionButton
          onClick={() => scrollToElement(announcementsRef.current)}
        >
          Jump to Announcements
        </AlertActionButton>
      }
      dismissible
      dismissWillMute
      disableOpenAnimation
    >
      <Typography variant="body1">
        Welcome to your classroom! Scroll down to see announcements posted by
        your teacher as you take your lessons.
      </Typography>
    </DismissibleAlert>
  );
};
