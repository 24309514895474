import { lazyWithRetry } from "@outschool/ui-utils";
import { navigate, useRedirect, useRoutes } from "@patched/hookrouter";
import React, { useEffect } from "react";

import { HeroLayoutWithTabs } from "../../layout/HeroLayoutWithTabs";
import * as Routes from "../../lib/Routes";
import { useLearnerAuth } from "../../providers/LearnerAuthProvider";
import LearnerDashboardSectionFragment from "../../queries/LearnerDashboardSectionFragment";
import ScheduleNavTabs from "./ScheduleNavTabs";

const AllLearnerActivities = lazyWithRetry(
  () => import("./AllLearnerActivities")
);
const UpcomingClasses = lazyWithRetry(() => import("./UpcomingClasses"));
const LearnerCalendar = lazyWithRetry(() => import("./LearnerCalendar"));

const dashboardRoutes = {
  "/calendar": () => <LearnerCalendar />,
  "/all": () => <AllLearnerActivities />,
  "/schedule": () => <UpcomingClasses />
};

function LearnerDashboard() {
  useRedirect("/", "/all");
  useRedirect("/upcoming-classes", "/all");
  useRedirect("/past-classes", "/all");

  const currentRoute = useRoutes(dashboardRoutes);
  const { learnerAuth } = useLearnerAuth();

  useEffect(() => {
    if (!learnerAuth) {
      OsPlatform.captureError(
        "Unauthenticated learner loaded learner dashboard"
      );
      navigate(Routes.homePath());
    }
  }, [learnerAuth]);

  return (
    <HeroLayoutWithTabs currentPath={Routes.dashboardPath()}>
      <ScheduleNavTabs />
      {currentRoute}
    </HeroLayoutWithTabs>
  );
}

export const navigateToSection = section => {
  if (section.activity.isClub) {
    navigate(Routes.clubPath(section.uid));
  } else {
    navigate(Routes.classroomPath(section.uid));
  }
};

LearnerDashboard.fragments = {
  section: LearnerDashboardSectionFragment
};

export default LearnerDashboard;
