import { Box, Image, Typography } from "@outschool/backpack";
import { ErrorMessage } from "@outschool/ui-components-shared";
import { Loading, Space } from "@outschool/ui-legacy-component-library";
import { useIsMobile, useLoadMoreIfEndOfScreen } from "@outschool/ui-utils";
import { useTitle } from "@patched/hookrouter";
import React, { useState } from "react";
import { useAnalytics } from "use-analytics";

import * as searchSvg from "../../../../images/search.svg";
import { useSearchSuggestionsContext } from "../../../../shared/client/providers/SearchSuggestionsProvider";
import { ClassCardsGrid } from "../../components/ClassCards";
import LearnerSearchFilters, {
  useActivityExperiencesFilter
} from "../../components/LearnerSearchFilters";
import * as Env from "../../Env";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useLearnerKeywordSearchResults } from "../../queries/LearnerKeywordSearchResultsQuery";
import RecommendedTopics from "./RecommendedTopics";

type KeywordSearchResultsProps = {
  keyword: string;
  onReachedEndOfResults?(): void;
  headingContent?: React.ReactNode;
  hideNoMatchesContent?: boolean;
};

function KeywordSearchResults({
  keyword,
  onReachedEndOfResults,
  headingContent,
  hideNoMatchesContent
}: KeywordSearchResultsProps) {
  const formattedKeyword = decodeURIComponent(keyword);
  const { track } = useAnalytics();
  useTitle(`Classes matching "${formattedKeyword}" | Outschool`);
  const activityExperiences = useActivityExperiencesFilter();
  const {
    activities,
    error,
    fetchMoreActivities,
    isInfiniteScrollEnabled,
    isFetchingInitial,
    canFetchMore,
    isFetchingMore
  } = useLearnerKeywordSearchResults({
    query: formattedKeyword,
    activityExperiences
  });
  const isMobile = useIsMobile();
  const searchSuggestionsContext = useSearchSuggestionsContext();
  const [endOfScreenRef, setEndOfScreenRef] = useState<HTMLDivElement>();
  useLoadMoreIfEndOfScreen({
    fetchMore: () => fetchMoreActivities(),
    endOfScreenRef,
    isInfiniteScrollEnabled,
    trackLoadMore: () =>
      track(`browse.search.load_more.keyword`, {
        query: formattedKeyword,
        activityExperiences
      })
  });

  React.useEffect(() => {
    if (!canFetchMore && !isFetchingMore && !!onReachedEndOfResults) {
      onReachedEndOfResults();
    }
  }, [canFetchMore, isFetchingMore, onReachedEndOfResults]);

  if (searchSuggestionsContext?.initialSelectedItem?.uid !== "keyword") {
    searchSuggestionsContext?.setInitialSelectedItem({
      uid: "keyword",
      type: "keyword",
      name: formattedKeyword
    });
  }

  if (isFetchingInitial) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorMessage value={error} showStatusPageLink={Env.IS_READ_ONLY_MODE} />
    );
  }

  return (
    <>
      <LearnerSearchFilters />
      <Box
        flex
        sx={{
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          width: "100%"
        }}
      >
        {!activities || activities.length === 0 ? (
          hideNoMatchesContent ? null : (
            <>
              <NoClassesMatch keyword={formattedKeyword} />
              <RecommendedTopics />
            </>
          )
        ) : (
          <>
            {!!headingContent && (
              <Typography variant={isMobile ? "h3" : "h2"} center>
                {headingContent}
              </Typography>
            )}
            <ClassCardsGrid
              activities={activities}
              trackingPrefix="browse.search"
            />
            {isFetchingMore && <Loading delay={0} />}
            <Box ref={setEndOfScreenRef}></Box>
          </>
        )}
      </Box>
    </>
  );
}

function NoClassesMatch({ keyword }) {
  return (
    <Box
      sx={{
        fontSize: 16,
        lineHeight: "24px",
        textAlign: "center",
        marginBottom: "3em"
      }}
    >
      <Image
        sx={{
          marginBottom: 8
        }}
        src={searchSvg}
      />
      <Box>
        No classes match{" "}
        <Typography
          variant="inherit"
          sx={{
            fontWeight: 500
          }}
        >
          "{keyword}"
        </Typography>
      </Box>
      <Space y="medium" />
      <Box>Try a different search, or explore the topics below.</Box>
    </Box>
  );
}

export default KeywordSearchResults;
