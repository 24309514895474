import {
  Box,
  Button,
  Icon,
  SxProps,
  Theme,
  Typography
} from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { faSearch } from "@outschool/icons";
import { useIsMobile, useIsSmall } from "@outschool/ui-utils";
import { navigate } from "@patched/hookrouter";
import React, { useEffect } from "react";
import { useAnalytics } from "use-analytics";

import Link from "../../components/Link";
import * as Routes from "../../lib/Routes";
import { useLearnerAuth } from "../../providers/LearnerAuthProvider";
import HeaderLogo from "./HeaderLogo";
import HeaderNavItem from "./HeaderNavItem";
import { LearnerSwitcher } from "./LearnerSwitcher";

interface HeaderLeftProps {
  isWhiteText: boolean;
}

const HeaderLeft: React.FC<HeaderLeftProps> = ({ isWhiteText }) => {
  const isMobile = useIsMobile();
  const isSmall = useIsSmall();
  const { learnerAuth } = useLearnerAuth();
  return (
    <Link to={learnerAuth ? Routes.dashboardPath() : Routes.homePath()}>
      <HeaderNavItem>
        <HeaderLogo isWhiteText={!isMobile && isWhiteText} iconOnly={isSmall} />
      </HeaderNavItem>
    </Link>
  );
};

const FindClassesPageLink: React.FC = () => {
  const { track } = useAnalytics();

  useEffect(() => {
    track("header_find_classes_button_view", { ignoreNetworkError: true });
  }, [track]);

  return (
    <Link
      to={Routes.findClassesPath()}
      trackingName="header_find_classes_button_touch"
      style={{ textDecoration: "none" }}
    >
      <HeaderNavItem>
        <Icon
          icon={faSearch}
          sx={(theme: Theme) => ({
            fontSize: "1.333em",
            marginRight: "0.5em",
            height: "1.333em",

            [theme.breakpoints.up("sm")]: {
              height: "32px"
            }
          })}
        />
        <Typography
          variant="h3"
          sx={theme => ({
            display: "none",

            [theme.breakpoints.up("md")]: {
              display: "block"
            }
          })}
        >
          Search
        </Typography>
      </HeaderNavItem>
    </Link>
  );
};

function HeaderNavSeparator() {
  return (
    <Box
      sx={{
        width: "1px",
        backgroundColor: "#ecf1f8",
        marginY: "2px"
      }}
    />
  );
}

interface HeaderProps {
  sx?: SxProps;
  includeBlob?: boolean;
  includeFindClasses?: boolean;
  whiteLogoText?: boolean;
  hideMenu?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  sx,
  includeBlob,
  includeFindClasses = false,
  whiteLogoText = false,
  hideMenu = false
}) => {
  const { learnerAuth } = useLearnerAuth();
  const LearnerMenu = () => {
    if (hideMenu) {
      return null;
    }
    return learnerAuth ? (
      <LearnerSwitcher includeBlob={includeBlob} />
    ) : (
      <Button
        sx={{
          marginRight: "0.5em"
        }}
        onClick={() => navigate(Routes.loginPath())}
      >
        Log in
      </Button>
    );
  };

  return (
    <Box
      flex
      sx={[
        (theme: Theme) => ({
          position: "absolute",
          zIndex: 1,
          width: "100%",
          height: "60px",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fcfdfd",
          paddingX: "1rem",

          [theme.breakpoints.up("md")]: {
            backgroundColor: "transparent",
            borderColor: "transparent",
            borderBottom: "0"
          },

          borderColor: "rgba(23,25,28,0.1)",
          borderBottom: "1px solid"
        }),
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <HeaderLeft isWhiteText={whiteLogoText} />
      {includeFindClasses ? (
        <Box flex>
          <FindClassesPageLink />
          <HeaderNavSeparator />
          <LearnerMenu />
        </Box>
      ) : (
        <LearnerMenu />
      )}
    </Box>
  );
};

export default Header;
