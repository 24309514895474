import { gql } from "@outschool/ui-apollo";

export const ActivityFragment = gql`
  fragment LearnerAppActivityFragment on Activity {
    uid
    slug_id
    user_uid
    title
    class_experience
    learning_goals
    assessment
    homework
    prerequisites
    published_at
    is_online
    age_min
    age_max
    size_min
    size_max
    duration_minutes
    duration_weeks
    weekly_meetings
    price_cents
    summary
    is_ongoing_weekly
    subject
    refundPolicy {
      name
      description
    }
    details {
      photo
    }
    languageOfInstruction
    summaryForLearners
    isClub
    isAutoSchedulingEnabled
    autoSchedulingDaysNoticeMin
    autoSchedulingDaysNoticeMax
    hasTeacherSchedule
  }
`;
