import { Box, Icon, Image, Theme, Typography } from "@outschool/backpack";
import {
  faExclamationTriangle,
  faPencil,
  faQuestionCircle
} from "@outschool/icons";
import { useMutation } from "@outschool/ui-apollo";
import {
  ErrorMessage,
  ExternalLink,
  TrackedButton,
  TrackedButtonProps
} from "@outschool/ui-components-shared";
import {
  Checkbox,
  Loading,
  Select,
  Space,
  TextArea
} from "@outschool/ui-legacy-component-library";
import { responsive, useIsMobile } from "@outschool/ui-utils";
import { navigate, useQueryParams } from "@patched/hookrouter";
import { SystemStyleObject } from "@styled-system/css";
import { Line as LineProgressBar } from "rc-progress";
import React from "react";

import reportIssuePng from "../../../images/learner/shield.png";
import * as Env from "../Env";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  LearnerSupportTopicsQuery,
  LearnerSupportType,
  SubmitLearnerSupportIssueMutation,
  SubmitLearnerSupportIssueMutationVariables
} from "../generated/graphql";
import { LearnerSwitcher } from "../layout/nav/LearnerSwitcher";
import {
  REPORT_ISSUE_REFERRER_PATH_NAME,
  crisisTextLineUrl,
  learnerRoutes,
  websiteRoutes
} from "../lib/Routes";
import {
  submitLearnerSupportIssue,
  useLearnerSupportTopics
} from "../queries/LearnerSupportTopicsQuery";

enum ReportIssueSteps {
  SelectIssue = "SelectIssue",
  TellUsMore = "TellUsMore",
  Submitted = "Submitted"
}

type LearnerSupportTopic =
  LearnerSupportTopicsQuery["learnerSupportTopics"][number];

export const REPORT_ISSUE_TRACKING_PREFIX = "report_issue";
const MAX_ISSUE_DESCRIPTION_LENGTH = 2000;

function ReportIssue() {
  const [reportStep, setReportStep] = React.useState<ReportIssueSteps>(
    ReportIssueSteps.SelectIssue
  );
  const [selectedSupportTopic, setSelectedSupportTopic] =
    React.useState<LearnerSupportTopic>();
  const { data, loading, error } = useLearnerSupportTopics();
  const isMobile = useIsMobile();

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return (
      <ErrorMessage value={error} showStatusPageLink={Env.IS_READ_ONLY_MODE} />
    );
  }

  const supportTopics = data.learnerSupportTopics;
  const progressBarWidth =
    ReportIssueSteps.Submitted === reportStep
      ? "100"
      : ReportIssueSteps.TellUsMore === reportStep
      ? "66.6"
      : "33.3";
  const onReportAnotherIssue = () => {
    setSelectedSupportTopic(undefined);
    setReportStep(ReportIssueSteps.SelectIssue);
  };
  return (
    <Box
      flex
      sx={theme => ({
        width: "100%",
        px: "1em",
        flexDirection: "column",
        background: "white",
        borderRadius: 16,
        boxShadow: "none",

        [theme.breakpoints.up("sm")]: {
          boxShadow:
            "0px 15px 30px rgba(189, 226, 233, 0.15), 0px 20px 40px rgba(189, 226, 233, 0.05)",
          marginTop: "1em",
          paddingX: "3em"
        },

        maxWidth: "792px",
        margin: "0 auto",
        marginTop: "3em",

        [theme.breakpoints.up("lg")]: {
          marginTop: 0
        },

        paddingX: "1em",
        paddingY: "36px",
        alignItems: "stretch"
      })}
    >
      <LineProgressBar
        percent={progressBarWidth}
        strokeWidth={isMobile ? "2" : "1"}
        trailWidth={isMobile ? "2" : "1"}
        strokeColor={"#380596"}
        trailColor="#F1F1F1"
      />
      <Space y="medium" />
      {(reportStep === ReportIssueSteps.SelectIssue ||
        !selectedSupportTopic) && (
        <SelectIssue
          setSelectedSupportTopic={setSelectedSupportTopic}
          supportTopics={supportTopics}
          selectedSupportTopic={selectedSupportTopic}
          setReportStep={setReportStep}
        />
      )}
      {reportStep === ReportIssueSteps.TellUsMore && selectedSupportTopic && (
        <TellUsMore
          selectedSupportTopic={selectedSupportTopic}
          setReportStep={setReportStep}
        />
      )}
      {reportStep === ReportIssueSteps.Submitted && selectedSupportTopic && (
        <SuccessfullySubmitted
          selectedSupportTopic={selectedSupportTopic}
          onReportAnotherIssue={onReportAnotherIssue}
        />
      )}
    </Box>
  );
}

interface SelectIssueProps {
  supportTopics: LearnerSupportTopic[];
  selectedSupportTopic: LearnerSupportTopic | undefined;
  setReportStep: React.Dispatch<React.SetStateAction<ReportIssueSteps>>;
  setSelectedSupportTopic: React.Dispatch<
    React.SetStateAction<LearnerSupportTopic | undefined>
  >;
}

function SelectIssue({
  supportTopics,
  selectedSupportTopic,
  setSelectedSupportTopic,
  setReportStep
}: SelectIssueProps) {
  return (
    <>
      <ShieldImage />
      <Space y="medium" />
      <ReportIssueHeading text="Report a problem" />
      <Space y="large" />
      {supportTopics.map(topic => (
        <Checkbox
          key={topic.type}
          id={topic.type}
          name="report-issue-options"
          checked={topic.type === selectedSupportTopic?.type}
          onChange={() => setSelectedSupportTopic(topic)}
          sx={{
            marginBottom: "20px",
            width: "100%",
            label: { width: "100%", marginBottom: 0 },
            input: {
              opacity: 0,
              width: 0,
              height: 0
            },
            "input:focus + label > div": {
              border: "1px solid #A0A9F9"
            }
          }}
          label={
            <SupportTopic
              selected={topic.type === selectedSupportTopic?.type}
              topic={topic}
            />
          }
          iconHidden={true}
        />
      ))}
      <ProceedButton
        sx={{
          alignSelf: "flex-end"
        }}
        disabled={!selectedSupportTopic}
        onClick={() => setReportStep(ReportIssueSteps.TellUsMore)}
        trackingSharedProperties={{
          type: selectedSupportTopic?.type
        }}
        trackingName={`${REPORT_ISSUE_TRACKING_PREFIX}.proceed_after_selection`}
      >
        Next
      </ProceedButton>
    </>
  );
}

interface TellUsMoreProps {
  selectedSupportTopic: LearnerSupportTopic;
  setReportStep: React.Dispatch<React.SetStateAction<ReportIssueSteps>>;
}
function TellUsMore({ selectedSupportTopic, setReportStep }: TellUsMoreProps) {
  const [issueDescription, setIssueDescription] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");

  const [submitIssue, { loading }] = useMutation<
    SubmitLearnerSupportIssueMutation,
    SubmitLearnerSupportIssueMutationVariables
  >(submitLearnerSupportIssue, {
    variables: { issueType: selectedSupportTopic.type, text: issueDescription },
    onCompleted: () => setReportStep(ReportIssueSteps.Submitted)
  });

  const onGoBackClick = () => setReportStep(ReportIssueSteps.SelectIssue);

  return (
    <>
      <ShieldImage />
      <Space y="medium" />
      <ReportIssueHeading text="Tell us more" />
      <Space y="large" />
      <TrackedButton
        variant="link"
        sx={{
          width: "100%",
          textAlign: "left"
        }}
        aria-label="Change Selected Topic"
        onClick={onGoBackClick}
        trackingSharedProperties={{
          type: selectedSupportTopic?.type
        }}
        trackingName={`${REPORT_ISSUE_TRACKING_PREFIX}.edit_issue`}
      >
        <SupportTopic selected topic={selectedSupportTopic} />

        <Icon
          icon={faPencil}
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: "1em",
            fontSize: 24,
            color: "primary.700"
          }}
        />
      </TrackedButton>
      {selectedSupportTopic.type === LearnerSupportType.IFeelUnsafe && (
        <SafetyWarning />
      )}
      <Space y={20} />
      {selectedSupportTopic.type === LearnerSupportType.IFeelUnsafe ? (
        <SafetyConcernDescribeIssue
          placeholder={selectedSupportTopic.placeholder}
          setIssueDescription={setIssueDescription}
        />
      ) : (
        <>
          <Space y="small" />
          <DescribeIssueTextArea
            label={
              <Typography
                variant="inherit"
                sx={{
                  lineHeight: "24px",
                  color: "grey.500"
                }}
              >
                {selectedSupportTopic.prompt}
              </Typography>
            }
            placeholder={selectedSupportTopic.placeholder}
            setIssueDescription={setIssueDescription}
          />
        </>
      )}
      <Space y="medium" />
      <MessageReaderInfo />
      <Space y={20} />
      <Divider />
      <ErrorMessage value={error} showStatusPageLink={Env.IS_READ_ONLY_MODE} />
      <Box
        flex
        sx={{
          justifyContent: "space-between"
        }}
      >
        <TrackedButton
          variant="link"
          onClick={onGoBackClick}
          trackingSharedProperties={{
            type: selectedSupportTopic?.type
          }}
          trackingName={`${REPORT_ISSUE_TRACKING_PREFIX}.go_back_to_select_issue_step`}
        >
          Back
        </TrackedButton>
        {loading ? (
          <Loading />
        ) : (
          <ProceedButton
            onClick={async () => {
              try {
                await submitIssue();
              } catch (e) {
                setError(e.message);
              }
            }}
            trackingSharedProperties={{
              type: selectedSupportTopic?.type
            }}
            trackingName={`${REPORT_ISSUE_TRACKING_PREFIX}.submit_issue`}
            disabled={
              issueDescription.length === 0 ||
              issueDescription.length > MAX_ISSUE_DESCRIPTION_LENGTH
            }
          >
            Submit
          </ProceedButton>
        )}
      </Box>
    </>
  );
}

const SAFETY_CONCERN_OPTIONS = [
  "I do not feel safe in my Outschool class",
  "I want to hurt myself",
  "Someone has hurt me physically",
  "I am scared at home"
];
function SafetyConcernDescribeIssue({
  setIssueDescription: setParentIssueDescription,
  placeholder
}) {
  const [concern, setConcern] = React.useState(SAFETY_CONCERN_OPTIONS[0]);
  const [issueDescription, setIssueDescription] = React.useState("");

  const safetyConcernAndIssueDescription =
    issueDescription.length > 0 ? concern + "\n" + issueDescription : "";

  React.useEffect(() => {
    setParentIssueDescription?.(safetyConcernAndIssueDescription);
  }, [setParentIssueDescription, safetyConcernAndIssueDescription]);

  return (
    <Box
      sx={{
        backgroundColor: "#f8f8f9",
        borderRadius: 8,
        padding: "20px"
      }}
    >
      <label>
        <Typography
          variant="inherit"
          sx={{
            fontWeight: 500
          }}
        >
          Why do you feel unsafe?
        </Typography>
        <Box
          sx={{
            marginTop: "0.5em"
          }}
        >
          <Select
            value={concern}
            onChange={setConcern}
            options={SAFETY_CONCERN_OPTIONS}
            sx={{ width: "100%" }}
          />
        </Box>
      </label>
      <Space y="large" />
      <DescribeIssueTextArea
        label={
          <Typography
            variant="inherit"
            sx={{
              fontWeight: 500
            }}
          >
            Please tell us what's going on. Share your first and last name,
            city, state, and country
          </Typography>
        }
        placeholder={placeholder}
        setIssueDescription={setIssueDescription}
        sx={{ backgroundColor: "white", marginTop: "small" }}
      />
    </Box>
  );
}

interface DescribeIssueTextAreaProps {
  placeholder: string;
  label: JSX.Element;
  setIssueDescription: (newIssueDesription: string) => void;
  sx?: SystemStyleObject;
}
function DescribeIssueTextArea({
  label,
  placeholder,
  setIssueDescription,
  sx = {}
}: DescribeIssueTextAreaProps) {
  return (
    <label>
      {label}
      <TextArea
        placeholder={placeholder}
        maxLength={MAX_ISSUE_DESCRIPTION_LENGTH}
        sx={{
          padding: "20px",
          backgroundColor: "gray7",
          borderColor: "gray7",
          minHeight: 210,
          fontSize: 16,
          ...sx
        }}
        onChange={e =>
          setIssueDescription((e.target as HTMLTextAreaElement).value)
        }
      />
    </label>
  );
}

interface SuccessfullySubmittedStepProps {
  selectedSupportTopic: LearnerSupportTopic;
  onReportAnotherIssue: () => void;
}
function SuccessfullySubmitted({
  selectedSupportTopic,
  onReportAnotherIssue
}: SuccessfullySubmittedStepProps) {
  const [params] = useQueryParams();
  const redirectPath =
    params?.[REPORT_ISSUE_REFERRER_PATH_NAME] || learnerRoutes.dashboardPath();

  return (
    <>
      <ShieldImage />
      <Space y="medium" />
      <ReportIssueHeading text="We got your message!" />
      <Space y={responsive({ default: 24, small: "large" })} />
      <Box
        flex
        sx={{
          borderRadius: 16,
          backgroundColor: "#f8f8f9",
          padding: "1em"
        }}
      >
        <Icon
          icon={faQuestionCircle}
          sx={{
            position: "relative",
            top: "4px"
          }}
        />
        <Space x="small" />
        <Box
          flex
          sx={{
            flexDirection: "column"
          }}
        >
          <Typography
            variant="inherit"
            sx={{
              fontWeight: "fontWeightBold",
              color: "#F0F0F0",
              lineHeight: "24px",
              fontSize: 16
            }}
          >
            What's going to happen after you sent the message?
          </Typography>
          <Typography
            variant="inherit"
            sx={{
              color: "#F0F0F0",
              lineHeight: "24px",
              fontSize: 16
            }}
          >
            {selectedSupportTopic.type === LearnerSupportType.IFeelUnsafe ? (
              <>
                Our support team will read your message and take action. In the
                meantime, see{" "}
                <ExternalLink
                  trackingName="learner_crisis_link"
                  url={crisisTextLineUrl()}
                >
                  tips
                </ExternalLink>{" "}
                from experts about what to do and how to get help when you feel
                unsafe.
              </>
            ) : (
              <>
                Our support team may contact your trusted adult(s) to help
                resolve the issue.
              </>
            )}
          </Typography>
        </Box>
      </Box>
      <Space y="large" />
      <Divider />
      <Box
        flex
        sx={{
          justifyContent: "space-between"
        }}
      >
        <TrackedButton
          variant="link"
          onClick={onReportAnotherIssue}
          trackingName={`${REPORT_ISSUE_TRACKING_PREFIX}.report_another`}
        >
          Report another issue
        </TrackedButton>
        <ProceedButton
          trackingSharedProperties={{
            type: selectedSupportTopic?.type
          }}
          trackingName={`${REPORT_ISSUE_TRACKING_PREFIX}.done`}
          onClick={() => navigate(redirectPath)}
        >
          Done
        </ProceedButton>
      </Box>
    </>
  );
}
const SupportTopic = ({ topic, selected }) => (
  <Box
    flex
    sx={{
      flexDirection: "column",
      borderRadius: 8,
      border: "1px solid",
      width: "100%",
      paddingX: "1em",
      paddingY: "0.5em",
      background: selected ? "#F0F3FF" : "white",
      borderColor: selected ? "#A0A9F9" : "grey.100"
    }}
  >
    <Typography
      variant="inherit"
      sx={{
        fontFamily: "'Ginto Normal',sans-serif",
        color: "grey.900",
        fontWeight: "fontWeightBold",
        lineHeight: "24px",
        fontSize: 16
      }}
    >
      {topic.title}
    </Typography>
    <Typography
      variant="inherit"
      sx={{
        color: "grey.500",
        lineHeight: "20px",
        fontSize: 16
      }}
    >
      {topic.description}
    </Typography>
  </Box>
);

const ProceedButton = (props: TrackedButtonProps) => (
  <TrackedButton variant="contained" {...props} />
);

const MessageReaderInfo = () => (
  <Box
    flex
    sx={{
      alignItems: "center"
    }}
  >
    <Icon icon={faQuestionCircle} />
    <Space x="small" />
    <Typography
      variant="inherit"
      sx={{
        color: "grey.500"
      }}
    >
      Members of the Outschool team will see this message
    </Typography>
  </Box>
);

const ShieldImage = () => (
  <Image
    src={reportIssuePng}
    sx={{
      height: 250,
      margin: "auto"
    }}
  />
);

const SafetyWarning = () => (
  <Box
    flex
    sx={{
      alignItems: "flex-start",
      marginTop: "0.5em"
    }}
  >
    <Icon
      icon={faExclamationTriangle}
      sx={{
        color: "#D52D2D",
        marginTop: "2px"
      }}
    />
    <Space x={4} />
    <Typography
      variant="inherit"
      sx={{
        fontSize: 14,
        fontWeight: 600
      }}
    >
      If someone is in immediate danger, contact the police right away. If you
      are feeling like you want to hurt yourself or need to talk to someone,
      please visit{" "}
      <ExternalLink url="https://suicidepreventionlifeline.org">
        this helpline
      </ExternalLink>
      .
    </Typography>
  </Box>
);

const ReportIssueHeading = ({ text }) => {
  return (
    <>
      <Typography
        sx={(theme: Theme) => ({
          lineHeight: "57px",
          fontSize: `${theme.typography.pxToRem(42)} !important`,
          fontWeight: 800
        })}
        variant="h1"
        align="center"
      >
        {text}
      </Typography>

      <Box
        sx={{
          textAlign: "center",
          marginTop: "0.5em",
          marginBottom: "1em"
        }}
      >
        <Typography
          variant="inherit"
          sx={{
            color: "grey.500",
            pt: "4px",
            fontSize: 16
          }}
        >
          This page is for learners. Adults should{" "}
        </Typography>
        <LearnerSwitcher
          switcherType={"button"}
          redirect={websiteRoutes.intercomPopUpPath()}
          onlyParent
          buttonProps={{
            variant: "link",
            title: "switch to parent mode",
            sx: { fontSize: "16 !important" }
          }}
        />
        <Typography
          variant="inherit"
          sx={{
            color: "grey.500",
            pt: "4px",
            fontSize: 16
          }}
        >
          .
        </Typography>
      </Box>
    </>
  );
};

const Divider = () => (
  <Box
    sx={{
      marginBottom: "2em",
      border: "1px solid",
      borderColor: "#f1f2f3"
    }}
  ></Box>
);

export default ReportIssue;
