import {
  TranslationsProvider,
  createUseMachineTranslationHook,
  createUseTranslationHook
} from "@outschool/localization";
import { VideoPlayerProvider } from "@outschool/ui-components-shared";
import {
  ComponentTrackingProvider,
  LegacyThemeProvider,
  Loading
} from "@outschool/ui-legacy-component-library";
import { NavigationProvider } from "@outschool/ui-utils";
import React from "react";
import { AnalyticsProvider } from "use-analytics";

import DefaultErrorBoundary from "./components/DefaultErrorBoundary";
import Link from "./components/Link";
import VideoPlayer from "./components/VideoPlayer";
import useClubRouteState from "./hooks/useClubRouteState";
import { useLearnerNavigation } from "./hooks/useLearnerNavigation";
import LearnerGlobalStyle from "./LearnerGlobalStyle";
import analytics from "./lib/analytics";
import i18n from "./lib/i18n";
import CurrentLearnerProvider from "./providers/CurrentLearnerProvider";
import { ExperimentsServiceProvider } from "./providers/ExperimentsServiceProvider";
import FeatureFlagProvider from "./providers/FeatureFlagProvider";
import { GraphqlProvider } from "./providers/GraphqlProvider";
import { LearnerAuthProvider } from "./providers/LearnerAuthProvider";
import LearnerNotificationsProvider from "./providers/LearnerNotificationsProvider";
import Routes from "./routes";

// learnerApp does not currently translate, so pass in function that returns false.
const useTranslation = createUseTranslationHook(() => false);
const useMachineTranslation = createUseMachineTranslationHook({
  useIsTranslating: () => false,
  onError: () => null,
  onTranslated: () => null
});

const App: React.FC = () => {
  useClubRouteState();
  return (
    <LearnerAuthProvider>
      <ExperimentsServiceProvider>
        <NavigationProvider
          linkComponent={Link}
          useNavigation={useLearnerNavigation}
        >
          <LegacyThemeProvider>
            <TranslationsProvider
              i18n={i18n}
              useTranslation={useTranslation}
              useMachineTranslation={useMachineTranslation}
            >
              <DefaultErrorBoundary>
                <AnalyticsProvider instance={analytics}>
                  <ComponentTrackingProvider externalTrackFn={analytics.track}>
                    <GraphqlProvider>
                      <VideoPlayerProvider videoPlayerComponent={VideoPlayer}>
                        <React.Suspense fallback={<Loading />}>
                          <CurrentLearnerProvider>
                            <FeatureFlagProvider>
                              <RoutesAndLearnerNotificationsProvider />
                            </FeatureFlagProvider>
                          </CurrentLearnerProvider>
                        </React.Suspense>
                      </VideoPlayerProvider>
                    </GraphqlProvider>
                  </ComponentTrackingProvider>
                </AnalyticsProvider>
              </DefaultErrorBoundary>
            </TranslationsProvider>
          </LegacyThemeProvider>
        </NavigationProvider>
        <LearnerGlobalStyle />
      </ExperimentsServiceProvider>
    </LearnerAuthProvider>
  );
};

const RoutesAndLearnerNotificationsProvider = () => {
  return (
    <LearnerNotificationsProvider>
      <Routes />
    </LearnerNotificationsProvider>
  );
};

export default App;
