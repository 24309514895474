// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Image, SxProps } from "@outschool/backpack";
import * as React from "react";

import blobLeft from "../../../images/blob-left.png";
import blobMiddle from "../../../images/blob-middle.png";
import blobRight from "../../../images/blob-right.png";

export interface BackgroundBlobProps {
  sx?: SxProps;
}

const BackgroundBlob: React.FC<
  React.PropsWithChildren<BackgroundBlobProps>
> = ({ children, sx }) => {
  return (
    <Box
      flex
      sx={{
        alignItems: "flex-start",
        justifyContent: "stretch"
      }}
    >
      <Image src={blobLeft} alt="" />
      <Box
        sx={[
          {
            backgroundImage: `url(${blobMiddle})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            height: 75
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
      >
        {children}
      </Box>
      <Image src={blobRight} alt="" />
    </Box>
  );
};

export default BackgroundBlob;
