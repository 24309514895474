import { Tab, TabProps } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { LearnerRoutes } from "@outschool/routes";
import { useFeatureFlag } from "@outschool/ui-components-shared";
import { Loading } from "@outschool/ui-legacy-component-library";
import { useRoutes } from "@patched/hookrouter";
import React from "react";

import Link from "../../components/Link";
import TabbedLayout from "../../layout/TabbedLayout";
import {
  LearnerClassroomProvider,
  useLearnerClassroomContext
} from "../../providers/LearnerClassroomProvider";
import { NotFoundPage } from "../NotFoundPage";
import { ClassroomAssignmentsPage } from "./ClassroomAssignmentsPage";
import ClassroomHeader from "./ClassroomHeader";
import { ClassroomLessonsPage } from "./ClassroomLessonsPage";
import LearnerClassroomPage from "./LearnerClassroomPage";
import PrivateClassMessagesPage from "./PrivateClassMessagesPage";
import RecordingPage from "./RecordingPage";
import SectionJoinPage from "./SectionJoinPage";
import SinglePostPage from "./SinglePostPage";

const tabValues = {
  Classroom: "classroom",
  Lessons: "lessons",
  Assignments: "assignments",
  PrivateClassMessages: "private-class-messages"
};

const classroomRoutes: {
  [key: string]: (routeParams: { [key: string]: string }) => {
    value?: string;
    renderPage: () => JSX.Element;
  };
} = {
  "/": () => ({
    value: tabValues.Classroom,
    renderPage: () => <LearnerClassroomPage />
  }),
  "/lessons": () => ({
    value: tabValues.Lessons,
    renderPage: () => <ClassroomLessonsPage />
  }),
  "/lessons/:lessonUid": ({ lessonUid }) => ({
    value: tabValues.Lessons,
    renderPage: () => <ClassroomLessonsPage lessonUid={lessonUid} />
  }),
  "/assignments": () => ({
    value: tabValues.Assignments,
    renderPage: () => <ClassroomAssignmentsPage />
  }),
  "/join/:enrollmentUid": ({ enrollmentUid }) => ({
    renderPage: () => <SectionJoinPage enrollmentUid={enrollmentUid} />
  }),
  "/private-class-messages": () => ({
    value: tabValues.PrivateClassMessages,
    renderPage: () => <PrivateClassMessagesPage />
  }),
  "/posts/:classPostUid": ({ classPostUid }) => ({
    renderPage: () => <SinglePostPage classPostUid={classPostUid} />
  }),
  "/recording/:recordingUid": ({ recordingUid }) => ({
    renderPage: () => <RecordingPage recordingUid={recordingUid} />
  })
};

const useTabs = ({
  sectionUid,
  hasLessons,
  hasAssignments
}: {
  sectionUid: string;
  hasLessons: boolean;
  hasAssignments: boolean;
}): TabProps[] => {
  const { t } = useTranslation("learnerApp\\LearnerClassroom");
  const isNewClassNavEnabled = useFeatureFlag("lexi-improved-class-nav-v1");
  const isAssignmentsLearnerUiEnabled = useFeatureFlag(
    "lexi-assignments-learner"
  );

  return [
    {
      value: tabValues.Classroom,
      label: t("Classroom"),
      component: Link,
      to: LearnerRoutes.classroomPath(sectionUid)
    } as TabProps<typeof Link>,
    ...(isNewClassNavEnabled && hasLessons
      ? [
          {
            value: tabValues.Lessons,
            label: t("Lessons"),
            component: Link,
            to: LearnerRoutes.lessonsPath(sectionUid)
          } as TabProps<typeof Link>
        ]
      : []),
    ...(isAssignmentsLearnerUiEnabled && hasAssignments
      ? [
          {
            value: tabValues.Assignments,
            label: t("Assignments"),
            component: Link,
            to: LearnerRoutes.assignmentsPath(sectionUid)
          } as TabProps<typeof Link>
        ]
      : []),
    {
      value: tabValues.PrivateClassMessages,
      label: t("Message Teacher"),
      component: Link,
      to: LearnerRoutes.privateClassMessagesPath(sectionUid)
    } as TabProps<typeof Link>
  ];
};

const LearnerClassroomLayout = () => {
  const { loading, classroom, sectionUid, hasLessons, hasAssignments } =
    useLearnerClassroomContext();

  const tab = useRoutes(classroomRoutes);
  const tabs = useTabs({ sectionUid, hasLessons, hasAssignments });

  if (loading) {
    return <Loading />;
  }

  if (!tab || !classroom) {
    return <NotFoundPage />;
  }

  const { value, renderPage } = tab;

  return (
    <TabbedLayout
      currentTab={value ?? false}
      header={<ClassroomHeader />}
      tabs={tabs.map(tab => (
        <Tab key={tab.value} {...tab} />
      ))}
    >
      {renderPage()}
    </TabbedLayout>
  );
};

interface LearnerClassroomProps {
  sectionUid: string;
}

const LearnerClassroom = ({ sectionUid }: LearnerClassroomProps) => {
  const isSectionLessonsEnabled = useFeatureFlag(
    "lexi-class-nav-section-lessons"
  );

  return (
    <LearnerClassroomProvider
      isSectionLessonsEnabled={isSectionLessonsEnabled}
      sectionUid={sectionUid}
    >
      <LearnerClassroomLayout />
    </LearnerClassroomProvider>
  );
};

export default React.memo(LearnerClassroom);
