import { LearnerTrackingEvent } from "@outschool/data-schemas";
import {
  AnalyticsPluginRestProxyConfig,
  AnalyticsPluginRestProxyTrackArgs,
  LEARNER_TRACKING_INTAKE_TOPIC,
  LEARNER_TRACKING_INTAKE_TOPIC_SCHEMA_ID,
  RestProxyBrowserClient
} from "@outschool/kafka-rest-proxy-client";
import { dayjs } from "@outschool/time";
import Analytics from "analytics";
import _ from "lodash";

import * as Env from "../../Env";

const getTimeZone = _.memoize(() => dayjs.tz.guess());

function getEventData(payload: any): LearnerTrackingEvent {
  // very long UAs are malicious
  const userAgent = navigator?.userAgent.substring(0, 200);
  const user = analytics.user();

  // computing this on every event seems a waste of cycles
  const isLearnerLogin =
    payload.traits?.isLearnerLogin || user.traits?.isLearnerLogin || false;
  const context = { userAgent, userTimeZone: getTimeZone(), isLearnerLogin };

  // by default, page() events track title, which can be derived from page_name / section_uid
  const {
    title: _title,
    page_name,
    ...trimmedEventData
  } = payload.properties || {};
  return {
    timestamp: dayjs(payload.meta.timestamp).toISOString(),
    anonymous_id: payload.anonymousId,
    // In learner mode the user we're tracking is the learner, so they're end up
    // in the payload as userId
    learner_uid: payload.userId,
    // We also track the parent's Uid as a trait, but that's only sent with the
    // identify call
    user_uid: payload.traits?.parentUid,
    // Page name is only tracked for page calls
    page_name: page_name,
    event_name:
      payload.event?.substring(0, 80) || payload.type?.substring(0, 80),
    // using "{}" in empty case for backwards compatibility
    event_data: JSON.stringify(trimmedEventData) || "{}",
    context: JSON.stringify(context)
  };
}

/** analytics.js plugging to send data via Kafka Rest Proxy
 * see https://getanalytics.io/plugins/writing-plugins/
 * an example in github: https://github.com/DavidWells/analytics/blob/master/packages/analytics-plugin-intercom/src/node.js
 * @param payload
 */
function RestProxyAnalyticsPluginLearnerSite(
  config: AnalyticsPluginRestProxyConfig
) {
  const client = new RestProxyBrowserClient(config);

  const sendEvent = ({ payload }: { payload: any }) => {
    const trackArgs: AnalyticsPluginRestProxyTrackArgs = {
      event: getEventData(payload),
      topic: LEARNER_TRACKING_INTAKE_TOPIC,
      topic_schema_id: LEARNER_TRACKING_INTAKE_TOPIC_SCHEMA_ID
    };

    // call provider specific event tracking, parameters not typed but they are typed on calls to track method
    client.sendEvent(trackArgs);
  };

  // return object for analytics to use
  return {
    name: "kafkaRestProxyClient",
    identify: sendEvent,
    page: sendEvent,
    track: sendEvent
  };
}

const restProxyAnalyticsPlugin = RestProxyAnalyticsPluginLearnerSite({
  endpoint: Env.KAFKA_REST_PROXY
});

const testLearnerTracking = () => ({
  name: "testLearnerTracking",
  identify: console.log,
  page: console.log,
  track: console.log
});

const analytics = Analytics({
  app: "learnerApp",
  version: "1",
  plugins: Env.isTest ? [testLearnerTracking()] : [restProxyAnalyticsPlugin]
});

export default analytics;
