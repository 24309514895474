// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box } from "@outschool/backpack";
import React from "react";

import BackToTopButton from "../../components/BackToTopButton";
import RecommendedClasses from "./RecommendedClasses";
import RecommendedTopics from "./RecommendedTopics";
import TeachersYouKnow from "./TeachersYouKnow";

function DefaultPage() {
  return (
    <Box
      flex
      sx={{
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <RecommendedTopics />
      <TeachersYouKnow />
      <RecommendedClasses />
      <BackToTopButton
        trackingName={"browse.recommended.back_to_top"}
        sx={{ marginTop: "2em" }}
      />
    </Box>
  );
}

export default DefaultPage;
