import { Component } from "@outschool/ownership-areas";
import { gql, useMutation } from "@outschool/ui-apollo";
import { useQueryParams } from "@patched/hookrouter";
import React, { useEffect } from "react";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  VerifyLearnerTransferMutation,
  VerifyLearnerTransferMutationVariables
} from "../../generated/graphql";
import { loginPath } from "../../lib/Routes";
import { useLearnerAuth } from "../../providers/LearnerAuthProvider";

export const verifyLearnerTransferToken = gql`
  mutation VerifyLearnerTransfer($token: String!, $isLearnerLogin: Boolean!) {
    verifyLearnerTransfer(token: $token, isLearnerLogin: $isLearnerLogin) {
      sessionToken
      ... on AuthenticationV2 {
        refreshToken
      }
    }
  }
`;

const AuthCodePage: React.FC<{ authCode: string }> = ({ authCode }) => {
  const [params] = useQueryParams();
  const { setSessionToken } = useLearnerAuth();
  const [mutation] = useMutation<
    VerifyLearnerTransferMutation,
    VerifyLearnerTransferMutationVariables
  >(verifyLearnerTransferToken, { context: { ignoreAutoRefresh: true } });

  useEffect(() => {
    if (authCode) {
      mutation({
        variables: {
          token: authCode,
          isLearnerLogin: params.ll === "t"
        }
      })
        .then(({ data }) => {
          const sessionToken = data?.verifyLearnerTransfer.sessionToken;
          const refreshToken =
            data?.verifyLearnerTransfer.__typename === "AuthenticationV2"
              ? data?.verifyLearnerTransfer.refreshToken
              : undefined;
          if (sessionToken) {
            setSessionToken({
              sessionToken,
              refreshToken
            });
          } else {
            throw new Error("No sessionToken was found");
          }
        })
        .then(() => {
          const redirectUrl = params.learnerAppUrl || "/";
          window?.location.replace(redirectUrl);
        })
        .catch(err => {
          OsPlatform.captureError(err, {
            tags: { component: Component.LearnerMode }
          });
          window?.location.replace(loginPath());
        });
    } else {
      window?.location.replace(loginPath());
    }
  }, [authCode, params, setSessionToken, mutation]);

  return null;
};

export default AuthCodePage;
