import { Box, Button, Icon, Image, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { faHeart } from "@outschool/icons";
import { navigate } from "@patched/hookrouter";
import React from "react";

import dashboardZeroState from "../routes/LearnerDashboard/zeroState.svg";
import savedActivityZeroState from "../routes/SavedActivities/zeroState.svg";

export function EmptyClassState({
  learnerPage
}: {
  learnerPage: "Dashboard" | "SavedActivityList";
}) {
  return (
    <Box
      sx={{
        display: "grid",
        justifyContent: "center",
        justifyItems: "center",
        m: "2em",
        flexDirection: "column",
        gap: "0.5em",
        textAlign: "center"
      }}
    >
      <Image
        sx={{
          maxWidth: "100%"
        }}
        src={
          learnerPage === "SavedActivityList"
            ? savedActivityZeroState
            : dashboardZeroState
        }
        alt=""
      />
      <Typography variant="h3">
        {learnerPage === "SavedActivityList"
          ? "You haven't saved any classes yet!"
          : "You don't have any scheduled classes!"}
      </Typography>
      <p>
        {learnerPage === "SavedActivityList" ? "Want to try it out? " : ""}
        <Button variant="link" onClick={() => navigate("/find-classes")}>
          Find classes you like
        </Button>{" "}
        and click the <Icon icon={faHeart} /> to save it{" "}
        {learnerPage === "SavedActivityList" ? "to this list" : ""}
      </p>
    </Box>
  );
}
