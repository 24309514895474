/* import-sort-ignore */
import "@babel/polyfill";
import { skipTreeshake } from "./src/init-platform-browser";
import "../stylesheets/fonts.css";

import React from "react";
import { createRoot } from "react-dom/client";

import App from "./src/App";

skipTreeshake();
const container = document.getElementById("app-main");
const root = createRoot(container!);
root.render(<App />);

(window as any).osClientJsLoadedAt = window.performance?.now?.() ?? Date.now();

const difference =
  (window as any).osClientJsLoadedAt - (window as any).osJsStartedAt;
const seconds = (difference / 1000).toFixed(2);
console.log(`OS Client JS finished execution after ${seconds} seconds.`);
