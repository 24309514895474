import { gql, useApolloClient } from "@outschool/ui-apollo";
import { FeatureFlagProvider as SharedFeatureFlagProvider } from "@outschool/ui-components-shared";
import React from "react";

import { ENABLED_FEATURE_FLAGS } from "../Env";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { FeatureFlagsQueryQuery } from "../generated/graphql";
import { useLearnerAuth } from "./LearnerAuthProvider";

const FeatureFlagsQuery = gql`
  query FeatureFlagsQuery {
    featureFlags
  }
`;

function useFetchFeatureFlags() {
  const client = useApolloClient();
  return React.useCallback(async () => {
    const { data } = await client.query<FeatureFlagsQueryQuery>({
      query: FeatureFlagsQuery,
      fetchPolicy: "network-only"
    });
    return data.featureFlags;
  }, [client]);
}

export default function FeatureFlagProvider({
  children
}: React.PropsWithChildren<{}>) {
  const { learnerAuth } = useLearnerAuth();
  const fetchFeatureFlags = useFetchFeatureFlags();
  return (
    <SharedFeatureFlagProvider
      currentUserUid={learnerAuth?.userUid}
      featureFlagOverrides={ENABLED_FEATURE_FLAGS}
      fetchFeatureFlags={fetchFeatureFlags}
    >
      {children}
    </SharedFeatureFlagProvider>
  );
}
