import * as Auth from "@outschool/auth-shared";
import { Box, Theme, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { AuthenticationV2 } from "@outschool/gql-backend-generated";
import {
  LoginMutation,
  LoginMutationVariables
} from "@outschool/gql-frontend-generated";
import { isValidEmailOrUsername } from "@outschool/text";
import { useMutation } from "@outschool/ui-apollo";
import {
  ContinueWithApple,
  ContinueWithFacebook,
  ContinueWithGoogle,
  ContinueWithKakao,
  ContinueWithLine,
  LoginUserFeedback,
  useAuthError,
  useShouldShowKakao,
  useShouldShowLine
} from "@outschool/ui-auth";
import { Card, HeroLayout } from "@outschool/ui-components-classroom";
import {
  FieldErrorMessage,
  TrackedButton
} from "@outschool/ui-components-shared";
import {
  Form,
  FormField,
  Input,
  LoadingIcon,
  Space
} from "@outschool/ui-legacy-component-library";
import { useFormState, useLinkComponent } from "@outschool/ui-utils";
import { navigate, useQueryParams } from "@patched/hookrouter";
import React from "react";

import { loginMutation } from "../../../../client/queries/AuthenticationQueries";
import { UserFacingError } from "../../../../shared/client/ApolloClient";
import {
  APPLE_SIGNIN_CLIENT_ID,
  FACEBOOK_APP_ID,
  GOOGLE_CLIENT_ID
} from "../../../../shared/Env";
import { FF_KAKAO_AUTH, FF_LINE_AUTH } from "../../Env";
import { CreateOrLoginError } from "../../generated/graphql";
import {
  useLearnerLoginWithRedirect,
  useLoginWithApple
} from "../../hooks/authentication";
import Footer from "../../layout/nav/Footer";
import * as Routes from "../../lib/Routes";
import { useLearnerAuth } from "../../providers/LearnerAuthProvider";

const Login = () => {
  const [query] = useQueryParams();
  const [userFeedbackMessage, setUserFeedbackMessage] = React.useState<
    React.ReactNode | undefined | null
  >(undefined);
  const { sessionTokenStore, setSessionToken } = useLearnerAuth();
  const generateLoginError = useAuthError();

  const Link = useLinkComponent();

  const redirect = query.redirect;
  const postLoginPath = redirect ? redirect : "/";

  if (sessionTokenStore?.getLearnerAuth()?.isLoggedIn) {
    navigate(Routes.dashboardPath());
  }

  const { fields, errors, validateAll } = useFormState({
    email: {
      value: "",
      validators: [
        value =>
          isValidEmailOrUsername(value || "")
            ? null
            : "Please provide a valid email address or username"
      ]
    },
    password: {
      value: "",
      validators: [
        value =>
          value === undefined || value === ""
            ? "Please enter your password."
            : null
      ]
    }
  });

  const failureStyle = {
    background: "#FAE3E3",
    border: "2px solid #F15D5D !important",
    boxShadow: "inset 0px 1px 2px rgba(102, 113, 123, 0.21) !important"
  };

  const [login, { loading: loginInProgress }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(loginMutation);

  function isAuthenticationV2(obj: any): obj is AuthenticationV2 {
    return obj.__typename === "AuthenticationV2";
  }

  const onSubmit = async (email, password) => {
    const { hasErrors } = validateAll();
    if (hasErrors) {
      return;
    }
    try {
      const { data } = await login({
        variables: {
          emailOrUsername: email,
          password
        }
      });
      if (!data) {
        return;
      }

      const { sessionToken } = data.login;
      const decodedToken = Auth.decodeToken(sessionToken);
      const isParentTransfer = Auth.isParentAuthTransfer(decodedToken);

      if (isParentTransfer) {
        window?.location.replace(
          Routes.websiteRoutes.parentAuthCodeTransferUrl(
            sessionToken,
            undefined,
            true
          )
        );
      } else {
        const refreshToken = isAuthenticationV2(data.login)
          ? data.login.refreshToken
          : undefined;
        if (data.login.sessionToken) {
          setSessionToken({ sessionToken, refreshToken });
          window?.location.replace(postLoginPath);
        }
      }
    } catch (e) {
      setUserFeedbackMessage(new UserFacingError(e).toString());
    }
  };

  const handleAuthError = React.useCallback(
    (
      _error: any,
      message: React.ReactNode = "Sorry that didn't work, try again"
    ) => {
      setUserFeedbackMessage(message);
    },
    []
  );

  const handleLoginSuccess = React.useCallback(
    ({ sessionToken, refreshToken, isParentTransfer }) => {
      if (isParentTransfer) {
        window?.location.replace(
          Routes.websiteRoutes.parentAuthCodeTransferUrl(
            sessionToken,
            undefined,
            true
          )
        );
      } else {
        setSessionToken({ sessionToken, refreshToken });
        window?.location.replace(postLoginPath);
      }
    },
    [postLoginPath, setSessionToken]
  );

  const handleLoginError = React.useCallback(
    (error: CreateOrLoginError) => {
      handleAuthError(new Error(error), generateLoginError(error));
    },
    [handleAuthError, generateLoginError]
  );

  const handleAppleLogin = useLoginWithApple(
    handleLoginSuccess,
    handleLoginError
  );

  useLearnerLoginWithRedirect(
    handleLoginError,
    Routes.redirectAfterLoginUrl(postLoginPath)
  );

  const showKakao = useShouldShowKakao();
  const showLine = useShouldShowLine();

  const footer = (
    <>
      <Space y="large"></Space>
      <Footer />
    </>
  );

  return (
    <HeroLayout includeBackgroundGradient footer={footer}>
      <Card
        title="Log In"
        sx={(theme: Theme) => ({
          maxWidth: "100%",
          paddingX: "1em",
          [theme.breakpoints.up("sm")]: {
            maxWidth: "579px",
            paddingX: "118px"
          },
          marginX: "auto",
          display: "grid",
          gap: "1em"
        })}
        headingSx={{
          marginY: "tiny",
          marginX: "auto"
        }}
      >
        {userFeedbackMessage && (
          <LoginUserFeedback
            userFeedbackMessage={userFeedbackMessage}
            isError
          />
        )}
        <Form
          sx={{ display: "grid", gap: "medium" }}
          onSubmit={e => {
            e && e.preventDefault();
            onSubmit(fields.email.value, fields.password.value);
          }}
          noValidate
        >
          <FormField label="Email or username" fieldId={fields.email.id}>
            <Input
              type="email"
              data-test-id="login-form-email"
              sx={errors?.email?.length ? failureStyle : {}}
              {...fields.email}
            />
            {errors.email && <FieldErrorMessage value={errors.email[0]} />}
          </FormField>
          <FormField label="Password" fieldId={fields.password.id}>
            <Input
              sx={errors?.password?.length ? failureStyle : {}}
              type="password"
              data-test-id="login-form-password"
              {...fields.password}
            />
            {errors.password && (
              <FieldErrorMessage value={errors.password[0]} />
            )}
            <Link to={Routes.changePasswordPath()}>Forgot your password?</Link>
          </FormField>
          <TrackedButton
            variant="contained"
            type="submit"
            disabled={loginInProgress}
            trackingName="learner_login.login_with_email"
            startIcon={loginInProgress ? <LoadingIcon /> : null}
          >
            Log In
          </TrackedButton>
        </Form>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            position: "relative",
            margin: "16px 0"
          }}
        >
          <Box
            sx={{
              borderTop: "1px solid #e3e3e3",
              content: '""',
              margin: "0 auto",
              position: "absolute",
              top: "12px",
              left: 0,
              right: 0,
              bottom: 0,
              width: "100%",
              zIndex: -1
            }}
          />
          <Typography
            variant="inherit"
            sx={{
              padding: "0 20px",
              backgroundColor: "common.white"
            }}
          >
            or log in with
          </Typography>
        </Box>
        <ContinueWithGoogle
          isLearnerApp
          trackingName="learner_login.login_with_google"
          setAuthStrategy={() => {}}
          clientId={GOOGLE_CLIENT_ID!}
        />
        {FF_LINE_AUTH && showLine && (
          <ContinueWithLine
            isLearnerApp
            trackingName="learner_login.login_with_line"
          />
        )}
        {FF_KAKAO_AUTH && showKakao && (
          <ContinueWithKakao
            isLearnerApp
            trackingName="learner_login.login_with_kakao"
          />
        )}
        <ContinueWithFacebook
          isLearnerApp
          trackingName="learner_login.login_with_facebook"
          setAuthStrategy={() => {}}
          clientId={FACEBOOK_APP_ID!}
        />
        <ContinueWithApple
          isLearnerApp
          onSuccess={handleAppleLogin}
          onError={handleAuthError}
          trackingName="learner_login.login_with_apple"
          clientId={APPLE_SIGNIN_CLIENT_ID!}
        />
      </Card>
    </HeroLayout>
  );
};

export default Login;
