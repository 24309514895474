import { ReviewName } from "@outschool/gql-backend-generated";
import { useLocalStorageState } from "@outschool/local-storage";
import { dayjs } from "@outschool/time";
import { gql, useMutation } from "@outschool/ui-apollo";
import { useCallback } from "react";

import * as Env from "../Env";
import {
  EnrollmentToReviewSoonFragmentFragment,
  SubmitInterestedInTakingMoreClassesAboutTopicMutation,
  SubmitInterestedInTakingMoreClassesAboutTopicMutationVariables
} from "../generated/graphql";
import { useCurrentLearner } from "../providers/CurrentLearnerProvider";

export const EnrollmentToReviewSoonFragment = gql`
  fragment EnrollmentToReviewSoonFragment on Enrollment {
    uid
    section {
      uid
      activity {
        uid
        title
        subject
        is_ongoing_weekly
      }
    }
  }
`;

interface ReviewState {
  enrollmentUid: string;
  startTime: string;
  learnerHasReviewed: boolean;
}

const REVIEW_STATE_KEY = "reviewStateKey";
const REVIEW_STATE_TTL_MINUTES = 30 * 24 * 60; // 1 month

export type EnrollmentToReviewSoon = EnrollmentToReviewSoonFragmentFragment;

export function useEnrollmentToReviewSoon(): EnrollmentToReviewSoon | null {
  const { enrollmentToReviewSoon = null } = useCurrentLearner() ?? {};

  const newReviewState = enrollmentToReviewSoon
    ? {
        enrollmentUid: enrollmentToReviewSoon.uid,
        startTime: dayjs().toISOString(),
        learnerHasReviewed: false
      }
    : undefined;

  const [reviewState, setReviewState] = useLocalStorageState<
    ReviewState | undefined
  >(REVIEW_STATE_KEY, newReviewState);

  if (!reviewState || Env.isTest) {
    return null;
  }

  const reviewStateIsStale = dayjs(reviewState.startTime)
    .add(REVIEW_STATE_TTL_MINUTES, "minute")
    .isBefore(dayjs());
  if (
    reviewStateIsStale &&
    newReviewState &&
    reviewState.enrollmentUid !== newReviewState.enrollmentUid
  ) {
    setReviewState(newReviewState);
    return enrollmentToReviewSoon;
  }

  const reviewStateIsForSameEnrollment =
    reviewState.enrollmentUid === enrollmentToReviewSoon?.uid;
  if (
    !reviewStateIsStale &&
    reviewStateIsForSameEnrollment &&
    !reviewState.learnerHasReviewed
  ) {
    return enrollmentToReviewSoon;
  }

  return null;
}

const SubmitInterestedInTakingMoreClassesAboutTopic = gql`
  mutation SubmitInterestedInTakingMoreClassesAboutTopic(
    $learnerSectionReviewInput: LearnerSectionReviewInput!
  ) {
    createOrUpdateLearnerSectionReview(
      learnerSectionReviewInput: $learnerSectionReviewInput
    ) {
      uid
      name
      response {
        vote
      }
    }
  }
`;

export type InterestedInTakingMoreClassesAboutTopicVote = "yes" | "no";
export function useSubmitLearnerSectionReview(enrollmentUid?: string) {
  const [reviewState, setReviewState] = useLocalStorageState<
    ReviewState | undefined
  >(REVIEW_STATE_KEY, undefined);
  const [mutate, mutationState] = useMutation<
    SubmitInterestedInTakingMoreClassesAboutTopicMutation,
    SubmitInterestedInTakingMoreClassesAboutTopicMutationVariables
  >(SubmitInterestedInTakingMoreClassesAboutTopic);
  const submitLearnerSectionReview = useCallback(
    async (vote: InterestedInTakingMoreClassesAboutTopicVote) => {
      if (enrollmentUid) {
        await mutate({
          variables: {
            learnerSectionReviewInput: {
              name: ReviewName.InterestedInTakingMoreClassesAboutTopic,
              response: { vote },
              enrollmentUid
            }
          }
        });
        if (reviewState) {
          setReviewState({
            ...reviewState,
            learnerHasReviewed: true
          });
        }
      }
    },
    [mutate, enrollmentUid, reviewState, setReviewState]
  );
  return [submitLearnerSectionReview, mutationState] as const;
}
