import { Box, Button, Typography } from "@outschool/backpack";
import { CLASSROOM_BOX_SX } from "@outschool/ui-components-classroom";
import { Banner, Space } from "@outschool/ui-legacy-component-library";
import React from "react";
import { useAnalytics } from "use-analytics";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { EnrollmentToReviewSoonFragmentFragment } from "../../generated/graphql";
import { useSubmitLearnerSectionReview } from "../../hooks/useLearnerSectionReview";

enum LearnerSectionReviewResponse {
  Yes = "yes",
  No = "no"
}

interface LearnerSectionReviewProps {
  enrollmentToReviewSoon: EnrollmentToReviewSoonFragmentFragment;
}

const LearnerSectionReview: React.FC<LearnerSectionReviewProps> = ({
  enrollmentToReviewSoon
}) => {
  const trackingName = "classroom_learner_section_review_page";
  const enrollmentUid = enrollmentToReviewSoon.uid;
  const { track } = useAnalytics();
  React.useEffect(() => {
    if (enrollmentUid) {
      track(`${trackingName}_view`, {
        enrollmentUid
      });
    }
  }, [enrollmentUid, track]);

  const responseButtonSx = {
    fontSize: 24,
    lineHeight: 1.5,
    px: "large"
  };

  const [response, setResponse] =
    React.useState<LearnerSectionReviewResponse | null>(null);

  const [submitLearnerSectionReview] =
    useSubmitLearnerSectionReview(enrollmentUid);
  const submitLearnerSectionReviewResponse = async (
    vote: LearnerSectionReviewResponse
  ) => {
    setResponse(vote);
    await submitLearnerSectionReview(vote);
    track(`${trackingName}_touch`, {
      enrollmentUid
    });
  };

  React.useEffect(() => {
    if (!!response) {
      const timer = setTimeout(() => window.location.reload(), 3000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [response]);

  if (!!response) {
    return (
      <Box
        data-test-id="LearnerSectionReviewResponse"
        sx={{
          backgroundColor: "common.white",
          height: "100%",
          pb: "2em"
        }}
      >
        <Box sx={CLASSROOM_BOX_SX}>
          <Box
            sx={{
              py: "1em"
            }}
          >
            <Banner
              round
              sx={{
                backgroundColor: "blue5",
                borderColor: "blue4",
                color: "blue1",
                "& a": {
                  color: "blue1",
                  textDecoration: "underline",
                  "&:hover": { color: "blue1" }
                }
              }}
            >
              <span
                role="img"
                aria-label="thumbs up"
                style={{
                  opacity:
                    response === LearnerSectionReviewResponse.Yes ? "1" : "0.3"
                }}
              >
                👍
              </span>{" "}
              <span
                role="img"
                aria-label="thumbs down"
                style={{
                  opacity:
                    response === LearnerSectionReviewResponse.No ? "1" : "0.3"
                }}
              >
                👎
              </span>{" "}
              Thanks for letting us know!
            </Banner>
          </Box>
        </Box>
      </Box>
    );
  }

  const isOngoing =
    !!enrollmentToReviewSoon.section!.activity.is_ongoing_weekly;

  const subject = enrollmentToReviewSoon.section!.activity.subject;
  return (
    <Box
      data-test-id="LearnerSectionReview"
      sx={{
        backgroundColor: "common.white",
        height: "100%",
        pb: "2em"
      }}
    >
      <Box sx={CLASSROOM_BOX_SX}>
        <Box
          sx={{
            py: "1em"
          }}
        >
          {isOngoing ? (
            <Banner
              round
              sx={{
                backgroundColor: "green5",
                borderColor: "green4",
                color: "green1",
                "& a": {
                  color: "green1",
                  textDecoration: "underline",
                  "&:hover": { color: "green1" }
                }
              }}
            >
              You've joined 3 meetings{" "}
              <span role="img" aria-label="Congratulations">
                🎉
              </span>{" "}
              Nice going!
            </Banner>
          ) : (
            <Banner
              round
              sx={{
                backgroundColor: "green5",
                borderColor: "green4",
                color: "green1",
                "& a": {
                  color: "green1",
                  textDecoration: "underline",
                  "&:hover": { color: "green1" }
                }
              }}
            >
              You completed this class{" "}
              <span role="img" aria-label="Congratulations">
                🎉
              </span>{" "}
              Nice going!
            </Banner>
          )}
        </Box>
        <Box
          sx={{
            textAlign: "center"
          }}
        >
          <Typography variant="h3">
            Would you like to take more classes{" "}
            {subject ? `about ${subject}` : "like this one"}?
          </Typography>
          <Space y="large" />
          <Box
            flex
            sx={{
              justifyContent: "center"
            }}
          >
            <Button
              variant="contained"
              onClick={() =>
                submitLearnerSectionReviewResponse(
                  LearnerSectionReviewResponse.Yes
                )
              }
            >
              <Box sx={responseButtonSx}>
                <span role="img" aria-label="thumbs up">
                  👍
                </span>{" "}
                Yes
              </Box>
            </Button>
            <Space x="large" />
            <Button
              variant="contained"
              onClick={() =>
                submitLearnerSectionReviewResponse(
                  LearnerSectionReviewResponse.No
                )
              }
            >
              <Box sx={responseButtonSx}>
                <span role="img" aria-label="thumbs down">
                  👎
                </span>{" "}
                No
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LearnerSectionReview;
