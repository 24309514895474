import { Box, Button, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  RequestPasswordChangeMutation,
  RequestPasswordChangeMutationVariables
} from "@outschool/gql-frontend-generated"; // TODO: update these imports once AuthenticationQueries is moved to a lang-js package
import { isValidEmail } from "@outschool/text";
import { useMutation } from "@outschool/ui-apollo";
import { ErrorMessage } from "@outschool/ui-components-shared";
import {
  Container,
  Form,
  FormField,
  Input,
  Space
} from "@outschool/ui-legacy-component-library";
import {
  isValidEmailOrUsername,
  required,
  useFormState
} from "@outschool/ui-utils";
import _ from "lodash";
import React, { useState } from "react";

import { requestPasswordChangeMutation } from "../../../../shared/client/queries/AuthenticationQueries"; // TODO: move this mutation to a lang-js package
import * as Env from "../../Env";
import HeaderlessLayout from "../../layout/HeaderlessLayout";

const ChangePassword = () => {
  const [userFeedback, setUserFeedback] = useState("");

  const [requestMutation] = useMutation<
    RequestPasswordChangeMutation,
    RequestPasswordChangeMutationVariables
  >(requestPasswordChangeMutation);

  const {
    fields: requestFields,
    values: requestValues,
    errors: requestErrors,
    validateAll
  } = useFormState({
    email: {
      value: "",
      validators: [
        required(() => "Email cannot be blank."),
        isValidEmailOrUsername(() => "Please enter a valid email or username")
      ]
    }
  });

  const requestPasswordChange = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUserFeedback("");
    const { hasErrors } = validateAll();
    if (hasErrors) {
      return;
    }
    try {
      await requestMutation({
        variables: {
          emailOrUsername: requestValues.email
        }
      });
      if (isValidEmail(requestValues.email)) {
        // matches server/services/Account/lib/messages.requestPasswordChangeEmail
        setUserFeedback(
          `We sent a password reset link to ${requestValues.email}. ` +
            "Email messages may take a few minutes to arrive."
        );
      } else {
        // matches server/services/Account/lib/messages.requestPasswordChangeUsername
        setUserFeedback(
          `We sent a password reset link to the email address associated with username ${requestValues.email}. ` +
            "Email messages may take a few minutes to arrive."
        );
      }
    } catch (err) {
      setUserFeedback(err.message);
    }
  };

  return (
    <HeaderlessLayout>
      <Container size="tiny" sx={{ marginY: "large" }}>
        <Box
          flex
          sx={{
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography variant="h2">Set a new password</Typography>
          <Space y="small" />
          <ErrorMessage
            value={userFeedback}
            showStatusPageLink={Env.IS_READ_ONLY_MODE}
          />
          <Form
            onSubmit={requestPasswordChange}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <FormField
              label="Enter the email address or username you use for Outschool, and we'll help you set a new password."
              error={_.first(requestErrors.email)}
              fieldId={requestFields.email.id}
            >
              <Input
                type="text"
                placeholder="Email or username"
                {...requestFields.email}
              />{" "}
            </FormField>
            <Button
              variant="contained"
              aria-label="submit-email-button"
              type="submit"
              disabled={!requestValues.email}
              sx={{
                marginTop: "0.5em"
              }}
            >
              Continue
            </Button>
          </Form>
        </Box>
      </Container>
    </HeaderlessLayout>
  );
};

export default ChangePassword;
