import { Box, Typography } from "@outschool/backpack";
import {
  Carousel,
  Loading,
  useImpressionTracking
} from "@outschool/ui-legacy-component-library";
import React, { useEffect, useState } from "react";
import { useAnalytics } from "use-analytics";

import ClassCard from "../../components/ClassCards";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { LearnerSearchTeacherActivitiesQuery } from "../../generated/graphql";
import { useLearnerSearchTeacherActivitiesQuery } from "../../queries/TeacherActivitiesQuery";

interface OtherClassesCarouselProps {
  teacherName: string;
  teacherUid: string;
  activities: LearnerSearchTeacherActivitiesQuery["learnerSearchTeacherActivities"]["activities"];
}

function OtherClassesCarousel({
  teacherName,
  teacherUid,
  activities
}: OtherClassesCarouselProps) {
  const [carouselNode, setCarouselNode] = useState<HTMLElement>();
  useImpressionTracking({
    node: carouselNode,
    uniqueId: teacherUid,
    trackingLabel: "browse.class.teacher_related_classes",
    impressionEventName: "browse.class.teacher_related_classes.view"
  });
  const { track } = useAnalytics();

  useEffect(() => {
    track("browse.class.teacher_related_classes.render");
  }, [track]);

  return (
    <Box
      flex
      sx={{
        flexDirection: "column",
        width: "100%"
      }}
      ref={setCarouselNode}
    >
      <Typography
        variant="h3"
        sx={{
          mt: "40px",
          mb: "1em",
          textAlign: "center"
        }}
      >
        Other classes taught by {teacherName}
      </Typography>
      <Carousel arrowSx={{ backgroundColor: "blue1" }}>
        <Box flex>
          {activities.map(activity => (
            <Box
              key={activity.uid}
              sx={theme => ({
                width: 250,

                [theme.breakpoints.up("md")]: {
                  width: 320
                },

                paddingBottom: "1em",
                paddingX: 12
              })}
            >
              <ClassCard
                key={activity.uid}
                activity={activity}
                trackingPrefix="browse.class.teacher_related_classes"
              />
            </Box>
          ))}
        </Box>
      </Carousel>
    </Box>
  );
}

interface TeachersOtherClassesCarouselProps {
  teacherUid: string;
  teacherName: string;
}

function TeachersOtherClassesCarousel({
  teacherUid,
  teacherName
}: TeachersOtherClassesCarouselProps) {
  const { loading, activities } = useLearnerSearchTeacherActivitiesQuery({
    teacherUid
  });

  if (loading) {
    return <Loading />;
  }
  if (!activities) {
    return null;
  }
  if (activities.length === 0) {
    return null;
  }

  const classes = activities.filter(activity => !activity.isClub);

  return (
    <OtherClassesCarousel
      teacherName={teacherName}
      teacherUid={teacherUid}
      activities={classes}
    />
  );
}

export default TeachersOtherClassesCarousel;
