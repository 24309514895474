// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  LearnerActivityDetailsQuery,
  LearnerActivityDetailsQueryVariables
} from "@outschool/gql-frontend-generated";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { AttachmentFragment } from "@outschool/ui-components-classroom";

import LearnerLeaderFragment from "./LearnerLeaderFragment";

export const LearnerActivityDetails = gql`
  query LearnerActivityDetails($uid: ID, $slugId: String) {
    activity(uid: $uid, slugId: $slugId) {
      uid
      title
      class_experience
      summary
      summaryForLearners
      duration_minutes
      duration_weeks
      age_min
      age_max
      size_max
      hasTeacherSchedule
      promotableSections {
        uid
        start_time
        end_time
        price_cents
      }
      price_cents
      weekly_meetings
      is_ongoing_weekly
      details {
        photo
      }
      video {
        ...AttachmentFragment
      }
      videoForLearners {
        ...AttachmentFragment
      }
      leader {
        ...LearnerLeaderFragment
      }
      isClub
      slug_id
      sampleClubPosts {
        text
        attachments {
          uid
          file {
            filename
            mimetype
            size
            url
          }
        }
        video {
          uid
          file {
            filename
            mimetype
            size
            url
          }
        }
        comments {
          text
        }
      }
    }
  }
  ${AttachmentFragment}
  ${LearnerLeaderFragment}
`;

export function useLearnerActivityDetailsQuery(
  variables: LearnerActivityDetailsQueryVariables
) {
  const { loading, error, data } = useQueryWithPreviousData<
    LearnerActivityDetailsQuery,
    LearnerActivityDetailsQueryVariables
  >(LearnerActivityDetails, {
    fetchPolicy: "cache-and-network",
    variables
  });

  return {
    loading,
    error,
    data
  };
}
