import { Box } from "@outschool/backpack";
import { OUTSCHOOL_TIMEZONE } from "@outschool/time";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  CanceledAnnouncement,
  ClassHeaderDetails
} from "@outschool/ui-components-classroom";
import { ReadOnlyBanner } from "@outschool/ui-components-website";
import { Subscript } from "@outschool/ui-legacy-component-library";
import { useLinkComponent } from "@outschool/ui-utils";
import React from "react";

import SectionStatusLabel from "../../../../shared/client/components/SectionStatusLabel";
import * as Env from "../../Env";
import * as Routes from "../../lib/Routes";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import { useLearnerClassroomContext } from "../../providers/LearnerClassroomProvider";

export default function ClassroomHeader() {
  const Link = useLinkComponent();
  const currentLearner = useCurrentLearner();
  const { loading, error, activity, section } = useLearnerClassroomContext();

  if (loading || error || !activity || !section) {
    return null;
  }

  return (
    <Box
      data-test-id="class-header"
      sx={{
        paddingTop: "1rem"
      }}
    >
      {Env.IS_READ_ONLY_MODE && <ReadOnlyBanner />}
      {section?.canceled_at && <CanceledAnnouncement />}
      <Subscript sx={{ marginBottom: "small" }}>
        <Link to={Routes.dashboardPath()}>Your enrolled classes »</Link>
        <SectionStatusLabel
          hasEnrollment
          section={section}
          activity={activity}
          sx={{ marginLeft: "small" }}
        />
      </Subscript>
      <ClassHeaderDetails
        activity={activity}
        section={section}
        userTimeZone={currentLearner?.timeZone || OUTSCHOOL_TIMEZONE}
      />
    </Box>
  );
}
