import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";

import { CurrentLearnerRecommendedTopicsQuery } from "../generated/graphql";

const LearnerRecommendedTopics = gql`
  query CurrentLearnerRecommendedTopics {
    currentLearnerRecommendedTopics {
      uid
      label
      image_url
    }
  }
`;

export default function useLearnerRecommendedTopicsQuery() {
  const { loading, error, data } =
    useQueryWithPreviousData<CurrentLearnerRecommendedTopicsQuery>(
      LearnerRecommendedTopics,
      {
        fetchPolicy: "cache-and-network",
        context: {
          batchKey: "LearnerRecommendedTopics"
        }
      }
    );
  const recommendedTopics = data?.currentLearnerRecommendedTopics || [];
  return {
    loading,
    error,
    recommendedTopics
  };
}
