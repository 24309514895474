import { Box, SxProps } from "@outschool/backpack";
import { useTitle } from "@patched/hookrouter";
import React from "react";

import { defaultTitle } from "../lib/PageMetadata";

type HeaderlessLayoutProps = {
  children: React.ReactNode;
  title?: string;
  sx?: SxProps;
};

const HeaderlessLayout = React.forwardRef(
  (
    { children, title, sx }: HeaderlessLayoutProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    useTitle(title ? `${title} | Outschool` : defaultTitle);
    return (
      <Box
        flex
        sx={[
          {
            flexDirection: "column",
            minHeight: "100vh",
            isolation: "isolate"
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
        ref={ref}
      >
        {children}
      </Box>
    );
  }
);

export default HeaderlessLayout;
