import { Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Loading } from "@outschool/ui-legacy-component-library";
import React, { useState } from "react";

import { ClassCardsGrid } from "../../components/ClassCards";
import { EmptyClassState } from "../../components/EmptyClassState";
import { useCurrentLearnerSavedActivities } from "../../queries/LearnerSavedActivitiesQuery";

const ClassList = ({ activities }) => {
  // Use initial activities so that unsaving a class doesn't remove it from the list
  const [initialActivities] = useState(activities);

  if (initialActivities.length === 0) {
    return <EmptyClassState learnerPage="SavedActivityList" />;
  }

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          paddingBottom: "0.5em",
          borderBottom: "1px solid #ddd",
          borderColor: "grey.100"
        }}
      >
        All Saved Classes
      </Typography>
      <ClassCardsGrid
        activities={initialActivities}
        trackingPrefix="saved_classes"
        addBottomPadding={true}
      />
    </>
  );
};

const SavedActivitiesList = () => {
  const { loading, savedActivities } = useCurrentLearnerSavedActivities();

  if (loading) {
    return <Loading />;
  }

  const activities = savedActivities.map(
    savedActivity => savedActivity.listing.activity
  );

  return <ClassList activities={activities} />;
};

export default SavedActivitiesList;
