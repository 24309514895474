// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Button, Icon, Typography } from "@outschool/backpack";
import { faChevronLeft } from "@outschool/icons";
import React from "react";

const BackButton: React.FC = () => (
  <Button
    sx={{
      marginBottom: "1em"
    }}
    onClick={() => window.history.go(-1)}
  >
    <Box
      flex
      sx={{
        alignItems: "center"
      }}
    >
      <Icon
        icon={faChevronLeft}
        sx={{
          marginRight: "8px"
        }}
      />
      <Typography variant="inherit">Back</Typography>
    </Box>
  </Button>
);

export default BackButton;
