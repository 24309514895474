import { useMutation } from "@outschool/ui-apollo";
import { dataIdFromObject } from "@outschool/ui-gql";
import { validOptimisticResponse } from "@outschool/ui-utils";
import { cloneDeep } from "lodash";
import React from "react";

import {
  LearnerNotificationsQuery,
  MarkLearnerNotificationsReadMutation,
  MarkLearnerNotificationsReadMutationVariables,
  NotificationFragmentFragment,
  NotificationStatus
} from "../generated/graphql";
import { useLearnerNotificationsContext } from "../providers/LearnerNotificationsProvider";
import { markLearnerNotificationsRead } from "../queries/LearnerNotificationsMutations";
import learnerNotificationsQuery, {
  notificationFragment
} from "../queries/LearnerNotificationsQuery";

export default function useMarkLearnerNotificationsRead(
  notificationGroup: Array<
    Pick<NotificationFragmentFragment, "uid" | "__typename">
  >
) {
  const learnerNotificationsContext = useLearnerNotificationsContext();
  const [mutate] = useMutation<
    MarkLearnerNotificationsReadMutation,
    MarkLearnerNotificationsReadMutationVariables
  >(markLearnerNotificationsRead);

  const markLearnerNotificationRead = React.useCallback(() => {
    const uids = notificationGroup.map(n => n.uid);
    if (!learnerNotificationsContext) {
      return;
    }
    const { queryVariables, hasUnreadNotifications } =
      learnerNotificationsContext;
    mutate({
      variables: {
        uids,
        after: queryVariables.after
      },
      optimisticResponse: validOptimisticResponse({
        __typename: "Mutation",
        markNotificationRead: {
          __typename: "MarkNotificationReadResult",
          learnerHasUnreadNotifications: hasUnreadNotifications
        }
      }),
      update: (store, { data }) => {
        for (const notification of notificationGroup) {
          const cacheId = dataIdFromObject({
            uid: notification.uid,
            __typename: notification.__typename
          });
          if (!cacheId) {
            return;
          }
          const notificationFragmentData = cloneDeep(
            store.readFragment<NotificationFragmentFragment>({
              id: cacheId,
              fragment: notificationFragment
            })
          );
          if (notificationFragmentData) {
            store.writeFragment({
              id: cacheId,
              fragment: notificationFragment,
              data: {
                ...notificationFragmentData,
                status: NotificationStatus.Read
              }
            });
          }
        }

        const learnerNotificationsData =
          store.readQuery<LearnerNotificationsQuery>({
            query: learnerNotificationsQuery,
            variables: queryVariables
          });

        const newLearnerNotificationsData = cloneDeep(learnerNotificationsData);

        const newHasUnreadNotifications =
          data?.markNotificationRead?.learnerHasUnreadNotifications;
        if (
          newLearnerNotificationsData &&
          newHasUnreadNotifications !== undefined
        ) {
          newLearnerNotificationsData.learnerHasUnreadNotifications =
            newHasUnreadNotifications;
          store.writeQuery<LearnerNotificationsQuery>({
            query: learnerNotificationsQuery,
            variables: queryVariables,
            data: newLearnerNotificationsData
          });
        }
      }
    });
  }, [mutate, notificationGroup, learnerNotificationsContext]);

  return {
    markLearnerNotificationRead
  };
}
