import {
  AddClassPostCommentMutationVariables,
  LearnerSinglePostQuery,
  LearnerSinglePostQueryVariables
} from "@outschool/gql-frontend-generated";
import {
  ApolloCache,
  Reference,
  gql,
  useQueryWithPreviousData
} from "@outschool/ui-apollo";
import {
  ClassPostFragments,
  ClassPostWithCommentsFragment
} from "@outschool/ui-components-classroom";

import { UpdateClassroomCache } from "../hooks/useLearnerClassroomAddClassPostCommentMutation";

export const singlePostQuery = gql`
  query LearnerSinglePost(
    $sectionUid: ID!
    $classPostUid: ID!
    $plainText: Boolean
  ) {
    classroom(sectionUid: $sectionUid) {
      uid
      usersCanComment
      postByUid(classPostUid: $classPostUid) {
        uid
        ...ClassPost_classPostWithComments
      }
      mentionNames {
        ...ClassPost_mentionNames
      }
    }
  }
  ${ClassPostWithCommentsFragment}
  ${ClassPostFragments.mentionNames}
`;

export const useSinglePostQuery = ({
  sectionUid,
  classPostUid
}: LearnerSinglePostQueryVariables) =>
  useQueryWithPreviousData<
    LearnerSinglePostQuery,
    LearnerSinglePostQueryVariables
  >(singlePostQuery, {
    variables: {
      sectionUid,
      classPostUid,
      plainText: false
    },
    fetchPolicy: "cache-and-network"
  });

export const updateLearnerClassroomSinglePostsCache: UpdateClassroomCache = (
  cache: ApolloCache<any>,
  variables: AddClassPostCommentMutationVariables,
  newClassPostComment
) => {
  cache.modify({
    // If modifying, reference shape of LearnerSinglePostQuery generated type for details
    id: cache.identify({
      __typename: "ClassPost",
      uid: variables.classPostCommentInput.classPostUid
    }),
    fields: {
      comments(prevCommentsRef: Reference[], { toReference }) {
        if (
          prevCommentsRef &&
          // Sanity check comments being an array
          Array.isArray(prevCommentsRef) &&
          newClassPostComment?.addClassPostComment
        ) {
          return [
            ...prevCommentsRef,
            toReference(newClassPostComment.addClassPostComment)
          ];
        }
        return prevCommentsRef;
      }
    }
  });
};
