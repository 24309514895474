import { gql } from "@outschool/ui-apollo";

export const notificationFragment = gql`
  fragment NotificationFragment on INotification {
    __typename
    uid
    createdAt
    sender {
      ... on LearnerSender {
        uid
        name
        avatar
      }
      ... on UserSender {
        uid
        name
        photo
      }
    }
    status
  }
`;

const privateClassMessageNotificationFragment = gql`
  fragment PrivateClassMessageNotificationFragment on PrivateClassMessageNotification {
    ...NotificationFragment
    privateClassMessageSectionUid: sectionUid
    isClub
  }
  ${notificationFragment}
`;

const classPostNotificationFragment = gql`
  fragment ClassPostNotificationFragment on ClassPostNotification {
    ...NotificationFragment
    classPostNotificationAction: action
    classroomClassPostUid: classPostUid
    classroomSectionUid: sectionUid
  }
  ${notificationFragment}
`;

const assignmentNotificationFragment = gql`
  fragment AssignmentNotificationFragment on AssignmentNotification {
    ...NotificationFragment
    assignmentNotificationAction: action
    assignmentUid
    assignmentSectionUid: sectionUid
  }
  ${notificationFragment}
`;

const clubNotificationFragment = gql`
  fragment ClubNotificationFragment on ClubNotification {
    ...NotificationFragment
    clubNotificationAction: action
    clubClassPostUid: classPostUid
    clubSectionUid: sectionUid
    classPostCommentUid
    threadParentCommentUid
  }
  ${notificationFragment}
`;

const learnerNotificationsQuery = gql`
  query LearnerNotifications(
    $direction: CursorDirection!
    $cursor: DateTime
    $limit: Int
    $after: DateTime!
    $skipHasUnreadNotifications: Boolean!
  ) {
    learnerHasUnreadNotifications(after: $after)
      @skip(if: $skipHasUnreadNotifications)
    learnerNotifications(
      direction: $direction
      cursor: $cursor
      limit: $limit
      after: $after
    ) {
      pageInfo {
        hasNextPage
        direction
      }
      results {
        __typename
        ... on PrivateClassMessageNotification {
          ...PrivateClassMessageNotificationFragment
        }
        ... on ClassPostNotification {
          ...ClassPostNotificationFragment
        }
        ... on AssignmentNotification {
          ...AssignmentNotificationFragment
        }
        ... on ClubNotification {
          ...ClubNotificationFragment
        }
      }
    }
  }
  ${privateClassMessageNotificationFragment}
  ${classPostNotificationFragment}
  ${assignmentNotificationFragment}
  ${clubNotificationFragment}
`;

export default learnerNotificationsQuery;
