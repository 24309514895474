// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Typography } from "@outschool/backpack";
import { ApolloError, gql, useMutation } from "@outschool/ui-apollo";
import { TrackedButton } from "@outschool/ui-components-shared";
import {
  Form,
  FormField,
  FormFieldError,
  Input
} from "@outschool/ui-legacy-component-library";
import {
  useFormState,
  useLinkComponent,
  validatePassword
} from "@outschool/ui-utils";
import React from "react";

import ErrorMessage from "../../../../client/components/ErrorMessage";
import * as Env from "../../Env";
import {
  SaveNewLearnerPasswordMutation,
  SaveNewLearnerPasswordMutationVariables
} from "../../generated/graphql";
import { learnerRoutes } from "../../lib/Routes";

const saveNewLearnerPassword = gql`
  mutation SaveNewLearnerPassword(
    $currentPassword: String!
    $newPassword: String!
  ) {
    changeLoggedInLearnerPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    )
  }
`;

const ChangePasswordSection = () => {
  const Link = useLinkComponent();
  const [isDirty, setIsDirty] = React.useState(false);
  const [newPasswordsMatchError, setNewPasswordsMatchError] =
    React.useState(false);
  const [passwordSaveError, setPasswordSaveError] =
    React.useState<ApolloError | null>(null);
  const [savePassword] = useMutation<
    SaveNewLearnerPasswordMutation,
    SaveNewLearnerPasswordMutationVariables
  >(saveNewLearnerPassword, {
    onError(error) {
      setPasswordSaveError(error);
    },
    onCompleted() {
      clearFields();
      setIsDirty(false);
    }
  });
  const { fields, validateAll, errors, setters } = useFormState(
    {
      currentPassword: {
        value: ""
      },
      newPassword: {
        value: "",
        validators: [validatePassword()]
      },
      confirmPassword: {
        value: ""
      }
    },
    { onFieldChange }
  );

  const clearFields = () => {
    setters.currentPassword("");
    setters.newPassword("");
    setters.confirmPassword("");
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { hasErrors } = validateAll();
    if (hasErrors) {
      return;
    }
    if (fields.confirmPassword.value !== fields.newPassword.value) {
      setNewPasswordsMatchError(true);
      return;
    }
    if (fields.currentPassword.value && fields.newPassword.value) {
      savePassword({
        variables: {
          currentPassword: fields.currentPassword.value,
          newPassword: fields.newPassword.value
        }
      });
    }
  };

  function onFieldChange() {
    setIsDirty(true);
    setPasswordSaveError(null);
    setNewPasswordsMatchError(false);
  }

  const failureStyle = {
    background: "#FAE3E3",
    border: "2px solid #F15D5D !important",
    marginBottom: "small",
    boxShadow: "inset 0px 1px 2px rgba(102, 113, 123, 0.21) !important"
  };

  function hasError(field: keyof typeof errors): boolean {
    return (isDirty && errors && Boolean(errors[field]?.length)) || false;
  }

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          marginTop: "1em",
          marginBottom: "0.5em"
        }}
      >
        Change Password
      </Typography>
      {passwordSaveError && (
        <ErrorMessage
          value={passwordSaveError}
          showStatusPageLink={Env.IS_READ_ONLY_MODE}
        />
      )}
      <Form
        sx={{ display: "grid", gap: "medium" }}
        onSubmit={handleSubmit}
        noValidate
      >
        <FormField
          label="Current password"
          sx={{ maxWidth: "40ch" }}
          labelSx={{ marginBottom: 0 }}
        >
          <Input type="password" {...fields.currentPassword} />
          <Link
            style={{ marginTop: "4px" }}
            to={learnerRoutes.changePasswordPath()}
          >
            Forgot your password?
          </Link>
        </FormField>
        <FormField
          label="New password"
          sx={{ maxWidth: "40ch" }}
          labelSx={{ marginBottom: 0 }}
        >
          <Input
            sx={hasError("newPassword") ? failureStyle : {}}
            type="password"
            {...fields.newPassword}
          />
          {errors.newPassword && hasError("newPassword") && (
            <FormFieldError includeIcon>{errors.newPassword[0]}</FormFieldError>
          )}
        </FormField>
        <FormField
          label="Confirm new password"
          sx={{ maxWidth: "40ch" }}
          labelSx={{ marginBottom: 0 }}
        >
          <Input
            sx={newPasswordsMatchError ? failureStyle : {}}
            type="password"
            {...fields.confirmPassword}
          />
          {newPasswordsMatchError && (
            <FormFieldError includeIcon>Passwords do not match.</FormFieldError>
          )}
        </FormField>
        <Box
          flex
          sx={{
            paddingTop: "2em",
            borderTop: "1px solid rgb(209, 209, 209)",
            justifyContent: "space-between"
          }}
        >
          <TrackedButton
            variant="contained"
            type="submit"
            trackingName="learner_login.learner_settings.change_password"
            disabled={!isDirty}
          >
            Save
          </TrackedButton>
        </Box>
      </Form>
    </>
  );
};

export default ChangePasswordSection;
