import { Box, Image, Theme, Typography } from "@outschool/backpack";
import { LearnerRoutes } from "@outschool/routes";
import { TeacherNameAndRatings } from "@outschool/ui-components-website";
import { Loading } from "@outschool/ui-legacy-component-library";
import { Redirect } from "@outschool/ui-utils";
import { useRoutes } from "@patched/hookrouter";
import React from "react";

import TabbedLayout from "../../../layout/TabbedLayout";
import {
  UnlimitedClassroomProvider,
  useUnlimitedClassroomContext
} from "../../../providers/UnlimitedClassroomProvider";
import { NotFoundPage } from "../../NotFoundPage";
import { BackToParentUnlimitedButton } from "../components/BackButton";
import { UnlimitedLessonsPage } from "./UnlimitedLessonsPage";

function RedirectToLessons() {
  const { sectionUid } = useUnlimitedClassroomContext();
  return <Redirect to={LearnerRoutes.unlimitedLessonsPath(sectionUid)} />;
}

const tabValues = {
  Classroom: "classroom",
  Lessons: "lessons"
};

const classroomRoutes: {
  [key: string]: (routeParams: { [key: string]: string }) => {
    value?: string;
    renderPage: () => JSX.Element;
  };
} = {
  "/": () => ({
    value: tabValues.Classroom,
    renderPage: () => <RedirectToLessons />
  }),
  "/lessons": () => ({
    value: tabValues.Lessons,
    renderPage: () => <UnlimitedLessonsPage />
  }),
  "/lessons/:lessonUid": ({ lessonUid }) => ({
    value: tabValues.Lessons,
    renderPage: () => <UnlimitedLessonsPage lessonUid={lessonUid} />
  })
};

const LearnerClassroomLayout = () => {
  const { loading, classroom, activity } = useUnlimitedClassroomContext();

  const tab = useRoutes(classroomRoutes);
  if (loading) {
    return <Loading />;
  }

  if (!tab || !classroom || !activity) {
    return <NotFoundPage />;
  }

  const { value, renderPage } = tab;

  return (
    <TabbedLayout
      outschoolUnlimitedMode
      currentTab={value ?? false}
      header={
        <Box
          sx={{
            display: "flex",
            gap: "60px",
            paddingTop: 20
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ alignSelf: "start" }}>
              <BackToParentUnlimitedButton />
            </Box>
            <Typography as="h1" variant="h2">
              {activity.title}
            </Typography>
            <Typography variant="body1">
              {activity.summaryTranslated}
            </Typography>
            <TeacherNameAndRatings
              leader={activity.leader}
              isTeacherDetails
              clipTeacherName={false}
            />
          </Box>
          <Box
            sx={(theme: Theme) => ({
              display: "none",
              [theme.breakpoints.up("md")]: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "end"
              }
            })}
          >
            <Image
              src={activity?.details.photo!}
              width={280}
              sx={{ borderRadius: 10 }}
            />
          </Box>
        </Box>
      }
      tabs={[]}
    >
      {renderPage()}
    </TabbedLayout>
  );
};

interface LearnerClassroomProps {
  sectionUid: string;
}

const UnlimitedClassroom = ({ sectionUid }: LearnerClassroomProps) => (
  <UnlimitedClassroomProvider sectionUid={sectionUid}>
    <LearnerClassroomLayout />
  </UnlimitedClassroomProvider>
);

export default React.memo(UnlimitedClassroom);
