import { LearnerSinglePostQuery } from "@outschool/gql-frontend-generated";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { ErrorBoundary, Loading } from "@outschool/ui-legacy-component-library";
import { navigate, useTitle } from "@patched/hookrouter";
import React from "react";

import { useLearnerClassroomAddClassPostCommentMutation } from "../../hooks/useLearnerClassroomAddClassPostCommentMutation";
import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import { defaultTitle } from "../../lib/PageMetadata";
import { clubPostPath } from "../../lib/Routes";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import { useLearnerClassroomContext } from "../../providers/LearnerClassroomProvider";
import {
  updateLearnerClassroomSinglePostsCache,
  useSinglePostQuery
} from "../../queries/LearnerSinglePostQuery";
import { NotFoundPage } from "../NotFoundPage";
import LearnerClassPost from "./ClassPost";
import ClassroomError from "./ClassroomError";

const SinglePostPage: React.FC<{
  classPostUid: string;
}> = ({ classPostUid }) => {
  const { sectionUid, activity } = useLearnerClassroomContext();

  useAnalyticsPage({
    pageName: PageType.ClassroomSinglePost,
    pageParams: { sectionUid, classPostUid }
  });
  const currentLearner = useCurrentLearner()!;
  const { data, loading, error } = useSinglePostQuery({
    sectionUid,
    classPostUid
  });
  const isInitialLoading = loading && !data;

  const { classroom } = data || ({} as LearnerSinglePostQuery);
  const { mentionNames, usersCanComment } =
    classroom || ({} as LearnerSinglePostQuery["classroom"]);

  const handleAddComment = useLearnerClassroomAddClassPostCommentMutation({
    updateClassroomCache: updateLearnerClassroomSinglePostsCache
  });

  useTitle(activity?.title ? `${activity?.title} | Outschool` : defaultTitle);

  if (!currentLearner || isInitialLoading || error) {
    return (
      <>
        {isInitialLoading && (
          // Since we poll the classroom we only want to show the loading component on the first get
          <Loading />
        )}
        {error && (
          // In case of an outage we render the live meeting section with an error message
          <ClassroomError error={error} />
        )}
      </>
    );
  }

  if (!classroom) {
    return <NotFoundPage />;
  }

  const { postByUid } = classroom;

  if (!postByUid) {
    return <NotFoundPage />;
  }

  if (activity?.isClub) {
    navigate(clubPostPath({ sectionUid, classPostUid }));
  }

  return (
    <ErrorBoundary>
      <LearnerClassPost
        post={postByUid}
        mentionNames={mentionNames}
        usersCanComment={usersCanComment}
        handleAddComment={handleAddComment}
      />
    </ErrorBoundary>
  );
};

export default SinglePostPage;
