// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Button, Image, Theme, Typography } from "@outschool/backpack";
import { Container } from "@outschool/ui-legacy-component-library";
import { navigate } from "@patched/hookrouter";
import React from "react";

import notFoundImage from "../../../../images/not-found-404.png";
import SimpleLayout from "../../layout/SimpleLayout";
import { learnerRoutes } from "../../lib/Routes";

export const NotFoundPage = () => {
  return (
    <SimpleLayout>
      <Container sx={{ textAlign: "center", marginY: "xlarge" }}>
        <Image
          src={notFoundImage}
          sx={(theme: Theme) => ({
            height: "120px",

            [theme.breakpoints.up("sm")]: {
              height: "200px",
              width: "200px"
            },

            width: "120px",
            marginTop: "64px"
          })}
        />
        <Typography
          variant="h4"
          sx={{
            marginBottom: "0.5em"
          }}
        >
          This page doesn't exist
        </Typography>
        <Typography
          variant="inherit"
          sx={{
            display: "block"
          }}
        >
          {" "}
          Try checking your link, or return to your homepage to view your
          classes and browse for more.{" "}
        </Typography>
        <Button
          sx={{
            marginTop: "0.5em"
          }}
          onClick={() => {
            navigate(learnerRoutes.dashboardPath());
          }}
        >
          Return Home
        </Button>
      </Container>
    </SimpleLayout>
  );
};
