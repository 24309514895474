import { gql, useMutation } from "@outschool/ui-apollo";
import React from "react";

import { validOptimisticResponse } from "../../../shared/client/ApolloClient";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  CurrentLearnerSavedActivitiesQuery,
  CurrentLearnerSavedActivitiesQueryVariables,
  LearnerSaveActivityMutation,
  LearnerSaveActivityMutationVariables,
  LearnerUnsaveActivityMutation,
  LearnerUnsaveActivityMutationVariables
} from "../generated/graphql";
import { useCurrentLearner } from "../providers/CurrentLearnerProvider";
import LearnerActivitySearchResultFragment from "./LearnerActivitySearchResultFragment";
import { CurrentLearnerSavedActivities } from "./LearnerSavedActivitiesQuery";

const saveActivityMutation = gql`
  mutation LearnerSaveActivity(
    $activityUid: ID!
    $referer: String
    $attribution: Attribution
  ) {
    saveActivity(
      activityUid: $activityUid
      referer: $referer
      attribution: $attribution
    ) {
      uid
      activity_uid
      created_at
      listing {
        activity {
          ...LearnerActivitySearchResultFragment
        }
      }
    }
  }
  ${LearnerActivitySearchResultFragment}
`;

const unsaveActivityMutation = gql`
  mutation LearnerUnsaveActivity($activityUid: ID!) {
    unsaveActivity(activityUid: $activityUid)
  }
`;

export function useSaveActivityForLearner(activity) {
  const currentLearner = useCurrentLearner();
  const [mutate] = useMutation<
    LearnerSaveActivityMutation,
    LearnerSaveActivityMutationVariables
  >(saveActivityMutation);

  return React.useCallback(
    (referer, attribution) => {
      if (!currentLearner?.uid) {
        return;
      }
      mutate({
        variables: {
          activityUid: activity.uid,
          referer,
          attribution
        },
        optimisticResponse: validOptimisticResponse({
          __typename: "Mutation",
          saveActivity: {
            __typename: "SavedActivity",
            uid: new Date(),
            activity_uid: activity.uid,
            created_at: new Date(),
            listing: { __typename: "Listing", activity }
          }
        }),
        update(store, { data }) {
          if (!data) {
            console.error("SaveActivityMutation data is null");
            return;
          }
          const prevSavedActivities =
            store.readQuery<CurrentLearnerSavedActivitiesQuery>({
              query: CurrentLearnerSavedActivities
            });
          const { saveActivity } = data;
          const prevSavedActivitiesListFiltered =
            prevSavedActivities?.currentLearner?.savedActivities?.filter(
              sa => sa.activity_uid !== saveActivity.activity_uid
            ) ?? [];
          store.writeQuery<
            CurrentLearnerSavedActivitiesQuery,
            CurrentLearnerSavedActivitiesQueryVariables
          >({
            query: CurrentLearnerSavedActivities,
            data: {
              currentLearner: {
                uid: currentLearner.uid,
                ...prevSavedActivities?.currentLearner,
                savedActivities: [
                  ...prevSavedActivitiesListFiltered,
                  {
                    ...saveActivity,
                    listing: { __typename: "Listing", activity }
                  }
                ]
              }
            }
          });
        }
      });
    },
    [mutate, activity, currentLearner]
  );
}

export function useUnsaveActivityForLearner(activity) {
  const [mutate] = useMutation<
    LearnerUnsaveActivityMutation,
    LearnerUnsaveActivityMutationVariables
  >(unsaveActivityMutation);
  return React.useCallback(() => {
    mutate({
      variables: {
        activityUid: activity.uid
      },
      optimisticResponse: validOptimisticResponse({
        __typename: "Mutation",
        unsaveActivity: true
      }),
      update(store) {
        const data = store.readQuery<CurrentLearnerSavedActivitiesQuery>({
          query: CurrentLearnerSavedActivities
        });
        if (!data) {
          console.error("unsaveActivityMutation failed, data is null");
          return;
        }
        const savedActivities = data.currentLearner.savedActivities.filter(
          sa => sa.activity_uid !== activity.uid
        );
        store.writeQuery({
          query: CurrentLearnerSavedActivities,
          data: {
            ...data,
            currentLearner: { ...data.currentLearner, savedActivities }
          }
        });
      }
    });
  }, [mutate, activity]);
}
