import {
  BackpackThemeProvider,
  Box,
  Button,
  Grid,
  Icon,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
  Typography
} from "@outschool/backpack";
import {
  AssignmentTrackerSyllabusFragment,
  LearnerClassroomAssignmentClassPostsBySectionQuery
} from "@outschool/gql-frontend-generated";
import { faLockRegular } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { LearnerRoutes } from "@outschool/routes";
import {
  AssignmentDueDate,
  AssignmentSubmissionThread,
  AssignmentSubmittedOn,
  AssignmentSummaryCard,
  AssignmentTracker,
  AssignmentTrackerLesson,
  AssignmentTrackerLessonAssignment,
  EditableMessageContent,
  EditableMessageContentSubmitType
} from "@outschool/ui-components-classroom";
import { useFeatureFlag } from "@outschool/ui-components-shared";
import {
  LegacyThemeProvider,
  LoadingIcon
} from "@outschool/ui-legacy-component-library";
import { Redirect, scrollToElementId, useIsMobile } from "@outschool/ui-utils";
import { orderBy, sortBy } from "lodash";
import React from "react";

import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import { useLearnerClassroomContext } from "../../providers/LearnerClassroomProvider";
import {
  useLearnerClassroomAssignmentsBySectionQuery,
  useLearnerCommentOnAssignmentMutation
} from "../../queries/LearnerClassroomAssignmentsBySectionQueries";

export const ClassroomAssignmentsPage = () => {
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\ClassroomAssignmentsPage"
  );
  const isMobile = useIsMobile();

  const { sectionUid, hasLessons, syllabus } = useLearnerClassroomContext();
  const isAssignmentsLearnerUiEnabled = useFeatureFlag(
    "lexi-assignments-learner"
  );
  useAnalyticsPage({
    pageName: PageType.Assignments,
    pageParams: { sectionUid }
  });

  const [sortAssignmentsBy, setSortAssignmentsBy] = React.useState<string>(
    SortAssignmentsBy.Default
  );

  if (!hasLessons || !isAssignmentsLearnerUiEnabled) {
    return <Redirect to={LearnerRoutes.classroomPath(sectionUid)} />;
  }

  // LegacyThemeProvider injects breakpoints which completely breaks how Grid works in MUI
  // Make BackpackThemeProvider wrap it immediately to avoid even referencing the LegacyThemeProvider
  return (
    <BackpackThemeProvider>
      <Box
        sx={(theme: Theme) => ({
          width: "100%",
          maxWidth: 1120,
          margin: "0 auto",
          paddingTop: 40,
          [theme.breakpoints.down("md")]: {
            paddingX: 16
          }
        })}
      >
        <Grid
          container
          spacing={60}
          direction={isMobile ? "column-reverse" : undefined}
        >
          <Grid
            xs={12}
            md={8}
            sx={{ display: "flex", flexDirection: "column", gap: 20 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center"
                }}
              >
                <Icon icon={faLockRegular} size="inherit" />
                {t("Only between you and your teacher")}
              </Typography>
              <AssignmentSortSelect
                sortAssignmentsBy={sortAssignmentsBy}
                setSortAssignmentsBy={setSortAssignmentsBy}
              />
            </Box>
            <SectionAssignments sortAssignmentsBy={sortAssignmentsBy} />
          </Grid>
          <Grid xs={12} md={4}>
            <LearnerAssignmentTracker syllabus={syllabus!} />
          </Grid>
        </Grid>
      </Box>
    </BackpackThemeProvider>
  );
};

enum SortAssignmentsBy {
  Default = "default", // by lesson number, ascending
  DueNext = "due_next", // by due date ascending, with already submitted assignments at end
  RecentlyAdded = "recently_added" // by publishAt descending
}

const sortAssignments = (
  assignments: NonNullable<
    LearnerClassroomAssignmentClassPostsBySectionQuery["section"]
  >["assignmentClassPosts"],
  order: SortAssignmentsBy
) =>
  // Due next
  order === SortAssignmentsBy.DueNext
    ? sortBy(assignments, [
        ({ comments }) => comments.some(c => c.isSubmission),
        "dueDate",
        "lesson.lessonNumber"
      ])
    : // Recently added
    order === SortAssignmentsBy.RecentlyAdded
    ? orderBy(
        assignments,
        [
          // Remove the seconds off of the publishAt date to ensure consistent sorting.
          // This is because publishAt can be seconds apart, despite the intention being they are published at the same time.
          // This lets us sort actual recent assignments to the top.
          ({ publishAt }) => new Date(publishAt).setSeconds(0),
          "lesson.lessonNumber"
        ],
        ["desc", "asc"]
      )
    : // Default
      sortBy(assignments, "lesson.lessonNumber");

type AssignmentSortSelectProps = {
  sortAssignmentsBy: string;
  setSortAssignmentsBy: (sortAssignmentsBy: string) => void;
};
const AssignmentSortSelect = ({
  sortAssignmentsBy,
  setSortAssignmentsBy
}: AssignmentSortSelectProps) => {
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\ClassroomAssignmentsPage"
  );

  return (
    <Select
      label={t("Assignments Sorted By")}
      size="small"
      sx={{ width: 200 }}
      value={sortAssignmentsBy}
      onChange={(e: SelectChangeEvent) => setSortAssignmentsBy(e.target.value)}
    >
      <MenuItem value={SortAssignmentsBy.Default}>{t("Default")}</MenuItem>
      <MenuItem value={SortAssignmentsBy.DueNext}>{t("Due Next")}</MenuItem>
      <MenuItem value={SortAssignmentsBy.RecentlyAdded}>
        {t("Recently Added")}
      </MenuItem>
    </Select>
  );
};

type SectionAssignmentsProps = {
  sortAssignmentsBy: string;
};
const SectionAssignments = ({ sortAssignmentsBy }: SectionAssignmentsProps) => {
  const { sectionUid } = useLearnerClassroomContext();
  const { timeZone } = useCurrentLearner()!;
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\ClassroomAssignmentsPage"
  );

  const { data, loading } = useLearnerClassroomAssignmentsBySectionQuery({
    sectionUid
  });

  if (loading) {
    // TODO loading state
    return null;
  }

  const { section } = data || {};
  const { assignmentClassPosts: assignments } = section || {};

  if (!assignments) {
    // This page should only be accessible if there are assignments
    // but this is a fallback
    return null;
  }

  const sortedAssignments = sortAssignments(
    assignments,
    sortAssignmentsBy as SortAssignmentsBy
  );

  return (
    <>
      {sortedAssignments.map(assignment => {
        const submission = assignment.comments.find(c => c.isSubmission);
        const hasSubmittedAssignment = !!submission;
        return (
          <AssignmentSummaryCard
            key={assignment.uid}
            assignmentPost={assignment}
            additionalAssignmentDetails={
              !hasSubmittedAssignment ? (
                <AssignmentDueDate dueDate={assignment.dueDate} />
              ) : (
                <AssignmentSubmittedOn
                  sentAt={submission.sentAt}
                  timeZone={timeZone}
                />
              )
            }
          >
            {assignment.comments.length ? (
              // There should only be a single top-level comment for the current learner
              <AssignmentSubmissionThread
                submission={assignment.comments[0]}
                timeZone={timeZone}
              />
            ) : (
              <Typography variant="subtitle2" sx={{ marginY: 16 }}>
                {t("Submit your assignment below!")}
              </Typography>
            )}
            <LearnerSubmissionThreadReplyInput
              assignmentClassPostUid={assignment.uid}
              hasSubmittedAssignment={hasSubmittedAssignment}
              sx={{ marginTop: 16 }}
            />
          </AssignmentSummaryCard>
        );
      })}
    </>
  );
};

interface LearnerSubmissionThreadReplyInputProps {
  assignmentClassPostUid: string;
  hasSubmittedAssignment: boolean;
  sx?: SxProps;
}
const LearnerSubmissionThreadReplyInput = ({
  assignmentClassPostUid,
  hasSubmittedAssignment,
  sx
}: LearnerSubmissionThreadReplyInputProps) => {
  const { sectionUid } = useLearnerClassroomContext();
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\ClassroomAssignmentsPage"
  );

  const { submitComment, loading } = useLearnerCommentOnAssignmentMutation({
    sectionUid
  });
  const handleAddComment = async (
    messageContentInput: EditableMessageContentSubmitType
  ) => {
    await submitComment({
      assignmentClassPostUid,
      messageContentInput
    });
  };

  return (
    <LegacyThemeProvider>
      <EditableMessageContent
        collapsedUntilFocused
        inClassContext
        placeholder={
          hasSubmittedAssignment
            ? t("Illustrate, elaborate, or just communicate.")
            : t("Go on, show off that hard work!")
        }
        onSubmit={handleAddComment}
        maxLength={25000}
        expandedRows={10}
        renderButton={({ submit, disabled, reset }) => (
          <Box sx={{ flex: 1 }}>
            <Button
              variant="contained"
              disabled={disabled || loading}
              onClick={() => {
                submit();
                reset();
              }}
              startIcon={loading ? <LoadingIcon /> : undefined}
            >
              {hasSubmittedAssignment
                ? t("Add a Comment")
                : t("Submit Assignment")}
            </Button>
          </Box>
        )}
        sx={sx}
      />
    </LegacyThemeProvider>
  );
};

interface LearnerAssignmentTrackerProps {
  syllabus: AssignmentTrackerSyllabusFragment;
}
const LearnerAssignmentTracker = ({
  syllabus
}: LearnerAssignmentTrackerProps) => {
  const { lessons } = syllabus;

  return (
    <AssignmentTracker>
      {lessons.map(lesson =>
        lesson.assignmentClassPosts.length > 0 ? (
          <AssignmentTrackerLesson
            key={lesson.uid}
            lessonNumber={lesson.lessonNumber}
          >
            {lesson.assignmentClassPosts.map(assignment => (
              <AssignmentTrackerLessonAssignment
                key={assignment.uid}
                assignmentTitle={assignment.title}
                submissionRequirement={assignment.submissionRequirement}
                onClick={() => {
                  scrollToElementId(assignment.uid);
                }}
              >
                <AssignmentTrackerDetails assignment={assignment} />
              </AssignmentTrackerLessonAssignment>
            ))}
          </AssignmentTrackerLesson>
        ) : null
      )}
    </AssignmentTracker>
  );
};

interface AssignmentTrackerProps {
  assignment: AssignmentTrackerSyllabusFragment["lessons"][number]["assignmentClassPosts"][number];
}
const AssignmentTrackerDetails = ({ assignment }: AssignmentTrackerProps) => {
  const { timeZone } = useCurrentLearner()!;

  const { comments: learnerSubmissions, dueDate } = assignment;
  // learners can only see their own comments
  // we can assume that if there is a submission, it is theirs
  const submission = learnerSubmissions.find(
    ({ isSubmission }) => isSubmission
  );
  const didSubmit = submission !== undefined;

  if (submission && didSubmit) {
    return (
      <AssignmentSubmittedOn sentAt={submission.sentAt} timeZone={timeZone} />
    );
  }

  return <AssignmentDueDate dueDate={dueDate} />;
};
