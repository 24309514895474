import { Box, Button, Image, Theme, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useLocalStorageState } from "@outschool/local-storage";
import { HeroLayout } from "@outschool/ui-components-classroom";
import { Container } from "@outschool/ui-legacy-component-library";
import { Screen, responsive } from "@outschool/ui-utils";
import { navigate } from "@patched/hookrouter";
import React, { useEffect } from "react";

import Header from "../../layout/nav/Header";
import { LEARNER_LOGIN_WELCOME_STORE_KEY } from "../../lib/constants";
import * as Routes from "../../lib/Routes";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import collageImage from "./welcome-collage.png";

const WelcomeText = ({ parentEmail }) => {
  if (parentEmail) {
    return (
      <Typography
        variant="inherit"
        sx={{
          marginBottom: "2em",
          paddingX: "0.5em"
        }}
      >
        We’re so glad you’re here!{" "}
        <b>
          Make sure your parent or guardian approves your account in the next 5
          days or it will be deleted.{" "}
        </b>{" "}
        We sent them an email at: {parentEmail}.
        <br />
        <br />
        In the meantime, start exploring classes about topics you love!
      </Typography>
    );
  }
  return (
    <Typography
      variant="inherit"
      sx={{
        marginBottom: "2em",
        paddingX: "0.5em",
        textAlign: "center"
      }}
    >
      Welcome to Outschool! You can now log into Outschool with your own account
      and view class content.
    </Typography>
  );
};

const Welcome = () => {
  const params = new URLSearchParams(window.location.search);
  const parentEmail = params.get("signup_email");

  const isSmall = Screen.useIsSmall();
  const [_hasLoggedIn, setHasLoggedIn] = useLocalStorageState(
    LEARNER_LOGIN_WELCOME_STORE_KEY,
    true
  );
  useEffect(() => {
    setHasLoggedIn(true);
  }, [setHasLoggedIn]);
  const currentLearner = useCurrentLearner();

  const header = (
    <Header
      sx={{
        position: "absolute",
        background: responsive({ default: "white", small: "transparent" }),
        borderColor: responsive({ default: "#535353", small: "transparent" })
      }}
      includeBlob
    />
  );

  return (
    <HeroLayout header={header}>
      <Box>
        <Container
          sx={{
            marginTop: responsive({ default: -120, small: -240 })
          }}
        >
          <Box
            flex
            sx={{
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Box
              flex
              sx={theme => ({
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "common.white",
                borderRadius: "1em",
                boxShadow:
                  "0px 15px 30px rgba(189, 226, 233, 0.15), 0px 20px 40px rgba(189, 226, 233, 0.05)",
                padding: "40px 20px",

                [theme.breakpoints.up("sm")]: {
                  padding: "48px 60px 36px"
                },

                margin: "0 18px 100px",
                maxWidth: "680px"
              })}
            >
              <Typography
                variant="inherit"
                sx={{
                  marginBottom: "0.5em"
                }}
              >
                Hi {currentLearner?.name}
              </Typography>
              <Typography
                variant={isSmall ? "h3" : "h2"}
                sx={{
                  textAlign: "center"
                }}
              >
                Welcome to Outschool!
              </Typography>
              <Image
                src={collageImage}
                sx={(theme: Theme) => ({
                  height: "128px",

                  [theme.breakpoints.up("sm")]: {
                    height: "240px"
                  },

                  width: "auto",
                  marginY: "1em"
                })}
              />
              <WelcomeText parentEmail={parentEmail} />
              <Button
                variant="contained"
                data-test-id="browse-classes-button"
                onClick={() => navigate(Routes.homePath())}
              >
                Browse Classes
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </HeroLayout>
  );
};

export default Welcome;
