// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Typography } from "@outschool/backpack";
import { Carousel, Loading } from "@outschool/ui-legacy-component-library";
import React from "react";

import ClassCard from "../../components/ClassCards";
import { useLearnerSimilarListingsByTfIdf } from "../../queries/LearnerSimilarClassesQuery";

interface SimilarClassesCarouselProps {
  activityUid: string;
}

function SimilarClassesCarousel({ activityUid }: SimilarClassesCarouselProps) {
  const { loading, similarActivities } = useLearnerSimilarListingsByTfIdf({
    activityUid
  });
  if (loading) {
    return <Loading />;
  }
  if (similarActivities.length === 0) {
    return null;
  }
  const listingElements = similarActivities.map(activity => (
    <Box
      key={activity.uid}
      sx={theme => ({
        width: 250,

        [theme.breakpoints.up("md")]: {
          width: 320
        },

        paddingBottom: "1em",
        paddingX: 12
      })}
    >
      <ClassCard
        key={activity.uid}
        activity={activity}
        trackingPrefix="browse.class.related_classes"
      />
    </Box>
  ));
  return (
    <Box
      flex
      sx={{
        flexDirection: "column",
        width: "100%"
      }}
    >
      <Typography
        variant="h3"
        sx={{
          mt: "40px",
          mb: "1em",
          textAlign: "center"
        }}
      >
        Explore more classes like this one
      </Typography>
      <Carousel>
        <Box flex>{listingElements}</Box>
      </Carousel>
    </Box>
  );
}

export default SimilarClassesCarousel;
