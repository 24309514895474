import { AuthProviders } from "@outschool/auth-shared";
import { Box } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { ContinueWithLine, LoginUserFeedback } from "@outschool/ui-auth";
import React from "react";

import { useLearnerAuth } from "../../../providers/LearnerAuthProvider";
import { useLoginWithRedirect } from "./connectOidcUtils";

export default function ConnectLine() {
  const { t } = useTranslation(
    "learnerApp\\Settings\\ConnectThirdPartyAccounts\\ConnectLine"
  );
  const { learnerAuth } = useLearnerAuth();
  const hasLine = React.useMemo(
    () => learnerAuth?.providers.includes(AuthProviders.Line) ?? false,
    [learnerAuth]
  );
  const userError = useLoginWithRedirect(AuthProviders.Line);

  return (
    <Box
      sx={{
        marginBottom: "0.5em"
      }}
    >
      {hasLine ? (
        <Box>
          {t("Your LINE account is linked and you may use it to log in.")}
        </Box>
      ) : (
        <Box>
          {userError && (
            <Box
              sx={{
                marginBottom: "0.5em"
              }}
            >
              <LoginUserFeedback
                userFeedbackMessage={userError}
                isError={true}
              />
            </Box>
          )}
          <ContinueWithLine
            trackingName="learner_login.learner_settings.connect_with_line"
            isLearnerApp={true}
          />
        </Box>
      )}
    </Box>
  );
}
