import { Box } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  useJoinNextMeeting,
  useSectionJoinRedirect
} from "@outschool/ui-components-classroom";
import { ExternalLink } from "@outschool/ui-components-shared";
import React, { PropsWithChildren } from "react";

import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import { useLearnerClassroomContext } from "../../providers/LearnerClassroomProvider";

export default function SectionJoinPage({
  enrollmentUid
}: {
  enrollmentUid: string;
}) {
  const { sectionUid } = useLearnerClassroomContext();

  useAnalyticsPage({
    pageName: PageType.SectionJoin,
    pageParams: { sectionUid, enrollmentUid }
  });
  const { loading, data, error } = useJoinNextMeeting(
    sectionUid,
    enrollmentUid
  );

  if (error || loading) {
    return null;
  }

  const { joinNextMeeting } = data ?? {};
  if (!joinNextMeeting) {
    return null;
  }

  return (
    <Redirect
      currentUserCanManage={joinNextMeeting.currentUserCanManage}
      redirectUrl={joinNextMeeting.url}
      sectionUid={sectionUid}
      enrollmentUid={enrollmentUid}
      shouldMarkAttendance={joinNextMeeting.shouldMarkAttendance}
    />
  );
}

function Redirect({
  redirectUrl,
  currentUserCanManage,
  sectionUid,
  enrollmentUid,
  shouldMarkAttendance
}) {
  const { shouldShowDirectLink } = useSectionJoinRedirect({
    redirectUrl,
    currentUserCanManage,
    sectionUid,
    enrollmentUid,
    shouldMarkAttendance
  });

  if (shouldShowDirectLink) {
    const startOrJoin = currentUserCanManage ? "start" : "join";
    return (
      <Wrapper>
        Sorry, we had trouble marking your attendance for this meeting. Please{" "}
        <ExternalLink url={redirectUrl}>click here</ExternalLink> to{" "}
        {startOrJoin} class.
      </Wrapper>
    );
  }

  return <Wrapper>Redirecting you to Zoom...</Wrapper>;
}

const Wrapper: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <Box
    sx={{
      paddingY: "4em",
      paddingX: "1em"
    }}
  >
    {children}
  </Box>
);
