import { Box, Image, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { LearnerBannerQuery } from "@outschool/gql-frontend-generated";
import {
  Space,
  useImpressionTracking
} from "@outschool/ui-legacy-component-library";
import { useNodeRef } from "@outschool/ui-utils";
import React from "react";

import blobBannerBg from "../../../../images/banners/welcome/blob_banner_bg.png";
import Link from "../../components/Link";

const CommunityBanner = ({
  bannerData
}: {
  bannerData: NonNullable<
    NonNullable<
      LearnerBannerQuery["contentfulLearnerBannerCollection"]
    >["items"][0]
  >;
}) => {
  const trackingPrefix = "banner.community";
  const [impressionNode, setImpressionNode] = useNodeRef();
  useImpressionTracking({
    node: impressionNode,
    uniqueId: trackingPrefix,
    trackingLabel: trackingPrefix,
    trackViews: true
  });

  const { title, subtitle, ctaText, ctaUrl, image } = bannerData;

  return (
    <Box
      sx={theme => ({
        backgroundImage: `url(${blobBannerBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center top",
        height: 335,

        [theme.breakpoints.up("sm")]: {
          height: 339
        }
      })}
      data-test-id="community-banner"
      ref={setImpressionNode}
    >
      <Box
        flex
        sx={theme => ({
          alignItems: "center",
          justifyContent: "center",
          paddingX: "12px",

          [theme.breakpoints.up("sm")]: {
            paddingX: "1em"
          },

          marginTop: "80px"
        })}
      >
        <Box
          flex
          sx={theme => ({
            flexDirection: "column",
            alignItems: "normal",

            [theme.breakpoints.up("sm")]: {
              alignItems: "center",
              textAlign: "center",
              maxWidth: "400px"
            },

            textAlign: "left",
            maxWidth: "300px"
          })}
        >
          <Typography
            variant="h2"
            component="div"
            sx={theme => ({
              fontSize: "1em",

              [theme.breakpoints.up("sm")]: {
                fontSize: "1.5em"
              },

              fontWeight: 900
            })}
          >
            {title}
          </Typography>
          <Space y={8} />
          <Typography
            variant="inherit"
            sx={theme => ({
              fontSize: "0.875em",

              [theme.breakpoints.up("sm")]: {
                fontSize: "1em"
              }
            })}
          >
            {subtitle}
          </Typography>
          <Space y={4} />
          <Link
            style={{ zIndex: 1, fontWeight: 500 }}
            to={ctaUrl}
            target="_blank"
            trackingName={trackingPrefix + "_touch"}
          >
            {ctaText}
          </Link>
        </Box>
        <Space x="medium" />
        <Image
          sx={{
            maxWidth: 125
          }}
          src={image?.url || undefined}
          alt={image?.title || undefined}
        />
      </Box>
    </Box>
  );
};

export default CommunityBanner;
