// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Space } from "@outschool/ui-legacy-component-library";
import { useRoutes, useTitle } from "@patched/hookrouter";
import React from "react";

import { SearchSuggestionsProvider } from "../../../../shared/client/providers/SearchSuggestionsProvider";
import { HeroLayoutWithTabs } from "../../layout/HeroLayoutWithTabs";
import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import * as Routes from "../../lib/Routes";
import DefaultPage from "./DefaultPage";
import KeywordSearchResults from "./KeywordSearchResults";
import RecommendedClassesResults from "./RecommendedClassesResults";
import FindClassesSearchBar from "./SearchBar";
import TeacherClasses from "./TeacherClasses";
import TeacherSearchResult from "./TeacherSearchResult";
import TopicSearchResults from "./TopicSearchResults";

type KeywordSearchProps = {
  keyword: string;
};

type TopicSearchProps = {
  topicUid: string;
};

export type SearchProps = KeywordSearchProps | TopicSearchProps;

export function isKeywordSearchProps(
  props: SearchProps
): props is KeywordSearchProps {
  return props.hasOwnProperty("keyword");
}

function SearchResultsWrapper(props: SearchProps) {
  if (isKeywordSearchProps(props)) {
    return <KeywordSearchResults {...props} />;
  }

  return <TopicSearchResults {...props} />;
}

const searchRoutes = {
  "/": () => <DefaultPage />,
  "/teacher/:leaderLink/classes": ({ leaderLink }) => (
    <TeacherClasses leaderLink={leaderLink} />
  ),
  "/teacher/:leaderLink": ({ leaderLink }) => (
    <TeacherSearchResult leaderLink={leaderLink} />
  ),
  "/topic/:topicUid": (props: TopicSearchProps) => (
    <SearchResultsWrapper {...props} />
  ),
  "/keyword/": () => <SearchResultsWrapper keyword="" />,
  "/keyword/:keyword": (props: KeywordSearchProps) => (
    <SearchResultsWrapper {...props} />
  ),
  "/recommended": () => <RecommendedClassesResults />
};

function LearnerFindClasses() {
  useTitle("Find Classes | Outschool");
  useAnalyticsPage({ pageName: PageType.FindClasses });
  const currentRoute = useRoutes(searchRoutes);

  return (
    <HeroLayoutWithTabs currentPath={Routes.findClassesPath()}>
      <SearchSuggestionsProvider>
        <Space y="medium" />
        <FindClassesSearchBar />
        <Space y="xlarge" />
        {currentRoute}
      </SearchSuggestionsProvider>
    </HeroLayoutWithTabs>
  );
}

export default LearnerFindClasses;
