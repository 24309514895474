import { AuthProviders } from "@outschool/auth-shared";
import { useTranslation } from "@outschool/localization";
import { Component } from "@outschool/ownership-areas";
import { useAuthError } from "@outschool/ui-auth";
import React from "react";

import { useLearnerLoginWithRedirect } from "../../../hooks/authentication";

export function useLoginWithRedirect(provider: AuthProviders) {
  const { t } = useTranslation(
    "learnerApp\\Settings\\ConnectThirdPartyAccounts\\connectOidcUtils"
  );

  const [userError, setUserError] = React.useState<string | null>(null);

  const generateLoginError = useAuthError(provider);
  const url = React.useMemo(() => {
    if (typeof window !== "undefined") {
      return window.location.href;
    }
    return "";
  }, []);
  const handleAuthError = React.useCallback(
    (error: any, message: string = t("Sorry that didn't work, try again")) => {
      OsPlatform.captureError(error, {
        tags: { component: Component.LearnerMode }
      });
      setUserError(message);
    },
    [t]
  );
  const handleLoginError = React.useCallback(
    error => {
      const errorObject = new Error(error);
      handleAuthError(errorObject, generateLoginError(error) as any);
    },
    [handleAuthError, generateLoginError]
  );
  useLearnerLoginWithRedirect(handleLoginError, url);

  return userError;
}
