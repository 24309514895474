import {
  BasicModal,
  Box,
  Button,
  Icon,
  Typography,
  accordionSummaryClasses
} from "@outschool/backpack";
import { faFileAlt } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import * as Time from "@outschool/time";
import {
  AssignmentDueDate,
  AssignmentMessageContent,
  AssignmentRequirementChip,
  AssignmentSubmittedOn,
  EditableMessageContent,
  EditableMessageContentSubmitType
} from "@outschool/ui-components-classroom";
import { AttachmentGallery } from "@outschool/ui-components-shared";
import {
  LegacyThemeProvider,
  LoadingIcon
} from "@outschool/ui-legacy-component-library";
import React from "react";

import { Assignment } from "../../../providers/ClassroomAssignmentsByLessonProvider";
import { useCurrentLearner } from "../../../providers/CurrentLearnerProvider";
import { useLearnerClassroomContext } from "../../../providers/LearnerClassroomProvider";
import { useLearnerCommentOnAssignmentMutation } from "../../../queries/LearnerClassroomAssignmentsBySectionQueries";
import { LessonViewAccordionSection } from "./LessonViewAccordionSection";

interface LessonAssignmentAccordionSectionProps {
  assignment: Assignment;
}
export const LessonAssignmentAccordionSection = ({
  assignment
}: LessonAssignmentAccordionSectionProps) => {
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\LessonAssignmentAccordionSection"
  );
  const { timeZone } = useCurrentLearner()!;

  const {
    title,
    publishAt,
    submissionRequirement,
    dueDate,
    messageContent,
    comments: [learnerThread]
  } = assignment;

  const isSubmitted = learnerThread ? !!learnerThread.isSubmission : false;

  return (
    <LessonViewAccordionSection
      startExpanded={false}
      summary={
        <>
          <Box sx={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Icon icon={faFileAlt} size="small" />
            <Typography variant="h6">{title || t("Assignment")}</Typography>
            {isSubmitted ? (
              <AssignmentSubmittedOn
                sentAt={learnerThread.sentAt}
                timeZone={timeZone}
              />
            ) : (
              <AssignmentRequirementChip
                submissionRequirement={submissionRequirement}
              />
            )}
          </Box>

          <Typography variant="body2">
            {t("Posted on {{ datePosted }} ", {
              datePosted: Time.formatDateTimeWithFullWeekday(
                publishAt,
                timeZone
              )
            })}
          </Typography>

          {!isSubmitted && <AssignmentDueDate dueDate={dueDate} />}
        </>
      }
      summarySx={{
        [`& .${accordionSummaryClasses.content}`]: {
          display: "flex",
          flexDirection: "column",
          gap: 4
        }
      }}
    >
      <AssignmentMessageContent messageContent={messageContent} />
      <LegacyThemeProvider>
        <AttachmentGallery
          video={messageContent.video}
          attachments={messageContent.attachments}
          containerSx={{ marginTop: 24, marginBottom: 0 }}
        />
      </LegacyThemeProvider>
      {!isSubmitted && <SubmitAssignmentButton assignment={assignment} />}
    </LessonViewAccordionSection>
  );
};

interface SubmitAssignmentButtonProps {
  assignment: Assignment;
}
const SubmitAssignmentButton = ({
  assignment
}: SubmitAssignmentButtonProps) => {
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\LessonAssignmentAccordionSection"
  );

  const [modalOpen, setModalOpen] = React.useState(false);

  const { uid, title, submissionRequirement, messageContent } = assignment;

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        sx={{ marginTop: 16 }}
        onClick={(_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setModalOpen(true);
        }}
      >
        {t("Submit Assignment")}
      </Button>
      <BasicModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        hasCloseButton
        closeButtonArialLabel={t("close")}
      >
        <Typography variant="h4" sx={{ marginBottom: 16 }}>
          {t("Submit Assignment")}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 24 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Typography variant="h6">{title || t("Assignment")}</Typography>
            <AssignmentRequirementChip
              submissionRequirement={submissionRequirement}
            />
          </Box>
          <AssignmentMessageContent messageContent={messageContent} />
          <LearnerSubmissionInput
            assignmentClassPostUid={uid}
            closeModal={() => setModalOpen(false)}
          />
        </Box>
      </BasicModal>
    </>
  );
};

interface LearnerSubmissionInputProps {
  assignmentClassPostUid: string;
  closeModal: () => void;
}
const LearnerSubmissionInput = ({
  assignmentClassPostUid,
  closeModal
}: LearnerSubmissionInputProps) => {
  const { sectionUid } = useLearnerClassroomContext();
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\LessonAssignmentAccordionSection"
  );

  const { submitComment, loading } =
    // TODO - provide update function to update cache of given assignment to indicate that it has been submitted
    useLearnerCommentOnAssignmentMutation({
      sectionUid,
      isTopLevelSubmission: true
    });
  const handleAddComment = async (
    messageContentInput: Omit<EditableMessageContentSubmitType, "video">
  ) => {
    await submitComment({
      assignmentClassPostUid,
      messageContentInput
    });
    closeModal();
  };

  return (
    <LegacyThemeProvider>
      <EditableMessageContent
        collapsedUntilFocused
        inClassContext
        placeholder={t("Go on, show off that hard work!")}
        onSubmit={handleAddComment}
        maxLength={25000}
        expandedRows={10}
        renderButton={({ submit, disabled, reset }) => (
          <Box sx={{ flex: 1 }}>
            <Button
              variant="contained"
              disabled={disabled || loading}
              onClick={() => {
                submit();
                reset();
              }}
              startIcon={loading ? <LoadingIcon /> : undefined}
            >
              {t("Submit Assignment")}
            </Button>
          </Box>
        )}
      />
    </LegacyThemeProvider>
  );
};
