import { Box, Image } from "@outschool/backpack";
import { TOPIC_ILLUSTRATIONS } from "@outschool/ui-components-classroom";
import { useIsLarge, useLinkComponent, useMotionSx } from "@outschool/ui-utils";
import React from "react";

import * as defaultBackgroundPattern from "../../../images/learner/topics/science-background-pattern.svg";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { CurrentLearnerRecommendedTopicsQuery } from "../generated/graphql";
import { topicSearchResultsPath } from "../lib/Routes";

const ILLUSTRATION_PADDING = 16;
const ANIMATION_SPEED = 0.5;
const EASE_OUT_BACK_FUNCTION = "cubic-bezier(0.34, 1.56, 0.64, 1)";

interface TopicCardProps {
  topic: CurrentLearnerRecommendedTopicsQuery["currentLearnerRecommendedTopics"][0];
}

function TopicCard({ topic }: TopicCardProps) {
  const Link = useLinkComponent();
  const isLarge = useIsLarge();
  const imageHoverSx = useMotionSx({
    "&:hover": {
      filter: "drop-shadow(0px 8px 15px rgba(0, 0, 0, 0.12))"
    },
    "&:hover img:nth-of-type(1)": {
      transform: "scale(0.98)"
    },
    "&:hover img:nth-of-type(2)": {
      transform: isLarge ? "scale(1.1)" : "scale(1.05)"
    }
  });
  return (
    <Link
      to={topicSearchResultsPath(topic.uid)}
      trackingName={`browse.recommended_topic.${topic.label}`}
      style={{ width: "100%", height: "100%" }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "342px",
          height: "286px",
          margin: "0 auto",
          position: "relative",
          transition: `filter ${ANIMATION_SPEED}s ${EASE_OUT_BACK_FUNCTION}`,
          ...imageHoverSx
        }}
      >
        <TopicBackgroundGradient topic={topic} />
        <TopicBackgroundPattern topic={topic} />
        <TopicImage topic={topic} />
      </Box>
      <TopicLabel topic={topic} />
    </Link>
  );
}

function TopicBackgroundGradient({ topic }: TopicCardProps) {
  const gradient =
    TOPIC_ILLUSTRATIONS[topic.uid]?.background ||
    "linear-gradient(125.81deg, #5494EF 2.62%, #3973C6 109.05%)";
  return (
    <Box
      sx={{
        position: "absolute",
        borderRadius: "0.5em",
        background: gradient,
        width: "100%",
        height: `calc(100% - ${ILLUSTRATION_PADDING * 2}px)`,
        top: ILLUSTRATION_PADDING * 2
      }}
    ></Box>
  );
}

const BACKGROUND_TOP_POSITION = ILLUSTRATION_PADDING;
const BACKGROUND_LEFT_POSITION = -ILLUSTRATION_PADDING / 2;

function TopicBackgroundPattern({ topic }: TopicCardProps) {
  let patternSrc =
    TOPIC_ILLUSTRATIONS[topic.uid]?.pattern || defaultBackgroundPattern;
  return (
    <Image
      src={patternSrc}
      sx={{
        position: "absolute",
        width: `calc(100% + ${ILLUSTRATION_PADDING}px)`,
        height: `calc(100% + ${ILLUSTRATION_PADDING}px)`,
        top: BACKGROUND_TOP_POSITION,
        left: BACKGROUND_LEFT_POSITION,
        transition: `transform ${ANIMATION_SPEED}s ${EASE_OUT_BACK_FUNCTION}`,
        transform: "scale(1.08)"
      }}
    />
  );
}

const ICON_WIDTH = `calc(100% + ${ILLUSTRATION_PADDING + 8}px)`;
const ICON_LEFT_POSITION = -ILLUSTRATION_PADDING + 4;

function TopicImage({ topic }: TopicCardProps) {
  if (!TOPIC_ILLUSTRATIONS[topic.uid]?.icon) {
    return null;
  }
  return (
    <>
      <Image
        src={TOPIC_ILLUSTRATIONS[topic.uid]?.icon}
        alt={`Search classes about ${topic.label}`}
        sx={{
          width: ICON_WIDTH,
          position: "absolute",
          bottom: 0,
          left: ICON_LEFT_POSITION,
          transition: `transform ${ANIMATION_SPEED}s ${EASE_OUT_BACK_FUNCTION}`,
          transform: "scale(1)",
          transformOrigin: "bottom"
        }}
      />
    </>
  );
}

function TopicLabel({ topic }: TopicCardProps) {
  return (
    <Box
      flex
      sx={{
        fontFamily: "'Ginto Normal',sans-serif",
        fontWeight: "fontWeightBold",
        fontSize: 24,
        lineHeight: "30px",
        color: "#000",
        textAlign: "center",
        marginTop: "1em",
        justifyContent: "center"
      }}
    >
      {topic.label}
    </Box>
  );
}
export default TopicCard;
