import { Box, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { LearnerActivityDetailsQuery } from "@outschool/gql-frontend-generated";
import React from "react";

import TeacherDetails from "../../components/TeacherDetails";

const MAX_WIDTH = 1050;
interface AboutTeacherSectionProps {
  activity: NonNullable<LearnerActivityDetailsQuery["activity"]>;
}
function AboutTeacherSection({ activity }: AboutTeacherSectionProps) {
  return (
    <Box
      flex
      sx={{
        py: "32px",
        backgroundColor: "primary.900",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Box
        sx={{
          maxWidth: MAX_WIDTH,
          width: "100vw"
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            marginBottom: "32px",
            justifyContent: "center",
            color: "common.white"
          }}
        >
          About the {activity.isClub ? "Group Leader" : "Teacher"}
        </Typography>
        <TeacherDetails
          teacher={activity.leader}
          sx={{
            fontSize: 20,
            color: "white"
          }}
          trackingPrefix={`browse.class.${activity.uid}`}
          trackingSuffix={activity.uid}
          showMoreClassesButton
        />
      </Box>
    </Box>
  );
}

export default AboutTeacherSection;
