import { Box, Icon, Typography } from "@outschool/backpack";
import { faComment, faNoteSticky, faPaperclip } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import * as Time from "@outschool/time";
import { PostSummaryItem } from "@outschool/ui-components-classroom";
import React from "react";

import {
  ClassPost,
  useClassroomPostsContext
} from "../../../providers/ClassroomPostsProvider";
import { useCurrentLearner } from "../../../providers/CurrentLearnerProvider";
import LearnerClassPost from "../ClassPost";
import { LessonViewAccordionSection } from "./LessonViewAccordionSection";

interface LessonClassPostAccordionSectionProps {
  classPost: ClassPost;
}
export const LessonClassPostAccordionSection = ({
  classPost
}: LessonClassPostAccordionSectionProps) => {
  const { mentionNames, handleAddComment } = useClassroomPostsContext();
  return (
    <LessonViewAccordionSection
      summary={<LessonClassPostSummary classPost={classPost} />}
    >
      <LearnerClassPost
        post={classPost}
        mentionNames={mentionNames}
        usersCanComment
        handleAddComment={handleAddComment}
      />
    </LessonViewAccordionSection>
  );
};

interface LessonClassPostSummaryProps {
  classPost: ClassPost;
}
const LessonClassPostSummary = ({ classPost }: LessonClassPostSummaryProps) => {
  const currentLearner = useCurrentLearner();
  const { t } = useTranslation(
    "learnerApp\\LearnerClassroom\\LessonClassPostAccordionSection"
  );

  const { messageContent, commentsPreview } = classPost;
  const totalNumberOfComments =
    commentsPreview.comments.length + commentsPreview.remainingCommentsCount;
  const hasAttachments = messageContent.attachments.length > 0;

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: 24
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 8
          }}
        >
          <Icon icon={faNoteSticky} size="small" />
          <Typography variant="h6">
            {classPost.title || t("Lesson post")}
          </Typography>
        </Box>
        <Typography variant="body2">
          {t("Posted on {{ datePosted }} ", {
            datePosted: Time.formatDateTimeWithFullWeekday(
              classPost.publishAt,
              currentLearner?.timeZone
            )
          })}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 24 }}>
        {hasAttachments && (
          <PostSummaryItem
            label={t("{{numberOfAttachments}} attachment", {
              numberOfAttachments: messageContent.attachments.length,
              count: messageContent.attachments.length,
              defaultValue_plural: "{{numberOfAttachments}} attachments"
            })}
            icon={faPaperclip}
          />
        )}
        <PostSummaryItem
          label={t("{{numberOfComments}} comment", {
            numberOfComments: totalNumberOfComments,
            count: totalNumberOfComments,
            defaultValue_plural: "{{numberOfComments}} comments"
          })}
          icon={faComment}
        />
      </Box>
    </Box>
  );
};
