import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";

import {
  LearnerSimilarListingsByTfIdfQuery,
  LearnerSimilarListingsByTfIdfQueryVariables
} from "../generated/graphql";
import LearnerActivitySearchResultFragment from "./LearnerActivitySearchResultFragment";

const LearnerSimilarListingsByTfIdf = gql`
  query LearnerSimilarListingsByTfIdf($activityUid: ID!) {
    listing(activityUid: $activityUid) {
      similarListings {
        tfidf {
          activity {
            ...LearnerActivitySearchResultFragment
          }
        }
      }
    }
  }
  ${LearnerActivitySearchResultFragment}
`;

export function useLearnerSimilarListingsByTfIdf(
  variables: LearnerSimilarListingsByTfIdfQueryVariables
) {
  const { loading, error, data } =
    useQueryWithPreviousData<LearnerSimilarListingsByTfIdfQuery>(
      LearnerSimilarListingsByTfIdf,
      {
        fetchPolicy: "cache-and-network",
        variables
      }
    );
  const similarActivities =
    data?.listing?.similarListings.tfidf.map(results => results.activity) || [];
  return {
    loading,
    error,
    similarActivities
  };
}
