// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, SxProps, Typography, useTheme } from "@outschool/backpack";
import { Attachment, Leader } from "@outschool/gql-backend-generated";
import { VideoModalWithImageFallback } from "@outschool/ui-components-classroom";
import { CollapsedText } from "@outschool/ui-legacy-component-library";
import { responsive } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import { Maybe } from "../generated/graphql";
import TeachersOtherClassesCarousel from "../routes/LearnerActivityDetails/TeachersOtherClassesCarousel";
import VideoPlayer from "./VideoPlayer";

const DEFAULT_PROFILE_IMAGE =
  "https://cdn.filestackcontent.com/26Xc9cgQFmpVTv8h268V";

type AttachmentFile = Pick<
  Attachment["file"],
  Exclude<keyof Attachment["file"], "fileSize">
>;
interface TeacherDetailsProps {
  teacher: Pick<Leader, "uid" | "name" | "photo"> & {
    video: Maybe<{ file: AttachmentFile }>;
    details: Pick<Leader["details"], "about">;
  };
  sx?: SxProps;
  videoSx?: SystemStyleObject;
  showMoreClassesButton?: boolean;
  trackingPrefix?: string;
  trackingSuffix?: string;
}

function TeacherDetails({
  teacher,
  sx = {},
  videoSx = {},
  trackingPrefix,
  showMoreClassesButton
}: TeacherDetailsProps) {
  const imageUrl =
    teacher.video?.file.thumbUrl ?? teacher.photo ?? DEFAULT_PROFILE_IMAGE;
  const videoUrl = teacher.video?.file.url ?? undefined;

  // Use useTheme instead of sx callback because the sx below is either a backpack component or a legacy component.
  const theme = useTheme();

  return (
    <Box
      flex
      sx={[
        {
          width: "100%",
          px: "1em",
          flexDirection: "column",
          minHeight: "210px"
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <Box
        flex
        sx={theme => ({
          flexDirection: "column",

          [theme.breakpoints.up("md")]: {
            flexDirection: "row"
          },

          alignItems: "flex-start"
        })}
      >
        <VideoModalWithImageFallback
          sx={{
            maxHeight: "300px",
            maxWidth: "460px",
            flex: 1,
            margin: "0 0 20 0",
            [theme.breakpoints.up("md")]: {
              flex: "2px",
              margin: "0 20 0 0"
            },
            float: "left",
            border: "8px solid white",
            transform: "rotate(-1deg)",
            ...videoSx
          }}
          imageSx={{
            maxHeight: 300,
            maxWidth: 460,
            margin: responsive({ default: "0 0 20 0", medium: "0 20 0 0" }),
            float: "left"
          }}
          imageUrl={imageUrl}
          videoUrl={videoUrl}
          trackingName={`${trackingPrefix}.play_teacher_video`}
          VideoPlayer={VideoPlayer}
        />
        <Box
          flex
          sx={theme => ({
            flexDirection: "column",
            flex: 1,

            [theme.breakpoints.up("md")]: {
              flex: "3px",
              marginX: "18px",
              marginTop: 0
            },

            marginX: 0,
            marginTop: "18px"
          })}
        >
          <Typography variant="h3">{teacher.name}</Typography>
          <CollapsedText
            maxCharacters={300}
            showMoreLabel="More"
            showLessLabel="Less"
            buttonSx={{
              fontSize: 20,
              fontWeight: "bold",
              textDecoration: "underline"
            }}
          >
            {teacher.details.about || ""}
          </CollapsedText>
        </Box>
      </Box>
      {showMoreClassesButton && (
        <TeachersOtherClassesCarousel
          teacherUid={teacher.uid}
          teacherName={teacher.name ?? "Teacher"}
        />
      )}
    </Box>
  );
}

export default TeacherDetails;
