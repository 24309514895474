import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  CurrentLearnerRecommendedTeachersQuery,
  CurrentLearnerRecommendedTeachersQueryVariables
} from "../generated/graphql";
import LearnerLeaderFragment from "./LearnerLeaderFragment";

const LearnerTeacherRecommendationsQuery = gql`
  query CurrentLearnerRecommendedTeachers($limit: Int) {
    currentLearnerRecommendedTeachers(limit: $limit) {
      teachers {
        ...LearnerLeaderFragment
      }
      recommendationType
    }
  }
  ${LearnerLeaderFragment}
`;

export function useRecommendedTeachersQuery(
  variables: CurrentLearnerRecommendedTeachersQueryVariables
) {
  const { loading, error, data } =
    useQueryWithPreviousData<CurrentLearnerRecommendedTeachersQuery>(
      LearnerTeacherRecommendationsQuery,
      {
        fetchPolicy: "cache-and-network",
        variables
      }
    );
  const teachers = data?.currentLearnerRecommendedTeachers.teachers || [];
  const teacherRecommendationType =
    data?.currentLearnerRecommendedTeachers.recommendationType;
  return {
    loading,
    error,
    teachers,
    teacherRecommendationType
  };
}
