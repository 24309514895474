// Ideally we should use isolation: isolate, but the switcher dropdown
// doesn't show on top of other elements when using isolation: isolate
export const LEARNER_SWITCHER_Z_INDEX = 1;

export const STANDARD_NAV_BUTTON_PSEUDO_CLASS_STYLE = {
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.2)"
  },
  "&:active": {
    background: "rgb(208, 228, 235)",
    boxShadow: "inset 0px 2px 4px rgba(69, 128, 171, 0.25)"
  }
};
