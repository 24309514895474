import { Box } from "@outschool/backpack";
import {
  Container,
  ErrorBoundary
} from "@outschool/ui-legacy-component-library";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

/**
 * Create a duplicate error instance with the same name and stack trace.
 * Used to prevent Sentry from deduplicating error messages.
 * @param error the error to duplicate
 * @param newMessagePrefix Prepend this message to the start of the original
 * message
 */
export const duplicateError = (error: Error, newMessagePrefix: string) => {
  const wrapperError = new Error(`${newMessagePrefix} ${error.message}`);
  wrapperError.name = error.name;
  wrapperError.stack = error.stack;
  return wrapperError;
};

export default function DefaultErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      fallback={error => {
        error =
          error ||
          new Error("fallback called but no error message was passed?");
        /*
         * Errors are deduplicated by Sentry, which will clobber any tags we
         * apply. To make sure that the tags are included, we'll wrap the
         * original error.
         */
        const wrappedError = duplicateError(
          error,
          "Oops page displayed for error: "
        );
        OsPlatform.captureError(wrappedError, {
          tags: {
            oops_page_displayed: true
          }
        });
        return <DefaultErrorBoundaryMessage />;
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export function DefaultErrorBoundaryMessage() {
  return (
    <React.Fragment>
      <Box
        sx={{
          textAlign: "center",
          marginTop: "2em"
        }}
      >
        <Container size="tiny">
          Oops! Something went wrong with the Outschool website. Our engineers
          have been notified. Please reload the page and try again, or contact
          us at <a href="mailto:support@outschool.com">support@outschool.com</a>{" "}
          if the error persists. You can also check{" "}
          <a href="https://status.outschool.com/">status.outschool.com</a> to
          see if there is a broader outage.
          <br />
          <br />
          If you have a class, you should still be able to join the class
          through a banner at the top of{" "}
          <a href="https://outschool.com/">outschool.com</a>.
          <br />
          <br />
          Thanks for bearing with us!
        </Container>
      </Box>
    </React.Fragment>
  );
}
