import { Box, Typography } from "@outschool/backpack";
import { LearnerActivityDetailsQuery } from "@outschool/gql-frontend-generated";
import { VideoModalWithImageFallback } from "@outschool/ui-components-classroom";
import React from "react";

import VideoPlayer from "../../components/VideoPlayer";
import ClassDetailsActionButtons from "./ClassDetailsActionButtons";

const DEFAULT_PROFILE_IMAGE =
  "https://cdn.filestackcontent.com/26Xc9cgQFmpVTv8h268V";

interface ActivityDetailsHeaderProps {
  activity: NonNullable<LearnerActivityDetailsQuery["activity"]>;
}

function ActivityDetailsHeader({ activity }: ActivityDetailsHeaderProps) {
  const imageUrl =
    activity.details.photo ??
    activity.video?.file.thumbUrl ??
    DEFAULT_PROFILE_IMAGE;
  const videoUrl =
    activity.videoForLearners?.file.url ??
    activity.video?.file.url ??
    undefined;
  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          marginBottom: "32px"
        }}
      >
        {activity.title}
      </Typography>
      <Box
        sx={theme => ({
          display: "grid",
          gridTemplateColumns: "100%",

          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "60% 40%",
            gridTemplateAreas: '"details activity-media"'
          },

          alignItems: "self-start",
          gridGap: "2em",
          gridTemplateAreas: '"activity-media" "details"'
        })}
      >
        <Box
          flex
          sx={{
            gridArea: "details",
            flexDirection: "column"
          }}
        >
          <Box
            sx={{
              marginBottom: "32px"
            }}
          >
            {activity.summaryForLearners || activity.summary}
          </Box>
          <ClassDetailsActionButtons activity={activity} />
        </Box>
        <Box
          sx={{
            gridArea: "activity-media",
            width: "100%",
            transform: "rotate(2.3deg)",
            border: "8px solid white",
            filter: "drop-shadow(2px 5px 8px rgba(0, 0, 0, 0.25))"
          }}
        >
          <VideoModalWithImageFallback
            sx={{
              width: "100% !important",
              maxHeight: "100%",
              minHeight: 210
            }}
            imageSx={{
              maxHeight: 300
            }}
            imageUrl={imageUrl}
            videoUrl={videoUrl}
            trackingName={`browse.class.${activity.uid}.play_class_video`}
            VideoPlayer={VideoPlayer}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ActivityDetailsHeader;
