import { gql } from "@outschool/ui-apollo";
import { AttachmentFragment } from "@outschool/ui-components-classroom";

export default gql`
  fragment LearnerActivitySearchResultFragment on Activity {
    uid
    title
    slug_id
    details {
      photo
    }
    leader {
      uid
      name
      photo
    }
    video {
      ...AttachmentFragment
    }
    videoForLearners {
      ...AttachmentFragment
    }
    price_cents
    age_max
    age_min
    isClub
  }
  ${AttachmentFragment}
`;
