import { useWindowReference } from "@outschool/local-storage";
import { useBooleanState } from "@outschool/ui-utils";
import { useEffect } from "react";

const useWindowHasFocus = (): boolean => {
  const { window } = useWindowReference();
  const [windowHasFocus, onFocus, onBlur] = useBooleanState(true);
  useEffect(() => {
    if (!window) {
      return () => null;
    }
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, [onBlur, onFocus, window]);
  return windowHasFocus;
};

export default useWindowHasFocus;
