import {
  Box,
  Button,
  ButtonColor,
  ButtonVariant,
  ClickAwayListener,
  Icon,
  Popper,
  SxProps,
  Typography
} from "@outschool/backpack";
import { faChevronDown } from "@outschool/icons";
import { useMutation } from "@outschool/ui-apollo";
import { LearnerProfileIcon } from "@outschool/ui-components-classroom";
import {
  AvatarImage,
  DEFAULT_AVATAR,
  TrackedButton
} from "@outschool/ui-components-shared";
import {
  switchToLearnerModeWithToken,
  useSwitchToParentMode
} from "@outschool/ui-components-website";
import {
  ErrorBoundary,
  Modal,
  Space
} from "@outschool/ui-legacy-component-library";
import { Screen, useBooleanState, useLinkComponent } from "@outschool/ui-utils";
import { navigate, usePath } from "@patched/hookrouter";
import _ from "lodash";
import React, { PropsWithChildren, useCallback } from "react";
import { useAnalytics } from "use-analytics";

import BackgroundBlob from "../../components/BackgroundBlob";
import NotificationList from "../../components/notifications/NotificationList";
import ParentImage from "../../components/ParentImage";
import { IS_REVIEW_APP as isReviewApp } from "../../Env";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  CurrentLearnerFragmentFragment,
  GetLearnerToParentTransferTokenMutation,
  GetLearnerToParentTransferTokenMutationVariables,
  GetLearnerToSiblingTransferTokenMutation,
  GetLearnerToSiblingTransferTokenMutationVariables,
  LearnerAccessLevel,
  LearnerModeSwitchAuthRequirement
} from "../../generated/graphql";
import { useIsLearnerLogin } from "../../hooks/useIsLearnerLogin";
import analytics from "../../lib/analytics";
import { hasAccess } from "../../lib/LearnerAccess";
import * as Routes from "../../lib/Routes";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import { useLearnerAuth } from "../../providers/LearnerAuthProvider";
import {
  GetLearnerToParentTransferToken,
  GetLearnerToSiblingTransferToken
} from "../../queries/LearnerSwitcherQuery";
import HeaderNavItem from "./HeaderNavItem";
import MultiplicationModal from "./MultiplicationModal";
import { LEARNER_SWITCHER_Z_INDEX } from "./Nav";
import PasswordModal from "./PasswordModal";

interface CurrentLearnerProps {
  learner: CurrentLearnerFragmentFragment;
  open: boolean;
  includeBlob?: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  switcherMenuButtonRef?: React.MutableRefObject<HTMLElement | undefined>;
  ref?: React.Ref<HTMLDivElement>;
  sx?: SxProps;
}

const CurrentLearner: React.FC<CurrentLearnerProps> = React.forwardRef(
  ({ learner, includeBlob, onClick, open, sx, switcherMenuButtonRef }, ref) => {
    const currentLearnerButton = (
      <Box
        flex
        sx={{
          alignItems: "center"
        }}
      >
        <Box
          ref={switcherMenuButtonRef}
          sx={{
            zIndex: LEARNER_SWITCHER_Z_INDEX
          }}
        >
          <HeaderNavItem
            as="button"
            role="button"
            aria-haspopup="menu"
            aria-expanded={open ? "true" : undefined}
            onClick={onClick}
            ref={ref}
            sx={[
              {
                border: "none",
                backgroundColor: "transparent"
              },
              ...(Array.isArray(sx) ? sx : [sx])
            ]}
          >
            <AvatarImage
              avatar={learner.avatar || DEFAULT_AVATAR}
              width={32}
              height={32}
              name={learner.name || ""}
            />
            <Space x="small" />
            <Typography
              variant="h3"
              sx={theme => ({
                fontWeight: "fontWeightBold",
                color: "grey.700",
                marginX: "0.5em",
                textTransform: "capitalize",
                display: "none",

                [theme.breakpoints.up("sm")]: {
                  display: "block"
                },

                maxWidth: 180,

                [theme.breakpoints.up("lg")]: {
                  maxWidth: 300
                },

                textOverflow: "ellipsis",
                overflow: "hidden"
              })}
            >
              {learner.name}
            </Typography>
            <Icon
              icon={faChevronDown}
              sx={{
                marginX: "0.5em",
                transform: open ? "rotate(180deg)" : undefined,
                transition: "transform 0.25s"
              }}
            />
          </HeaderNavItem>
        </Box>

        <ErrorBoundary>
          <Box
            sx={{
              zIndex: LEARNER_SWITCHER_Z_INDEX
            }}
          >
            <NotificationList />
          </Box>
        </ErrorBoundary>
      </Box>
    );

    if (!includeBlob) {
      return currentLearnerButton;
    }

    return (
      <Box flex>
        <BackgroundBlob
          sx={theme => ({
            display: "none",
            [theme.breakpoints.up("sm")]: {
              display: "flex"
            },
            alignItems: "center",
            justifyContent: "flex-end"
          })}
        >
          {currentLearnerButton}
        </BackgroundBlob>
      </Box>
    );
  }
);

interface LearnerSwitcherRowProps {
  id?: string;
  onClick: () => void;
}

const LearnerSwitcherRow: React.FC<
  PropsWithChildren<LearnerSwitcherRowProps>
> = ({ id, onClick, children }) => {
  return (
    <Box
      component="button"
      id={id}
      role="menuitem"
      onClick={onClick}
      sx={{
        all: "unset",
        width: "100%",
        paddingY: "1em",
        cursor: "pointer",
        position: "relative",
        alignItems: "center",

        "&:hover, &:focus": {
          backgroundColor: "#ecf1f8"
        }
      }}
    >
      {children}
    </Box>
  );
};

interface SiblingRowProps {
  learner: CurrentLearnerFragmentFragment["siblings"][0];
  onClick(learner: CurrentLearnerFragmentFragment["siblings"][0]): void;
}

const SiblingRow: React.FC<SiblingRowProps> = ({ learner, onClick }) => {
  return (
    <LearnerSwitcherRow key={learner.uid} onClick={() => onClick(learner)}>
      <AvatarImage
        avatar={learner.avatar || DEFAULT_AVATAR}
        width={36}
        height={36}
        name={learner.name || ""}
        sx={{ position: "absolute", top: 0, bottom: 0, margin: "auto" }}
      />
      <Box
        sx={{
          fontWeight: 500,
          color: "grey.700",
          marginLeft: "48px"
        }}
      >
        {learner.name}
      </Box>
    </LearnerSwitcherRow>
  );
};

interface ParentRowProps {
  parent: CurrentLearnerFragmentFragment["parent"];
  onClick(parent: CurrentLearnerFragmentFragment["parent"]): void;
  id?: string;
}

const ParentRow: React.FC<ParentRowProps> = ({ parent, onClick, id }) => {
  return (
    <LearnerSwitcherRow
      key={parent.uid}
      id={id}
      onClick={() => onClick(parent)}
    >
      <ParentImage
        user={parent}
        size={36}
        sx={{ position: "absolute" as any, top: 0, bottom: 0, margin: "auto" }}
      />
      <Box
        sx={{
          fontWeight: 500,
          color: "grey.700",
          marginLeft: "48px"
        }}
      >
        {parent.name}
      </Box>
    </LearnerSwitcherRow>
  );
};

export const MobileLearnerSwitcher: React.FC<LearnerSwitcherProps> = ({
  handleLearnerClick,
  handleParentClick,
  sx
}) => {
  const currentLearner = useCurrentLearner()!;
  const [open, onOpen, onClose] = useBooleanState(false);

  return (
    <>
      <CurrentLearner
        learner={currentLearner}
        open={open}
        onClick={onOpen}
        sx={sx}
      />
      <LearnerSwitcherModal
        handleLearnerClick={handleLearnerClick}
        handleParentClick={handleParentClick}
        open={open}
        onClose={onClose}
      />
    </>
  );
};

export const ButtonLearnerSwitcher: React.FC<LearnerSwitcherProps> = ({
  handleLearnerClick,
  handleParentClick,
  buttonProps: { title, color, variant, sx, trackingName } = {},
  onlyParent
}) => {
  const [isSwitcherModalOpen, openSwitcherModal, closeSwitcherModal] =
    useBooleanState();
  const currentLearner = useCurrentLearner()!;
  const onButtonClick = useCallback(() => {
    if (onlyParent) {
      handleParentClick(currentLearner.parent);
    } else {
      openSwitcherModal();
    }
  }, [onlyParent, currentLearner.parent, handleParentClick, openSwitcherModal]);
  return (
    <>
      <TrackedButton
        variant={variant}
        color={color}
        sx={sx}
        onClick={onButtonClick}
        trackingName={trackingName}
      >
        {title ?? "Switch accounts"}
      </TrackedButton>
      <LearnerSwitcherModal
        handleLearnerClick={handleLearnerClick}
        handleParentClick={handleParentClick}
        open={isSwitcherModalOpen}
        onClose={closeSwitcherModal}
      />
    </>
  );
};

export const LearnerSwitcherModal: React.FC<
  LearnerSwitcherProps & {
    open?: boolean;
    onClose: () => void;
  }
> = ({ handleLearnerClick, handleParentClick, open, onClose }) => {
  const currentLearner = useCurrentLearner()!;
  const isLearnerLogin = useIsLearnerLogin();
  return (
    <Modal
      open={Boolean(open)}
      onClose={onClose}
      sx={{
        minHeight: "unset",
        padding: 16,
        marginX: 16
      }}
      fullBleed={false}
    >
      <Box
        role="menu"
        sx={{
          padding: 8
        }}
      >
        {isLearnerLogin ? (
          <LearnerMenuItems currentLearner={currentLearner} />
        ) : (
          <LearnerSwitcherItems
            currentLearner={currentLearner}
            handleLearnerClick={handleLearnerClick}
            handleParentClick={handleParentClick}
          />
        )}
      </Box>
    </Modal>
  );
};

function LearnerProfileLink({ learner }) {
  const Link = useLinkComponent();

  return (
    <Box
      sx={{
        paddingBottom: 8,
        marginBottom: 8
      }}
    >
      <Link
        to={Routes.profilePath(learner)}
        trackingName="learner_profile.learner_switcher.open_profile"
        data-test-id="LearnerMenuProfile"
        style={{ textDecoration: "none" }}
      >
        <LearnerProfileIcon learner={learner} />
      </Link>
    </Box>
  );
}

interface LearnerSwitcherButtonProps {
  title?: string | React.ReactElement;
  color?: ButtonColor;
  variant?: ButtonVariant;
  sx?: SxProps;
  trackingName?: string;
}

type OnLearnerClickFn = (
  sibling: CurrentLearnerFragmentFragment["siblings"][0]
) => void;
type OnParentClickFn = (
  parent: CurrentLearnerFragmentFragment["parent"]
) => void;

interface LearnerSwitcherProps {
  includeBlob?: boolean;
  handleLearnerClick: OnLearnerClickFn;
  handleParentClick: OnParentClickFn;
  buttonProps?: LearnerSwitcherButtonProps;
  onlyParent?: boolean;
  sx?: SxProps;
}

export const LearnerSwitcherItems = ({
  currentLearner,
  handleParentClick,
  handleLearnerClick
}: {
  currentLearner: CurrentLearnerFragmentFragment;
  handleParentClick: OnParentClickFn;
  handleLearnerClick: OnLearnerClickFn;
}) => {
  const path = usePath();
  return (
    <Box
      sx={{
        paddingX: 16
      }}
    >
      {hasAccess({
        currentLearner,
        minimumAccessLevel: LearnerAccessLevel.Full
      }) && <LearnerProfileLink learner={currentLearner} />}
      {hasAccess({
        currentLearner,
        minimumAccessLevel: LearnerAccessLevel.Limited
      }) && (
        <TrackedButton
          onClick={() =>
            navigate(Routes.reportIssuePath(), undefined, {
              [Routes.REPORT_ISSUE_REFERRER_PATH_NAME]: path
            })
          }
          trackingName={"learner_menu.help"}
          data-test-id="LearnerMenuHelp"
          fullWidth
        >
          Help
        </TrackedButton>
      )}
      <Typography variant="h5" sx={{ paddingTop: 16 }}>
        Switch to:
      </Typography>
      <ParentRow parent={currentLearner.parent} onClick={handleParentClick} />
      {_.sortBy(currentLearner.siblings, l => l.name).map(learner => (
        <SiblingRow
          key={learner.uid}
          learner={learner}
          onClick={handleLearnerClick}
        />
      ))}
    </Box>
  );
};

export const LearnerMenuItems = ({ currentLearner }) => {
  const { track } = useAnalytics();
  const { setSessionToken } = useLearnerAuth();
  const path = usePath();

  return (
    <Box
      sx={{
        paddingX: "1em"
      }}
    >
      {hasAccess({
        currentLearner,
        minimumAccessLevel: LearnerAccessLevel.Full
      }) && <LearnerProfileLink learner={currentLearner} />}
      <Button
        onClick={() => {
          navigate(Routes.settingsPath());
        }}
        sx={{
          width: "100%",
          marginBottom: "0.5em"
        }}
        data-test-id="LearnerMenuSettings"
      >
        Settings
      </Button>
      {hasAccess({
        currentLearner,
        minimumAccessLevel: LearnerAccessLevel.Limited
      }) && (
        <TrackedButton
          onClick={() =>
            navigate(Routes.reportIssuePath(), undefined, {
              [Routes.REPORT_ISSUE_REFERRER_PATH_NAME]: path
            })
          }
          trackingName={"learner_menu.help"}
          sx={{
            width: "100%",
            marginBottom: "0.5em"
          }}
          data-test-id="LearnerMenuHelp"
        >
          Help
        </TrackedButton>
      )}
      <Button
        onClick={() => {
          track("learner_login.logout");
          analytics.reset();
          setSessionToken(false);
          window?.location.replace(Routes.loginPath());
        }}
        sx={{
          width: "100%"
        }}
        data-test-id="LearnerMenuLogOut"
      >
        Log out
      </Button>
    </Box>
  );
};

export const DesktopLearnerSwitcher: React.FC<LearnerSwitcherProps> = ({
  handleParentClick,
  handleLearnerClick,
  includeBlob,
  sx
}) => {
  const currentLearner = useCurrentLearner()!;
  const isLearnerLogin = useIsLearnerLogin();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = !!anchorEl;
  const hide = () => setAnchorEl(null);

  return (
    <ClickAwayListener onClickAway={hide}>
      <Box>
        <CurrentLearner
          learner={currentLearner}
          open={isOpen}
          includeBlob={includeBlob}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(anchorEl ? null : e.currentTarget);
          }}
          sx={sx}
        />
        <Popper open={isOpen} anchorEl={anchorEl} sx={{ padding: 0 }}>
          <Box
            role="menu"
            aria-label="Switch to"
            sx={{
              paddingY: "1em",
              width: "200px"
            }}
          >
            {isOpen &&
              (isLearnerLogin ? (
                <LearnerMenuItems currentLearner={currentLearner} />
              ) : (
                <LearnerSwitcherItems
                  currentLearner={currentLearner}
                  handleLearnerClick={handleLearnerClick}
                  handleParentClick={handleParentClick}
                />
              ))}
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export const LearnerSwitcher: React.FC<{
  includeBlob?: boolean;
  redirect?: string;
  switcherType?: "header-dropdown" | "button";
  buttonProps?: LearnerSwitcherButtonProps;
  onlyParent?: boolean;
  title?: string;
  menuSx?: SxProps;
}> = ({
  includeBlob,
  switcherType,
  redirect,
  buttonProps,
  onlyParent,
  title,
  menuSx
}) => {
  const isMobile = Screen.useIsMobile();
  const switchToParentModeWithToken = useSwitchToParentMode(redirect);
  const { track } = useAnalytics();
  const isLearnerLogin = useIsLearnerLogin();
  const [passwordModalOpen, onPasswordModalOpen, onPasswordModalClose] =
    useBooleanState(false);

  // Modals for multiplication question for learners
  const [
    multiplicationModalOpen,
    onMultiplicationModalOpen,
    onMultiplicationModalClose
  ] = useBooleanState(false);
  const [selectedSiblingUid, setSelectedSiblingUid] = React.useState("");
  const currentLearner = useCurrentLearner();
  const isCurrentLearnerUnder13 =
    currentLearner?.age && currentLearner?.age < 13;
  const [
    createParentTokenAndRedirectMutation,
    { loading: loadingParentToken, error: parentTransferError }
  ] = useMutation<
    GetLearnerToParentTransferTokenMutation,
    GetLearnerToParentTransferTokenMutationVariables
  >(GetLearnerToParentTransferToken);
  const [parentTransferToken, setParentTransferToken] = React.useState("");

  const [
    createSiblingTokenAndRedirectMutation,
    { loading: loadingSiblingToken, error: siblingTransferError }
  ] = useMutation<
    GetLearnerToSiblingTransferTokenMutation,
    GetLearnerToSiblingTransferTokenMutationVariables
  >(GetLearnerToSiblingTransferToken);

  const switchViewsAfterMultiplication = () => {
    if (Boolean(selectedSiblingUid)) {
      createSiblingTokenAndRedirect({ siblingUid: selectedSiblingUid });
    } else {
      createParentTokenAndRedirect();
    }
  };

  const createParentTokenAndRedirect = async ({
    password,
    transferToken
  }: { password?: string; transferToken?: string } = {}) => {
    try {
      let transferTokenToUse = transferToken || parentTransferToken;
      if (password) {
        const { data } = await createParentTokenAndRedirectMutation({
          variables: { password }
        });
        const transferData = data?.learnerToParentTransferToken;
        if (!transferData?.transferToken) {
          console.error("No transferToken!", data);
          return;
        }
        const { transferToken: _transferToken } = transferData;
        transferTokenToUse = _transferToken;
      }
      if (password) {
        track("switch_to_parent_with_password_redirect", {
          ignoreNetworkError: true
        });
      } else {
        track("switch_to_parent_redirect", { ignoreNetworkError: true });
      }
      switchToParentModeWithToken(transferTokenToUse);
    } catch (e) {
      if (password) {
        track("switch_views_with_password_fail", {
          error: e.message
        });
      }
    }
  };

  const createSiblingTokenAndRedirect = async (
    variables: GetLearnerToSiblingTransferTokenMutationVariables
  ) => {
    try {
      const { data } = await createSiblingTokenAndRedirectMutation({
        variables
      });
      const transferData = data?.learnerToSiblingTransferToken;
      if (transferData?.transferToken) {
        track("switch_to_learner_redirect", { ignoreNetworkError: true });
        switchToLearnerModeWithToken({
          isReviewApp,
          transferToken: transferData?.transferToken,
          learnerAppUrl: Routes.dashboardPath()
        });
      }
    } catch (e) {}
  };

  const handleParentClick = async () => {
    track("switch_to_parent_touch", { ignoreNetworkError: true });
    const { data } = await createParentTokenAndRedirectMutation();
    const transferData = data?.learnerToParentTransferToken;
    if (!transferData) {
      console.error("No transferData!", transferData);
      return;
    }
    const { transferToken, learnerModeSwitchAuthRequirement } = transferData;
    if (
      learnerModeSwitchAuthRequirement ===
        LearnerModeSwitchAuthRequirement.Password ||
      isLearnerLogin
    ) {
      onPasswordModalOpen();
    } else if (isCurrentLearnerUnder13) {
      setParentTransferToken(transferToken || "");
      onMultiplicationModalOpen();
    } else if (transferToken) {
      setParentTransferToken(transferToken);
      createParentTokenAndRedirect({ transferToken });
    } else {
      console.error("Invalid transferData!", transferData);
    }
  };

  const handleLearnerClick = (
    sibling: CurrentLearnerFragmentFragment["siblings"][0]
  ) => {
    track("switch_to_sibling_touch", { ignoreNetworkError: true });
    setSelectedSiblingUid(sibling.uid);
    // If age is null, we'll make the assumption that sibling is over 13
    const isSiblingOver13 = sibling?.age ? sibling.age >= 13 : true;
    if (isCurrentLearnerUnder13 && isSiblingOver13) {
      onMultiplicationModalOpen();
    } else {
      createSiblingTokenAndRedirect({ siblingUid: sibling.uid });
    }
  };

  if (!currentLearner) {
    return null;
  }

  const Switcher =
    switcherType === "button" ? (
      <ButtonLearnerSwitcher
        handleParentClick={handleParentClick}
        handleLearnerClick={handleLearnerClick}
        buttonProps={buttonProps}
        onlyParent={onlyParent}
      />
    ) : isMobile ? (
      <MobileLearnerSwitcher
        handleParentClick={handleParentClick}
        handleLearnerClick={handleLearnerClick}
        sx={menuSx}
      />
    ) : (
      <DesktopLearnerSwitcher
        includeBlob={includeBlob}
        handleParentClick={handleParentClick}
        handleLearnerClick={handleLearnerClick}
        sx={menuSx}
      />
    );

  return (
    <>
      {Switcher}
      <PasswordModal
        switchViews={password => createParentTokenAndRedirect({ password })}
        open={passwordModalOpen}
        onClose={onPasswordModalClose}
        loading={loadingSiblingToken || loadingParentToken}
        error={parentTransferError?.message || siblingTransferError?.message}
        title={title}
      />
      <MultiplicationModal
        switchViews={switchViewsAfterMultiplication}
        open={multiplicationModalOpen}
        onClose={onMultiplicationModalClose}
        loading={loadingSiblingToken || loadingParentToken}
        error={parentTransferError?.message || siblingTransferError?.message}
        title={title}
      />
    </>
  );
};
