import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  LearnerSearchTeacherActivitiesQuery,
  LearnerSearchTeacherActivitiesQueryVariables
} from "../generated/graphql";
import LearnerActivitySearchResultFragment from "./LearnerActivitySearchResultFragment";

export const learnerSearchTeacherActivitiesQuery = gql`
  query LearnerSearchTeacherActivities($teacherUid: ID!) {
    learnerSearchTeacherActivities(teacherUid: $teacherUid) {
      cursor
      activities {
        ...LearnerActivitySearchResultFragment
      }
    }
  }
  ${LearnerActivitySearchResultFragment}
`;

export function useLearnerSearchTeacherActivitiesQuery(
  variables: LearnerSearchTeacherActivitiesQueryVariables
) {
  const { loading, error, data } =
    useQueryWithPreviousData<LearnerSearchTeacherActivitiesQuery>(
      learnerSearchTeacherActivitiesQuery,
      {
        fetchPolicy: "cache-and-network",
        variables
      }
    );
  return {
    loading,
    error,
    activities: data?.learnerSearchTeacherActivities.activities
  };
}
