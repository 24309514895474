import {
  OAUTH2_ERROR,
  OAUTH2_ON_ERROR,
  OAUTH2_ON_SUCCESS,
  OAUTH2_SESSION_TOKEN,
  decodeToken,
  isParentAuthTransfer
} from "@outschool/auth-shared";
import { gql, useMutation } from "@outschool/ui-apollo";
import {
  AppleAuthResponse,
  LoginErrorOrResultFragmentV2
} from "@outschool/ui-auth";
import Cookies from "js-cookie";
import React from "react";

import { CreateOrLoginError } from "../generated/graphql";
import { useLearnerAuth } from "../providers/LearnerAuthProvider";

export interface AppleLoginSuccessPayload {
  sessionToken: string;
  refreshToken: string;
  isParentTransfer: boolean;
}

export function useLearnerLoginWithRedirect(
  onError: (error: CreateOrLoginError | undefined) => void,
  postLoginUrl: string
) {
  const learnerAuth = useLearnerAuth();
  React.useEffect(() => {
    const authError = Cookies.get(OAUTH2_ERROR);
    if (onError && authError) {
      Cookies.remove(OAUTH2_ERROR);
      onError(authError as CreateOrLoginError);
    }
  }, [onError]);

  React.useEffect(() => {
    Cookies.set(OAUTH2_ON_ERROR, window.location.href);
    Cookies.set(OAUTH2_ON_SUCCESS, postLoginUrl);
    if (learnerAuth) {
      const sessionToken =
        learnerAuth.sessionTokenStore?.getSessionToken() ?? null;
      if (sessionToken) {
        Cookies.set(OAUTH2_SESSION_TOKEN, sessionToken);
      } else {
        Cookies.remove(OAUTH2_SESSION_TOKEN);
      }
    }
  }, [postLoginUrl, learnerAuth]);
}

const LoginLearnerWithAppleV2 = gql`
  mutation LoginLearnerWithApple($code: String!) {
    loginOrCreateAccountWithAppleV2(code: $code, canCreateAccount: false) {
      ...LoginErrorOrResultFragmentV2
    }
  }
  ${LoginErrorOrResultFragmentV2}
`;

export function useLoginWithApple(
  onSuccess: (payload: AppleLoginSuccessPayload) => void,
  onError: (error: CreateOrLoginError) => void
) {
  // TODO: replace with generated types when they are back in sync.
  const [loginWithAppleV2] = useMutation<any, any>(LoginLearnerWithAppleV2);

  return React.useCallback(
    async (response: AppleAuthResponse) => {
      const { data } = await loginWithAppleV2({
        variables: {
          code: response.authorization.code
        }
      });
      const result = data?.loginOrCreateAccountWithAppleV2;
      if (result?.__typename === "LoginError") {
        onError(result.error);
      } else if (result) {
        const {
          authentication: { sessionToken, refreshToken }
        } = result;

        const decodedToken = decodeToken(sessionToken);
        const isParentTransfer = isParentAuthTransfer(decodedToken);
        onSuccess({ sessionToken, refreshToken, isParentTransfer });
      }
    },
    [onError, onSuccess, loginWithAppleV2]
  );
}
