import { Box, Typography } from "@outschool/backpack";
import { Loading } from "@outschool/ui-legacy-component-library";
import React from "react";
import { useAnalytics } from "use-analytics";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { TeacherRecommendationType } from "../../generated/graphql";
import { useRecommendedTeachersQuery } from "../../queries/LearnerRecommendedTeachersQuery";
import TeacherCard from "./TeacherCard";

function TeachersYouKnow() {
  const TEACHER_DISPLAY_LIMIT = 4;
  const { loading, teachers, teacherRecommendationType } =
    useRecommendedTeachersQuery({ limit: TEACHER_DISPLAY_LIMIT });
  const { track } = useAnalytics();

  React.useEffect(() => {
    if (teacherRecommendationType) {
      track(`browse.teacher_section_view`, {
        teacherRecommendationType
      });
    }
  }, [teacherRecommendationType, track]);

  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      flex
      data-test-id="recommended-teachers"
      sx={theme => ({
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100vw",
        marginBottom: "2em",
        backgroundColor: "common.white",

        [theme.breakpoints.up("md")]: {
          backgroundColor: "#f8f8f9",
          marginTop: "48px"
        },

        marginTop: "1em"
      })}
    >
      <Typography
        variant="h2"
        sx={theme => ({
          marginTop: "1em",

          [theme.breakpoints.up("md")]: {
            marginTop: "32px"
          },

          marginBottom: "1em"
        })}
      >
        {teacherRecommendationType ===
        TeacherRecommendationType.CurrentLearnersFavoriteTeachers
          ? "Teachers you know"
          : teacherRecommendationType ===
              TeacherRecommendationType.PopularAndCurrentLearnerFavoriteTeachers ||
            teacherRecommendationType ===
              TeacherRecommendationType.SimilarTeachers
          ? "Teachers you might like"
          : "Popular teachers"}
      </Typography>
      <Box
        sx={theme => ({
          display: "flex",

          [theme.breakpoints.up("md")]: {
            display: "grid",
            flexDirection: "initial",
            gridTemplateColumns: "repeat(2, auto)"
          },

          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          gridTemplateColumns: "auto",

          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4, auto)"
          }
        })}
      >
        {teachers.map((teacher, index) => (
          <TeacherCard key={teacher.uid} teacher={teacher} index={index} />
        ))}
      </Box>
    </Box>
  );
}

export default TeachersYouKnow;
