import {
  InitOptions,
  i18next,
  initReactI18next
} from "@outschool/localization";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import resourcesToBackend from "i18next-resources-to-backend";

import { isTest } from "../Env";

/* PLEASE KEEP THIS FILE IN SYNC WITH i18n.ts in website */
/* This is currently out of sync with website because learnerApp does not require a userSelectedLocale */

const i18nOpts: InitOptions = {
  debug: false,
  fallbackLng: "en",
  // the default, load: "all", will load variants we may not have
  // ex: when lng = "zh-TW", it will try to load "zh" and "zh-TW"
  load: "currentOnly",
  ns: [
    "client",
    "shared",
    "ui-components-shared",
    "ui-components-classroom",
    "ui-components-website",
    "learnerApp"
  ],
  defaultNS: "client",
  keySeparator: "\\",
  nsSeparator: "\\",
  // This next line is necessary unless we want to polyfill Intl.PluralRules
  // https://www.i18next.com/misc/json-format#i18next-json-v4
  compatibilityJSON: "v3",
  // This function is called when `t` can't find a key, either because
  // the key doesn't exist in JSON, or because there's no JSON file.
  // `key` is the string passed into `t`, including the keyPrefix
  // `defaultValue` is the prop of the same name from `t` options
  parseMissingKeyHandler(key, defaultValue) {
    // This regex removes the prefix from the `key`
    // E.g. client\Button\Hello there! --> Hello there!
    return (defaultValue ?? key).replace(/.*\\([^\\]+)$/, "$1");
  },
  interpolation: {
    // React already escapes, so we don't want to double escape
    escapeValue: false
  },
  react: {
    // This option is not supported by `i18next-parser`, so we turn it off
    // (Open issue: https://github.com/i18next/i18next-parser/issues/264)
    transSupportBasicHtmlNodes: false
  }
};

const englishBackend = resourcesToBackend((language, ns, callback) => {
  import(`../../../../../../../locales/${language}/${ns}.json`)
    .then(({ default: resources }) => {
      callback(null, resources);
    })
    .catch(error => {
      callback(error, null);
    });
});

i18next.use(LanguageDetector).use(initReactI18next);

if (!isTest) {
  i18next.use(ChainedBackend);
  i18nOpts.backend = {
    backends: [],
    backendOptions: []
  };

  if (
    false //TODO turn on translation for LearnerApp
  ) {
    /* i18nOpts.backend.backends = [HttpApi, englishBackend];
    i18nOpts.backend.backendOptions = [
      {
        loadPath: (languages, namespaces) => {
          const [language] = languages;
          const [namespace] = namespaces;
          const ns = namespace.split("\\").shift();
          return ""; //TODO turn on translation for LearnerApp `${TRANSLATION_SERVICE_URL}/${ns}+(${language}).json`;
        }
      }
    ]; */
  } else {
    (i18nOpts.backend as any).backends = [englishBackend];
  }
}

i18next.init(i18nOpts);

export default i18next;
