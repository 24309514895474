import { fetchLearnerExpAssignments } from "@outschool/experiments-shared";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Component } from "@outschool/ownership-areas";
import React from "react";

import * as Env from "../Env";
import { useLearnerAuth } from "./LearnerAuthProvider";

const ExperimentsServiceContext = React.createContext<{
  experimentAssignments: Record<string, string>;
  osEnv: string;
}>({
  experimentAssignments: {},
  osEnv: ""
});

async function fetchExperimentAssignments(learnerUid?: string) {
  if (!Env.isTest && !!learnerUid) {
    try {
      const assignmentResponse = await fetchLearnerExpAssignments({
        fetchFn: fetch,
        learnerUid,
        experimentsApiKey: Env.EXPERIMENTS_API_KEY,
        experimentsServiceUrl: Env.EXPERIMENTS_SERVICE_URL,
        timeoutMs: Env.EXPERIMENT_ASSIGNMENTS_MAX_REQ_TIME_MS
      });
      return assignmentResponse;
    } catch (err) {
      OsPlatform.captureError(err, {
        tags: {
          component: Component.ExperimentationFramework
        }
      });
      return {};
    }
  }
  return {};
}

export const ExperimentsServiceProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const { learnerAuth } = useLearnerAuth();
  const learnerUid = learnerAuth?.learnerUid;

  const [experimentAssignments, setExperimentAssignments] = React.useState<
    Record<string, string>
  >(Env.EXPERIMENT_ASSIGNMENTS);

  React.useEffect(() => {
    async function fetch() {
      const assignments = await fetchExperimentAssignments(learnerUid);
      setExperimentAssignments(assignments);
    }
    fetch();
  }, [learnerUid]);

  return (
    <ExperimentsServiceContext.Provider
      value={{
        experimentAssignments,
        osEnv: Env.OUTSCHOOL_ENV
      }}
    >
      {children}
    </ExperimentsServiceContext.Provider>
  );
};

export function useServerExperimentContext() {
  return React.useContext(ExperimentsServiceContext);
}
