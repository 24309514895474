import { LearnerAccessLevel } from "../generated/graphql";

interface HasAccessProps {
  currentLearner: {
    accessLevel: LearnerAccessLevel;
  };
  minimumAccessLevel: LearnerAccessLevel;
}

export const accessLevels: { [key in LearnerAccessLevel]: number } = {
  [LearnerAccessLevel.Restricted]: 0,
  [LearnerAccessLevel.Limited]: 1,
  [LearnerAccessLevel.Full]: 2
};

export function hasAccess({
  currentLearner: { accessLevel },
  minimumAccessLevel
}: HasAccessProps) {
  return accessLevels[accessLevel] >= accessLevels[minimumAccessLevel];
}
