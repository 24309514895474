import { Box, Button, Typography } from "@outschool/backpack";
import {
  MarkLessonCompleteMutation,
  MarkLessonCompleteMutationVariables
} from "@outschool/gql-frontend-generated";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import * as Time from "@outschool/time";
import { gql, useMutation } from "@outschool/ui-apollo";
import {
  PlayerOrProcessingMessage,
  useMuxClassRecordingQuery
} from "@outschool/ui-components-classroom";
import { ErrorMessage } from "@outschool/ui-components-shared";
import {
  Loading,
  Space,
  Subscript,
  TrackingPage
} from "@outschool/ui-legacy-component-library";
import { useBooleanState } from "@outschool/ui-utils";
import React from "react";
import { useAnalytics } from "use-analytics";

import Link from "../../components/Link";
import useAnalyticsPage, {
  PageType
} from "../../lib/analytics/useAnalyticsPage";
import * as Routes from "../../lib/Routes";
import { useCurrentLearner } from "../../providers/CurrentLearnerProvider";
import { useLearnerClassroomContext } from "../../providers/LearnerClassroomProvider";

const TermsAcceptance = ({ handleTermsAccepted }) => (
  <Box
    sx={{
      backgroundColor: "#f1f2f3",
      marginBottom: "1em",
      paddingX: "1em",
      paddingY: "2em",
      textAlign: "center"
    }}
  >
    <Box>
      Families' privacy is of utmost importance.
      <Space y="medium" />
      Please confirm that you agree not to share this recording with anyone.
    </Box>
    <Space y="medium" />
    <Button onClick={handleTermsAccepted}>I agree</Button>
  </Box>
);

export default function RecordingPage({
  recordingUid
}: {
  recordingUid: string;
}) {
  const { sectionUid, hasLessons } = useLearnerClassroomContext();

  const currentLearner = useCurrentLearner();
  const [termsAccepted, setTermsAccepted] = useBooleanState(false);
  useAnalyticsPage({
    pageName: PageType.Recording,
    pageParams: { sectionUid, recordingUid }
  });
  const { track } = useAnalytics();
  const [markLessonComplete] = useMutation<
    MarkLessonCompleteMutation,
    MarkLessonCompleteMutationVariables
  >(MarkLessonCompleteForRecording, {
    variables: { recordingUid }
  });

  const userTimeZone = currentLearner?.timeZone || Time.guessBrowserTimeZone();
  const { loading, error, isExpired, activity, section, startTime, endTime } =
    useMuxClassRecordingQuery({
      sectionUid,
      recordingUid
    });

  if (error || isExpired) {
    return (
      <ErrorMessage
        value={
          error ||
          "This recording is no longer available.  Outschool retains recordings for three months."
        }
      />
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (!section) {
    OsPlatform.captureError("Recording page loaded but section is not defined");
    return (
      <ErrorMessage value="Oops, we encountered an error when loading this recording." />
    );
  }

  const handleTermsAccepted = () => {
    if (termsAccepted) {
      return;
    }
    track("accept_recording_terms", {
      activityUid: activity?.uid,
      sectionUid: section?.uid,
      recordingId: recordingUid
    });
    setTermsAccepted();
  };

  const handlePlayClick = () => {
    if (hasLessons) {
      markLessonComplete();
    }
  };

  return (
    <TrackingPage
      name="learnerClassRecording"
      uniqueId={recordingUid}
      activity={activity}
      section={section}
    >
      {setImpressionNode => (
        <Box ref={node => setImpressionNode(node)} sx={{ marginTop: "1rem" }}>
          <Typography variant="h2">Class recording</Typography>
          <Subscript>
            {startTime &&
              endTime &&
              Time.pastDurationStringWithTimes(
                startTime,
                endTime,
                userTimeZone
              )}
          </Subscript>
          <Link to={Routes.classroomPath(sectionUid)}>Back to classroom</Link>
          <Space y="medium" />
          {termsAccepted && (
            <Box>You have agreed not to share this recording with anyone.</Box>
          )}
          <Space y="medium" />
          {termsAccepted ? (
            <Box
              flex
              sx={{
                justifyContent: "center"
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  overflow: "auto"
                }}
              >
                <PlayerOrProcessingMessage
                  sectionUid={sectionUid}
                  recordingUid={recordingUid}
                  handlePlayClick={handlePlayClick}
                />
                <Space y="medium" />
              </Box>
            </Box>
          ) : (
            <TermsAcceptance handleTermsAccepted={handleTermsAccepted} />
          )}
        </Box>
      )}
    </TrackingPage>
  );
}

const MarkLessonCompleteForRecording = gql`
  mutation MarkLessonComplete($recordingUid: ID!) {
    markLessonCompleteFromClassRecording(classRecordingUid: $recordingUid)
  }
`;
