import { LearnersRow } from "@outschool/db-queries";
import { LearnerAccessLevel } from "@outschool/gql-backend-generated";
import { dayjs } from "@outschool/time";
import _ from "lodash";

export function pronounIsSpecified(pronoun: string | null | undefined) {
  return !!pronoun;
}

const currentYear = dayjs().year();

export const yearOptions = _.range(currentYear - 18, currentYear - 2);

export function hasConfirmedEmail(
  learner: Pick<LearnersRow, "email" | "email_confirmed_at">
) {
  return !!learner && !!learner.email && !!learner.email_confirmed_at;
}

export function countText(num: number) {
  let text = "";
  if (num === 1) {
    text = "1 learner";
  } else {
    text = num + " learners";
  }
  return text;
}

interface HasAccessProps {
  currentLearner: {
    accessLevel: LearnerAccessLevel;
  };
  minimumAccessLevel: LearnerAccessLevel;
}

export const accessLevels: { [key in LearnerAccessLevel]: number } = {
  [LearnerAccessLevel.Restricted]: 0,
  [LearnerAccessLevel.Limited]: 1,
  [LearnerAccessLevel.Full]: 2
};

export function hasAccess({
  currentLearner: { accessLevel },
  minimumAccessLevel
}: HasAccessProps) {
  return accessLevels[accessLevel] >= accessLevels[minimumAccessLevel];
}
